<div class="close-section">
    <mat-dialog-actions>
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
    </mat-dialog-actions>
</div>
<div class="main-container">
    <div class="contents">
        <span class="survey-note">Review survey</span>
        <div class="survey-info">
            <div *ngFor="let obj of surveyDetails; index as i" class="qa-container">                            
                <div class="survey-ques">{{i+1}}. {{obj.label}}</div>
                <div class="survey-ans" *ngIf="obj.type === 'radio'">
                    <span *ngFor="let options of obj.values">
                        <span *ngIf="options.key === obj.selected_values">
                            {{options.value}}
                        </span>
                    </span>
                </div>
                <div class="survey-ans" *ngIf="obj.type === 'checkbox'">
                    <ul>
                        <li *ngFor="let options of obj.values">
                            <span *ngIf="options.key === obj.selected_values">
                                {{options.value}}
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="survey-ans" *ngIf="obj.type === 'textarea'">
                    {{obj.selected_values}}
                </div>
                <div class="survey-ans" *ngIf="obj.type === 'textbox'">
                    {{obj.selected_values}}
                </div>
            </div>
        </div>
    </div>
</div>
