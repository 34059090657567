import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { AuthService } from '../auth/auth.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';
import { config } from '../config';

@Component({
  selector: 'app-themeing',
  templateUrl: './themeing.component.html',
  styleUrls: ['./themeing.component.sass']
})
export class ThemeingComponent implements OnInit {
  selectedFile:any = '';
  selectedItem:string = '';
  customerData:any = {
    themecolour: ''
  };
  companyDetails$:any = {};
  imageLoading = false;
  constructor(private commonService: CommonService, private ui: UiService,
    private logService: LogService, private httpService: HttpService,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    const routes:any = [{
      name: 'Themeing',
      path: 'themeing'
    }];
    this.commonService.gaEvent('themeing', 'themeing', 'Theme Screen');

    this.commonService.setHeaderData(routes);
    this.authService.onCompanyDetails.subscribe(result => {
      this.companyDetails$ = result;
    });
  }

  onAction(item:any) {
    this.customerData[this.selectedItem] = item.value;
    this.selectedItem = '';
    this.ui.spin$.next(false);
  }

  onItemSelect(val:string) {
    this.selectedItem = val;
  }

  uploadImage(fileInputEvent: any) {
    this.selectedFile = fileInputEvent.target.files[0];
  }

  onSubmitTheme() {
    const formData = new FormData();
    formData.append("color_code", this.customerData.themecolour);
    formData.append("logo", this.selectedFile, `${new Date().getTime()}.jpg`);

    this.imageLoading = true;
    this.httpService.putCall(`${config.apiUrl}/companyadmin/updatecompany/${this.companyDetails$.id}`, formData).subscribe((data:any) => {
      const time = new Date().getTime();
      // this.userData.avatar = `${this.imgDomainUrl}${data.avatar}?t=${time}`;
      const obj = {
        data: {
          avatar: data.avatar,
        },
        type: 'image',
        isSave: true
      };
      this.onAction(obj);
    },
    (err:any) => {
      this.logService.logError(err);
    });
  }

}
