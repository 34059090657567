<div class="main-container">
    <div class="header">
        Exercises Uploader
    </div>
    <div>
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="Create">
                <app-exercisesuploadercreate #createTemplates  [exercisesCategories]="exercisesCategories" ></app-exercisesuploadercreate>
            </mat-tab>
            <mat-tab label="Edit">
                <app-exercisesuploaderedit #editTemplates [exercisesCategories]="exercisesCategories" ></app-exercisesuploaderedit>
            </mat-tab>
            <mat-tab label="Categories">
                <app-exercisesuploadercategories [exercisesCategories]="exercisesCategories" ></app-exercisesuploadercategories>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>