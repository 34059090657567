<h1 mat-dialog-title>Create Company</h1>
<div mat-dialog-content class="dialogContent" >
    <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>Company Name</mat-label>
        <input  matInput [(ngModel)]="data.name" required="true">
      </mat-form-field>
      <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>First Name</mat-label>
        <input  matInput [(ngModel)]="data.firstname" required="true">
      </mat-form-field>
      <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>Last Name</mat-label>
        <input  matInput [(ngModel)]="data.lastname" required="true">
      </mat-form-field>
      <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>Email</mat-label>
        <input  matInput [(ngModel)]="data.email" required="true">
      </mat-form-field>
      <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>Password</mat-label>
        <input type="password"  matInput [(ngModel)]="data.password" required="true">
      </mat-form-field>
      <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>Company Domain</mat-label>
        <input type="text"  matInput [(ngModel)]="data.company_url" required="true">
      </mat-form-field>
      <!-- <div class="create-company-full-width">
        <mat-label>Company Logo</mat-label>
        <input type="file">
      </div> -->
      <!-- <mat-form-field class="columns" appearance="fill">
        <mat-label *ngIf="data.selectedFile; else newFile">{{data.selectedFile.item(0).name}}</mat-label>
        <ng-template #newFile>
            <mat-label>Add Company Logo</mat-label>
        </ng-template>
        <input matInput disabled>
        <button mat-icon-button matSuffix (click)="fileInput.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
        <input hidden (change)="selectFile($event)" #fileInput type="file" id="file">
    </mat-form-field> -->
</div>
<div mat-dialog-actions class="dialogActions text-right">
    <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" [mat-dialog-close]="data"
    cdkFocusInitial [disabled]="!data.company_url || !data.password || !data.email || !data.lastname || !data.firstname || !data.name">Save</button>
</div>
