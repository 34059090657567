<div>
    <div class="col-33 padding-5">
        <div class="marginBottom">Name</div>
        <div>
            <app-selectinput placeholder="Name" type="nutritionName" [itemValue]="nutritionName"
                (action)="onAction($event)"></app-selectinput>
        </div>
    </div>
    <div class="select-ddl col-33 padding-5">
        <div>Nutrition Program Category</div>
        <mat-form-field class="width-full">
            <mat-label>Nutrition Program Category</mat-label>
            <mat-select [(ngModel)]="selected_nutrition_category" (ngModelChange)="onChangeNutritionCategory()">
                <mat-option *ngFor="let data of nutritionCategories" [value]="data.id">{{data.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <div class="col-33 inlineBlock padding-5">
            <app-selectinput placeholder="New category..." type="newNutritionCategory"
                [itemValue]="newNutritionCategory" (action)="onAction($event)"></app-selectinput>
        </div>
        <div class="select-ddl col-33 inlineBlock padding-5">
            <mat-form-field class="width-full">
                <mat-label>Make subcategory of...</mat-label>
                <mat-select [(ngModel)]="selected_sub_nutrition_category"
                    (ngModelChange)="onChangeNutritionSubCategory()">
                    <mat-option *ngFor="let data of nutritionCategories" [value]="data.id">{{data.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div>Description</div>
        <textarea [(ngModel)]="description"></textarea>
    </div>

    <div cdkDropListGroup>
        <div>
            <div class="add-plan-input">
                <app-selectinput placeholder="New plan name" type="text" [itemValue]="newPlanName" type="newPlanName"
                    (action)="onAction($event)"></app-selectinput>
            </div>
            <div class="btn-add">
                <button color="primary" (click)="onAddPlan()" class="spec-btn"
                    [ngClass]="{'btn-active': newPlanName.length > 0}" [disabled]="newPlanName.length === 0">Add
                    Plan</button>
            </div>
        </div>

        <div class="col-30 side-nutri-options">
            <div (window:scroll)="onSideScroll($event)" [ngClass]="{'fixed-div': fixedDiv}">
                <div class="search-container">
                    <div class="input-container">
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text" placeholder="Meal / Food filter" class="search-input" autocomplete="off"
                            (input)="filterChanged($event)" />
                    </div>
                </div>

                <div>
                    <div>
                        <div cdkDropList [cdkDropListData]="dataSource" class="matCard">
                            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
                                    matTreeNodePaddingIndent="0" cdkDrag [cdkDragData]="node" class="cursorPointer">
                                    <button mat-icon-button disabled></button>
                                    {{node.name}}
                                </mat-tree-node>
                                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                                    matTreeNodePaddingIndent="10" cdkDrag [cdkDragData]="node" cdkDragDisabled="true">
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                        {{node.name}}
                                    </button>
                                </mat-tree-node>
                            </mat-tree>
                        </div>
                    </div>
                </div>
                <div class="matCard matcard_plan_total" *ngIf="mealPlans.length > 0"
                    [ngClass]="{'fixed-bottom-div': fixedDiv}">
                    <div>
                        <div class="plan_totals_hdr">Plan Totals</div>
                        <div>
                            <div class="plan_totals">
                                <div>
                                    Calories:
                                </div>
                                <div>
                                    {{commonService.roundValues(sideTotal.calories)}}
                                </div>
                            </div>
                            <div class="plan_totals">
                                <div>
                                    Protein:
                                </div>
                                <div>
                                    {{commonService.roundValues(sideTotal.protein)}} ({{sideTotal.protein_pct}}%)
                                </div>
                            </div>
                            <div class="plan_totals">
                                <div>
                                    Carbs:
                                </div>
                                <div>
                                    {{commonService.roundValues(sideTotal.carbs)}} ({{sideTotal.carbs_pct}}%)
                                </div>
                            </div>
                            <div class="plan_totals">
                                <div>
                                    Fat:
                                </div>
                                <div>
                                    {{commonService.roundValues(sideTotal.fat)}} ({{sideTotal.fat_pct}}%)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-70">

            <div>
                <div class="meal-sections">
                    <button class="meal-btn" (click)="onMealSelection(i)"
                        [ngClass]="i === selectedMealIndex ? 'active_meal' : ''" [title]="item.name"
                        *ngFor="let item of mealPlans; let i = index">{{item.name}}</button>
                </div>
            </div>
            <div>
                <section class="week-section" *ngIf="mealPlans.length > 0">
                    <div>
                        <div></div>
                        <div class="cursorPointer" (click)="onSelectAllDays()">
                            <mat-icon *ngIf="!mealPlans[selectedMealIndex].isAllWeeksSelected">done</mat-icon>
                            <mat-icon color="primary" *ngIf="mealPlans[selectedMealIndex].isAllWeeksSelected">check_circle</mat-icon>
                        </div>
                    </div>
                    <div *ngFor="let item of mealPlans[selectedMealIndex].weeks">
                        <div>{{item.value}}</div>
                        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="item.checked" (change)="onWeekSelection()"></mat-checkbox>
                    </div>
                    <div class="deletePlan cursorPointer" title="Delete Plan" (click)="deletePlan()">
                        Delete Plan
                    </div>
                </section>
                <div *ngIf="mealPlans.length > 0">
                    <div *ngFor="let item of mealPlans[selectedMealIndex].meals; let i = index;" class="matCard">
                        <div class="spec-card">
                            <div>
                                <div class="spec-program">
                                    <form class="example-form">
                                        <div class="inp-section">
                                            <input type="text" [placeholder]="item.name" [matAutocomplete]="auto"
                                                class="meal_plan_input" (blur)="onSelectMealPlan(i, $event)" />
                                        </div>
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let rec of specificSessionPlan" [value]="rec"
                                                (click)="onChangeMealType(i, rec)">
                                                {{rec}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </form>
                                </div>
                                <div class="time-container">
                                    <input type="time" placeholder="Add Food" (change)="onChangeMealType(i)"
                                        [(ngModel)]="item.mealtime" class="time-input" autocomplete="off"
                                        (change)="timeChange($event)" />
                                </div>
                                <div class="inline floatRight">
                                    <div class="inline cursorPointer" (click)="copyMealSection(i)" title="Copy">
                                        <img alt="Copy" src="../../assets/icons/paperplus.svg" />
                                    </div>
                                    <div class="inline cursorPointer color_red" (click)="deleteMealPlan(item, i)"
                                        title="Delete">
                                        <mat-icon>delete</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div cdkDropList [cdkDropListData]="item.mealfoods" (cdkDropListDropped)="drop($event, i)">
                                <div class="nutri-header">
                                    <div class="col-5"></div>
                                    <div class="col-30">Name</div>
                                    <div class="col-10">Qty</div>
                                    <div class="col-10">Unit</div>
                                    <div class="col-7">Cals</div>
                                    <div class="col-7">Protn</div>
                                    <div class="col-7">Carbs</div>
                                    <div class="col-7">Fat</div>
                                </div>
                                <div class="nutri-items" *ngFor="let foods of item.mealfoods; let j = index;" cdkDrag>
                                    <div class="col-5">
                                        <mat-icon class="menu_item_icon">menu</mat-icon>
                                    </div>
                                    <div class="col-30 add-nutrition">
                                        <form class="example-form">
                                            <div class="inp-section">
                                                <input type="text"
                                                    [placeholder]="foods.name ? foods.name : 'Drag food or type'"
                                                    [matAutocomplete]="auto" (change)="onSearchNutrition($event)"
                                                    (keyup)="onSearchNutrition($event)" [formControl]="nutritionCtrl"
                                                    [(ngModel)]="foods.displayName" />
                                            </div>
                                            <mat-autocomplete #auto="matAutocomplete" [panelWidth]="350">
                                                <mat-option *ngFor="let item of nutrition" [value]="item.item_name"
                                                    (click)="onSelectNutrition(item, i, j)">
                                                    <span class="nutrition_item_image">
                                                        <img alt="Nutrition Image" [src]="item.thumbnail"
                                                            class="nutrition_search_image" />
                                                    </span>
                                                    <span class="nutrition_item_name">
                                                        {{item.item_name}}
                                                        {{item.serving_qty}} {{item.serving_uom}}
                                                    </span>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </form>
                                    </div>
                                    <div class="col-10">
                                        <app-selectinput placeholder="Quantity" [indexValue]="i" [setsValue]="j"
                                            [itemValue]="foods.qty" type="qty" (action)="onAction($event)">
                                        </app-selectinput>
                                    </div>
                                    <div class="col-10">
                                        <app-selectinput placeholder="Unit" [indexValue]="i" [setsValue]="j"
                                            [itemValue]="foods.unit" type="unit" (action)="onAction($event)"
                                            [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled">
                                        </app-selectinput>
                                    </div>
                                    <div class="col-7">
                                        <app-selectinput placeholder="Cals" [indexValue]="i" [setsValue]="j"
                                            [itemValue]="foods.calories" type="calories" (action)="onAction($event)"
                                            [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled">
                                        </app-selectinput>
                                    </div>
                                    <div class="col-7">
                                        <app-selectinput placeholder="Protn" [indexValue]="i" [setsValue]="j"
                                            [itemValue]="foods.protein" type="protein" (action)="onAction($event)"
                                            [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled">
                                        </app-selectinput>
                                    </div>
                                    <div class="col-7">
                                        <app-selectinput placeholder="Carbs" [indexValue]="i" [setsValue]="j"
                                            [itemValue]="foods.carbs" type="carbs" (action)="onAction($event)"
                                            [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled">
                                        </app-selectinput>
                                    </div>
                                    <div class="col-7">
                                        <app-selectinput placeholder="Fat" [indexValue]="i" [setsValue]="j"
                                            [itemValue]="foods.fat" type="fat" (action)="onAction($event)"
                                            [isDisabled]="foods.enabled ? !foods.enabled : itemDisabled">
                                        </app-selectinput>
                                    </div>
                                    <div class="col-15 action_icons">
                                        <div class="cursorPointer icon_position" (click)="copyFoodItem(foods, i)">
                                            <img alt="Copy" title="Copy" src="../../assets/icons/paperplus.svg" />
                                        </div>
                                        <div class="cursorPointer icon_position" (click)="addNoteFoodItem(i, j, foods)"
                                            title="Note">
                                            <mat-icon>note</mat-icon>
                                        </div>
                                        <div class="cursorPointer color_red icon_position" title="Delete"
                                            (click)="deleteMealFood(foods, i, j)">
                                            <mat-icon>close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="cursorPointer" (click)="addFoodItem(i)">
                                    <mat-icon>add_box</mat-icon>
                                </div>
                                <div>
                                    <div class="col-30 align-right">
                                        Meal Totals:
                                    </div>
                                    <div class="col-20">
                                    </div>
                                    <div class="col-7">
                                        {{item.totalCals}}
                                    </div>
                                    <div class="col-7">
                                        {{item.totalProtein}}
                                    </div>
                                    <div class="col-7">
                                        {{item.totalCarbs}}
                                    </div>
                                    <div class="col-7">
                                        {{item.totalFat}}
                                    </div>
                                    <div class="col-15">
                                    </div>
                                </div>
                                <div>
                                    <div class="col-30"></div>
                                    <div class="col-20"></div>
                                    <div class="col-10"></div>
                                    <div class="col-10">{{item.protein_pct}}%</div>
                                    <div class="col-10">{{item.carbs_pct}}%</div>
                                    <div class="col-10">{{item.fat_pct}}%</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="submit_nutrition_plan">
        <button mat-stroked-button (click)="onResetNutrition()" class="margin-right-5">Reset Changes</button>
        <button mat-raised-button color="primary" (click)="onSubmitNutrition()">Save Nutrition Program</button>
    </div>
</div>