<h1 mat-dialog-title>{{data.title}} {{data.copyPlanObject.clientDetais?.firstname}}
    {{data.copyPlanObject.clientDetais?.lastname}}</h1>
<div mat-dialog-content class="dialogContent " [ngClass]="!data.initial ? 'copyProgram' : 'copyplan'">
    <ng-container *ngIf="data.initial">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="data.copyPlanObject.client">
            <mat-radio-button value="sameClinet">Same Client</mat-radio-button> &nbsp;
            <mat-radio-button value="differentClient">Different Client</mat-radio-button>
        </mat-radio-group>
    </ng-container>
    <ng-container *ngIf="!data.copyUser && data.copyPlanObject.users.length > 0">
        <form>
            <mat-form-field  appearance="fill" class="session">
              <input type="text"
                     placeholder="Select User"
                     matInput
                     [formControl]="myControl"
                     [matAutocomplete]="auto"
                     >
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  [displayWith]="displayFn" >
                <mat-option *ngFor="let user of filteredUsers " [value]="user" (click)="selectUser(user)">
                    {{user.firstname}}{{user.lastname}} {{(!user.firstname && !user.lastname) ? user.email : ''}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
    </ng-container>
    <ng-container *ngIf="data.copyUser && data.copyPlanObject.sessions.length > 0">
        <mat-form-field appearance="fill" class="session">
            <mat-label>Select Session</mat-label>
            <mat-select [(ngModel)]="data.copyPlanObject.selectedSession">
                <mat-option *ngFor="let session of data.copyPlanObject.sessions" [value]="session.id"
                (click)="selectSession(session)"><span *ngIf="session.goal.name && session.goal.name!=''">{{session.goal.name}} - </span>{{session.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="data.copyPlanObject.selectedSession && data.copyPlanObject.plan">
        <mat-form-field appearance="fill" class="session">
            <mat-label>Select Meal Plans*</mat-label>
            <mat-select [(ngModel)]="data.copyPlanObject.selectedMeal">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let meal of data.copyPlanObject.sessionPlansOptions" [value]="meal">{{meal.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions class="dialogActions text-right">
    <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)"
        cdkFocusInitial>Confirm</button>
    <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
</div>
