import { Component, Inject, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.sass']
})
export class SiteSettingsComponent implements OnInit {

  allCompanies:any = [];
  selectedCompany = {id: 0};
  colorcompliance:any = {};

  datasource:any = new MatTableDataSource<any>();
  columnsList: string[] = ['start_age', 'end_age', 'gender', 'lean_start', 'lean_end', 'ideal_start', 'ideal_end', 'average_start', 'average_end', 'aboveaverage_start', 'aboveaverage_end', 'bf_can_lose_per_week', 'mbr_gain_per_pound'];

  constructor(
    private commonService: CommonService,
    private httpService: HttpService,
    private toasterService: ToasterService,
    private ui: UiService,
    private dialog: MatDialog
  ) { 
    this.getCompanyList();
  }

  ngOnInit(): void {
    const routes:any = [{
      name: 'Site Settings',
      path: 'site-settings'
    }];
    this.commonService.setHeaderData(routes);
  }

  onSelectCompany(company:any) {
    const selected = JSON.parse(JSON.stringify(company));
    this.selectedCompany = selected;
    this.getComplianceSettings(selected.id);
  }

  getCompanyList() {
    const url = `${config.apiUrl}/companyadmin/getcompanies`;
    this.httpService.getCall(url).subscribe(result => {
      this.allCompanies = result;
      if (result.length > 0 && this.selectedCompany.id === 0) {
        this.onSelectCompany(result[0]);
      } else {
        const record = result.filter((item:any) => item.id === this.selectedCompany.id);
        this.onSelectCompany(record[0]);
      }
      this.ui.spin$.next(false);
    }, err => {
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

  getComplianceSettings(companyId: number) {
    const url = `${config.apiUrl}/superadmin/getcompliancesettings`;
    const obj = {
      "company_id": companyId
    };
    this.ui.spin$.next(true);
    this.httpService.postCall(url, obj).subscribe(result => {
      this.ui.spin$.next(false);
      const { ageranges, ...colorcomplianceObject } = result;
      this.datasource.data = ageranges;
      this.colorcompliance = colorcomplianceObject;
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });

  }

  onSaveCompliance() {
    const url = `${config.apiUrl}/superadmin/updatecompliancesettings/${this.colorcompliance.id}`;
    const payload = {
      "colorcompliance_yellow_min": this.colorcompliance.colorcompliance_yellow_min,
      "colorcompliance_yellow_max": this.colorcompliance.colorcompliance_yellow_max,
      "colorcompliance_green_min": this.colorcompliance.colorcompliance_green_min,
      "colorcompliance_green_max": this.colorcompliance.colorcompliance_green_max,
      "colorcompliance_red_min": this.colorcompliance.colorcompliance_red_min,
    };
    this.ui.spin$.next(true);
    this.httpService.putCall(url, payload).subscribe(result => {
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Compliance saved successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

  enableEdit(row:any, field:any) {
    this.datasource.data = this.datasource.data.map((ob:any) => { 
      if(ob.id === row.id){
        const key = field + 'edit';
        ob[key] = true;
        ob[`new${field}`] = ob[field];
      } 
      return ob;
    });
  }

  saveNewValue(row:any, field:any) {
    const url = `${config.apiUrl}/superadmin/updateagerangesettings/${row.id}`;
    const payload:any = {
      start_age: row.start_age,
      end_age: row.end_age,
      gender: row.gender,
      lean_start: row.lean_start,
      lean_end: row.lean_end,
      ideal_start: row.ideal_start,
      ideal_end: row.ideal_end,
      average_start: row.average_start,
      average_end: row.average_end,
      aboveaverage_start: row.aboveaverage_start,
      aboveaverage_end: row.aboveaverage_end,
      bf_can_lose_per_week: row.bf_can_lose_per_week,
      mbr_gain_per_pound: row.mbr_gain_per_pound
    };
    payload[field] = row[`new${field}`];
    this.httpService.putCall(url, payload).subscribe(result => {
      this.cancelEdit(row, field, true);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

  cancelEdit(row:any, field:any, isUpdate = false) {
    this.datasource.data = this.datasource.data.map((ob:any) => { 
      if(ob.id === row.id){
        const key = field + 'edit';
        ob[key] = false;
        if (isUpdate) {
          ob[field] = row[`new${field}`];
        }
      } 
      return ob;
    });
  }

  rebuildIndices() {
    const url = `${config.apiUrl}/superadmin/reindexdata/${this.selectedCompany.id}`;
    this.ui.spin$.next(true);
    this.httpService.putCall(url, {}).subscribe(result => {
      this.ui.spin$.next(false);
      this.getComplianceSettings(this.selectedCompany.id);
      this.toasterService.show('success', 'Success', 'Rebuilding of indices completed successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error occurred while Rebuilding of indices, Please try again');
    })
  }

  onRebuildIndices() {
    const dialogRef = this.dialog.open(IndicesDialog, { minWidth: 350, minHeight: 400 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result.status) {
        this.rebuildIndices();
      }
    });
  }
}


@Component({
  selector: 'dialog-overview-indices-dialog',
  template: `
    <h1 mat-dialog-title>Rebuild indices</h1>
    <div mat-dialog-content class="dialogContent">
      <div class="alert_info_icon"><mat-icon class="settings-icon">error_outline</mat-icon></div>
      <div>Initiate index rebuild. Process will begin immediately and will complete shortly.</div>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Index</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>
    `,
  styleUrls: ['./site-settings.component.sass']
})
export class IndicesDialog {
  constructor(
    public dialogRef: MatDialogRef<IndicesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    let obj = {
      status: false
    };
    if (action) {
      obj = {
        status: true
      };
    }
    this.dialogRef.close(obj);
  }  
}
