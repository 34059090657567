<div class="main-content">
    <div>
        <div class="inlineBlock add-user">
          <span>
            <button mat-raised-button color="primary" *ngIf="selectedNotes.length > 0" (click)="shareNotes()"
              style="margin-right: 10px;"><mat-icon>share</mat-icon>&nbsp;Share</button>
            <button mat-raised-button color="primary" (click)="addNotes()">
              <mat-icon>add_circle</mat-icon>
              &nbsp;Add Notes</button>
          </span>
        </div>
      </div>
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" > 

          <ng-container matColumnDef="select" >
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox *ngIf="!clientId" class="example-margin" [(ngModel)]="element.checked" (click)="selectNotes(element)"></mat-checkbox>
            </td>
          </ng-container>
          
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element" > 
                {{element.name}}
              </td>
            </ng-container>
          

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef> Description</th>
              <td mat-cell *matCellDef="let element" > {{element?.description}} </td>
            </ng-container>
           
            <ng-container matColumnDef="created_on">
              <th mat-header-cell *matHeaderCellDef> Created On </th>
              <td mat-cell *matCellDef="let element">
              {{element.created_on | date}}
              </td>
            </ng-container>


            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="settings" (click)="eidtNotes(element)"><mat-icon>edit</mat-icon></button>
                <!-- <button mat-raised-button class="settings"><mat-icon>build</mat-icon></button> -->
                <button mat-raised-button class="settings" (click)="deleteNotes(element)"><mat-icon>delete</mat-icon></button>
              </td>
              
            </ng-container>

            <section>
              <mat-slide-toggle class="p-status"></mat-slide-toggle>
            </section>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
          </table>
          <span *ngIf="!dataSource.length" class="emtmsg">No records found</span>
    </div>
</div>