import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventDrop]'
})
export class PreventDropDirective {

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
  }
}
