import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { SocialAuthService, GoogleLoginProvider, SocialUser, FacebookLoginProvider } from 'angularx-social-login';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { CommonService } from '../services/common/common.service';
import { AuthService } from '../auth/auth.service';
import { AppleLoginProvider } from '../providers/apple-login.provider'
import { UiService} from '../services/uiloader/uiloader.service';
import { LogService } from '../services/log/log.service';

@Component({
  selector: 'app-createuser',
  templateUrl: './createuser.component.html',
  styleUrls: ['./createuser.component.sass']
})
export class CreateuserComponent implements OnInit {
  socialUser: SocialUser = {response: '', provider: '', email: '', id: '', name: '', photoUrl: '', firstName: '', lastName: '', authToken: '', idToken: '', authorizationCode: ''};

  userData:any = {
    firstname: '',
    lastname: '',
    mobilephone: '',
    feedback_status: 'completed'
  };
  emailId:string = '';

  surveyFeedbackId:String = '';

  totalProgress:any = 85;

  surveyProgress:any = 85;

  questionProgress = 5;

  perQuestionProgress = 5;

  signedInBy = '';

  ERROR_TYPES = {
    INVALID_EMAIL_FORMAT: 'Email is invalid',
    PHONE_NUMBER_FORMAT: 'Phone number is invalid',
    FILL_ALL_FIELDS: 'Please fill all fields'
  }

  errorInfo = '';
  invalidEmail = '';

  constructor(private router: Router, private location: Location,
    private httpService: HttpService, private common: CommonService,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private logService: LogService,
    private appleLoginProvider: AppleLoginProvider,
    private loader: UiService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.common.gaEvent('create_user', 'create_user_survey', 'Create User on filling Survey');

    this.emailId = this.authService.getSurveyEmail();
    this.surveyFeedbackId = this.authService.getSurveyFeedbackId();

    this.calculateSurveyProgress();

    this.route.queryParams.subscribe((params:any) => {
      if (params.isSurveyRedirect) {
        this.userData = this.authService.getUserDetails();
        this.emailId = this.authService.getSurveyEmail();
        if (params.email === this.emailId) {
          this.authService.setUserDetails(this.userData);
          this.onFinish();
        } else {
          this.invalidEmail = 'Email Mismatch, Please login with survey registered email'
        }
      }
    });

    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      if (this.emailId === user.email) {
        const obj = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email
        };
        this.authService.setUserDetails(obj);
        this.onFinish();
      } else {
        this.invalidEmail = 'Email Mismatch, Please login with survey registered email'
      }
    });
  }

  calculateSurveyProgress() {
    const arr = ['firstname', 'lastname', 'mobilephone'];
    let progress = 0;

    arr.forEach((val) => {
      if (this.userData[val] && this.userData[val].length > 0) {
        progress = progress + this.perQuestionProgress;
      }
    });
    this.totalProgress = this.surveyProgress + progress;
  }

  onInputChange() {
    this.calculateSurveyProgress();
  }

  validateInputFields() {
    const data = {
      ...this.userData
    };
    this.errorInfo = '';

    const isAllFields = this.common.validateAllFields(data);
    const isValidPhoneNumber = this.common.validatePhone(data.mobilephone);
    if (!isAllFields) {
      this.errorInfo = this.ERROR_TYPES.FILL_ALL_FIELDS;
      return false;
    } else if (!isValidPhoneNumber) {
      this.errorInfo = this.ERROR_TYPES.PHONE_NUMBER_FORMAT;
      return false;
    }
    return true;
  }

  loginWithGoogle(): void {
    // this.invalidEmail = '';
    // const isValid = this.validateInputFields();
    // if (isValid) {
      this.common.gaEvent('login_google_survey', 'login_google_survey', 'Login with Google on Completing Survey');
      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    // }

  }

  loginWithFacebook():void {
    // this.invalidEmail = '';
    // const isValid = this.validateInputFields();
    // if (isValid) {
      this.common.gaEvent('login_facebook_survey', 'login_facebook_survey', 'Login with Facebook on Completing Survey');
      this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // }
  }

  loginWithApple(): void {
    this.invalidEmail = '';
    const isValid = this.validateInputFields();
    if (isValid) {
      this.authService.setUserDetails(this.userData);
      this.common.gaEvent('login_apple_survey', 'login_apple_survey', 'Login with Apple on Completing Survey');
      this.appleLoginProvider.signIn();
    }
  }

  onFinish() {
    this.common.gaEvent('create_user_finish', 'create_user_finish', 'Finish action in Create User on completing Survey');

    const reqData = {
      ...this.userData
    };
    const isValid = this.validateInputFields();
    if (!isValid) {
      return false;
    }

    reqData.user_id = this.authService.getUserId();
    this.loader.showSpinner();
    const url = `${config.apiUrl}/surveysfeedback/updatesurveyfeedback/${this.surveyFeedbackId}`;
    this.httpService.putCall(url, reqData).subscribe(result=>{
      this.loader.stopSpinner();
      this.router.navigate(['surveyfinish']);
    }, err => {
      this.loader.stopSpinner();
      this.logService.logError(err);
    });
    return true;
  }

  onBack() {
    this.common.gaEvent('back_to_survey', 'back_to_survey', 'Back to survey from Create User');
    this.location.back();
  }

}
