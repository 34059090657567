<div class="composition-container">
    <div class="title">
        Composition
    </div>
    <div class="contents">
        <div class="topsection">
            <mat-form-field>
                <mat-select [(ngModel)]="program_id" name="dropdown" (selectionChange)="onChangeProgram($event)" placeholder="Select Session">
                  <mat-option *ngFor="let data of programsData" [value]="data.id">
                    {{data.name}}<span *ngIf="data.goal.name!=null && data.goal.name!=''"> ({{data.goal.name}})</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-raised-button class="margin_10" color="secondary"  (click)="downloadAsPDF()">
                <mat-icon>print</mat-icon>
                Print
            </button>
            <span class="timeline">{{timeline}}</span>
        </div>
        <div class="section2">
            <div id="weightChart"></div>
        </div>
        <div class="section3">
            <div class="sub-title">Weight Measurements</div>
            <table mat-table [dataSource]="weightTabledata" class="mat-elevation-z8" >            
                <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> DATE </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="client-name">{{element.date}}</div> 
                </td>
                </ng-container>
            
                <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef> WEIGHT </th>
                <td mat-cell *matCellDef="let element">
                    {{element.weight}}
                </td>
                </ng-container>
                
                <ng-container matColumnDef="lmuscle">
                    <th mat-header-cell *matHeaderCellDef> LEAN MUSCLE </th>
                    <td mat-cell *matCellDef="let element">
                    {{element.lmuscle}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="waterweight">
                    <th mat-header-cell *matHeaderCellDef> WATER WEIGHT </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.waterweight}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="fatmass">
                    <th mat-header-cell *matHeaderCellDef> FAT MASS </th>
                    <td mat-cell *matCellDef="let element">
                    {{element.fatmass}}
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
            </table>
            <div *ngIf="weightTabledata.length === 0" class="emtmsg">No records found</div>
        </div>
        <div class="section4">
            <div id="bodyFatChart"></div>
        </div>
        <div class="section5">
            <div class="sub-title">Body Fat Measurements</div>
            <table mat-table [dataSource]="weightTabledata" class="mat-elevation-z8" >                
                <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> DATE </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="client-name">{{element.date}}</div> 
                </td>
                </ng-container>
            
                <ng-container matColumnDef="bodyfat">
                <th mat-header-cell *matHeaderCellDef> BODY FAT </th>
                <td mat-cell *matCellDef="let element">
                    {{element.bodyfat}}
                </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="bodyfatColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: bodyfatColumns;" ></tr>
            </table>
            <div *ngIf="weightTabledata.length === 0" class="emtmsg">No records found</div>
        </div>
        <div class="section6">
            <div class="sub-title">Circumference Measurements</div>
            <table mat-table [dataSource]="circumferenceMeasures" class="mat-elevation-z8" >                
                <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> DATE </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="client-name">{{element.date}}</div> 
                </td>
                </ng-container>
            
                <ng-container matColumnDef="neck">
                <th mat-header-cell *matHeaderCellDef> NECK </th>
                <td mat-cell *matCellDef="let element">
                    {{element.neck}}
                </td>
                </ng-container>

                <ng-container matColumnDef="arm">
                    <th mat-header-cell *matHeaderCellDef> ARM </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.arm}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="chest">
                    <th mat-header-cell *matHeaderCellDef> CHEST </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.chest}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="waist">
                    <th mat-header-cell *matHeaderCellDef> WAIST </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.waist}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="hip">
                    <th mat-header-cell *matHeaderCellDef> HIP </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.hip}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="thigh">
                    <th mat-header-cell *matHeaderCellDef> THIGH </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.thigh}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="calf">
                    <th mat-header-cell *matHeaderCellDef> CALF </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.calf}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="forearm">
                    <th mat-header-cell *matHeaderCellDef> FOREARM </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.forearm}}
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="circumferenceColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: circumferenceColumns;" ></tr>
            </table>
            <div *ngIf="circumferenceMeasures.length === 0" class="emtmsg">No records found</div>
        </div>
    </div>
</div>