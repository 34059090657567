import { Component, Input, OnInit } from '@angular/core';
import { config } from 'src/app/config';
import { HttpService } from 'src/app/services/http/http.service';
import { LogService } from 'src/app/services/log/log.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { UiService } from 'src/app/services/uiloader/uiloader.service';

@Component({
  selector: 'app-template-setup',
  templateUrl: './template-setup.component.html',
  styleUrls: ['./template-setup.component.sass']
})
export class TemplateSetupComponent implements OnInit {
  @Input() allCompanies:any =[];
  typesOfTemplates: any[] = [
    {templateName:'Food Templates',templateValue:'copyandcreatefoodtemplates',disabled:false},
    {templateName:'Meal Templates',templateValue:'copyandcreatemealtemplates',disabled:false},
    {templateName:'Exercise Templates',templateValue:'copyandcreateexercisetemplates',disabled:false},
    {templateName:'Exercise Plan Templates',templateValue:'copyandcreateexerciseplantemplates',disabled:false},
    {templateName:'Nutrition Program Templates',templateValue:'copyandcreatenutritionprograms',disabled:false},
    {templateName:'Recipes Templates',templateValue:'copyandcreaterecipetemplates',disabled:false}
  ];
  selectedOptions:any = [];
  // allCompanies:any = [];
  selectedCompany:any;
  templateProgress: number = 0;
  percentageValue:any;
  showSpinner:Boolean = false;
  isDisableSubmitButton:any = false;

  constructor(private ui: UiService,
     private toasterService: ToasterService,
     private httpService: HttpService,private logService: LogService) { }

  ngOnInit(): void {

  }

  onSelectCompany(company:any){
    this.selectedCompany =company;
    this.selectedOptions = [];
    this.isDisableSubmitButton =false;
    this.typesOfTemplates.forEach(template => template.disabled =false);
    if(company.exercise_plan_templates_purchased){
      this.selectedOptions.push(this.typesOfTemplates[3])
      this.typesOfTemplates[3].disabled = true;
    }
    if(company.exercise_templates_purchased){
      this.selectedOptions.push(this.typesOfTemplates[2])
      this.typesOfTemplates[2].disabled = true;
    }
    if(company.food_templates_purchased){
      this.selectedOptions.push(this.typesOfTemplates[0])
      this.typesOfTemplates[0].disabled = true;
    }
    if(company.meal_templates_purchased){
      this.selectedOptions.push(this.typesOfTemplates[1])
      this.typesOfTemplates[1].disabled = true;
    }
    if(company.nutrition_program_templates_purchased){
      this.selectedOptions.push(this.typesOfTemplates[4])
      this.typesOfTemplates[4].disabled = true;
    }
    if(company.recipes_purchased){
      this.selectedOptions.push(this.typesOfTemplates[5])
      this.typesOfTemplates[5].disabled = true;
    }

    if(this.selectedOptions.length == 6) this.isDisableSubmitButton =true;
  }

  onsubmitTemplateCopy() {
    this.showSpinner = true;
    this.templateProgress = 0;
    let totalOptions = this.selectedOptions.filter((option:any) => !option.disabled)
    this.percentageValue =  100 /totalOptions.length 
    totalOptions.forEach((selectedTemplate: any) => {
      if(!selectedTemplate.disabled){
      this.httpService.postCall(`${config.apiUrl}/superadmin/${selectedTemplate.templateValue}`, { 'company_id': this.selectedCompany.id }).subscribe(data => {
        if (data.message) {
           this.templateProgress = this.templateProgress+this.percentageValue;
           if(this.templateProgress === 100){
            this.showSpinner = false;
            this.toasterService.show('success', 'Success', data.message);
           } 
        }
      },
        err => {
          this.showSpinner =false;
          this.logService.logError(err);
        });
    }
  })

  }

}
