import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { User } from './user';
import { HttpService } from '../services/http/http.service';
// import { ProgressbarService } from '../services/progressbar/progressbar.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { SidenavService } from '../services/sidenav/sidenav.service';
import { config } from '../config';
import { Location } from '@angular/common';
import { UiService } from '../services/uiloader/uiloader.service';
import { WebpushService } from '../services/webpush/webpush.service';
import { CommonService } from '../services/common/common.service';

import { SocialAuthService } from 'angularx-social-login';
// import { BrowserModule, Title } from '@angular/platform-browser';
import { LogService } from '../services/log/log.service';

@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private displayHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loggedUserAvatar: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sideMenuSelected: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public companyDetails: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public companyAdminIdMap = new Map();
  public sideNavs:any;

  get onSideMenuSelected() {
    return this.sideMenuSelected.asObservable();
  }

  get onCompanyDetails() {
    return this.companyDetails.asObservable();
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isLoggedUserAvatar() {
    return this.loggedUserAvatar.asObservable();
  }

  get isDisplayHeader() {
    return this.displayHeader.asObservable();
  }

  setGoalData(goalData:any) {
    this.cookieService.set('goal_data_user', JSON.stringify(goalData), 1, '/',window.location.hostname, false, "Strict");
  }

  getGoalData() {
    const data = this.cookieService.get('goal_data_user');
    return data === '' ? {} : JSON.parse(data);
  }

  setProgramCreation(data:any) {
    this.cookieService.set('program_creation', JSON.stringify(data), 1, '/',window.location.hostname, false, "Strict");
  }

  public setSelectedProgramId = (programId:any) => {
    this.cookieService.set('selected_program_id', programId, 1, '/',window.location.hostname, false, "Strict");
  };

  public getSelectedProgramId = () => {
    return this.cookieService.get('selected_program_id');
  };

  displayHeaderValue() {
    this.displayHeader.next(true);
  }

  hideDisplayHeaderValue() {
    this.displayHeader.next(false);
  }

  constructor(
    private router: Router,
    private httpService: HttpService,
    private commonService: CommonService,
    // private progressbarService: ProgressbarService,
    private webpush: WebpushService,
    private toasterService: ToasterService,
    private sidenavService: SidenavService,
    private cookieService: CookieService,
    private http: HttpClient,
    private location: Location,
    private ui : UiService,
    private socialAuthService: SocialAuthService,
    private logService: LogService,
    // private title:Title
    
  ) {
    
    // this.refreshSessionOfUser();

    if (this.getJwtToken()) {
      this.loadInitialComponents();
    }
  }

  navigateToPath(activeLink:any = "", sortDate:any="") {
    let userRole = this.getUserRole();
    let targetRoute: string;
    
    this.sidenavService.sideNavData.subscribe((data: any) => {
      this.sideNavs = data;
    });
    if(activeLink){
      userRole =activeLink;
    }
  
    switch (userRole) {

      case "CLIENT_ACTIVE_EXERCISE_PLAN":
        targetRoute = "/clientexercise";
        break;

      case "CLIENT_ACTIVE_NUTRITION_PLAN":
        targetRoute = "/clientnutrition";
        break;

      case config.CLIENT_USER_ROLE:
        targetRoute = '/dashboard';
        break;

      case config.ADMIN_USER_ROLE:
        targetRoute = this.findRouteForRole('company-clients');
        break;

      default:
        targetRoute = this.findRouteForRole('consultant-clients');
        break;
    }
  
    this.sideMenuSelected.next(targetRoute.replace('/', ''));
    if(sortDate){
      this.router.navigate([targetRoute], {queryParams:{sortDate:sortDate}})
    }
    else this.router.navigate([targetRoute]);
    this.ui.spin$.next(false);
  }
  
  private findRouteForRole(roleName: string): string {
    const hasDashboard = this.sideNavs.some((obj: any) => obj.name === roleName);
    return hasDashboard ? `/${roleName}` : '/dashboard';
  }
  

  onActAsUser(data: any, isSwitch: any, activeLink?:any, sortDate="") {
    this.storeJwtToken(data);
    this.ui.spin$.next(true);
    this.loadInitialComponents(isSwitch);
    this.navigateToPath(activeLink,sortDate);
    
  }

  switchBackToAdmin() {
    const userIdMap = new Map(JSON.parse(localStorage.getItem('companyAdminIdMap') ?? ''));
    const userId = parseInt(this.getActualLoggedUserId());
    const url = `${config.apiUrl}/companyadmin/impersonatetoadmin/${userIdMap.get(userId)}`;
    this.httpService.getCall(url).subscribe((res)=>{
      userIdMap.delete(userId);
      this.companyAdminIdMap = userIdMap;
      localStorage.setItem('companyAdminIdMap', JSON.stringify(Array.from(userIdMap.entries())));
      this.onActAsUser(res, userIdMap.size > 0);
    }, (err) => {
      console.log(err);
      this.logService.logError(err);
    });
  }

  onLoginSuccess() {
    this.loadInitialComponents();
    this.webpush.registerPushNotification();
    this.navigateToPath();
  }

  loadInitialComponents(isSwitch?:false) {
    const path = this.location.path();
    if (path === '/login') {
      this.router.navigate(['/dashboard']);
    }
    this.loggedIn.next(true);
    setTimeout(()=>{
      this.loadSideMenu(isSwitch);
      this.getCustomerData();
    }, 1000);
  }

  loadSideMenu(isSwitch: any) {
    const apiUrl = `${config.apiUrl}/dashboard/sidemenu`;
    this.ui.spin$.next(true); // Show loading spinner
    this.httpService.getCall(apiUrl).subscribe((data: any[]) => {
        const companyIds = localStorage.getItem('companyAdminIdMap') || "";
        const userIdMap = new Map(JSON.parse(companyIds || '[]'));
        if (isSwitch || userIdMap.size > 0) {
          data.push({"id": 9999, "name": "", "display_name": "Switch Back to Admin", "icon": "track_changes","show_in_menu":"1"});
        }
        this.sidenavService.fetchSideNav(data);
      },
      err => {
        console.error(err);
        this.logService.logError(err);
      },
      () => this.ui.spin$.next(false) // Hide loading spinner in case of success or error
    );
  }
  

  getCustomerData() {
    this.httpService.getCall(`${config.apiUrl}/companyadmin/getmycompanydetails`).subscribe(data => {
      const [record] = data;
      this.companyDetails.next(record);
      // this.title.setTitle(record && record.name);
    },
    err => {
      console.log(err)
      this.logService.logError(err);
    });
  }

  login(user: any) {
    if (user.userName !== '' && user.password !== '' ) {
      // this.progressbarService.handleSpinner(true);
      this.ui.spin$.next(true);
      const data = {
        email: user.userName,
        password: user.password,
        company_id: user.company_id
      };
      this.httpService.postCall(`${config.apiUrl}/auth`,data).subscribe(data => {
        this.storeJwtToken(data);
        this.onLoginSuccess();
        this.ui.spin$.next(false);
      },
      err => {
        this.ui.spin$.next(false);
        this.toasterService.show('error', 'Invalid Credentials', err?.error?.error)
        this.logService.logError(err);
      },
      () => { 
        this.ui.spin$.next(false);
      });
    }
  }

  onLogoutClear() {
    this.loggedIn.next(false);
    localStorage.removeItem('userRefreshToken');
    localStorage.removeItem('companyAdminIdMap')
    this.cookieService.delete('userToken');
    this.cookieService.deleteAll( '/' , window.location.hostname );
    if (this.getCompanyUrl()) {
      this.router.navigate([this.getCompanyUrl()+'/login']);
      this.cookieService.delete('company_url');
    }
    else {
      this.router.navigate(['/login']);
      this.cookieService.delete('company_url');
    }
  }

  logout() {
    this.socialAuthService.signOut(true).then(_data => {  
      this.onLogoutClear();
    }, _err => {
      this.onLogoutClear();
    });
  }

  private storeRefreshToken(refresh: string) {
    localStorage.setItem('userRefreshToken', refresh);
  }

  setSurveyEmail(email:string, surveyId:string) {
    this.cookieService.set('user_survey_email', email, 1, '/',window.location.hostname, false, "Strict");
    this.cookieService.set('user_survey_id', surveyId, 1, '/',window.location.hostname, false, "Strict");
  }

  setSurveyFeedbackId(surveyFeedbackId:string) {
    this.cookieService.set('user_survey_feedback_id', surveyFeedbackId, 1, '/',window.location.hostname, false, "Strict");
  }

  getSurveyEmail() {
    const email = this.cookieService.get('user_survey_email');
    return email;
  }

  setLoggedUserId(userId:any) {
    this.cookieService.set('logged_user_id', userId, 1, '/',window.location.hostname, false, "Strict");
  }

  getLoggedUserId() {
    return this.cookieService.get('logged_user_id');
  }

  setUserId(surveyFeedbackId:string) {
    this.cookieService.set('user_id', surveyFeedbackId, 1, '/',window.location.hostname, false, "Strict");
  }

  getUserId() {
    const email = this.cookieService.get('user_id');
    return email;
  }

  getSurveyId() {
    const surveyId = this.cookieService.get('user_survey_id');
    return surveyId;
  }

  getSurveyFeedbackId() {
    const surveyFeedbackId = this.cookieService.get('user_survey_feedback_id');
    return surveyFeedbackId;
  }

  setUserDetails(data:any) {
    this.cookieService.set('user_details', JSON.stringify(data));
  }

  getUserDetails() {
    const data = this.cookieService.get('user_details');
    if (data.length > 0) {
      return JSON.parse(data);
    }
    return '';
  }

  getJwtToken() {
    return this.cookieService.get('userToken');
  }

  refreshSessionOfUser() {
    if (!this.getRefreshToken()) {
      return false;
    }
    this.http.post<any>(`${config.apiUrl}/auth/refresh`, {
      'refresh_token': this.getRefreshToken()
    }).subscribe( result => {
      this.storeJwtToken(result);
    }, err => {
      this.logService.logError(err);    
    });
    return true;
  }

  refreshToken() {
    this.ui.spin$.next(false);
    this.logout();
    return this.http.post<any>(`${config.apiUrl}/auth/refresh`, {
      'refresh_token': this.getRefreshToken()
    });
  }

  setUserRole(data:any) {
    this.cookieService.set('user_role', data.userData.role.id, new Date(data.expiresAt));
  }

  public getUserRole() {
    return this.cookieService.get('user_role');
  }

  setUserAvatar(data:any) {
    if (data.userData && data.userData.personal) {
      let avatar = data.userData.personal.avatar || '';
      if (avatar.length === 0) {
        avatar = this.commonService.getUserInitials(data.userData);
      }
      this.cookieService.set('user_avatar', avatar, data.expiresAt);
      this.loggedUserAvatar.next(avatar);
    }
  }

  public getUserAvatar() {
    return this.cookieService.get('user_avatar');
  }

  setActualLoggedUserId(data:any) {
    this.cookieService.set('user_actual_logged_id', data.userData.id, data.expiresAt);
  }

  public getActualLoggedUserId() {
    return this.cookieService.get('user_actual_logged_id');
  }

  public getCompanyUrl(){
    return this.cookieService.get('company_url');
  }

  storeJwtToken(data:any) {
    // this.cookieService.set('userToken', data.accessToken, data.expiresAt);
    document.cookie = `userToken=${data.accessToken}; expires=${new Date(data.expiresAt)}; path=/`;
   if(data?.userData?.company_url) document.cookie = `company_url =${data?.userData?.company_url};`
    this.storeRefreshToken(data.refreshToken);
    this.setUserRole(data);
    if (data.userData.personal) {
      this.setUserAvatar(data);
    }
    this.setActualLoggedUserId(data);
  }

  private getRefreshToken() {
    return localStorage.getItem('userRefreshToken');
  }

  public storeCompanyIdValuesAsMap(id: any, companyAdminId: any) {
   if(!this.companyAdminIdMap.has(id)) this.companyAdminIdMap.set(id,companyAdminId);
    localStorage.setItem('companyAdminIdMap', JSON.stringify(Array.from(this.companyAdminIdMap.entries())));
  }
}