<div>
  <div class="top-section">
    <div class="search-container">
      <div class="input-container">
          <mat-icon matPrefix>search</mat-icon>
          <input type="text" placeholder="Search" [(ngModel)]="searchTxt" class="search-input" autocomplete="off" (keyup)="searchResult($event)" />
      </div>
      <button class="deletebtn" [ngClass]="{'highlight': deletehighlight}" [disabled]="!deletehighlight" *ngIf="userPrivilege > 1" (click)="deleteFoods()">Delete Selected</button>
    </div>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="datasource" multiTemplateDataRows>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> 
          <mat-checkbox class="checkbx" (change)="setSelection($event)" [checked]="isAllSelected"></mat-checkbox> 
          <span class="disp-name">Name</span>
        </th>
        <td mat-cell *matCellDef="let element"> 
          <mat-checkbox class="checkbx" (click)="$event.stopPropagation()" (change)="setSelection($event, element)" [checked]="element.checked"></mat-checkbox>
          <div class="expand-icon">
            <span class="icon chevron_expand_icon" (click)="element.isExpanded = !element.isExpanded">
              <mat-icon *ngIf="!element.isExpanded">keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="element.isExpanded">keyboard_arrow_down</mat-icon>
            </span>
            <span class="food-name">
              <span class="disp-name" (click)="enableEdit(element, 'name')" *ngIf="!element.nameedit"> {{element.name}} </span>
              <span *ngIf="element.nameedit" class="edit-column" >
                <input [(ngModel)]="element.newname" class="edit-field" />
                <button class="savebtn" *ngif="userPrivilege > 1" (click)="saveNewValue(element, 'name')">Save</button>
                <button class="cancelbtn" (click)="cancelEdit(element, 'name')">Cancel</button>
              </span>
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> <span class="disp-name">category</span> </th>
        <td mat-cell *matCellDef="let element"> 
          <span class="disp-name" *ngIf="!element.categoryedit" (click)="enableEdit(element, 'category')">{{element?.foodtemplatecategory?.name}}</span>
          <span *ngIf="element.categoryedit" class="edit-column dropdown" >
            <mat-form-field>
              <mat-select [(ngModel)]="element.newcategory" name="dropdown">
                  <span *ngFor="let data of foodsCategories">
                      <mat-option [value]="data.id">
                          {{data.name}}
                      </mat-option>
                      <span class="opt-child" *ngIf="data.children">
                          <mat-option *ngFor="let sub of data.children" [value]="sub.id">
                              {{sub.name}}
                          </mat-option>
                      </span>
                  </span>
              </mat-select>
          </mat-form-field>
          <button class="savebtn" *ngif="userPrivilege > 1" (click)="saveNewValue(element, 'category')">Save</button>
          <button class="cancelbtn" (click)="cancelEdit(element, 'category')">Cancel</button>
        </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="calories">
        <th mat-header-cell *matHeaderCellDef><span class="disp-name"> calories </span></th>
        <td mat-cell *matCellDef="let element"> 
          <span class="disp-name" (click)="enableEdit(element, 'calories')" *ngIf="!element.caloriesedit">{{element.calories}} </span>
          <span *ngIf="element.caloriesedit" class="edit-column" >
            <input [(ngModel)]="element.newcalories" class="edit-field" />
            <button class="savebtn" *ngif="userPrivilege > 1" (click)="saveNewValue(element, 'calories')">Save</button>
            <button class="cancelbtn" (click)="cancelEdit(element, 'calories')">Cancel</button>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="protein">
        <th mat-header-cell *matHeaderCellDef><span class="disp-name"> protein </span></th>
        <td mat-cell *matCellDef="let element">
          <span class="disp-name" (click)="enableEdit(element, 'protein')" *ngIf="!element.proteinedit">{{element.protein}} </span>
          <span *ngIf="element.proteinedit" class="edit-column" >
            <input [(ngModel)]="element.newprotein" class="edit-field" />
            <button class="savebtn" *ngif="userPrivilege > 1" (click)="saveNewValue(element, 'protein')">Save</button>
            <button class="cancelbtn" (click)="cancelEdit(element, 'protein')">Cancel</button>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="carbs">
        <th mat-header-cell *matHeaderCellDef><span class="disp-name"> carbs </span></th>
        <td mat-cell *matCellDef="let element">
          <span class="disp-name" (click)="enableEdit(element, 'carbs')" *ngIf="!element.carbsedit">{{element.carbs}} </span>
          <span *ngIf="element.carbsedit" class="edit-column" >
            <input [(ngModel)]="element.newcarbs" class="edit-field" />
            <button class="savebtn" *ngif="userPrivilege > 1" (click)="saveNewValue(element, 'carbs')">Save</button>
            <button class="cancelbtn" (click)="cancelEdit(element, 'carbs')">Cancel</button>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="fat">
        <th mat-header-cell *matHeaderCellDef><span class="disp-name"> fat </span></th>
        <td mat-cell *matCellDef="let element">
          <span class="disp-name" (click)="enableEdit(element, 'fat')" *ngIf="!element.fatedit">{{element.fat}} </span>
          <span *ngIf="element.fatedit" class="edit-column" >
            <input [(ngModel)]="element.newfat" class="edit-field" />
            <button class="savebtn" *ngif="userPrivilege > 1" (click)="saveNewValue(element, 'fat')">Save</button>
            <button class="cancelbtn" (click)="cancelEdit(element, 'fat')">Cancel</button>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef><span class="disp-name"> unit </span></th>
        <td mat-cell *matCellDef="let element">
          <span class="disp-name" (click)="enableEdit(element, 'unit')" *ngIf="!element.unitedit">{{element.unit}} </span>
          <span *ngIf="element.unitedit" class="edit-column" >
            <input [(ngModel)]="element.newunit" class="edit-field" />
            <button class="savebtn" *ngif="userPrivilege > 1" (click)="saveNewValue(element, 'unit')">Save</button>
            <button class="cancelbtn" (click)="cancelEdit(element, 'unit')">Cancel</button>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef><span class="disp-name"> qty </span></th>
        <td mat-cell *matCellDef="let element">
          <span class="disp-name" (click)="enableEdit(element, 'qty')" *ngIf="!element.qtyedit">{{element.qty}} </span>
          <span *ngIf="element.qtyedit" class="edit-column" >
            <input [(ngModel)]="element.newqty" class="edit-field" />
            <button class="savebtn" *ngif="userPrivilege > 1" (click)="saveNewValue(element, 'qty')">Save</button>
            <button class="cancelbtn" (click)="cancelEdit(element, 'qty')">Cancel</button>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsList.length">
          <div class="expanded-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
            <div class="details">
              <span *ngFor="let prop of element | keyvalue" class="spn-margin">{{prop.key}}: {{prop.value | json}} </span>
            </div>
          </div>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="columnsList;sticky: true"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsList;" class="element-row" [class.expanded-row]="element.isExpanded"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <mat-paginator
                [length]="pagination.count"
                [pageIndex]="pagination.pageIndex"
                [pageSize]="pagination.perPage"
                [pageSizeOptions]="[10, 25, 50, 100, 200]"
                (page)="getServerData($event)"
          showFirstLastButtons></mat-paginator>
  </div>
</div>