import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UiService} from '../services/uiloader/uiloader.service';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../services/common/common.service';
import { LogService } from '../services/log/log.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.sass']
})
export class SurveyComponent implements OnInit {

  surveyQuestions:any = [];
  surveyPageQuestions:any = [];
  selectedValue:any = '';
  fArr:any = [];
  errorInfo:string = '';
  surveyProgress:any = 0;
  surveyAnswers:any = {};
  questionsStartCount:number = 0;
  emailId:string = '';
  pageId:number = 0;
  surveyId:string = '';
  surveyIncomplete = false;
  userId:string = '';

  constructor(private httpService: HttpService,
    private router: Router,
    private loader: UiService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private logService: LogService,
    private commonService: CommonService) { 

    }

  ngOnInit(): void {
    this.commonService.gaEvent('survey', 'survey', 'Survey Screen');

    this.route.paramMap.subscribe((params:any) => {
      this.pageId = parseInt(params.get('pageId'));
      this.emailId = this.authService.getSurveyEmail();
      this.surveyId = this.authService.getSurveyId();

      this.commonService.gaEvent('load_survey', 'load_survey', `Survey Screen Page Id: ${this.pageId}`);
      this.fetchSurveyQueries(this.emailId, this.pageId);
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  merge = (a:any, b:any, i=0) => a.splice(i, 0, ...b) && a;

  fetchSurveyQueries(emailId:string, pageId:number) {
    this.loader.showSpinner();
    const userData = this.authService.getUserDetails();
    const url = `${config.apiUrl}/surveys/startsurvey`;
    const reqData = {
      "company_id": 1,
      "email_address": emailId,
      "feedback_status" : 'inprogress',
      "survey_id": this.surveyId
    };
    this.httpService.postCall(url, reqData).subscribe(result => {
      const { success } = result;
      const { survey_details } = success;
      this.userId = success.udser_id ? success.udser_id : success.user_id;
      this.surveyId = success.survey_feedback_id;
      this.authService.setSurveyFeedbackId(success.survey_feedback_id);
      this.authService.setUserId(this.userId);
      const resultData:any = [];
      const resultPageData:any = [];
      let genderOption = '';
      const farrLen = this.fArr.length;
      survey_details.forEach((item:any) => {
        if (item.label) {
          if (item.selected_values === 'f') {
            genderOption = item.selected_values;
          }
          if (item.display_for === 'f' && genderOption !== 'f') {
            if (item.values.length === 2) {
              item.colType = '20';
            }
            if (farrLen === 0 ) {
              this.fArr.push(item);
            }
          } else {
            if (['ability_to_use_gym_equipment', 'motivation_score'].includes(item.name)) {
              item.colType = '10';
            } else if(item.values.length === 2) {
              item.colType = '20';
            } else if (item.values.length === 3) {
              item.colType = '100';
            } else {
              item.colType = '50';
            }
            resultData.push(item);
            if (this.pageId === item.displayInPage) {
              if (this.questionsStartCount === 0) {
                this.questionsStartCount = resultData.length;
              }
              resultPageData.push(item);
            }
          }
        }
      });
      this.surveyQuestions = resultData;
      this.surveyPageQuestions = resultPageData;
      this.loader.stopSpinner();
      this.calculateProgress();
    }, err=>{
      const { error } = err;
      if (error?.error) {
        this.errorInfo = error.error;
      } else {
        this.errorInfo = 'Error Occurred, Please try again';
      }
      this.loader.stopSpinner();
    });
  }

  calculateProgress() {
    const filled = Object.keys(this.surveyAnswers).length + this.questionsStartCount - 1;
    const total = this.surveyQuestions.length;
    const progress:any = (filled / total) * 100;
    this.surveyProgress = parseInt(progress) * 0.6;
  }

  onAction(data:any) {
    this.selectedValue = data.info;
    if (data.info) {
      this.surveyPageQuestions[data.index].selected_values = data.info;
      if (data.info && data.info.length === 0) {
        delete this.surveyPageQuestions[this.surveyPageQuestions[data.index].name];
      } else {
        this.surveyPageQuestions[this.surveyPageQuestions[data.index].name] = data.info;
      }
    } else {
      delete this.surveyPageQuestions[this.surveyPageQuestions[data.index].name];
    } 
    if (data.other_input) {
      this.surveyPageQuestions[data.index].otherValue = data.other_input;
    }
    if (data.info === 'f') {
      const filtered = this.surveyPageQuestions.filter((item:any) => item.display_for === 'f');
      if (filtered.length === 0) {
        this.merge(this.surveyPageQuestions, this.fArr, data.index + 1);
        this.merge(this.surveyQuestions, this.fArr, this.questionsStartCount + data.index);
      }
    } else if (data.info === 'm') {
      const filtered = this.surveyPageQuestions.filter((item:any) => item.display_for === 'f');
      if (filtered.length > 0) {
        this.surveyPageQuestions.splice(data.index+1, 2);
        this.surveyQuestions.splice(this.questionsStartCount + data.index, 2);
      }
    }
    this.calculateProgress();
    console.log(data);
  }

  onSubmitNext() {
    this.surveyIncomplete = false;
    const obj:any = {};
    let incomplete = false;
    this.surveyPageQuestions.forEach((item:any) => {
      if (item.selected_values) {
        obj[item.name] = Array.isArray(item.selected_values) ? item.selected_values.join(',') : item.selected_values;
      } else {
        incomplete = true;
      }
      if (item.otherValue) {
        obj[`${item.name}_other`] = item.otherValue;
      }
    });
    if (incomplete) {
      this.surveyIncomplete = true;
      return false;
    }
    this.loader.showSpinner();

    obj.feedback_status = 'inprogress';
    obj.user_id = this.userId;
    const url = `${config.apiUrl}/surveysfeedback/updatesurveyfeedback/${this.surveyId}`;
    this.httpService.putCall(url, obj).subscribe(result => {
      const totalCount = this.questionsStartCount + this.surveyPageQuestions.length - 1;
      if (totalCount < this.surveyQuestions.length) {
        this.questionsStartCount = 0;
        this.router.navigate([`/survey/${this.pageId + 1}`]);
      } else {
        this.questionsStartCount = 0;
        this.router.navigate(['/createuser']);
      }
      this.loader.stopSpinner()
    }, err => {
      this.loader.stopSpinner()
      this.logService.logError(err);
    })
    return true;
  }

  onBack() {
    this.commonService.gaEvent('back_survey', 'back_survey', 'Go to Previous screen of Survey');
    this.questionsStartCount = 0;
    this.router.navigate([`/survey/${this.pageId - 1}`]);
  }

}
