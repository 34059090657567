import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {LiveAnnouncer} from '@angular/cdk/a11y';

import {MatTableDataSource} from '@angular/material/table';

import {MatSort, Sort} from '@angular/material/sort';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { UiService } from '../services/uiloader/uiloader.service';
import { CommonService } from '../services/common/common.service';
import { LogService } from '../services/log/log.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


let searchTimeout:any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})
export class UsersComponent implements OnInit {

  constructor(private router: Router,
    private httpService: HttpService,
    private ui: UiService,
    private logService: LogService,
    public commonService: CommonService,
    private _liveAnnouncer: LiveAnnouncer,
    private toasterService: ToasterService) { }

    @ViewChild(MatSort) sort: any = {};

  ngOnInit(): void {
    const routes = [{
      name: 'Clients',
      path: 'company-clients'
    }];
    this.commonService.setHeaderData(routes);
    this.fetchUsers();
  }

  ngAfterViewInit() {
  }

  displayedColumns: string[] = ['profilePic', 'name', 'companyName', 'consultantName', 'roles', 'userStatus','settings'];
  dataSource:any = {data: []};
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0,
    orderBy: 'DESC',
    orderByOn: 'id'
  };

  specificUser(item:any){
    // console.log(item);
    this.router.navigate(['/userdetails', item.id ]);
  }

  navigateToSettings(item:any){
    this.router.navigate(['/userdetails', item.id ],{ queryParams: { tab: 'settings' }});
  }

  statusUpdate(event:MatSlideToggleChange, clientId:any){
    const url = event.checked ? `/companyadmin/reactiveclient/${clientId}` : `/companyadmin/deactiveclient/${clientId}`;
    this.httpService.putCall(`${config.apiUrl}${url}`,{}).subscribe(data => {
      this.ui.spin$.next(false);
    },
    err => {
      this.ui.spin$.next(false);
    });
  }

  addUser() {
    this.router.navigate(['/adduser']);
  }

  private getUserRoles(data:any) {
    const result = data ? data.map((item:any)=>(item.display_name)) : [];
    return result;
  }

  onTableSort(sortState: any) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
    let column = '';
    let columnDirection = sortState.direction;
    if (columnDirection) {
      if (sortState.active === 'name') {
        column = 'firstname';
      } else if (sortState.active === 'companyName') {
        column = 'company';
      } else if (sortState.active === 'roles') {
        column = 'role';
      } else if (sortState.active === 'userStatus') {
        column = 'enabled';
      } else if (sortState.active === 'consultantName') {
        column = 'consultant';
      } else {
        column = sortState.active;
      }
    } else {
      column = 'firstname';
      columnDirection = 'asc';
    }
    this.pagination.orderBy = columnDirection;
    this.pagination.orderByOn = column;
    this.fetchUsers();
  }

  private getFormatedData(data:any) {
    const { rows = [], count = 0 } = data;
    this.pagination.count = count;
    return rows.map((item:any) => ({
      id: item.id,
      name: `${item.firstname} ${item.lastname}`,
      email: item.email,
      companyName: item.company ? item.company.name: '',
      consultantName: item.consultant ? `${item.consultant.firstname} ${item.consultant.lastname}` : '',
      roles: this.getUserRoles(item.roles),
      userStatus: item.enabled === 1 ? true : false,
      isProfilePicExisted: item.personal && item.personal.avatar && item.personal.avatar.length > 0,
      userChars:  this.commonService.getUserInitials(item),
      profilePic: item.personal && item.personal.avatar && item.personal.avatar.length > 0 ? `${config.imageUrl}${item.personal.avatar}` : config.defaultProfilePic
    }));
  }

  private fetchUsers() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: (this.pagination.pageIndex * this.pagination.perPage),
      limit: this.pagination.perPage,
      orderBy: this.pagination.orderBy,
      orderByOn: this.pagination.orderByOn,
      searchtext: this.pagination.searchtext
    };
    this.httpService.postCall(`${config.apiUrl}/getclients`, obj).subscribe(data => {
      this.ui.spin$.next(false);
      this.dataSource.data = new MatTableDataSource(this.getFormatedData(data));
      this.dataSource.sort = this.sort;

      // if (this.pagination.searchtext.length === 0) { //Pavan commented on 26th Jan 22
        this.pagination.totalCount = data.count;
      // }      
    },
    err => {
      this.ui.spin$.next(false);
      this.dataSource.data = [];
      this.pagination.totalCount = 0;
      this.toasterService.show('error', 'Alert', err?.error?.error);
      this.logService.logError(err);
    });
  }

  getServerData(e:any) {
    console.log(e.pageIndex);
    console.log(e.pageSize);
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.fetchUsers();
  }

  onSearchUser(x:any) {
    this.pagination.pageIndex = 0;
    this.pagination.searchtext = x.target.value;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(()=>{
      this.fetchUsers();
    }, 1000);
  }
}
