import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-nutrition-table',
  templateUrl: './nutrition-table.component.html',
  styleUrls: ['./nutrition-table.component.sass']
})
export class NutritionTableComponent implements OnInit {

  @Input() datasource:any;
  @Input() type:any;
  @Input() overAllTotal:any;
  @Input() overAllTableUpdate:any;
  localData:any;
  @Output() rowChange = new EventEmitter<string>();
  @Output() delete = new EventEmitter();
  @Output() onAddNote = new EventEmitter();

  constructor(public commonService: CommonService,
    private dialog: MatDialog,) { }
  
  columnsList:string[] = ['data', 'calories', 'protein', 'carbs', 'fat', 'servingsize', 'delete'];
  selectAll:boolean = false;
  selectedrow: any;
  totalClr: boolean = false;
  
  ngOnInit(): void {

  }

  ngOnChanges() {
    this.localData = this.datasource.map((f:any) => f);
    let selectAll = true;
    if(this.datasource.length) {
      this.datasource.forEach((element:any) => {
        if(element.data.checked === false) selectAll = false;
      });
      this.selectAll = selectAll;
      const checkedlist = this.datasource.filter((ob:any) => {
        return ob.data.checked === true
      });
      checkedlist.length ? this.totalClr = true : this.totalClr = false;
    }
  }

  deleteClick(key:any) {
    console.log(key);
    this.delete.emit({val:key});
  }

  addNote(ev:any, data: any, type:any) {
    // const data = {clientnote: '', changeNotes: ''}; // this.exerciseData[i];
    let dialogRef = this.dialog.open(NoteDialog, { data: {'note': data.clientnote}, minWidth: 350, minHeight: 300 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result.status) {
        // data.changeNotes = result.note;
        this.rowselection(ev, data.rowid, type, result.note);
        //this.updateExerciseData({clientnote: result.note, index: i });
      }
    });
  }

  sizeChange(row: any) {
    console.log(row, this.datasource);
  }

  rowselection(ev:any, id: any, type:any, note:string = '') {
    const value:any = { ev: ev, id: id, type: type, obj: this.datasource, clientnote: note};
    this.rowChange.emit(value);
  }

  changeHandler(ev:any, row:any, type:any) {
    const times = ev.target.value;
    // /^\d*$/.test(times) &&
    if(times > 0 && times !== '') {
      if (times.split('.')[times.length-1] == '') {
        return false;
      }
      let isChecked: any;
      this.datasource.filter((obj:any) => {
        if(obj.id === row.id && times !== '') {
          isChecked = obj.data.checked;
        }
      });
      let cons:any = {ev: {checked: isChecked}, id: row.id, type: type, count: times, obj: this.datasource};
      this.rowChange.emit(cons);
    }
    return true;
  }

  calculateAll() {
    this.overAllTableUpdate(this.type, this.selectAll);
  }

  ngOnDestroy() {
    
  }
}

@Component({
  selector: 'dialog-overview-add-note-dialog',
  template: `
  <h1 mat-dialog-title>Note</h1>
    <div mat-dialog-content class="dialogContent">
      <textarea (change)="onNoteChange($event)" [value]="data.note" class="add-note-textarea"> </textarea>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>
    `,
  styleUrls: ['./nutrition-table.component.sass']
})
export class NoteDialog {
  constructor(
    public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoteChange(ev:any) {
      this.data.note = ev.target.value;
    }

  responseHandler(action:any): void {
    let obj = {
      status: false,
      note: ''
    };
    if (action) {
      obj = {
        status: true,
        note: this.data.note
      };
    }
    this.dialogRef.close(obj);
  }  
}