<div class="new-composition" #pdfTable>
    <div>
        <div class="dropdown inlineBlock">
            <mat-form-field>
                <mat-select [(ngModel)]="selectedPreference" name="dropdown" (selectionChange)="onSelectedPreference($event)" >
                    <mat-option value="all">
                        All
                    </mat-option>
                    <mat-option value="6weeks">
                        Limit to 6 weeks past
                    </mat-option>
                    <mat-option value="last8">
                        Show last 8
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="inlineBlock float_right">
            <button mat-raised-button class="margin_10" color="secondary" (click)="onPrintOptions()">
                <mat-icon>print</mat-icon>
                Print
            </button>
        </div>
    </div>
    <div class="title-container">
        <span class="title">Composition</span>
    </div>
    <div>
        <table mat-table [dataSource]="localData" class="mat-elevation-z8" >
            <ng-container matColumnDef="measured_at">
                <th mat-header-cell *matHeaderCellDef> DATE </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'totls': element.id === 'total'}">
                    <ng-container  *ngIf="element.id !== 'total'">
                    <input [matDatepicker]="basicDatepicker" [(ngModel)]="element.startDate">
                    <mat-datepicker-toggle [for]="basicDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #basicDatepicker></mat-datepicker>
                    </ng-container>
                    <span *ngIf="element.id === 'total'">{{element.measured_at}}</span>
                    {{element.active ? '(Active)' : ''}}
                </td>
            </ng-container>
            <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef class="padd-left-5"> WEIGHT (lbs) </th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element.weight | number:'1.0-1'}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'weight')" (input)="calculateleanmass(element)" [(ngModel)]="element.weight" />
                </td>
            </ng-container>
            <ng-container matColumnDef="bodyfat">
                <th mat-header-cell *matHeaderCellDef class="padd-left-5"> body fat (%)</th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element.bodyfat | number:'1.0-1'}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'bodyfat')" (input)="calculateleanmass(element)" [(ngModel)]="element.bodyfat" />
                </td>
            </ng-container>
            <ng-container matColumnDef="leanmuscle">
                <th mat-header-cell *matHeaderCellDef class="padd-left-5"> lean mass (lbs)</th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5">{{element.leanmuscle | number:'1.0-1'}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="waterweight">
                <th mat-header-cell *matHeaderCellDef class="padd-left-5"> water weight (lbs)</th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element.waterweight | number:'1.0-1' }}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'waterweight')" [(ngModel)]="element.waterweight" />
                </td>
            </ng-container>
            <ng-container matColumnDef="fatmass">
                <th mat-header-cell *matHeaderCellDef class="padd-left-5"> fat mass (lbs)</th>
                <td mat-cell *matCellDef="let element" class="last-cell">
                    <span class="padd-left-5">{{element.fatmass | number:'1.0-1' }}</span>
                    <button *ngIf="element.active" [ngClass]="{'highlight': !element.edit}" (click)="saveCompositionData(element)">Save</button>
                    <div class="actions">
                    <span *ngIf="!element.active && element.id != 'total'">
                        <mat-icon (click)="element.active = true" >edit</mat-icon>
                    </span>
                    <span *ngIf="!element.active && element.id != 'total'" [ngStyle]="{'color':'red','padding-left':'5px;'}">
                        <mat-icon (click)="onDeleteComposition(element)">delete</mat-icon>
                    </span> 
                </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsList"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsList;" (click)="editRow(row)"></tr>
        </table>
        <div *ngIf="!localData.length" class="emtmsg">No records found</div>
        <div class="note-info">Measurements are in pounds, percentages in whole numbers (Ex. 22.5, 10.0)</div>
        <div class="marg-btm-50"></div>
    </div>
    <div class="title-container">
        <span class="title">Circumference Measurements</span>
    </div>
    <table mat-table [dataSource]="circumference" class="mat-elevation-z8" >
        <ng-container matColumnDef="measured_at">
            <th mat-header-cell *matHeaderCellDef> DATE </th>
            <td mat-cell *matCellDef="let element" class="dateColumn" >
                <ng-container  *ngIf="element.id !== 'total'">
                <input [matDatepicker]="basicDatepicker" [(ngModel)]="element.startDate">
                <mat-datepicker-toggle [for]="basicDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #basicDatepicker></mat-datepicker>
                </ng-container>
                <span *ngIf="element.id === 'total'">{{element.measured_at}}</span>
                {{element.active ? '(Active)' : ''}}
            </td>
        </ng-container>
        <ng-container matColumnDef="neck">
            <th mat-header-cell *matHeaderCellDef> neck </th>
            <td mat-cell *matCellDef="let element">
                <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.neck}}</div>
                <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'neck')" [(ngModel)]="element.neck" />
            </td>
        </ng-container>
        <ng-container matColumnDef="arm">
            <th mat-header-cell *matHeaderCellDef> arm </th>
            <td mat-cell *matCellDef="let element">
                <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.arm}}</div>
                <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'arm')" [(ngModel)]="element.arm" />
            </td>
        </ng-container>
        <ng-container matColumnDef="chest">
            <th mat-header-cell *matHeaderCellDef> chest </th>
            <td mat-cell *matCellDef="let element">
                <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.chest}}</div>
                <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'chest')" [(ngModel)]="element.chest" />
            </td>
        </ng-container>
        <ng-container matColumnDef="waist">
            <th mat-header-cell *matHeaderCellDef> waist </th>
            <td mat-cell *matCellDef="let element">
                <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.waist}}</div>
                <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'waist')" [(ngModel)]="element.waist" />
            </td>
        </ng-container>
        <ng-container matColumnDef="hip">
            <th mat-header-cell *matHeaderCellDef> hip </th>
            <td mat-cell *matCellDef="let element">
                <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.hip}}</div>
                <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'hip')" [(ngModel)]="element.hip" />
            </td>
        </ng-container>
        <ng-container matColumnDef="thigh">
            <th mat-header-cell *matHeaderCellDef> thigh </th>
            <td mat-cell *matCellDef="let element">
                <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.thigh}}</div>
                <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'thigh')" [(ngModel)]="element.thigh" />
            </td>
        </ng-container>
        <ng-container matColumnDef="calf">
            <th mat-header-cell *matHeaderCellDef> calf </th>
            <td mat-cell *matCellDef="let element">
                <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.calf}}</div>
                <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'calf')" [(ngModel)]="element.calf" />
            </td>
        </ng-container>
        <ng-container matColumnDef="forearm">
            <th mat-header-cell *matHeaderCellDef> forearm </th>
            <td mat-cell *matCellDef="let element" class="last-cell">
                <span class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.forearm}}</span>
                <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'forearm')" [(ngModel)]="element.forearm" />
                <button *ngIf="element.active" [ngClass]="{'highlight': !element.edit}" (click)="saveCircumferenceData(element)">Save</button>
                <div class="actions">
                    <span *ngIf="!element.active && element.id != 'total'">
                        <mat-icon (click)="element.active = true" >edit</mat-icon>
                    </span>
                    <span *ngIf="!element.active && element.id != 'total'" [ngStyle]="{'color':'red','padding-left':'5px;'}">
                        <mat-icon (click)="onDeleteCircumference(element)">delete</mat-icon>
                    </span> 
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="circumColumnsList"></tr>
        <tr mat-row *matRowDef="let row; columns: circumColumnsList;"></tr>
    </table>
    <div *ngIf="!circumference.length" class="emtmsg">No records found</div>
    <div class="note-info">Measurements are in inches</div>
    <div class="marg-btm-50"></div>
    <div>
        <div class="title-container">
            <span class="title">Vitals</span>
        </div>

        <table mat-table [dataSource]="vitals" class="mat-elevation-z8" >

            <ng-container matColumnDef="measured_at">
                <th mat-header-cell *matHeaderCellDef> DATE </th>
                <td mat-cell *matCellDef="let element" class="dateColumn" >
                    <ng-container  *ngIf="element.id !== 'total'">
                    <input [matDatepicker]="basicDatepicker" [(ngModel)]="element.startDate">
                    <mat-datepicker-toggle [for]="basicDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #basicDatepicker></mat-datepicker>
                    </ng-container>
                    <span *ngIf="element.id === 'total'">{{element.measured_at}}</span>
                    {{element.active ? '(Active)' : ''}}
                </td>
            </ng-container>

            <ng-container matColumnDef="resting_heart_rate">
                <th mat-header-cell *matHeaderCellDef> Resting Heart Rate </th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.resting_heart_rate}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'resting_heart_rate')" [(ngModel)]="element.resting_heart_rate" />
                </td>
            </ng-container>

            <ng-container matColumnDef="active_heart_rate">
                <th mat-header-cell *matHeaderCellDef> Active Heart Rate </th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.active_heart_rate}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'active_heart_rate')" [(ngModel)]="element.active_heart_rate" />
                </td>
            </ng-container>

            <ng-container matColumnDef="resting_blood_pressure">
                <th mat-header-cell *matHeaderCellDef> Systolic Blood Pressure <span>(mm Hg)</span> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.resting_blood_pressure}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'resting_blood_pressure')" [(ngModel)]="element.resting_blood_pressure" />
                </td>
            </ng-container>

            <ng-container matColumnDef="active_blood_pressure">
                <th mat-header-cell *matHeaderCellDef> Diastolic Blood Pressure <span>(mm Hg)</span> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.active_blood_pressure}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'active_blood_pressure')" [(ngModel)]="element.active_blood_pressure" />
                </td>
            </ng-container>

            <ng-container matColumnDef="o2_saturation">
                <th mat-header-cell *matHeaderCellDef> Oxygen Saturation<span>(%)</span> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.o2_saturation}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'o2_saturation')" [(ngModel)]="element.o2_saturation" />
                </td>
            </ng-container>

            <ng-container matColumnDef="body_temperature">
                <th mat-header-cell *matHeaderCellDef> Body Temperature  <span>(&deg;F)</span> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.body_temperature}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'body_temperature')" [(ngModel)]="element.body_temperature" />
                </td>
            </ng-container>

            <ng-container matColumnDef="vo2_max">
                <th mat-header-cell *matHeaderCellDef> V02 Max </th>
                <td mat-cell *matCellDef="let element" class="last-cell">
                    <div class="padd-left-5" *ngIf="!element.active || element.edit">{{element?.vo2_max}}</div>
                    <input *ngIf="element.active && !element.edit" (change)="inputValidator(element, 'vo2_max')" [(ngModel)]="element.vo2_max" />
                    <button *ngIf="element.active" [ngClass]="{'highlight': !element.edit}" (click)="onSaveVital(element)">Save</button>
                    <div class="actions">
                        <span *ngIf="!element.active && element.id != 'total'">
                            <mat-icon (click)="element.active = true" >edit</mat-icon>
                        </span>
                        <span *ngIf="!element.active && element.id != 'total'" [ngStyle]="{'color':'red','padding-left':'5px;'}">
                            <mat-icon (click)="onDeleteVital(element)">delete</mat-icon>
                        </span> 
                    </div>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="vitalsColumnList"></tr>
            <tr mat-row *matRowDef="let row; columns: vitalsColumnList;"></tr>
        </table>
    </div>
</div>