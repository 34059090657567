<div class="signin-content">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="formUsername" (ngSubmit)="onUserNameSubmit()">
        <p>Please login to continue</p>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="User" formControlName="userName" required>
          <mat-error *ngIf="isFieldUsernameInvalid('userName')">
            Please enter user name
          </mat-error>
        </mat-form-field>

        <mat-error *ngIf="invalidLogins">
          Invalid Logins, Please check logins
        </mat-error>

        <button mat-raised-button color="primary" type="submit">Submit</button>
      </form>
      <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <p>Please login to continue</p>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="User" formControlName="userName" required>
          <mat-error *ngIf="isFieldInvalid('userName')">
            Please enter user name
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="Password" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('userName')">
            Please enter password
          </mat-error>
        </mat-form-field>

        <mat-error *ngIf="invalidLogins">
          Invalid Logins, Please check logins
        </mat-error>

        <button mat-raised-button color="primary" type="submit">Login</button>
      </form> -->
      <div>
        <button type="button" (click)="loginWithGoogle()" class="btn btn-danger">Login with Google</button>
      </div>
      <div id="appleid-signin" class="signin-button" data-color="black" data-border="true" data-type="sign in"></div>
    </mat-card-content>
  </mat-card>
</div>
<!-- <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="box">
      <mat-card-header>
        <mat-card-title>Log in</mat-card-title>
      </mat-card-header>
  
      <form class="example-form">
        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Username">
          </mat-form-field>
  
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Password">
          </mat-form-field>
        </mat-card-content>
        <button mat-stroked-button color="accent" (click)="login()" class="btn-block">Log in</button>
      </form>
    </mat-card>
  
  </div> -->