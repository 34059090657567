<div>
    <div class="main-header-survey"
        [ngStyle]="{'width': totalProgress + '%' }"
    >
    </div>
    <div class="main-container">
        <div class="getting-know">
            Let's Finish Signing You Up!
        </div>
        <div class="would-love">
            Please add your contact information so we can get your account ready.
        </div>
        <div class="sub-container">
            <p class="error-info">{{errorInfo}}</p>
            <div class="row">
                <div class="col-33">
                    <label>First Name <span>*</span></label>
                    <div>
                        <input class="input" [(ngModel)]="userData.firstname" (change)="onInputChange()" />
                    </div>
                </div>
                <div class="col-33">
                    <label>Last Name <span>*</span></label>
                    <div>
                        <input class="input" [(ngModel)]="userData.lastname" (change)="onInputChange()" />
                    </div>
                </div>
                <div class="col-33">
                    <label>Phone Number <span>*</span></label>
                    <div>
                        <input class="input" [(ngModel)]="userData.mobilephone" (change)="onInputChange()" />
                    </div>
                </div>
            </div>
            <div>
                <div class="social-actions">
                    <p class="error-info">{{invalidEmail}}</p>

                    <button class="social-btn btn-google"
                        (click)="loginWithGoogle()"
                    >
                        <img src="/assets/google.jpg"  alt="Google" class="social-icons" />
                        <span>Sign in with Google</span>
                    </button>
                    <button class="social-btn btn-apple"
                        (click)="loginWithApple()"
                    >
                        <img src="/assets/apple.png"  alt="Apple" class="social-icons" />
                        <span>Sign in with Apple</span>
                    </button>
                    <button class="social-btn btn-facebook"
                        (click)="loginWithFacebook()"
                    >
                        <img src="/assets/fb.ico"  alt="Facebook" class="social-icons" />
                        <span>Sign in with Facebook</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="btm-ftr"></div>
    <div >
        <div class="col-50">
            <div class="ftr-back">
                <button mat-stroked-button (click)="onBack()">Back</button>
            </div>
        </div>
        <div class="col-50">
            <div class="ftr-action">
                <button mat-raised-button color="primary" (click)="onFinish()" >Finish</button>
            </div>
        </div>
    </div>
</div>