import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { ToasterService } from '../../services/toaster/toaster.service';
import { UiService } from '../../services/uiloader/uiloader.service';

@Component({
  selector: 'app-exercisesuploadercreate',
  templateUrl: './exercisesuploadercreate.component.html',
  styleUrls: ['./exercisesuploadercreate.component.sass']
})
export class ExercisesuploadercreateComponent implements OnInit {
  exerciseName: string = '';
  newExerciseCategory: string = '';
  selected_sub_exercise_category = -1;
  selected_exercise_category = -1;
  description: string = '';
  note: string = '';
  videoUrl: string = '';
  subcat: string = '';
  message: string = '';
  validationErrors: any = {} 
  
  @ViewChild('imageInput', {static: false})
  imageInputVariable: ElementRef;

  @Input() exercisesCategories: any;



  exerciseImageSequence: any = [];
  exerciseImageSequenceFormData: any = [];

  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private ui: UiService) { }

  ngOnInit(): void {
  }

  onChangeExerciseCategory() {
    this.newExerciseCategory = '';
    this.selected_sub_exercise_category = -1;
  }

  onChangeExerciseSubCategory() {
    this.selected_exercise_category = -1;
  }

  onAction(event: any) {
    if (event.type === 'exerciseName') {
      this.exerciseName = event.info;
    } else if (event.type === 'newExerciseCategory') {
      this.newExerciseCategory = event.info;
    } else if (event.type === 'videoUrl') {
      this.videoUrl = event.info;
    }
  }

  clearValues() {
    this.exerciseName = '';
    this.selected_sub_exercise_category = -1;
    this.description = '';
    this.videoUrl = '';
    this.note = '';
    this.newExerciseCategory = '';
    this.selected_exercise_category = -1;
    this.imageInputVariable.nativeElement.value = "";
    this.exerciseImageSequence= [];
    this.exerciseImageSequenceFormData = [];
  }

  onSubmitExercise() {
    this.validationErrors = {};
    let validationErrorMsg:any = {"header":"Validation errors", "message":""};
    const obj = {
      exercisename: this.exerciseName,
      subcat: this.selected_sub_exercise_category,
      description: this.description,
      video_url: this.videoUrl,
      note: this.note,
      exercisecategory: this.newExerciseCategory || this.selected_exercise_category
    };

    if(obj.exercisename==""){
      this.validationErrors['exerciseName'] = "Exercise Name is required";
      validationErrorMsg['message'] = this.validationErrors['exerciseName']+", ";
    }
    if(obj.exercisecategory=="" || obj.exercisecategory<=0){
      this.validationErrors['exercisecategory'] = "Exercise Category is required";
      validationErrorMsg['message'] += this.validationErrors['exercisecategory']+", ";
    }
    
    if (Object.keys(this.validationErrors).length) {
      validationErrorMsg['message'] = validationErrorMsg['message'].trim();
      validationErrorMsg['message'] = validationErrorMsg['message'].substring(0, validationErrorMsg['message'].length - 1);
      this.toasterService.show('error', validationErrorMsg['header'], validationErrorMsg['message']);
      return
    }
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/exercises/createexercisetemplate`;
    this.httpService.postCall(url, obj).subscribe(result => {
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Exercise template created succesfully');
      this.onUploadImage(result.success.id);
      this.clearValues();
    }, err => {
      this.ui.spin$.next(false);
      let errMsg = (err.error.message && err.error.message != "") ? err.error.message : "Error occurred, Please try again";
      this.toasterService.show('error', 'Error Occurred', errMsg);
    });

  }

  onResetExercise() {
    this.clearValues();
  }


  toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async preview(files: any) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    this.exerciseImageSequenceFormData.push(files[0]);

    const data: any = await this.toBase64(files[0]);
    const modified = data.split('base64,');

    this.exerciseImageSequence.push(data);

  }

  onUploadImageSequence(fileInputEvent: any) {
    var files = fileInputEvent.target.files;
    this.preview(files);
  }

  onUploadImage(templateId: number) {
    if (this.exerciseImageSequenceFormData.length == 0) {
      return false;
    }
    const formData = new FormData();
    this.exerciseImageSequenceFormData.forEach((item: any) => {
      formData.append("sequence", item);
    });

    const url = `${config.apiUrl}/exercises/uploadimages/${templateId}`;

    this.httpService.putCall(url, formData).subscribe((result) => {
      this.exerciseImageSequence = [];
      this.exerciseImageSequenceFormData = [];
    });
    return true;
  }
}
