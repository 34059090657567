import { Component, OnInit, ElementRef } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';
import * as Highcharts from 'highcharts';
import { config } from '../config';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../services/toaster/toaster.service';

@Component({
  selector: 'app-composition',
  templateUrl: './composition.component.html',
  styleUrls: ['./composition.component.sass']
})
export class CompositionComponent implements OnInit {

  constructor(private commonService: CommonService,
    private ui: UiService,
    private logService: LogService,
    private httpService: HttpService,
    private elementref:ElementRef,
    private route:ActivatedRoute,
    private toasterService:ToasterService) { }

  ngOnInit(): void {
    const routes:any = [
      { name: 'Composition', path: 'composition'}
    ];
    this.commonService.setHeaderData(routes);
    this.ui.spin$.next(true);
    this.route.paramMap.subscribe((params: any) => {
      const program_id = params.get('programId');
      if (program_id) {
        this.initCall(program_id);
      }
      else {
        this.getPrograms();
      }
    });  
  }
  displayedColumns: string[] = ['date', 'weight', 'lmuscle', 'waterweight', 'fatmass'];
  bodyfatColumns: string[] = ['date', 'bodyfat'];
  circumferenceColumns:string[] = ['date', 'neck', 'arm', 'chest', 'waist', 'hip', 'thigh', 'calf', 'forearm'];
  Highcharts: typeof Highcharts = Highcharts;
  weightChart:any;
  bodyFatChart: any;
  programsData:any;
  program_id: any;
  timeline: any;
  configuration: any = {
    credits: {enabled: false},
    plotOptions: {
      series: {
        marker: { enabled: true}
      }
    },
    chart: {
      type: 'spline',
    },
    title: {
      text: "Weight chart",
      align: 'left',
      style: {        
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#11101D'
      }
    },
    yAxis: {
      title: { text: '' },
      gridLineWidth: 0,
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineColor: '#E4E4E4',
      lineWidth: 0,
    },
    series: [
      { name: 'Weight', data: [0] },
      { name: 'Lean Muscle', data: [0] },
    ]
  };
  bfConfiguration = this.configuration;
  circumferenceMeasures:any = [];
  weightTabledata:any = [];
  clientDetais:any ={};

  formatDate(date:Date) {
    let dateobj = new Date(date);
    return (dateobj.getMonth()+1) +'/'+ dateobj.getDate() +'/'+ dateobj.getFullYear();
  }

  initCall(data:any = '') {
    this.httpService.postCall(`${config.apiUrl}/getclientcompositions`, {'program_id': data}).subscribe(data => {
      console.log(data);
      this.formatTableData(data);
      this.setWeightChartValue(data);
      this.setBodyFatChartValue(data);
      this.program_id = data.programGoal.id;
      this.formatTimeline(data);
      this.ui.spin$.next(false);
    },
    err => {
      this.ui.spin$.next(false);
      this.logService.logError(err);
    });
  }

  async downloadAsPDF() {
    const url = `${config.apiUrl}/getmeasurementspdf/all/${this.clientDetais.id}/${this.program_id}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while created successfully');
      console.log(err);
    });
    return true;
  }

  formatTimeline(data:any) {
    if(data.programGoal) {
      const goalData = data.programGoal.program_availability;
      const start = this.commonService.getTextMonthFormat(goalData.program_startdate);
      // let end:any = this.commonService.addDays(goalData.packagepurchased, goalData.start_date);
      let end = goalData.program_enddate ? this.commonService.getTextMonthFormat(goalData.program_enddate) : "Until date";
      this.timeline = `${start.split(',')[0]} - ${end}`;
    }
  }

  formatTableData(data:any) {
    if(data.compositions) {
      this.circumferenceMeasures = data.compositions.map((obj:any) => {
        return {
          date: this.formatDate(obj.measured_at), 
          neck: obj.neck, 
          arm: obj.arm, 
          chest: obj.chest, 
          waist: obj.waist, 
          hip: obj.hip, 
          thigh: obj.thigh, 
          calf: obj.calf, 
          forearm: obj.arm
        }
      });
      this.weightTabledata = data?.weights?.map((obj:any) => {
        return {
          date: this.formatDate(obj.measured_at), 
          bodyfat: obj.bodyfat, 
          weight: obj.weight, 
          lmuscle: obj.leanmuscle, 
          waterweight: obj.waterweight, 
          fatmass: obj.fatmass
        }
      });
    }
  }

  ngAfterViewInit() {
    this.configuration.chart['renderTo'] = this.elementref.nativeElement.querySelector('#weightChart');
    this.weightChart = Highcharts.chart(this.configuration);
    this.bfConfiguration.series = [{name: 'Body Fat', data: [0]}];
    this.bfConfiguration.title.text = "Body Fat Chart";
    this.configuration.chart['renderTo'] = this.elementref.nativeElement.querySelector('#bodyFatChart');
    this.bodyFatChart = Highcharts.chart(this.bfConfiguration);
  }

  setWeightChartValue(data:any) {
    if(data.weights){
      this.weightChart.series[0].setData(data.weights.map((obj:any) => obj.weight));
      this.weightChart.series[1].setData(data.weights.map((obj:any) => obj.leanmuscle));
      this.weightChart.xAxis[0].update({categories: data.weights.map((obj:any) => this.formatDate(obj.measured_at)), reversed: true});
    }
  }

  setBodyFatChartValue(data:any) {
    if(data.weights){
      this.bodyFatChart.xAxis[0].update({categories: data.weights.map((obj:any) => this.formatDate(obj.measured_at)), reversed: true});
      this.bodyFatChart.series[0].setData(data.weights.map((obj:any) => obj.bodyfat));
    }
  }

  getPrograms() {
    this.httpService.postCall(`${config.apiUrl}/getclientprograms`, {}).subscribe(data => {
      this.clientDetais = data.clientDetais;
      this.programsData = data.programs.map((ob:any, index:any) => {
        if(index === 0) {
          ob.name = 'Current Session';
        }
        return ob;
      })
      this.initCall(this.programsData[0].id);
    }, 
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  onChangeProgram(ev:any) {
    this.ui.spin$.next(true);
    this.initCall(ev.value);
  }
}
