<div class="main-content">
  <div>
    <span class="title">Uploader</span>
  </div>
  <div class="content-section">
    <div>
      <mat-card class="main-card"
        (click)="onUploaderItem('foods-uploader')">
        <mat-icon>supervised_user_circle</mat-icon>
        <span class="card-title">Foods Uploader</span>
      </mat-card>
      <mat-card class="main-card"
      (click)="onUploaderItem('exercises-uploader')">
      <mat-icon>supervised_user_circle</mat-icon>
      <span class="card-title">Exercises Uploader</span>
    </mat-card>
    <mat-card class="main-card"
    (click)="onUploaderItem('meals-uploader')">
    <mat-icon>supervised_user_circle</mat-icon>
    <span class="card-title">Meals Uploader</span>
  </mat-card>
  <mat-card class="main-card"
  (click)="onUploaderItem('recipes-uploader')">
  <mat-icon>supervised_user_circle</mat-icon>
  <span class="card-title">Recipes Uploader</span>
</mat-card>
<mat-card class="main-card"
(click)="onUploaderItem('nutrition-program-uploader')">
<mat-icon>supervised_user_circle</mat-icon>
<span class="card-title">Nutrition Program Uploader</span>
</mat-card>
<mat-card class="main-card"
(click)="onUploaderItem('exercise-plan-uploader')">
<mat-icon>supervised_user_circle</mat-icon>
<span class="card-title">Exercise Plan Uploader</span>
</mat-card>
    </div>
  </div>
</div>
