import { Component, OnInit, Input, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpService } from '../../services/http/http.service';
import { LogService } from '../../services/log/log.service';
import { config } from '../../config';
import { UiService } from '../../services/uiloader/uiloader.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

let exeTimer: any;

@Component({
  selector: 'app-exercisesuploaderedit',
  templateUrl: './exercisesuploaderedit.component.html',
  styleUrls: ['./exercisesuploaderedit.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ExercisesuploadereditComponent implements OnInit {
  deletehighlight: boolean = false;
  searchTxt: string = '';
  isAllSelected: any = false;
  imageCustomUrl = config.exerciseImageUrl;
  exercisesTemplates = [];
  searchText = '';
  @Input() exercisesCategories: any;

  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  datasource: any = [];
  // columnsList: string[] = ['name', 'description', 'note', 'category', 'videourl'];
  columnsList: string[] = ['name', 'description', 'note', 'category', 'video_url'];

  constructor(private httpService: HttpService,
    private ui: UiService,
    private toasterService: ToasterService,
    private logService: LogService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    //Calling on tab change event. So commented this from here. After creating the new exercise also, it is not getting refreshed, so calling from here will do that.
    // this.getExercisesTemplatesList(); 
    this.exercisesTemplates = [];
    this.searchText = '';
  }

  getExercisesTemplatesList() {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/exercises/getexercisetemplates/`;
    const reqObj = {
      "orderByOn": "name",
      "orderBy": "ASC",
      "limit": this.pagination.perPage,
      "fromIndex": this.pagination.pageIndex,
      "searchtext": this.searchText
    };
    this.httpService.postCall(url, reqObj).subscribe(result => {
      this.datasource = result.rows.map((item: any) => ({
        ...item,
        isExpanded: false
      }
      ));
      this.pagination.count = result.count;
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

  onUploadImageSequence(fileInputEvent: any, index: number) {
    var file = fileInputEvent.target.files[0];
    const row = this.datasource[index];
    const formData = new FormData();
    formData.append("sequence", file);
    const url = `${config.apiUrl}/exercises/uploadimages/${row.id}`;
    this.httpService.putCall(url, formData).subscribe((result) => {
      this.datasource[index].exercisetemplateimages = result;
      this.toasterService.show('success', 'Success', 'Image uploaded successfully');
    });
  }

  deleteExercises() {
    let dialogRef = this.dialog.open(NavCopyDialog, { data: "" });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ui.spin$.next(true);
        const payload = this.datasource.filter((obj: any) => obj.checked).map((obj: any) => obj.id);
        this.httpService.putCall(`${config.apiUrl}/exercises/deleteexercisetemplate`, { exercisetemplate_ids: payload }).subscribe((result: any) => {
          this.datasource = this.datasource.filter((val: any) => !val.checked);
          this.masterSelection();
          this.toasterService.show('success', 'Success', 'Exercise template deleted successfully');
          this.ui.spin$.next(false);
          this.getExercisesTemplatesList();
        }, (err: any) => {
          this.logService.logError(err);
          this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
          this.ui.spin$.next(false);
        });
      }
    });
  }

  deleteExerciseTemplateImage(template_id: number, image_id: number, index: number) {
    if(template_id && template_id>0 && image_id && image_id>0){
      this.ui.spin$.next(true);
      this.httpService.deleteCall(`${config.apiUrl}/exercises/deleteexercisetemplateimage/${template_id}/${image_id}`,{}).subscribe((result: any) => {
        this.datasource[index].exercisetemplateimages = result;
        this.ui.spin$.next(false);
        this.toasterService.show('success', 'Success', 'Image deleted successfully');
      }, (err: any) => {
        this.logService.logError(err);
        this.toasterService.show('error', 'Error', (err?.error?.error) ? err?.error?.error : err?.error?.msg);
        this.ui.spin$.next(false);
      });
    }else {
      this.toasterService.show('error', 'Validation error', "Image does not exist to delete");
    }
  }

  searchRecords(text: string) {
    this.searchText = text;
    this.pagination.pageIndex = 0;
    this.getExercisesTemplatesList();
  }

  searchResult(ev: any) {
    clearTimeout(exeTimer);
    exeTimer = setTimeout(() => {
      this.searchRecords(ev.target.value);
    }, 1000);
  }

  getServerData(e: any) {
    // console.log(e.pageIndex);
    // console.log(e.pageSize);
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.getExercisesTemplatesList();
  }

  masterSelection() {
    let highlgt = this.datasource.find((o: any) => o.checked == true);
    highlgt ? this.deletehighlight = true : this.deletehighlight = false;
  }

  setSelection(ev: any, row: any = '') {
    let overall = true;
    this.datasource = this.datasource.map((ob: any) => {
      if (row && ob.id === row.id) ob.checked = ev.checked;
      if (!row) ob.checked = ev.checked;
      if (!ob.checked) overall = false;
      return ob;
    });
    this.masterSelection();
    this.isAllSelected = overall;
  }

  enableEdit(row: any, field: any, i: number) {
    this.datasource[i][`${field}Edit`] = true;
  }

  saveNewValue(row: any, key: string, i: number) {
    const url = `${config.apiUrl}/exercises/updateexercisetemplate/${row.id}`;
    let req: any = {
    }
    if (key === 'category') {
      req.exercisecategory = row.exercisetemplatecategory_id;
    } else {
      req[key] = row[`${key}`];
    }
    if(req[key]=="" || req.exercisecategory=="" || req.exercisecategory<=0){
      this.toasterService.show('error', "Invalid "+key+" value", key+" value is required to update");
      return;
    }
    
    this.httpService.putCall(url, req).subscribe(result => {
      if (key === 'category') {
        const records = this.exercisesCategories.filter((item: any) => item.id === row.exercisetemplatecategory_id);
        this.datasource[i].exercisetemplatecategory.name = records[0].name;
      }
      // else {
      //   console.log("=============", key);
      //   this.datasource[i][key] = req.key;
      // }
      this.cancelEdit(row, key, i);
      this.toasterService.show('success', 'Success', 'Updated successfully');
    }, err => {
      this.toasterService.show('error', 'Failed', 'Failed to update');
    });
  }

  cancelEdit(row: any, field: string, i: number = 5) {
    this.datasource[i][`${field}Edit`] = false;
  }

  clearSearchTxt() {
    //To clear the search text on tab change.
    if (this.searchTxt != "") {
      this.searchTxt = "";
      this.searchRecords("");
    }
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <h1 mat-dialog-title>Delete Exercise uploader</h1>
  <div mat-dialog-content class="dialogContent">
      <p>Are you sure? This cannot be undone.</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
  styleUrls: ['/../../exercisesuploader/exercisesuploader.component.sass']
})
export class NavCopyDialog {
  constructor(
    public dialogRef: MatDialogRef<NavCopyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}
