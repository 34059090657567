import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data/data.service';
import { Location } from '@angular/common';
import { UiService } from '../services/uiloader/uiloader.service';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { CommonService } from '../services/common/common.service';
import { LogService } from '../services/log/log.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { SurveysummaryComponent } from '../surveysummary/surveysummary.component';

@Component({
  selector: 'app-prospectprofile',
  templateUrl: './prospectprofile.component.html',
  styleUrls: ['./prospectprofile.component.sass']
})
export class ProspectprofileComponent implements OnInit {
  prospectProfile:any = {};
  selectedItem:string = '';
  userNamesData:any = [];
  consultantData:any = [];
  isDisplayDeleteProspect:boolean = true;
  pagination = {
    count: 0,
    perPage: 500,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  statusData:any = [
    {
      key: 1,
      value: 'Active'
    },
    {
      key: 0,
      value: 'InActive'
    }
  ];

  constructor(private dataService: DataService, private location: Location, private logService: LogService,
    private ui: UiService, private httpService: HttpService, private commonService: CommonService,
    private toasterService: ToasterService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.prospectProfile = this.dataService.getProspectSelected();
    this.setConsultantData();
    this.setUserNamesData(this.prospectProfile);
    this.commonService.gaEvent('prospect_profile_initialize', 'prospect_profile_initialize', 'On initializing prospect profile');
    this.commonService.setHeaderData([
      { name: 'Prospects', path: 'prospects'},
      { name: `${this.prospectProfile.firstname} ${this.prospectProfile.lastname}`, path: 'prospectprofile'}
    ]);
  }

  setConsultantData() {
    const { consultant } = this.prospectProfile;
    this.commonService.gaEvent('prospect_profile_consultant_set', 'prospect_profile_consultant_set', 'Setting consultant in Prospect Profile');

    if (consultant) {
      this.prospectProfile.consultant_id = consultant.id;
      this.prospectProfile.consultant = (consultant.firstname || consultant.lastname) ? `${consultant.firstname} ${consultant.lastname}` : consultant.email
    }
  }

  setUserNamesData(data:any) {
    this.userNamesData = [
      {
        key: 'firstname',
        value: data.firstname,
        placeholder: 'First Name'
      },
      {
        key: 'lastname',
        value: data.lastname,
        placeholder: 'Last Name'
      }
    ];
  }

  onGoBack() {
    this.commonService.gaEvent('back_to_prospect', 'back_to_prospect', 'Back to Prospect from Prospect Profile');
    this.location.back();
  }

  getFormatConsultants(data:any) {
    const { rows = [] } = data;
    return rows.map((item:any) => ({
      key: item.id,
      value: (item.firsname || item.lastname) ? `${item.firstname} ${item.lastname}` : item.email
    }));
  }

  getConsultants() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: this.pagination.pageIndex,
      limit: this.pagination.perPage,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: this.pagination.searchtext
    };
    this.httpService.postCall(`${config.apiUrl}/companyadmin/getconsultants`, obj).subscribe((data:any) => {
      this.consultantData = this.getFormatConsultants(data);
      this.selectedItem = 'consultant_id';
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }
  onItemSelect(val:string) {
    if (val === 'consultant_id' && this.consultantData.length === 0) {
      this.getConsultants();
    } else {
      this.selectedItem = val;
    }
  }

  getStatus(key: string) {
    const val = this.statusData.filter((item:any) => item.key == key );
    return val[0];
  }

  onDeleteProspect() {
    this.ui.spin$.next(true);
    this.httpService.deleteCall(`${config.apiUrl}/companyadmin/deleteprospect/${this.prospectProfile.id}`, {user_id: this.prospectProfile.id}).subscribe((data:any) => {
      this.ui.spin$.next(false);
      this.isDisplayDeleteProspect = false;
    },
    err => {
      this.toasterService.show('error', 'Error', 'Error occurred, Please try again');
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  onAction(item:any) {
    if (item.isSave) {
      this.ui.spin$.next(true);
      const obj = {
          client_id: this.prospectProfile.id,
          [this.selectedItem]: item.value
        };
        if (!obj.consultant_id) {
          obj.consultant_id = this.prospectProfile.consultant_id;
        }
        if (!('enabled' in obj)) {
          obj.enabled = this.prospectProfile.enabled;
        }
      this.httpService.postCall(`${config.apiUrl}/companyadmin/assignprospecttoconsultant`, obj).subscribe((data:any) => {
        if (this.selectedItem === 'enabled') {
          this.prospectProfile[this.selectedItem] = item.value
        } else if (this.selectedItem === 'consultant_id') {
          this.prospectProfile.consultant_id = item.value;
          const matchedRecord = this.consultantData.filter( (rec: any) => rec.key === item.value);
          if (matchedRecord.length > 0) {
            this.prospectProfile.consultant = matchedRecord[0].value;
          }

        }
        this.selectedItem = '';
        this.ui.spin$.next(false);
      },
      err => {
        this.logService.logError(err);
        this.selectedItem = '';
        this.ui.spin$.next(false);
      });
    } else {
      this.selectedItem = '';
    }
  }

  onSurveyResults() {
    const dialogRef = this.dialog.open(SurveysummaryComponent, {
      height: 'calc(100vh - 90px)',
      width: 'calc(100% - 200px)',
      data  :  {
        person: {
          userId: this.prospectProfile.id
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
