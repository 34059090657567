import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderService {
  private header: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  get headerData() {
    return this.header.asObservable();
  }

  constructor(
  ) {}

  setHeaderData(data: any) {
      this.header.next(data);
  }

}
