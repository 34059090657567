import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {FormControl} from '@angular/forms';
import { CommonService } from '../services/common/common.service';
import * as Highcharts from 'highcharts';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { AuthService } from '../auth/auth.service';
import { AppConstants } from '../constants/appConstants';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-clientdashboard',
  templateUrl: './clientdashboard.component.html',
  styleUrls: ['./clientdashboard.component.sass']
})
export class ClientdashboardComponent implements OnInit,OnDestroy {
  isDisplayClientDashboard: boolean = false;
  maxDate:any = new Date();
  @Input() selectedUserRole:any = '';
  @Input() userId:any =  '';

  errorMsg:string = '';
  constructor(public commonService: CommonService, private httpService: HttpService,
    private loader: UiService,
    private toasterService: ToasterService,
    private authService: AuthService, private platformLocation:PlatformLocation, private route:ActivatedRoute) { 
      history.pushState(null, '', location.href);
      this.platformLocation.onPopState(()=>{
        history.pushState(null, '', location.href)
      })

    }
    isTodayDate = true;
  dashboardData:any = {
    exercise: {},
    goal: {},
    nutrition: {
      calories: {}
    },
    weights: [{}]
  };

  isDashboardLoaded:boolean = false;

  exerciseData: any = [{
    title: 'Exercises',
    actual: '2',
    total: '3',
    percent: 40
  },
{
  title: 'Total Sets',
  actual: 5,
  total: 6,
  percent: 60
},
{
  title: 'Total Reps',
  actual: 10,
  total: 20,
  percent: 50
},
{
  title: 'Cardio',
  actual: 8,
  total: 10,
  percent: 70
}];

goalsDate = new FormControl(new Date());

updateNutritionFlag = false;
isConsultantView =false;
nutritionChartOptions: Highcharts.Options = {
  title: {
      text: '',
      align: 'left'
  },
  credits: {
    enabled: false
  },
  xAxis: {
      categories: [
          'Protein <br/> <span>0</span',
          'Fat<br/> <span>0</span',
          'Carbs<br/> <span>0</span'
      ],
      gridLineWidth: 0,
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '16px',
          textAlign: 'left',
          color: '#11101D'
        }
      }
  },
  yAxis: [{
      min: 0,
      title: {
          text: ''
      },
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
  }, {
      title: {
          text: ''
      },
      opposite: true
  }],
  legend: {
      enabled: false
  },
  tooltip: {
      shared: true
  },
  plotOptions: {
      column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
      }
  },
  series: [{
    data: [12, 8, 43],
    color: '#C4C4C4',
    enableMouseTracking: false,
    pointPadding: 0.39,
    pointPlacement: -0.1,
    borderRadius: 5,
    type: 'column'
  },
  {
    data: [6, 4, 30],
    color: '#11101D',
    enableMouseTracking: false,
    pointPadding: 0.39,
    pointPlacement: -0.15,
    borderRadius: 5,
    type: 'column'
  }]
  }
  unsubscriber:Subject<void> =new Subject<void>();

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab) {
        this.isConsultantView = true;
      }
      else {
        const id = this.route.snapshot.paramMap.get('id');
        if (id) this.isConsultantView = true;
      }
    })

    if (!this.selectedUserRole) {
      const routes:any = [
        { name: 'Dashboard', path: 'clientdashboard'}
      ];
      this.commonService.setHeaderData(routes);
    }
    const userRole = parseInt(this.authService.getUserRole());
      if (AppConstants.Constants.CLIENT_ROLE === userRole || this.selectedUserRole === AppConstants.Constants.CLIENT_ROLE) {
        this.isDisplayClientDashboard = true;
      } else {
        this.isDisplayClientDashboard = false;
      }
    if (this.isDisplayClientDashboard) {
      this.getClientDashboard();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getClientDashboard() {
    this.loader.showSpinner();
    const url = `${config.apiUrl}/clientdashboard`;
    const reqData:any = {
      "logdate": this.commonService.getGeneralFormatDate(this.goalsDate.value)
    };
    const userIdVal = parseInt(this.userId);
    if (userIdVal > 0) {
      reqData['user_id'] = userIdVal;
    }

    this.httpService.postCall(url, reqData).subscribe(result => {
      this.isDashboardLoaded = true;
      const weeksNow = this.commonService.weeksBetween(new Date(result.goal.start_date));
      this.dashboardData = result;
      if (this.dashboardData.weights && this.dashboardData.weights.length === 0) {
        this.dashboardData.weights = [{ leanmuscle: 0, bodyfat: 0, weight: 0 }];
      }
      this.dashboardData.weeksNow = weeksNow;
      this.dashboardData.weightsDiffer = this.commonService.differValues(result.weights[result.weights.length - 1].weight, result.weights[0].weight);
      this.dashboardData.bodyfatDiffer = this.commonService.differValues(result.weights[result.weights.length - 1].bodyfat, result.weights[0].bodyfat);
      this.dashboardData.musclemassDiffer = this.commonService.differValues(result.weights[result.weights.length - 1].leanmuscle, result.weights[0].leanmuscle);
      this.calculateExercises(result);
      const xaxisData:any = this.nutritionChartOptions.xAxis;
      xaxisData.categories = [
        `Protein <br/> <span>${this.commonService.roundValues(result.nutrition.protein.consumed)} / ${this.commonService.roundValues(result.nutrition.protein.total)}g</span`,
        `Fat<br/> <span>${this.commonService.roundValues(result.nutrition.fat.consumed) } / ${ this.commonService.roundValues(result.nutrition.fat.total)}g</span`,
        `Carbs<br/> <span>${this.commonService.roundValues(result.nutrition.carbs.consumed) } / ${ this.commonService.roundValues(result.nutrition.carbs.total)}g</span`
    ];
      const seriesData:any = this.nutritionChartOptions.series; 
      seriesData[0].data = [
        result.nutrition.protein.total,
        result.nutrition.fat.total,
        result.nutrition.carbs.total
      ];
      seriesData[1].data = [
        result.nutrition.protein.consumed,
        result.nutrition.fat.consumed,
        result.nutrition.carbs.consumed
      ];
      this.updateNutritionFlag = true;
      this.calculateTopChartsSection(result);
      this.errorMsg = '';
      this.loader.stopSpinner();
    }, err => {
      this.loader.stopSpinner();
      this.errorMsg = err?.error?.message || '';
      if (!this.errorMsg) {
        this.errorMsg = err?.error?.error || '';
      }
      if (err.status !== 404 && err.status !== 500) {
        this.toasterService.show('error', 'Error Occurred', this.errorMsg);
      }
    });
  }

  formatDateToYYYYMMDD(date:any) {
    if (!(date instanceof Date)) {
        throw new Error('Invalid input; expected a Date object.');
    }
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    // Return formatted date string in "YYYYMMDD" format
    return `${year}${month}${day}`;
}

  navugateToClientPage(page:any){
    const url = `${config.apiUrl}/companyadmin/impersonate/${this.userId}`;
    this.httpService.getCall(url).subscribe((res)=>{
      this.authService.storeCompanyIdValuesAsMap(res.userData.id, res.companyAdminId);
      let sortDate = this.formatDateToYYYYMMDD(this.goalsDate.value).replace(/\-/gi,"");
      this.authService.onActAsUser(res, true, page == "exercise"  ? "CLIENT_ACTIVE_EXERCISE_PLAN" : "CLIENT_ACTIVE_NUTRITION_PLAN",sortDate);
    }, (err) => {
      console.log(err);
    });
  }

  calculateTopChartsSection(result: any) {
    const weights:any = [];
    const bodyfat:any = [];
    const leanmuscle:any = [];
    result.weights.forEach((item:any) => {
      weights.unshift(item.weight);
      bodyfat.unshift(item.bodyfat);
      leanmuscle.unshift(item.leanmuscle);
    });

    const dashboardWeight:any = this.dashboardWeightChartOptions.series; 
    dashboardWeight[0].data = weights;

    const dashboardBodyFat:any = this.dashboardBodyFatChartOptions.series; 
    dashboardBodyFat[0].data = bodyfat;

    const dashboardLeanMuscle:any = this.dashboardLeanMuscleChartOptions.series; 
    dashboardLeanMuscle[0].data = leanmuscle;
  }

  calculateExercises(result:any) {
    const order = ["exercises", "sets", "reps", "cardio"];
    order.forEach((key, index) => {
      this.exerciseData[index].total = result.exercise[key].total;
      this.exerciseData[index].actual = result.exercise[key].consumed;
      this.exerciseData[index].percent = this.commonService.getPercentageValue(result.exercise[key].consumed, result.exercise[key].total);
    });

  }

  highcharts = Highcharts;

  dashboardWeightChartOptions: Highcharts.Options = {
    title: {
      text: "",
    },
    credits: {
      enabled: false
    },
    xAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        labels: {
          enabled: false
        },
        visible: false
    },
    yAxis: {
      title: {
        text: ""
      },
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
          marker: {
              enabled: false
          }
      }
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
    series: [{
      data: [12, 8, 43, 35, 20, 90, 100, 110],
      type: 'spline',
      color: '#0080F5',
      enableMouseTracking: false
    }]
  }

  dashboardBodyFatChartOptions: Highcharts.Options = {
    title: {
      text: "",
    },
    credits: {
      enabled: false
    },
    xAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        labels: {
          enabled: false
        },
        visible: false
    },
    yAxis: {
      title: {
        text: ""
      },
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
          marker: {
              enabled: false
          }
      }
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
    series: [{
      data: [12, 8, 43, 35, 20, 90, 100, 110],
      type: 'spline',
      color: '#0080F5',
      enableMouseTracking: false
    }]
  }

  dashboardLeanMuscleChartOptions: Highcharts.Options = {
    title: {
      text: "",
    },
    credits: {
      enabled: false
    },
    xAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        labels: {
          enabled: false
        },
        visible: false
    },
    yAxis: {
      title: {
        text: ""
      },
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
          marker: {
              enabled: false
          }
      }
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
    series: [{
      data: [12, 8, 43, 35, 20, 90, 100, 110],
      type: 'spline',
      color: '#0080F5',
      enableMouseTracking: false
    }]
  }
    onDateSelect(event:any) {
      const datepickerDate = this.commonService.getGeneralFormatDate(this.goalsDate.value);
      const todayDate = this.commonService.getGeneralFormatDate(new Date());
      if (datepickerDate === todayDate) {
        this.isTodayDate = true;
      } else {
        this.isTodayDate = false;
      }
      this.getClientDashboard();
    }

  }
