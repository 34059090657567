<div class="main-container">
    <div class="header">
        Meals Uploader
    </div>
    <div>
        <mat-tab-group mat-align-tabs="start" >
            <mat-tab label="Create">
                <app-mealsuploadercreate [mealsCategories]="mealsCategories"></app-mealsuploadercreate>
            </mat-tab>
            <mat-tab label="Edit">
                <app-mealsuploaderedit [mealsCategories]="mealsCategories"></app-mealsuploaderedit>
            </mat-tab>
            <mat-tab label="Categories">
                <app-mealsuploadercategories [mealsCategories]="mealsCategories"></app-mealsuploadercategories>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>