<div class="main-container">
    <div class="header">
        Site Settings
    </div>
    <div>
        <div>
            <mat-form-field class="template-copy-width" appearance="fill" class="form_field_companies">
                <mat-label>All Companies</mat-label>
                <mat-select [(ngModel)]="selectedCompany.id">
                  <mat-option *ngFor="let company of allCompanies" [value]="company.id" (click)="onSelectCompany(company)">
                    {{company.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="rebuild-indices">
              <button mat-stroked-button color="primary" (click)="onRebuildIndices()" ><mat-icon class="settings-icon">settings</mat-icon>Rebuild indices for this company</button>
            </div>
        </div>
        <div>
            <div>
                Client dashboard nutrition color coding (Percentage compliance):
            </div>
            <div>
                <mat-grid-list cols="4" rowHeight="60px">
                    <mat-grid-tile class="color-range">
                        <span>Yellow:</span>
                        <mat-form-field class="width-set-input">
                            <mat-label>From</mat-label>
                            <input matInput placeholder="From" [(ngModel)]="colorcompliance.colorcompliance_yellow_min">
                        </mat-form-field>
                        <mat-form-field class="width-set-input">
                            <mat-label>To</mat-label>
                            <input matInput placeholder="To" [(ngModel)]="colorcompliance.colorcompliance_yellow_max">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="color-range">
                        <span>Green:</span>
                        <mat-form-field class="width-set-input">
                            <mat-label>From</mat-label>
                            <input matInput placeholder="From" [(ngModel)]="colorcompliance.colorcompliance_green_min">
                        </mat-form-field>
                        <mat-form-field class="width-set-input">
                            <mat-label>To</mat-label>
                            <input matInput placeholder="To" [(ngModel)]="colorcompliance.colorcompliance_green_max">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="color-range">
                        <span>Red:</span>
                        <mat-form-field class="width-set-input">
                            <mat-label>From</mat-label>
                            <input matInput placeholder="From" [(ngModel)]="colorcompliance.colorcompliance_red_min">
                        </mat-form-field>
                        <span>and beyond</span>
                    </mat-grid-tile>
                    <mat-grid-tile class="color-range">
                        <div><button mat-flat-button color="primary" (click)="onSaveCompliance()">Save Compliance</button></div>
                      </mat-grid-tile>
                  </mat-grid-list>
            </div>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="datasource">
                <ng-container matColumnDef="start_age">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Start Age</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'start_age')" *ngIf="!element.start_ageedit"> {{element.start_age}} </span>
                          <span *ngIf="element.start_ageedit" class="edit-column" >
                            <input [(ngModel)]="element.newstart_age" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'start_age')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'start_age')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="end_age">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">End Age</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'end_age')" *ngIf="!element.end_ageedit"> {{element.end_age}} </span>
                          <span *ngIf="element.end_ageedit" class="edit-column" >
                            <input [(ngModel)]="element.newend_age" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'end_age')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'end_age')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Gender</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'gender')" *ngIf="!element.genderedit"> {{element.gender}} </span>
                          <span *ngIf="element.genderedit" class="edit-column" >
                            <input [(ngModel)]="element.newgender" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'gender')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'gender')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="lean_start">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Lean Start</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'lean_start')" *ngIf="!element.lean_startedit"> {{element.lean_start}} </span>
                          <span *ngIf="element.lean_startedit" class="edit-column" >
                            <input [(ngModel)]="element.newlean_start" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'lean_start')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'lean_start')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="lean_end">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Lean End</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'lean_end')" *ngIf="!element.lean_endedit"> {{element.lean_end}} </span>
                          <span *ngIf="element.lean_endedit" class="edit-column" >
                            <input [(ngModel)]="element.newlean_end" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'lean_end')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'lean_end')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ideal_start">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Ideal Start</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'ideal_start')" *ngIf="!element.ideal_startedit"> {{element.ideal_start}} </span>
                          <span *ngIf="element.ideal_startedit" class="edit-column" >
                            <input [(ngModel)]="element.newideal_start" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'ideal_start')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'ideal_start')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ideal_end">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Ideal End</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'ideal_end')" *ngIf="!element.ideal_endedit"> {{element.ideal_end}} </span>
                          <span *ngIf="element.ideal_endedit" class="edit-column" >
                            <input [(ngModel)]="element.newideal_end" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'ideal_end')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'ideal_end')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="average_start">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Average Start</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'average_start')" *ngIf="!element.average_startedit"> {{element.average_start}} </span>
                          <span *ngIf="element.average_startedit" class="edit-column" >
                            <input [(ngModel)]="element.newaverage_start" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'average_start')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'average_start')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="average_end">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Average End</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'average_end')" *ngIf="!element.average_endedit"> {{element.average_end}} </span>
                          <span *ngIf="element.average_endedit" class="edit-column" >
                            <input [(ngModel)]="element.newaverage_end" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'average_end')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'average_end')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="aboveaverage_start">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Above Average Start</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'aboveaverage_start')" *ngIf="!element.aboveaverage_startedit"> {{element.aboveaverage_start}} </span>
                          <span *ngIf="element.aboveaverage_startedit" class="edit-column" >
                            <input [(ngModel)]="element.newaboveaverage_start" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'aboveaverage_start')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'aboveaverage_start')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="aboveaverage_end">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Above Average End</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'aboveaverage_end')" *ngIf="!element.aboveaverage_endedit"> {{element.aboveaverage_end}} </span>
                          <span *ngIf="element.aboveaverage_endedit" class="edit-column" >
                            <input [(ngModel)]="element.newaboveaverage_end" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'aboveaverage_end')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'aboveaverage_end')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bf_can_lose_per_week">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">Bodyfat can lose per week</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'bf_can_lose_per_week')" *ngIf="!element.bf_can_lose_per_weekedit"> {{element.bf_can_lose_per_week}} </span>
                          <span *ngIf="element.bf_can_lose_per_weekedit" class="edit-column" >
                            <input [(ngModel)]="element.newbf_can_lose_per_week" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'bf_can_lose_per_week')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'bf_can_lose_per_week')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="mbr_gain_per_pound">
                    <th mat-header-cell *matHeaderCellDef> 
                      <span class="disp-name">MBR gained per # lean muscle gained</span>
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                      <div class="table_td_container_width">
                        <span class="food-name">
                          <span class="disp-name" (click)="enableEdit(element, 'mbr_gain_per_pound')" *ngIf="!element.mbr_gain_per_poundedit"> {{element.mbr_gain_per_pound}} </span>
                          <span *ngIf="element.mbr_gain_per_poundedit" class="edit-column" >
                            <input [(ngModel)]="element.newmbr_gain_per_pound" class="edit-field" />
                            <button class="savebtn" (click)="saveNewValue(element, 'mbr_gain_per_pound')">Save</button>
                            <button class="cancelbtn" (click)="cancelEdit(element, 'mbr_gain_per_pound')">Cancel</button>
                          </span>
                        </span>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsList"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsList;" ></tr>
            </table>
        </div>
    </div>
</div>