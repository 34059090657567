<div class="toplbls">
    <span>Sample listing:</span>
    <div class="dropdown">
        <mat-form-field>
            <mat-select name="dropdown" [(ngModel)]="searchtxt" (ngModelChange)="filterTable($event)">
                <mat-option value="all">All Categories</mat-option>
                <span *ngFor="let data of foodCategories">
                    <mat-option [value]="data.id">
                        {{data.name}}
                    </mat-option>
                </span>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div *ngIf="userPrivilege >1">
    <mat-icon class="curpointer" title="Create category" (click)="createNewCategory()">add_box</mat-icon>
</div>
<table mat-table [dataSource]="datasource" class="mat-elevation-z10" multiTemplateDataRows>
    <ng-container matColumnDef="name">
        <th class="fieldlbls" mat-header-cell *matHeaderCellDef> NAME </th>
        <td mat-cell *matCellDef="let element"> 
            <div class="namefld">
                {{element.name}}
            </div> 
        </td>
    </ng-container>
    <ng-container matColumnDef="parentcategory">
        <th class="fieldlbls" mat-header-cell *matHeaderCellDef> PARENT CATEGORY </th>
        <td mat-cell *matCellDef="let element"> 
            <div class="client-name dropdown">
                <mat-form-field>
                    <mat-select name="dropdown" [(ngModel)]="element.parentcategory.category" (ngModelChange)="onChangeCategory($event, element.id)">
                        <mat-option value="noparent">No Parent</mat-option>
                        <span *ngFor="let data of element.parentcategory.list">
                            <mat-option [value]="data.id">
                                {{data.name}}
                            </mat-option>
                        </span>
                    </mat-select>
                </mat-form-field>
            </div> 
        </td>
    </ng-container>
    <ng-container matColumnDef="foodtemplates">
        <th class="fieldlbls" mat-header-cell *matHeaderCellDef> FOOD TEMPLATES </th>
        <td mat-cell *matCellDef="let element"> 
            <div class="client-name food-uploader-settings">
                <div class="label-primary" *ngFor="let obj of element.foodtemplates">
                    {{obj.name}}
                </div>
                <mat-icon class="deleteicon curpointer" *ngIf="element.foodtemplates.length === 0 && userPrivilege > 1" (click)="deleteHandle(element.id)">delete_outline</mat-icon>
            </div> 
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="example-detail-row"></tr>
</table>