import { Component, OnInit, ViewChild, ElementRef, Input, Inject } from '@angular/core';
import { CommonService } from '../../services/common/common.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import { HttpService } from '../../services/http/http.service';
import { LogService } from '../../services/log/log.service';
import { config } from '../../config';
import {jsPDF} from 'jspdf';
import html2canvas from "html2canvas";
import { ToasterService } from '../../services/toaster/toaster.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-compositionsession',
  templateUrl: './compositionsession.component.html',
  styleUrls: ['./compositionsession.component.sass']
})
export class CompositionsessionComponent implements OnInit {

  localData:any = [];
  columnsList:any = ["measured_at", "weight", "bodyfat", "leanmuscle", "waterweight", "fatmass"];
  editrows:any = [];
  circumference:any = [];
  vitals:any = [];
  circumColumnsList:any = ["measured_at", "neck", "arm", "chest", "waist", "hip", "thigh", "calf", "forearm"];
  vitalsColumnList:any = ["measured_at", "resting_heart_rate", "active_heart_rate", "resting_blood_pressure", "active_blood_pressure", "o2_saturation", "body_temperature", "vo2_max"];

  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @Input() sessionId:any;
  @Input() userId:any;
  newCircumference:any;
  newComposition:any;
  newVitals:any = [];

  selectedPreference = 'all';

  constructor(private commonService: CommonService,
    private ui: UiService,
    private logService: LogService,
    private toasterService: ToasterService,
    private httpService: HttpService,
    private dialog:MatDialog) { }

  ngOnInit(): void {
    this.ui.spin$.next(true);
    const todate:any = new Date();
    this.newCircumference = {
      arm: '',
      calf: '',
      chest: '',
      forearm: '',
      hip: '',
      measured_at: this.commonService.getFormattedDate(todate),
      neck: '',
      program_id: this.sessionId,
      thigh: '',
      waist: '',
      edit: false,
      active: true,
      startDate: new Date(),
    }

    this.newVitals = {
      measured_at: this.commonService.getFormattedDate(todate),
      edit: false,
      active: true,
      resting_heart_rate: '',
      active_heart_rate: '', 
      resting_blood_pressure: '',
      active_blood_pressure: '',
      o2_saturation: '',
      body_temperature: '',
      vo2_max: '',
      startDate: new Date()
    };
    this.newComposition = {
      bodyfat: '',
      fatmass: '',
      leanmuscle: '',
      measured_at: this.commonService.getFormattedDate(todate),
      program_id: this.sessionId,
      waterweight: '',
      weight: '',
      edit: false,
      active: true,
      startDate: new Date(),
    }
    this.assignTableData();
  }

  async downloadAsPDF() {
    const url = `${config.apiUrl}/getmeasurementspdf/${this.userId}/${this.sessionId}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while created successfully');
      console.log(err);
    });
  }

  editRow(val:any) {
    this.editrows.push(val.id);
  }

  getDateFormat(data:any) {
    return new Date(data);
  }

  numvalidation(val:any){
    return /^\s*(\d+|\.\d+|\d+\.\d+|\d+\.)?\s*$/.test(val);
  }

  inputValidator(data:any, key: any) {
    if(!this.numvalidation(data[key])) {
      data[key] = 0;
      this.toasterService.show('error', 'Error ', key + ' value should in numeric');
    }
    this.commonService.setIsPendingChanges(true);
  }

  onSelectedPreference(val:any) {
    this.ui.spin$.next(true);
    this.selectedPreference = val.value;
    this.assignTableData();
  }

  onDeleteVital(obj:any) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Confirmation', message: 'Are you sure you want to delete?' }, width: '400px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ui.spin$.next(true);
        const url = `${config.apiUrl}/deletevital/${obj.program_id}/${obj.id}`; 
        this.httpService.deleteCall(url, {}).subscribe(data => {
          let actuals = this.vitals.filter((ob:any) => (ob.id !== 'total' && ob.id !== obj.id));
          let total = this.totalVitals(actuals);
          actuals.push(total);
          this.vitals = actuals;
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'Vital deleted successfully');
        }, err => {
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', 'Error while deleting Vital');
        });
      }
    });
  }

  onSaveVital(obj:any) {
    if(obj.edit) {
      this.vitals[0].edit = false;
    } else {
      this.ui.spin$.next(true);
      obj.measured_at = this.commonService.getFormattedDate(obj.startDate);
      obj.program_id = this.sessionId;
      this.httpService.postCall(`${config.apiUrl}/createvital`, obj).subscribe(data => {
        this.vitals[0].startDate = new Date();
        this.vitals[0].resting_heart_rate = null;
        this.vitals[0].active_heart_rate = null;
        this.vitals[0].resting_blood_pressure = null;
        this.vitals[0].active_blood_pressure = null;
        this.vitals[0].o2_saturation = null;
        this.vitals[0].body_temperature = null;
        this.vitals[0].vo2_max = null;

        this.assignTableData();
        this.ui.spin$.next(false);
        this.toasterService.show('success', 'Success', 'Vitals record created successfully');
      },
      err => {
        this.ui.spin$.next(false);
        this.logService.logError(err);
        this.toasterService.show('error', 'Error', 'Error Occured while creating');
      });
    }
  }

  assignTableData() {
    let url = `${config.apiUrl}/getsessionmeasurements`;
    if (this.selectedPreference !== 'all') {
      url += `?fetchFrom=${this.selectedPreference}`;
    }
    this.httpService.postCall(url, {'program_id': this.sessionId}).subscribe(data => {
      let add_row = true;
      let today:any = new Date();
      today = this.commonService.getFormattedDate(today);
      if(data.circumferences){
        let circumference = data.circumferences.map((o:any) => {
          o.measured_at = this.commonService.getActualTimeZoneFormat(o.measured_at);
          // if(this.getDateFormat(today).getTime() === this.getDateFormat(o.measured_at).getTime()) {
          //   o.startDate =new Date(o.measured_at);
          //   o.active = true;
          //   o.edit = true;
          //   add_row = false;
          // } else 
          o.startDate = o.measured_at;
           return o;
        });
        let total = this.totalComposition(circumference);
        circumference.push(total);
        if(add_row) {
          circumference.unshift(this.newCircumference);
        }
        this.circumference = circumference;
      } else {
        this.circumference = [this.newCircumference]
      }
      add_row = true;
      if (data.compositions) {
        let composition = data.compositions.map((o:any) => {
          o.measured_at = this.commonService.getActualTimeZoneFormat(o.measured_at);
          // if(this.getDateFormat(today).getTime() === this.getDateFormat(o.measured_at).getTime()) {
          //   o.startDate =new Date(o.measured_at);
          //   o.active = true;
          //   o.edit = true;
          //   add_row = false;
          // }
          // else 
          o.startDate = o.measured_at;
          return o;
        });
        let total = this.totalCalculation(composition);
        composition.push(total);
        if(add_row) {        
          composition.unshift(this.newComposition);
        }
        this.localData = composition;
      } else {
        this.localData = [this.newComposition];
      }
      if (data.vitals) {
        const vitals = data.vitals.map((o:any) => {
          o.measured_at = this.commonService.getActualTimeZoneFormat(o.measured_at);
          o.startDate = o.measured_at;
          return o;
        });
        let total = this.totalVitals(vitals);
        vitals.push(total);
        if(add_row) {
          vitals.unshift(this.newVitals);
        }
        this.vitals = vitals;
      } else {
        this.vitals = [this.newVitals];
      }
      this.commonService.setIsPendingChanges(false);
      this.ui.spin$.next(false);
    },
    err => {
      this.ui.spin$.next(false);
      this.logService.logError(err);
      this.localData = [this.newComposition];
      this.circumference = [this.newCircumference];
      this.vitals = [this.newVitals];
    });
  }

  diffCalc(arr:any) {
    let len = arr.length;
    let diff = len > 1 ? arr[0] - arr[len-1] : len === 1 ? arr[0] : 0;
    return Math.round(diff * 10) / 10;
  }

  totalCalculation(tdata:any) {
    let dataNeeded = [...this.columnsList];
    dataNeeded = dataNeeded.filter((val:any) => val !== 'measured_at');
    let actuals = tdata.filter((ob:any) => ob.id !== 'total');
    let values:any = {};
    dataNeeded.map((o:any) => {
      let newob= {[o]:actuals.map((obj:any) => obj[o]).filter((obje:any) => {if(obje) return obje})};
      values = {...values, ...newob};
      return o;
    });
    return {
      bodyfat: this.diffCalc(values.bodyfat),
      fatmass: this.diffCalc(values.fatmass),
      id: 'total',
      leanmuscle: this.diffCalc(values.leanmuscle),
      measured_at: 'TOTALS:',
      program_id: this.sessionId,
      waterweight: this.diffCalc(values.waterweight),
      weight: this.diffCalc(values.weight),
    };
  }
  diffComposition(arr:any) {
    let len = arr.length;
    let diff = len > 1 ? arr[0] - arr[len-1] : len === 1 ? arr[0] : 0;
    return Math.round(diff * 10) / 10;
  }
  
  totalComposition(tdata:any) {
    let dataNeeded = [...this.circumColumnsList];
    dataNeeded = dataNeeded.filter((val:any) => val !== 'measured_at');
    let actuals = tdata.filter((ob:any) => ob.id !== 'total');
    let values:any = {};
    dataNeeded.map((o:any) => {
      let newob= {[o]:actuals.map((obj:any) => obj[o]).filter((obje:any) => {if(obje) return obje})};
      values = {...values, ...newob};
      return o;
    });
    return {
      arm: this.diffComposition(values.arm),
      calf: this.diffComposition(values.calf),
      chest: this.diffComposition(values.chest),
      forearm: this.diffComposition(values.forearm),
      hip: this.diffComposition(values.hip),
      id:'total',
      measured_at: 'TOTALS:',
      neck: this.diffComposition(values.neck),
      thigh: this.diffComposition(values.thigh),
      waist: this.diffComposition(values.waist),
    }
  }

  totalVitals(tdata:any) {
    let dataNeeded = [...this.vitalsColumnList];
    dataNeeded = dataNeeded.filter((val:any) => val !== 'measured_at');
    let actuals = tdata.filter((ob:any) => ob.id !== 'total');
    let values:any = {};
    dataNeeded.map((o:any) => {
      let newob= {[o]:actuals.map((obj:any) => obj[o]).filter((obje:any) => {if(obje) return obje})};
      values = {...values, ...newob};
      return o;
    });
    return {
      resting_heart_rate: this.diffCalc(values.resting_heart_rate),
      active_heart_rate: this.diffCalc(values.active_heart_rate),
      resting_blood_pressure: this.diffCalc(values.resting_blood_pressure),
      active_blood_pressure: this.diffCalc(values.active_blood_pressure),
      id:'total',
      measured_at: 'TOTALS:',
      o2_saturation: this.diffCalc(values.o2_saturation),
      body_temperature: this.diffCalc(values.body_temperature),
      vo2_max: this.diffCalc(values.vo2_max),
    }
  }

  calculateleanmass(obj:any) {
    if(obj.weight && obj.bodyfat) {
      obj.weight = parseFloat(obj.weight);
      obj.bodyfat = parseFloat(obj.bodyfat);
      obj.leanmuscle = obj.weight - (obj.weight * (obj.bodyfat / 100));
      const weightBodyfat:any = obj.weight * (obj.bodyfat / 100);
      obj.fatmass = parseFloat(weightBodyfat)
      .toFixed(1)
      .replace(/[.,]0$/, '')
    } else {
      obj.leanmuscle = null;
      obj.fatmass =  null;
    }
  }

  saveCompositionData(obj:any) {
    if(obj.edit) {
      this.localData[0].edit = false;
    } else {
      this.ui.spin$.next(true);
      obj.measured_at = this.commonService.getFormattedDate(obj.startDate);
      delete obj.created_by;
      delete obj.id;
      this.httpService.postCall(`${config.apiUrl}/createcomposition`, obj).subscribe(data => {
        // let total = this.totalCalculation(this.localData);
        // let actuals = this.localData.filter((ob:any) => ob.id !== 'total');
        // actuals.push(total);
        // this.localData = actuals;
        this.localData[0].waterweight = null;
        this.localData[0].leanmuscle = null;
        this.localData[0].bodyfat = null;
        this.localData[0].weight = null;
        this.localData[0].fatmass = null;
        this.localData[0].startDate = new Date();

        this.assignTableData();
        this.toasterService.show('success', 'Success', 'Composition record created successfully');
        this.ui.spin$.next(false);
      },
      err => {
        this.ui.spin$.next(false);
        this.logService.logError(err);
        this.toasterService.show('error', 'Error', 'Error Occured while creating');
      });
    }
  }

  onDeleteComposition(obj: any) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Confirmation', message: 'Are you sure you want to delete?' }, width: '400px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ui.spin$.next(true);
        const url = `${config.apiUrl}/deleteweight/${obj.program_id}/${obj.id}`;
        this.httpService.deleteCall(url, {}).subscribe(data => {
          let actuals = this.localData.filter((ob:any) => (ob.id !== 'total' && ob.id !== obj.id));
          let total = this.totalCalculation(actuals);
          actuals.push(total);
          this.localData = actuals;
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'Composition deleted successfully');
        }, err => {
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', 'Error while deleting Composition');
        });
      }
    });
  }
  onDeleteCircumference(obj: any) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Confirmation', message: 'Are you sure you want to delete?' }, width: '400px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ui.spin$.next(true);
        const url = `${config.apiUrl}/deletecircumference/${obj.program_id}/${obj.id}`; 
        this.httpService.deleteCall(url, {}).subscribe(data => {
          let actuals = this.circumference.filter((ob:any) => (ob.id !== 'total' && ob.id !== obj.id));
          let total = this.totalComposition(actuals);
          actuals.push(total);
          this.circumference = actuals;
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'Circumference deleted successfully');
        }, err => {
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', 'Error while deleting Circumference');
        });
      }
    });
  }
 
  saveCircumferenceData(obj:any) {
    if(obj.edit) {
      this.circumference[0].edit = false;
    } else {
      this.ui.spin$.next(true);
      obj.measured_at = this.commonService.getFormattedDate(obj.startDate);
      this.httpService.postCall(`${config.apiUrl}/createcircumference`, obj).subscribe(data => {
        // this.circumference[0].edit = true;
        this.circumference[0].startDate = new Date();
        this.circumference[0].neck = null;
        this.circumference[0].arm = null;
        this.circumference[0].chest = null;
        this.circumference[0].waist = null;
        this.circumference[0].hip = null;
        this.circumference[0].thigh = null;
        this.circumference[0].calf = null;
        this.circumference[0].forearm = null;

        this.assignTableData();
        this.ui.spin$.next(false);
        this.toasterService.show('success', 'Success', 'Circumference record created successfully');
      },
      err => {
        this.ui.spin$.next(false);
        this.logService.logError(err);
        this.toasterService.show('error', 'Error', 'Error Occured while creating');
      });
    }
  }

  onPrintOptions() {
    let dialogRef = this.dialog.open(PrintDialogComponent, { data: { title: 'Print measurements' }, width: '400px' });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // console.log(result);
        this.ui.spin$.next(true);
        let subUrl = '';
        if (result !== 'all') {
          subUrl = `${result}/`; 
        }
        let url = `${config.apiUrl}/getmeasurementspdf/${subUrl}${this.userId}/${this.sessionId}`;
        if (this.selectedPreference !== 'all') {
          url += `?fetchFrom=${this.selectedPreference}`;
        }
        this.httpService.getCallFile(url).subscribe(result => {
          var file = new Blob([result], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'PDF file created successfully');
        }, err => {
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', 'Error while creating PDF file');
        });
      }
    });
  }
}

@Component({
  selector: 'confirm-dialog',
  template: `<h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content class="dialogContent">
      <p>{{data.message}}</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
    styleUrls : ['./confirmdialog.component.sass']
})

export class ConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    responseHandler(action:any): void {
      this.dialogRef.close(action);
    }
}

@Component({
  selector: 'print-dialog',
  template: `<h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content class="dialogContent">
      <div class="dropdown">
        <mat-form-field>
          <mat-select [(ngModel)]="selectedPrintOption" name="dropdown" >
              <mat-option value="all">
                  All measurements
              </mat-option>
              <mat-option value="composition">
                  Composition
              </mat-option>
              <mat-option value="circumference">
                  Circumference
              </mat-option>
              <mat-option value="vitals">
                Vitals
              </mat-option>
          </mat-select>
        </mat-form-field>  
      </div>
    </div>
    <div mat-dialog-actions class="dialogActions float_right">
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>  
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
    </div>`,
    styleUrls : ['./printdialog.component.sass']
})

export class PrintDialogComponent {
  selectedPrintOption = 'all';
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    responseHandler(action:any): void {
      if (action) {
        this.dialogRef.close(this.selectedPrintOption);
      } else {
        this.dialogRef.close(action);
      }
    }
}
