<h1 mat-dialog-title>{{data.title}} {{data.copySessionObject.clientDetais?.firstname}}
    {{data.copySessionObject.clientDetais?.lastname}}</h1>
<div mat-dialog-content class="dialogContent " [ngClass]="!data.initial ? 'copyProgram' : 'copyplan'" >
    <ng-container *ngIf="data.copyUser && data.copySessionObject.sessions.length > 0">
        <mat-form-field appearance="fill" class="session">
            <mat-label>Select Session</mat-label>
            <mat-select [(ngModel)]="data.copySessionObject.selectedSession">
                <mat-option *ngFor="let session of data.copySessionObject.sessions" [value]="session"><span *ngIf="session.goal.name && session.goal.name!=''">{{session.goal.name}} - </span>{{session.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions class="dialogActions text-right">
    <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)"
        cdkFocusInitial>Confirm</button>
    <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
</div>
