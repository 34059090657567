import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { SidenavService } from '../services/sidenav/sidenav.service';

@Component({
  selector: 'app-foodsuploader',
  templateUrl: './foodsuploader.component.html',
  styleUrls: ['./foodsuploader.component.sass']
})
export class FoodsuploaderComponent implements OnInit {

  constructor(private commonService: CommonService, private httpService: HttpService, private sideNavService:SidenavService) { }
  userPrivilege:any;
  ngOnInit(): void {
    const routes:any = [{
      name: 'Uploader',
      path: 'uploader'
    },{
      name: 'Foods Uploader',
      path: 'foods-uploader'
    }];

    this.commonService.setHeaderData(routes);
    this.sideNavService.sideNavData.subscribe((data:any) => {
      const sideNavObject = data.find((obj: any) => obj.name === 'uploader');
      this.userPrivilege =  Math.max(...sideNavObject?.privileges, 0);
    })
    this.getFoodTemplateCategory();
  }
  foodsCategories:any;
  getFoodTemplateCategory() {
    const url =  `${config.apiUrl}/foods/getfoodtemplatecategories`;
    this.httpService.getCall(url).subscribe(result => {
      this.foodsCategories = result;
    }, err => {

    });
  }
}
