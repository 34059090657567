import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';
import { config } from '../../config';
import { CommonService } from '../common/common.service';

@Injectable()
export class LogService {

  constructor(
    private httpService: HttpService,
    private commonService: CommonService
  ) {}

  logError(err:any, customMsg:any = '', feedCode:any = '') {
    let code:any = feedCode ? feedCode : err.status,
      msg:any;
    if (err?.error?.errors) {
      msg = err?.error?.errors[0];
    } else if (err?.error?.error) {
      msg = err?.error?.error;
    } else if (err?.error?.message) {
      msg = err?.error?.message;
    } else {
      msg = err?.error;
    }
    msg = msg ? msg : customMsg;
    const obj = {
        "statusCode": code,
        "errorMsg": `${this.commonService.detectBrowser()}-${ JSON.stringify(msg) }`
    };
    const url = `${config.apiUrl}/loguierror`;
    this.httpService.postCall(url, obj).subscribe(data => {

    }, err => {
    })
  }

}
