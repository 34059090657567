<div>
    <div class="top-section">
        <div class="search-container">
          <div class="input-container">
              <mat-icon matPrefix>search</mat-icon>
              <input type="text" placeholder="Search" [(ngModel)]="searchTxt" class="search-input" autocomplete="off" (keyup)="searchResult($event)" />
          </div>
          <button mat-raised-button color="primary" style="float: right;" (click)="addCompanyDialog()">Add Company</button>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="datasource" multiTemplateDataRows>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> 
              <span class="disp-name">Company Id</span>
            </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"
              class="name_field"
            > 
           <div class="expand-icon">
                <span class="food-name">
                  <span class="disp-name">{{element.id}} </span>
                </span>
              </div>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef><span class="disp-name"> Company Name </span></th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"> 
              <span class="disp-name" (click)="enableEdit(element, 'description', i)" *ngIf="!element.descriptionEdit">{{element.name}}</span>
              <span *ngIf="element.descriptionEdit" class="edit-column" >
                <input [(ngModel)]="element.name" [placeholder]="element.name" class="edit-field" />
                <button class="savebtn" (click)="saveNewValue(element, 'name', i)">Save</button>
                <button class="cancelbtn" (click)="cancelEdit(element, 'description', i)">Cancel</button>
              </span>
            </td>
          </ng-container> 
          
          <ng-container matColumnDef="companyDomain">
            <th mat-header-cell *matHeaderCellDef><span class="disp-name"> Company Domain </span></th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"> 
              <span class="disp-name" (click)="enableEdit(element, 'companyDomain', i)" *ngIf="!element.companyDomainEdit">{{element.company_url}}</span>
              <span *ngIf="element.companyDomainEdit" class="edit-column" >
                <input [(ngModel)]="element.company_url" [placeholder]="element.company_url" class="edit-field" />
                <button class="savebtn" (click)="saveNewValue(element, 'company_url', i)">Save</button>
                <button class="cancelbtn" (click)="cancelEdit(element, 'companyDomain', i)">Cancel</button>
              </span>
            </td>
          </ng-container> 

          <ng-container matColumnDef="actions">
            <th mat-header-cell  *matHeaderCellDef > Actions </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex" >
              <mat-icon class="padding-t-10" (click)="deleteCompany(element,i)" *ngIf="element.id != adminId">delete</mat-icon>
            </td>
          </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsList;sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsList;" class="element-row" [class.expanded-row]="element.isExpanded"></tr>
              <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr> -->
        </table>
        <mat-paginator
        [length]="pagination.count"
        [pageIndex]="pagination.pageIndex"
        [pageSize]="pagination.perPage"
        [pageSizeOptions]="[10, 25, 50, 100, 200]"
        (page)="getServerData($event)"
  showFirstLastButtons></mat-paginator>
    </div>
</div>