import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.sass']
})
export class UploaderComponent implements OnInit {

  constructor(private commonService: CommonService,
    private router: Router) { }

  ngOnInit(): void {
    const routes:any = [
      { name: 'Uploader', path: 'uploader'}
    ];
    this.commonService.setHeaderData(routes);
  }

  onUploaderItem(route:string) {
    this.router.navigate([`/${route}`]);
  }
}
