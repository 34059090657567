import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { AuthService } from '../auth/auth.service';
import { ToasterService } from '../services/toaster/toaster.service';

@Component({
  selector: 'app-apple',
  templateUrl: './apple.component.html',
  styleUrls: ['./apple.component.sass']
})
export class AppleComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private authService: AuthService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params:any) => {
      const appleToken= params.get('token');
      this.validateAppleToken(appleToken);
    });
  }

  validateAppleToken(token:string) {
    const url = `${config.apiUrl}/auth/apple`;
    const reqData = {
      token: token
    };
    this.httpService.postCall(url, reqData).subscribe((result:any) => {
      const userEmail = this.authService.getSurveyEmail();
      if (!userEmail) {
        this.authService.storeJwtToken(result);
        this.onUserSuccess();
      } else {
        this.router.navigate(['/createuser'], { queryParams: { isSurveyRedirect: true, email: result.email }});
      }
    }, (err:any) => {
      console.log(err);
      this.router.navigate(['/login'], { queryParams: { invalid: true }});
      this.toasterService.show('error', 'error', err?.errors ? err.errors : 'Error while validating apple');
    });
  }

  onUserSuccess() {
    this.authService.onLoginSuccess();
  }

}
