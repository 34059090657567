import { Component, OnInit, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { CommonModule } from '@angular/common';
import { UiService } from '../../services/uiloader/uiloader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../config';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonService } from '../../services/common/common.service';
import { LogService } from '../../services/log/log.service';

@Component({
  selector: 'app-consultantsubs',
  templateUrl: './consultantsubs.component.html',
  styleUrls: ['./consultantsubs.component.sass']
})
export class ConsultantsubsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private httpService: HttpService, private ui: UiService, private logService: LogService,
    private router: Router, private el: ElementRef, public dialog: MatDialog, private commonService: CommonService) { }

  currentPage: any;
  userId: any;
  roadmap: any = [];
  goalsColumns: string[] = ['measurement', 'current', 'classification', 'chart'];
  goalsData: any = [
    {measurement: 'Body Fat', current: '%', classification: '', chart: 'bodyfat'},
    {measurement: 'Muscle Mass', current: '25', classification: 'Fair', chart: 'musclemass'}
  ];
  defaultDurations = ["4 weeks", "8 weeks", "12 weeks", "16 weeks", "20 weeks", "24 weeks"];
  goalsDuartions = this.defaultDurations;
  inbodyDataComp:any;
  estData:any;
  inbodyData:any = {
    height: '',
    weight: '',
    age: '',
    sex: ''
  }
  inbodyHeight:any = '';
  inbodyWeight:any = '';
  inbodyAge:any = '';
  inbodySex:any = '';
  objectKeys = Object.keys;
  recommenedGoalTime:any = "12";
  notes: any;
  notesList:any = [];
  consultantData:any;
  bmrResult:any = '';
  hideNotes: boolean = false;
  isUpdate: boolean = true;
  editRow:any = '';
  surveyDetails: any;
  lbls: any = {
    weight: 'Weight',
    bodyfat: 'Body Fat',
    musclegain: 'Muscle Gain'
  }
  units: any = {
    weight: 'lbs',
    bodyfat: '%',
    musclegain: 'lbs',
    height: 'ft',
    age: 'years'
  }
  goalsBodyFat: any = "";
  goalsMuscleGain: any = "";
  goalDuration: any = "-";
  errorDisp:boolean = false;
  numError:boolean = false;
  roadmapData: any;
  bodyFatChart: any;
  preventsubmit:any = false;
  inbodypause: boolean = false;
  showmanual:boolean = false;
  startBodyFat: any = '';
  startWeight: any = '';
  startGender: any = '';
  startAge: any = '';
  stnumError:boolean = false;
  stfillError:boolean = false;
  inbodyError:boolean = false;
  breadcrumb:any = [{
    name: 'Prospects',
    path: 'prospects'
  }];

  ngOnInit(): void {
    this.currentPage = this.route.snapshot.params.subs;
    this.userId = this.route.snapshot.params.id;
    this.commonService.setHeaderData(this.breadcrumb);
    this.ui.spin$.next(true);
    this.httpService.getCall(`${config.apiUrl}/surveysfeedback/surveysfeedback/${this.userId}`).subscribe(data => {
      this.surveyDetails = data.success.survey_details;
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
    // this.goalEstimate();
    this.httpService.getCall(`${config.apiUrl}/consultantclientnotes/${this.userId}`).subscribe(data => {
      this.notesList = data;
    },
    err => {
      this.logService.logError(err);
    });
    this.httpService.postCall(`${config.apiUrl}/getclients`, {"user_id": this.userId}).subscribe(data => {
      this.breadcrumb.push({
        name: `${data.rows[0].firstname} ${data.rows[0].lastname}`,
        path: `consultants/survey/${this.userId}`
      });
      this.consultantData = data;
      this.commonService.setHeaderData(this.breadcrumb);
      this.getClientMeasurements();
      this.startGender = data.rows[0].personal.gender;
    },
    err => {
      this.logService.logError(err);
    });
  }

  getClientMeasurements() {
    let token;
    if(this.consultantData && this.consultantData.rows) {
      let userdata = this.consultantData.rows[0]?.personal;
      if (userdata) {
        token = userdata.mobilephone;
      }
    }
    this.httpService.postCall(`${config.apiUrl}/inbody/getclientmeasurements`, {"usertoken": token}).subscribe(data => {
      this.inbodyDataComp = data;
      let inch:any = (parseFloat(data.height_in_feet) - parseInt(data.height_in_feet)) * 100;
      this.inbodyData = {
        height: `${parseInt(data.height_in_feet)} ft ${parseInt(inch)} in`,
        weight: `${data.WT} lbs`,
        age: `${data.AGE} years`,
        sex: data.GENDER === "f" ? "Female" : "Male"
      }
      this.inbodyHeight = data.height_in_feet;
      this.inbodyWeight = data.WT;
      this.inbodyAge = data.AGE;
      this.inbodySex = data.GENDER;
      this.bmrResult = data.BMR;
      let dupData = this.goalsData;
      dupData[0].current = data.FT + '%';
      this.goalsData = dupData;
      this.getBodyFatClassification(data.FT, data.GENDER, data.AGE);
    },
    err => {
      this.inbodypause = true;
      this.logService.logError(err);
    });
  }

  getBodyFatClassification(fat:any, gender:any, age:any) {
    this.httpService.postCall(`${config.apiUrl}/getbodyfatclassifications`, {"user_age": age, "gender": gender}).subscribe(data => {
      let dupData = this.goalsData;
      let fatval = parseInt(fat.replace('%', ''));
      let vals = Object.keys(data);
      for (let i = 0; i < vals.length; i++) {
        let limit = data[vals[i]].displayValue;
        if (limit.indexOf('-') !== -1) {
          let limits = limit.split('-');
          let baselimit = parseInt(limits[0].replace('%', ''));
          let upperlimit = parseInt(limits[1].replace('%', ''));
          if (fatval > baselimit && fatval < upperlimit) {
            dupData[0].classification = data[vals[i]].displayName;
            break;
          }
        } else {
          let limits = limit.split(' ');
          let baselimit = parseInt(limits[1].replace('%', ''));
          if (fatval > baselimit) {
            dupData[0].classification = data[vals[i]].displayName;
            break;
          }
        }
      }
      this.goalsData = dupData;
      this.bodyFatChart = data;
    },
    err => {
      this.logService.logError(err);
    });
  }

  goalEstimate(data = "") {
    this.numError = false;
    this.errorDisp = false;
    if(this.numvalidation(this.goalsBodyFat) && this.numvalidation(this.goalsMuscleGain)) {
      let reqdata = {
        start_weight: this.inbodyDataComp ? this.inbodyDataComp.WT : this.startWeight,
        start_bodyfat: this.inbodyDataComp ? this.inbodyDataComp.PBF : this.startBodyFat,
        goal_bodyfat: this.goalsBodyFat,
        goal_muscle_gain_pounds: this.goalsMuscleGain,
        user_age: this.inbodyDataComp ? this.inbodyDataComp.AGE : this.convertDate2Age(this.startAge),
        gender: this.inbodyDataComp ? this.inbodyDataComp.GENDER : this.startGender
      };
      let xeqt = true;
      if (!this.goalsBodyFat || !this.goalsMuscleGain) xeqt = false;
      if (xeqt) {
        this.goalDuration = "";
        this.httpService.postCall(`${config.apiUrl}/getestimatedweeksforgoal`, reqdata).subscribe(data => {
          this.roadmap = this.formatRoadmap(data);
          this.roadmapData = this.roadmap;
          this.assignGoalsData(data);
          this.estData = data;
        },
        err => {
          this.logService.logError(err);
        });
      }
    } else {
      this.numError = true;
    }
  }

  numberValidation() {

  }

  disableSave() {
    (this.numvalidation(this.inbodyHeight) && this.numvalidation(this.inbodyWeight) &&
    this.numvalidation(this.inbodyAge)) ? this.preventsubmit = false : this.preventsubmit = true;
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(NavDialog, { data: "" });
    dialogRef.afterClosed().subscribe(result => {
      result === "ok" ? this.router.navigate(['/prospects']) : "";
    });
  }

  formatRoadmap(data: any) {
    return data.monthsdata.map((obj: any) => {
      let month = Object.keys(obj)[0];
      let rData: any = {
        for: 'Month ' + month.split('month')[1]
      }
      let val = obj[month];
      let vKeys = Object.keys(val);
      rData["values"] = vKeys.map((vdata: any) => ({
        "label": this.lbls[vdata],
        "data": val[vdata] + this.units[vdata]
      }))
      return rData;
    })
  }
  
  assignGoalsData(data: any) {
    this.goalsBodyFat = data.goal.bodyfat;
    this.goalsMuscleGain = data.goal.musclegain;
    this.goalDuration = data.weekstogoal;
      let arr = [];
      for (let i = 0; i < Math.floor(data.weekstogoal / 4); i++) {
        let numb = (i+1) * 4;
        if (numb === data.weekstogoal) {
          arr.push(numb + ' weeks (recommended)');
          this.recommenedGoalTime = numb;
        } else {
          arr.push(numb + ' weeks');
        }
      }
      if (data.weekstogoal % 4 !== 0) {
        arr.push(data.weekstogoal+ ' weeks (recommended)');
        this.recommenedGoalTime = data.weekstogoal;
      }
      this.goalsDuartions = arr;
  }

  viewChart(key: any) {
    if (key == "bodyfat") {
      let val: any = '<table>';
      Object.keys(this.bodyFatChart).forEach((element:any) => {
        val = val + '<tr><td>'+this.bodyFatChart[element].displayValue+'</td><td>'+this.bodyFatChart[element].displayName+'</td></tr>';
      });
      val = val+'</table>';
      this.dialog.open(ChartDialog, { data: val });
    }
  }

  ngAfterContentChecked(): void {
    if (this.isUpdate) {
      this.containerSpace();
    }
  }

  containerSpace() {
    let containerWidth = this.el.nativeElement.querySelector('.container').offsetWidth;
    let mainConWidth = containerWidth * 70 / 100;
    let notesConWidth = containerWidth - mainConWidth - 20;
    if (this.el.nativeElement.querySelector('.main-containerc')) {
      this.el.nativeElement.querySelector('.main-containerc').style.width = mainConWidth + 'px';
    }
    if (this.el.nativeElement.querySelector('.notes-container')) {
      this.el.nativeElement.querySelector('.notes-container').style.width = notesConWidth + 'px';
    }
  }

  manualInbodyEntry() {
    this.showmanual = true;
  }

  numvalidation(val:any){
    return /^\s*(\d+|\.\d+|\d+\.\d+|\d+\.)?\s*$/.test(val);
  }

  startEntry() {
    this.inbodyError = false;
    if((this.numvalidation(this.startBodyFat) || this.startBodyFat === '')
     && (this.numvalidation(this.startWeight) || this.startWeight === '')
     && (this.startAge === '' || Object.prototype.toString.call(this.startAge) === '[object Date]')) {
      this.stnumError = false; 
      this.stfillError = false;
    } else { 
      this.stnumError = true;
    }
  }

  handleBack() {
    if (this.currentPage === "inbody") {
      this.router.navigate(['/consultants', 'survey', this.userId]);
      this.currentPage = 'survey';
    } else if (this.currentPage === "goals") {
      this.router.navigate(['/consultants', 'inbody', this.userId]);
      this.currentPage = 'inbody';
    } else if (this.currentPage === "roadmap") {
      this.router.navigate(['/consultants', 'goals', this.userId]);
      this.currentPage = 'goals';
    } else if (this.currentPage === "cost") {
      this.router.navigate(['/consultants', 'roadmap', this.userId]);
      this.currentPage = 'roadmap';
    }
  }

  convertDate2Age(dateString:Date) {
    var today = new Date();
    var ageArr = [];
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    ageArr.push(age);
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
        ageArr[0] = age;
    }
    if (m > 0){
      ageArr.push(m);
    } else if (m < 0) {
      ageArr.push(12 + m);
    } else if (m === 0 && today.getDate() < birthDate.getDate()){
      ageArr.push(11);
    }
    return ageArr.join('.');
  }

  handleNext() {
    if (this.currentPage === "inbody") {
      if (!this.inbodypause) {
        this.router.navigate(['/consultants', 'goals', this.userId]);
        this.currentPage = 'goals';
      } else {
        this.inbodyError = true;
        if(this.showmanual) {
          this.inbodyError = false;
          if(this.startBodyFat && this.startWeight && this.startAge && this.startGender) {
            let dupData = this.goalsData;
            dupData[0].current = this.startBodyFat + '%';
            this.goalsData = dupData;
            this.getBodyFatClassification(this.startBodyFat, this.startGender, this.convertDate2Age(this.startAge));
            this.router.navigate(['/consultants', 'goals', this.userId]);
            this.currentPage = 'goals';
          } else {
            this.stfillError = true;
          }
        }
      }
    } else if (this.currentPage === "goals") {
      if (this.goalsMuscleGain && this.goalsBodyFat && !this.errorDisp && this.goalDuration) {
        this.router.navigate(['/consultants', 'roadmap', this.userId]);
        this.currentPage = 'roadmap';
      } else {
        this.errorDisp = true;
      }
    } else if (this.currentPage === "roadmap") {
      this.router.navigate(['/consultants', 'cost', this.userId]);
      this.currentPage = 'cost';
    } else if (this.currentPage === "survey") {
      this.router.navigate(['/consultants', 'inbody', this.userId]);
      this.currentPage = 'inbody';
    } else if (this.currentPage === "cost") {
      this.ui.spin$.next(true);
      let obj = {
        user_id: this.userId,
        start_weight: this.estData.start.weight,
        start_bodyfat: this.estData.start.bodyfat,
        goal_bodyfat: this.goalsBodyFat,
        goal_muscle_gain_pounds: this.goalsMuscleGain,
        user_age: this.inbodyDataComp ? this.inbodyDataComp.AGE : this.convertDate2Age(this.startAge),
        gender: this.inbodyDataComp ? this.inbodyDataComp.GENDER : this.startGender,
        packages_purchased: this.goalDuration
      }       
      this.httpService.postCall(`${config.apiUrl}/savegoal`, obj).subscribe(data => {
        this.ui.spin$.next(false);
        this.router.navigate(['/prospects']);
      },
      err => {
        this.logService.logError(err);
        this.ui.spin$.next(false);
      });
    }
  }

  notesClose(noteid: any) {
    let Id = noteid;
    this.httpService.deleteCall(`${config.apiUrl}/consultantclientnotes/${noteid}`, {}).subscribe(data => {
      this.notesList = this.notesList.filter((obj: any) => Id !== obj.id);
    },
    err => {
      this.logService.logError(err);
    });
  }

  notesSlide() {
    this.hideNotes = !this.hideNotes;
    this.isUpdate = false;
    if (this.hideNotes) { 
      let containerWidth = this.el.nativeElement.querySelector('.container').offsetWidth;
      if (this.el.nativeElement.querySelector('.main-containerc')) {
        this.el.nativeElement.querySelector('.main-containerc').style.width = (containerWidth - 81) + 'px';
      }
      if (this.el.nativeElement.querySelector('.notes-container')) {
        this.el.nativeElement.querySelector('.notes-container').style.width = "60px";
      }
    } else {
      this.containerSpace();
    }
  }

  setGoalsDuration() {
    let months = this.recommenedGoalTime / 4;
    this.roadmap = this.roadmapData.slice(0, months);
    if (this.goalDuration == this.recommenedGoalTime) {
      this.roadmap = this.roadmapData;
    }
  }

  addNotes() {
    if (this.notes !== "") {
      this.httpService.postCall(`${config.apiUrl}/consultantclientnotes`, {"notes":this.notes, "client_id":this.userId}).subscribe(data => {
        this.notes = "";
        this.notesList.unshift(data.success);
      },
      err => {
        this.logService.logError(err);
      });
    }
  }

  inbodyResult() {
    let inch: any = (parseFloat(this.inbodyHeight) - parseInt(this.inbodyHeight)) * 100;
    this.inbodyData = {
      height: parseInt(this.inbodyHeight) + ' ft ' + parseInt(inch) + ' in',
      weight: `${this.inbodyWeight} lbs`,
      age: `${this.inbodyAge} years`,
      sex: this.inbodySex === 'm' ? "Male" : "Female"
    }
    let obj = {
      "user_height": this.inbodyHeight,
      "user_weight": this.inbodyWeight,
      "user_age": this.inbodyAge,
      "gender": this.inbodySex
    }
    this.editRow = '';
    this.bmrResult = '';
    this.httpService.postCall(`${config.apiUrl}/calculatebmr`, obj).subscribe(data => {
      console.log(data);
      this.bmrResult = data.bmr;
    },
    err => {
      console.log(err)
    });
  }

  handleInbodyEdit(row: any) {
    this.editRow = row;
  }

  handleEditCancel() {
    this.editRow = '';
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `<div mat-dialog-content class="dialogContent">
      <p>Are you sure you want to leave the page?</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-button class="resultsbtn cancaldlg" (click)="responseHandler('cancel')">Cancel</button>
      <button class="resultsbtn" mat-button (click)="responseHandler('ok')" cdkFocusInitial>Ok</button>
    </div>`,
  styleUrls: ['./consultantsubs.component.sass']
})
export class NavDialog {
  constructor(
    public dialogRef: MatDialogRef<NavDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    responseHandler(action:any): void {
      this.dialogRef.close(action);
    }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <div mat-dialog-content class="dialogContent chartDialog">
      <div [innerHTML]="data"></div>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button class="resultsbtn" mat-button (click)="responseHandler()" cdkFocusInitial>Ok</button>
    </div>`,
  styleUrls: ['./consultantsubs.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ChartDialog {
  constructor(
    public dialogRef: MatDialogRef<ChartDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    
    responseHandler(): void {
      this.dialogRef.close();
    }
}