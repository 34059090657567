import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LogService } from '../services/log/log.service';
import { AppConstants } from '../constants/appConstants';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { UiService } from '../services/uiloader/uiloader.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { CommonService } from '../services/common/common.service';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';


@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.sass']
})
export class AdduserComponent implements OnInit {
  selectedItem:string = '';
  isDisplayFieldsRequired:boolean = false;
  genderOptions:any = AppConstants.genderOptions;
  isDisabled = true;
  selectedState:any = '';
  userAddressData:any = [];
  selectData:any = [];
  selectedCountry:any= "US";
  phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  userNamesData:any = [
    {
      key: 'firstname',
      value: '',
      placeholder: 'First Name'
    },
    {
      key: 'lastname',
      value: '',
      placeholder: 'Last Name'
    }
  ];

  addUser:any = {
    name: '',
    dateValue: '',
    email: '',
    gender: '',
    mobilephone: ''
  };
  registerForm:any;
  submitted = false;
  maxBirthDate:any;
  minStartDate:any;

  constructor(private location: Location,
    private httpService: HttpService,
    private loader: UiService,
    private logService: LogService,
    private toasterService: ToasterService,
    private common: CommonService,
    private formBuilder: FormBuilder) {
      const dt = new Date();
      dt.setFullYear(dt.getFullYear() - 11);
      this.maxBirthDate = dt;
  
      const minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - 100);
      this.minStartDate = minDate;
     }

  ngOnInit(): void {
    this.getStates();
    this.setUserAddress({});
    this.registerForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      gender: new FormControl(null, [Validators.required]),
      firstname: new FormControl(null, [Validators.required]),
      lastname: new FormControl(null, [Validators.required]),
      birthdate: new FormControl('', [Validators.required]),
      mobilephone: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      aptsuite: new FormControl(null, []),
      city: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      postalcode: new FormControl(null, [Validators.required]),
      user_id: new FormControl(""), 
    },
    )
  }

  validatephoneNumber() {
    if(!this.registerForm.value.mobilephone || !this.selectedCountry) {
      this.registerForm.get('mobilephone').setErrors({message:"Mobile Number and Country required"});
      return;
    }
    const pNumber = this.phoneUtil.parseAndKeepRawInput( '' + this.registerForm.value.mobilephone.toString() + '', this.selectedCountry);
    const isValidNumber = this.phoneUtil.isValidNumber(pNumber);
    if(!isValidNumber){
      this.registerForm.get('mobilephone').setErrors({message:"Mobile Number is invalid"});
    }
  }

  dateChangeHandler(date: Date){
    this.registerForm.get('birthdate')?.setValue(date);
  }

  selectCountry(ev:any) {
    this.selectedCountry = ev;
  }

  setUserAddress(data:any) {
    this.userAddressData = [
      {
        key: 'address',
        value: data.address,
        placeholder: 'Address'
      },
      {
        key: 'aptsuite',
        value: data.aptsuite,
        placeholder: 'Apt Suite'
      },
      {
        key: 'city',
        value: data.city,
        placeholder: 'City'
      },
      {
        key: 'state',
        value: data.state,
        placeholder: 'State',
        elementType: 'select'
      },
      {
        key: 'postalcode',
        value: data.postalcode,
        placeholder: 'Postal Code'
      },
    ];
    return true;
  }

  getStates() {
    this.httpService.getCall(`${config.apiUrl}/getstates`).subscribe(data => {
      this.selectData = data;
    },
    err => {
        this.logService.logError(err);
    });
  }

  getUserGender(gender: string) {
    if (gender === 'm') {
      return 'Male';
    } else if ( gender === 'f') {
      return 'Female';
    }
    return '';
  }

  onItemSelect(eve: any, val: string) {
    if (eve.keyCode === 32 || eve.keyCode === 13) {
      this.selectedItem = val;
      if (this.userAddressData[4].key === "postalcode") {
        let pinCode = this.userAddressData[4].value;
        if (pinCode && pinCode.length > 2) {
          this.loader.showSpinner();
          const url = `${config.apiUrl}/getzipcodeaddress`;
          this.httpService.postCall(url, { zip: parseInt(pinCode) }).subscribe(data => {
            let state = this.selectData.find((state: any) => state.stateCode == data[0].state_id);
            if (!state) this.selectData.push({ stateCode: data[0].state_id, stateName: data[0].state_name });
            this.userAddressData[2].value = data[0].city;
            this.userAddressData[3].value = data[0].state_id;
            this.userAddressData[4].value = data[0].zip;
            this.loader.stopSpinner();
          }, err => {
            this.loader.stopSpinner();
          })
        }
      }
    }

  }

  formatNum(val:any) {
    return parseInt(val) < 10 ? `0${val}` : val;
  }

  getDateFormat(date:any) {
    return `${this.formatNum(date.getMonth() + 1)}/${this.formatNum(date.getDate())}/${date.getFullYear()}`;
  }

  saveUserDetails(userId:number) {
    this.registerForm.get('birthdate').setValue(this.getDateFormat(this.registerForm.value.birthdate));
    this.registerForm.get('user_id').setValue(userId);
    console.log(this.registerForm.value);
    const url = `${config.apiUrl}/updateclient`;
    this.httpService.putCall(url, this.registerForm.value).subscribe(data => {
      this.toasterService.show('success', 'Success', 'User Created succesfully');
      this.onGoBack();
      this.loader.stopSpinner();
    }, err => {
      this.loader.stopSpinner();
    })
  }

  onSubmit(e:any) {
    e.preventDefault();
    this.validatephoneNumber();
    this.isDisplayFieldsRequired = false;
    if (this.registerForm.status === 'INVALID') {
      this.isDisplayFieldsRequired = true;
      return false;
    }
    else{
    this.loader.showSpinner();
    const req = {
      email: this.registerForm.value.email
    };
    const url = `${config.apiUrl}/createclient`;
    this.httpService.postCall(url, req).subscribe(data=>{
      const { success } = data;
      const split = success.split(':');
      let user_id = 0;
      if (split.length === 2) {
        user_id = parseInt(split[1]);
      }
      this.saveUserDetails(user_id);
    }, err => {
      const msg = this.common.getFormattedError(err);
      this.toasterService.show('error', 'Error Occurred', msg);
      this.loader.stopSpinner();
    });
    return true;
  }
  }

  onGoBack() {
    this.location.back();
  }
}
