import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { config } from '../config';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.sass']
})
export class PlatformComponent implements OnInit {
  isAdminRole = false;
  constructor(private commonService: CommonService, private authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.isAdminRole = this.authService.getUserRole() === config.ADMIN_USER_ROLE;
    const routes:any = [{
      name: 'Platform Setup',
      path: 'platform-setup'
    }];
    this.commonService.setHeaderData(routes);
  }

  onPlatformItem(route:string) {
    this.router.navigate([`/${route}`]);
  }

}
