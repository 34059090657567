import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-surveyinit',
  templateUrl: './surveyinit.component.html',
  styleUrls: ['./surveyinit.component.sass']
})
export class SurveyinitComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params:any) => {
      const emailId= params.get('emailId');
      const surveyId = params.get('surveyId');
      this.authService.setSurveyEmail(emailId, surveyId);
      this.router.navigate(['/survey/1']);
    });
  }

}
