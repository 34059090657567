import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { UiService } from '../services/uiloader/uiloader.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../services/common/common.service';
import { LogService } from '../services/log/log.service';
import { AuthService } from '../auth/auth.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { AppConstants } from '../constants/appConstants';
import { Router, ActivatedRoute } from '@angular/router';
import { SidenavService } from '../services/sidenav/sidenav.service';


@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class UserdetailsComponent implements OnInit {

  selectedItem:string = '';
  specificProspectId:any = 0;
  userData:any = {
    avatar: null,
    roles: {},
    mobilephone: ''
  };
  imageLoading:boolean = true;
  imgDomainUrl:string = '';
  genderOptions:any = [
    {
      value: 'm',
      text: 'Male'
    },
    {
      value: 'f',
      text: 'Female'
    }
  ];
  pagination = {
    count: 0,
    perPage: 500,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  userNamesData:any = [];
  userAddressData:any = [];
  selectData:any = [];
  activeData: any = [];
  inactiveData: any = [];
  consultantData:any = [];
  colsdef: string[] = ['name', 'purchased', 'duration', 'status'];
  showFields: boolean = true;
  rolesData:any = [];
  isActAsUserDisplay:boolean = false;
  isActiveUserRole:number;
  inbodyDataComp:any;
  activeIndex:number = 0;
  actAsMenuObject: any ={};
  sideNavData$:any = [];
  companyUrl:any;


  constructor(private route: ActivatedRoute,
    private location: Location,
    private httpService: HttpService,
    private cookieService: CookieService,
    private commonService: CommonService,
    private logService: LogService,
    private ui: UiService,
    private authService: AuthService,
    private router: Router,
    private toasterService: ToasterService,
    private sidenavService: SidenavService) {
    const userRole = parseInt(this.authService.getUserRole());
    this.isActiveUserRole = userRole;
    this.sidenavService.sideNavData.subscribe((data: any) => {
      this.sideNavData$ = data;
    });
    this.actAsMenuObject = this.sideNavData$.find((sideNav: any) => sideNav.id == 35);
    if (this.actAsMenuObject && this.actAsMenuObject?.privileges?.length > 0) {
      this.isActAsUserDisplay = true;
    }
    else {
      this.isActAsUserDisplay = false;
    }

      // if (userRole === AppConstants.Constants.SUPER_USER_ROLE || userRole === AppConstants.Constants.CONSULTANT_ROLE) {
      //   this.isActAsUserDisplay = true;
      // } else {
      //   this.isActAsUserDisplay = false;
      // }

    }

  ngOnInit(): void {
    let id = this.route.snapshot.params.id;
    this.companyUrl = this.authService.getCompanyUrl();
   this.route.queryParams.subscribe(params =>{
    if(params.tab === 'settings'){
      this.activeIndex = 2;
    }
   })
    this.specificProspectId = id;
    this.imgDomainUrl = config.imageUrl;
    this.imageLoading = true;
    this.getUserData(id);
    this.getStates();
    this.programsList(id);
    this.fetchRoles();
    if(this.cookieService.get('consultant')) {
      this.showFields = false;
    }
  }

  fetchRoles() {
    const url = `${config.apiUrl}/permission/roles`;
    this.httpService.getCall(url).subscribe(data => {
      const result = data.map((item:any) => ({
        key: item.id,
        value: item.display_name
      }));
      this.rolesData = result;
    },
    err => {
      this.logService.logError(err);
    });
  }

  onImgError() {
    const userAvatarArr = this.userData.avatar.split('/');
    const userAvatarName = userAvatarArr[userAvatarArr.length - 1];
    if (userAvatarName.length > 0) {
      this.imgDomainUrl = config.webImageUrl;
    }
    this.userData.avatar = userAvatarName.length > 0 ? `${this.imgDomainUrl}${userAvatarName}` : null;
  }

  onImageLoaded() {
    this.imageLoading = false;
  }

  formatDate(date:Date) {
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    return `${month + 1}/${day}/${year}`
  }

  programsList(id:any) {
    this.httpService.postCall(`${config.apiUrl}/getclientprogramswithgoals`, {"client_id": id}).subscribe(data => {
      let progs = data.programswithgoals;
      let obj = [];
      for(let i=0; i<progs.length; i++) {
        let dur = new Date(progs[i].start_date);
        let stDate = this.formatDate(dur);
        let sessions = progs[i].packagepurchased;
        let endDate = this.formatDate(new Date(progs[i].estimated_end_date));
        let sobj:any = {
          name: progs[i].name, 
          purchased:  sessions + ' Sessions', 
          duration: `${stDate} - ${endDate}`,
          goal_id: progs[i].id,
          id: progs[i].user_id
        }
        if ((progs[i].id === data.user.activeprogram_id) || (!data.user.activeprogram_id && i === 0)) {
          this.activeData = [];
          sobj['status'] = 'Active';
          this.activeData.push(sobj);
        } else {
          sobj['status'] = 'Inactive';
          obj.push(sobj);
        }
      }
      this.inactiveData = obj;
    },
    err => {
      this.ui.spin$.next(false);
      this.logService.logError(err);
    });
  }

  getUserGender(gender: string) {
    if (gender === 'm') {
      return 'Male';
    } else if ( gender === 'f') {
      return 'Female';
    }
    return '';
  }

  setUserNamesData(data:any) {
    this.userNamesData = [
      {
        key: 'firstname',
        value: data.firstname,
        placeholder: 'First Name'
      },
      {
        key: 'lastname',
        value: data.lastname,
        placeholder: 'Last Name'
      }
    ];
  }

  getFormatConsultants(data:any) {
    const { rows = [] } = data;
    return rows.map((item:any) => ({
      key: item.id,
      value: (item.firsname || item.lastname) ? `${item.firstname} ${item.lastname}` : item.email
    }));
  }

  getConsultants() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: this.pagination.pageIndex,
      limit: this.pagination.perPage,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: this.pagination.searchtext
    };
    this.httpService.postCall(`${config.apiUrl}/companyadmin/getconsultants`, obj).subscribe((data:any) => {
      this.consultantData = this.getFormatConsultants(data);
      this.selectedItem = 'consultant_id';
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  getRoles() {
    
  }

  setUserAddress(data:any) {
    if (!data) {
      return false;
    }
    this.userAddressData = [
      {
        key: 'address',
        value: data.address,
        placeholder: 'Address'
      },
      {
        key: 'aptsuite',
        value: data.aptsuite,
        placeholder: 'Apt Suite'
      },
      {
        key: 'city',
        value: data.city,
        placeholder: 'City'
      },
      {
        key: 'state',
        value: data.state,
        placeholder: 'State',
        elementType: 'select'
      },
      {
        key: 'postalcode',
        value: data.postalcode,
        placeholder: 'Postal Code'
      },
    ];
    return true;
  }

  getUserFormattedData(result:any) {
    const { rows = [] } = result;
    if (rows.length > 0) {
      const [data] = rows;
      this.setUserNamesData(data);
      this.setUserAddress(data.personal);
      let consultantName = '';
      if (data.consultant) {
        if (data.consultant.firsname || data.consultant.lastname) {
          consultantName = `${data.consultant.firstname} ${data.consultant.lastname}`;
        } else {
          consultantName = data.consultant.email;
        }
      }
      return {
        user_id: data.id,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        enabled: data.enabled,
        roles: data.roles[0],
        gender: this.getUserGender(data.personal ? data.personal.gender : ''),
        genderShort: data.personal ? data.personal.gender : '',
        mobilephone: data.personal ? data.personal.mobilephone : '',
        address: data.personal ? data.personal.address : '',
        aptsuite: data.personal ? data.personal.aptsuite : '',
        city: data.personal ? data.personal.city : '',
        state: data.personal ? data.personal.state : '',
        postalcode: data.personal ? data.personal.postalcode : '',
        birthdate: data.personal ? data.personal.birthdate : '',
        avatar: data?.personal?.avatar ? `${this.imgDomainUrl}${data.personal.avatar}` : null,
        companyName: data.company ? data.company.name : '',
        consultant_id: data.consultant_id,
        consultantName: consultantName
      }
    } 
    return {};
  }

  getUserData(id:number) {
    const obj = {
      user_id: id
    }
    this.ui.spin$.next(true);

    this.httpService.postCall(`${config.apiUrl}/getclients`, obj).subscribe(data => {
      this.userData = this.getUserFormattedData(data);
      this.ui.spin$.next(false);
      const breadcrumb = [
        {name: 'Clients', path: this.isActiveUserRole === AppConstants.Constants.CONSULTANT_ROLE ? 'consultant-clients' : 'company-clients'},
        {name: `${data.rows[0].firstname} ${data.rows[0].lastname}`, path: `userdetails/${this.route.snapshot.params.id}`}
      ];
      this.commonService.setHeaderData(breadcrumb);
    },
    err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Alert', err?.error?.error);
      this.logService.logError(err);
    });
  }

  getStates() {
    this.httpService.getCall(`${config.apiUrl}/getstates`).subscribe(data => {
      this.selectData = data;
    },
    err => {
        this.logService.logError(err);
    });
  }

  onItemSelect(val:string) {
    if(!this.showFields && val === 'roles') return;
    this.selectedItem = val;

    if (val === 'consultant_id' && this.consultantData.length === 0 && this.isActAsUserDisplay) {
      this.getConsultants();
    }
  }
  updateRoles(item:any) {
    const url = `${config.apiUrl}/companyadmin/updateuserroles/${this.userData.user_id}`;
    const reqData = {
      role_ids: [item.value]
    };

    this.httpService.putCall(url, reqData).subscribe(result => {
      const selectedRole = this.rolesData.filter( (rec:any) => rec.key === item.value);
      this.userData.roles.display_name = selectedRole[0].value;
      this.userData.roles.id = selectedRole[0].key;
      this.selectedItem = '';
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
    });
  }

  onAction(item:any) {
    if (item.isSave) {
      this.ui.spin$.next(true);
      if(this.selectedItem === 'roles') {
        this.updateRoles(item);
        return true;
      }
      let obj:any = {};
      if (!item.type) {
        obj = {
          user_id: this.userData.user_id,
          [this.selectedItem]: item.value
        };
      } else {
        obj = item.data;
        obj.user_id = this.userData.user_id;
      }
      this.httpService.putCall(`${config.apiUrl}/updateclient`, obj).subscribe((data:any) => {
        if (item.type) {
          this.userData = {...this.userData, ...item.data};
          if (item.type === 'image') {
            this.userData.avatar = `${config.imageUrl}${item.data.avatar}`;
          }
          // this.setUserNamesData(this.userData);  
        }
        else if (this.selectedItem === 'gender') {
          this.userData[this.selectedItem] = this.getUserGender(item.value);
        } else if (this.selectedItem === 'consultant_id') {
            const selectedConsultant = this.consultantData.filter( (rec:any) => rec.key === item.value);
            this.userData.consultantName = selectedConsultant[0].value;
        } else {
          this.userData[this.selectedItem] = item.value;
        }
        this.selectedItem = '';
        this.ui.spin$.next(false);
      },
      err => {
        this.logService.logError(err);
        this.selectedItem = '';
        this.ui.spin$.next(false);
      });
    } else {
      this.selectedItem = '';
      this.setUserNamesData(this.userData);
      this.setUserAddress(this.userData);
    }
    return true;
  }

  // imageFile(cb:any, blob:any) {
  //   const formData = new FormData();
  //   formData.append("photo", blob, cb.userData.email);
  //   cb.httpService.postImageCall(`${config.apiUrl}/uploadprofilephoto`, formData).subscribe((data:any) => {
  //     console.log(data);
  //   },
  //   (err:any) => {
  //     console.log(err)
  //   });
  // }

  // toBase64(file:any) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = error => reject(error);
  //   });
  // }

  // DataURIToBlob(dataURI: any) {
  //   const splitDataURI = dataURI.split(',')
  //   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
  
  //   const ia = new Uint8Array(byteString.length)
  //   for (let i = 0; i < byteString.length; i++)
  //       ia[i] = byteString.charCodeAt(i)
  
  //   return new Blob([ia], { type: mimeString })
  // }

  async uploadImage(fileInputEvent: any) {
    var file = fileInputEvent.target.files[0];
    console.log(file);
    //const data = await this.toBase64(file);

    const formData = new FormData();
    formData.append("photo", file, `${this.userData.email}.jpg`);

    this.imageLoading = true;
    this.httpService.postCall(`${config.apiUrl}/uploadprofilephoto`, formData).subscribe((data:any) => {
      const time = new Date().getTime();
      this.userData.avatar = `${this.imgDomainUrl}${data.avatar}?t=${time}`;
      const obj = {
        data: {
          avatar: data.avatar,
        },
        type: 'image',
        isSave: true
      };
      this.onAction(obj);
    },
    (err:any) => {
      this.logService.logError(err);
    });
    // console.log(data);
    // let reader = new FileReader();
    // const cb:any = this;
    // reader.onload = function(e:any) {
    //     let blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
    //     cb.imageFile(cb, blob);
    // }.bind(this);
    // reader.readAsArrayBuffer(file);
    // var reader = new FileReader();
    // reader.addEventListener('load', this.readFile);
    // reader.readAsText(file);
  }

  onGoBack() {
    this.location.back();
  }

  actAsUser() {
    const url = `${config.apiUrl}/companyadmin/impersonate/${this.specificProspectId}`;
    this.httpService.getCall(url).subscribe((res)=>{
      this.authService.storeCompanyIdValuesAsMap(res.userData.id, res.companyAdminId);
      this.authService.onActAsUser(res, true);
    }, (err) => {
      console.log(err);
    });
  }

  deactivateAccount() {
    this.ui.spin$.next(true);
    if (this.userData.enabled === 1) {
      const url = `${config.apiUrl}/companyadmin/deleteclient/${this.specificProspectId}`;
      this.httpService.deleteCall(url, {}).subscribe((res)=>{
        this.selectedItem = '';
        this.userData.enabled = 0;
        this.ui.spin$.next(false);
        this.toasterService.show('success', 'Success', 'User deleted successfully');
        this.onGoBack();
      }, (err) => {
        this.ui.spin$.next(false);
        this.toasterService.show('error', 'Error Occurred', err?.error?.error);
        console.log(err);
      });
    } else {
      const url = `${config.apiUrl}/companyadmin/reactiveclient/${this.specificProspectId}`;
      this.httpService.putCall(url, {}).subscribe((res)=>{
        console.log(res);
        this.selectedItem = '';
        this.userData.enabled = 1;
        this.ui.spin$.next(false);

      }, (err) => {
        this.ui.spin$.next(false);
        this.toasterService.show('error', 'Error Occurred', err?.error?.error);
        console.log(err);
      });

    }
  }

  disableAccount(){
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/updateuserstatus/${this.specificProspectId}`
    this.httpService.putCall(url, {"enabled":1}).subscribe((res)=>{
     console.log(res);
      this.ui.spin$.next(false);
    }, (err) => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', err?.error?.error);
      console.log(err);
    });

  }

  onRowSelection(record:any) {
    const obj = {
      name: record.name,
      duration: record.duration,
      goal_id: record.goal_id,
      userName: `${this.userData.firstname} ${this.userData.lastname}`,
      client_id: record.id
    };
    this.authService.setGoalData(obj);
    this.router.navigate([`program/${this.route.snapshot.params.id}/${record.goal_id}`]);
  }

  onCreateProgram() {
    const obj = {
      userName: `${this.userData.firstname} ${this.userData.lastname}`,
      selectedUserId: this.specificProspectId
    };
    this.authService.setGoalData(obj);

    this.router.navigate(['programcreate',this.specificProspectId]);
  }

}
