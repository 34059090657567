import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';
import { ToasterService } from '../services/toaster/toaster.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.sass']
})
export class ResourcesComponent implements OnInit {
  specificProspectId:string = '';
  constructor(private authService: AuthService,private httpService: HttpService,private toasterService:ToasterService,
    private logService:LogService) { 
   
  }

  ngOnInit(): void {
    this.httpService.getCall(`${config.apiUrl}/getuser`).subscribe(data => {
     if(data && data.length > 0) this.specificProspectId = data[0].mobilephone
    },
    err => {
      this.toasterService.show('error', 'Alert', err?.error?.error);
      this.logService.logError(err);
    });

   }

}
