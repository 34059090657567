import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    const routes:any = [];
    this.commonService.setHeaderData(routes);
  }
  highcharts = Highcharts;

  weightChartOptions: Highcharts.Options = {
    title: {
      text: "Weight Chart",
      align: "left"
    },
    xAxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        gridLineWidth: 1,
        gridLineColor: '#E4E4E4',
        lineWidth: 0,
    },
    yAxis: {
      title: {
        text: ""
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
          marker: {
              enabled: false
          }
      }
  },
  legend: {
    enabled: false
  },
    series: [{
      data: [12, 8, 43, 35, 20, 90, 100, 110],
      type: 'spline',
      color: '#0080F5'
    }, 
    {
      data: [6, 4, 21.5, 17.5, 10, 45, 50, 55],
      type: 'spline',
      color: '#28C76C'
    }]
  }

  bodyFatChartOptions: Highcharts.Options = {
    title: {
      text: "Body Fat Chart",
      align: "left"
    },
    xAxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        gridLineWidth: 1,
        gridLineColor: '#E4E4E4',
        lineWidth: 0,
    },
    yAxis: {
      title: {
        text: ""
      },
      gridLineWidth: 0,
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
          marker: {
              enabled: false
          }
      }
  },
    series: [{
      data: [12, 8, 43, 35, 20, 90, 100, 110],
      type: 'spline',
      color: '#0080F5'
    }]
  }

  dashboardWeightChartOptions: Highcharts.Options = {
    title: {
      text: "",
    },
    xAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        labels: {
          enabled: false
        }
    },
    yAxis: {
      title: {
        text: ""
      },
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
          marker: {
              enabled: false
          }
      }
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
    series: [{
      data: [12, 8, 43, 35, 20, 90, 100, 110],
      type: 'spline',
      color: '#0080F5',
      enableMouseTracking: false
    }]
  }

  nutritionChartOptions: Highcharts.Options = {
  title: {
      text: 'Nutrition',
      align: 'left'
  },
  xAxis: {
      categories: [
          'Protein <br/> 33',
          'Fat<br/> 33',
          'Carbs<br/> 33'
      ],
      gridLineWidth: 0,
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '16px',
          textAlign: 'left'
        }
      }
  },
  yAxis: [{
      min: 0,
      title: {
          text: ''
      },
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
  }, {
      title: {
          text: ''
      },
      opposite: true
  }],
  legend: {
      enabled: false
  },
  tooltip: {
      shared: true
  },
  plotOptions: {
      column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
      }
  },
  series: [{
    data: [12, 8, 43],
    color: '#C4C4C4',
    enableMouseTracking: false,
    pointPadding: 0.39,
    pointPlacement: -0.1,
    borderRadius: 5,
    type: 'column'
  },
  {
    data: [6, 4, 30],
    color: '#11101D',
    enableMouseTracking: false,
    pointPadding: 0.39,
    pointPlacement: -0.15,
    borderRadius: 5,
    type: 'column'
  }]
  }
}
