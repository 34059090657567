<div class="themeing-main-content">
    <div class="themeing-container">
        <section class="spec-section">
            <div class="header"><span>Theme</span></div>
            <div [ngClass]="{'highlight': selectedItem === 'themecolour'}">
                <div class="highlight-inner">
                    <div class="item" (click)="onItemSelect('themecolour')">
                        <span>Theme Colour</span><span>{{customerData.themecolour}}</span>
                    </div>
                    <app-useritem *ngIf="selectedItem === 'themecolour'"  name="Theme Colour" [itemValue]="customerData.themecolour" (action)="onAction($event)"></app-useritem>
                </div>
            </div>
            <input #companyImageInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="uploadImage($event)" accept="image/*"/>
            <div>
                <div class="highlight-inner" (click)="companyImageInput.click()">
                    <div class="item">
                        <span>Upload Company Image</span>
                        <span>Attach</span>
                    </div>
                </div>
            </div>

            <div class="themeing-footer">
                <button mat-flat-button color="primary" (click)="onSubmitTheme()">Submit</button>
            </div>
        </section>
    </div>
</div>