import { Injectable } from '@angular/core';
import { BaseLoginProvider, SocialUser } from 'angularx-social-login';
import { config } from '../config';
// import { resolve } from 'dns';

declare let AppleID: any;
@Injectable()
export class AppleLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'APPLE';

  protected auth2: any;
  private clientId: string = 'com.totalhealth.elevate.web';
  private _initOptions: any = { scope: 'email name' };
  constructor(

  ) {
    super();
  }

  public initialize(): Promise<void> {
    return new Promise((resolve, _reject) => {
      this.loadScript(
        AppleLoginProvider.PROVIDER_ID,
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
        () => {
          // AppleID.auth.init({
          //   clientId: this.clientId,
          //   scope: 'name email',
          //   redirectURI: 'https://thfweb.s3-website.us-east-2.amazonaws.com/auth/apple',
          //   state: 'totalhealthandfitness', //used to prevent CSFR
          //   usePopup: false,
          // });
    const REDIRECT_API_URL = `${config.apiUrl}/api/auth-apple-signin`
    window.open(
        `https://appleid.apple.com/auth/authorize? +
            client_id=${this.clientId}& +
            redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}& +
            response_type=code id_token& +
            scope=name email& +
            response_mode=form_post`,
        '_blank'
    );

    window.addEventListener('message', async event => {
        const decodedToken = event.data.id_token;
        //this.jwtHelper.decodeToken(event.data.id_token);
        let requestData = {}
        if (event.data.user) {
            const userName = JSON.parse(event.data.user);
            requestData = {
                "email": decodedToken.email,
                "name": `${userName.name.firstName} ${userName.name.lastName}`,
                "socialId": decodedToken.sub,
            };
        } else {
            requestData = {
                "email": decodedToken.email,
                "socialId": decodedToken.sub,
            };
        }
        console.log(`User Data : ${requestData}`);
        // do your next stuff here
    });
          resolve();
        }
      );
    });
  }

  public getLoginStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      // todo: implement
    //   resolve();
    });
  }

  public async signIn(signInOptions?: any): Promise<any> {
    // try {
    //   const data = await AppleID.auth.signIn()
    // } catch (er) {
    //   console.log(er);
    // }
    const REDIRECT_API_URL = `${config.apiUrl}/auth/applesignin`
    window.open(
        `https://appleid.apple.com/auth/authorize?client_id=${this.clientId}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name email&response_mode=form_post`,
        '_self'
    );

    window.addEventListener('message', async event => {
        const appleToken = event.data.id_token;
        //this.jwtHelper.decodeToken(event.data.id_token);
        // let requestData = {}
        // if (event.data.user) {
        //     const userName = JSON.parse(event.data.user);
        //     requestData = {
        //         "email": decodedToken.email,
        //         "name": `${userName.name.firstName} ${userName.name.lastName}`,
        //         "socialId": decodedToken.sub,
        //     };
        // } else {
        //     requestData = {
        //         "email": decodedToken.email,
        //         "socialId": decodedToken.sub,
        //     };
        // }
        console.log(`Apple Token : ${appleToken}`);
        // do your next stuff here
    });
  }

  public signOut(revoke?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      // AppleID doesnt have revoke method
      resolve();
    });
  }
}