import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { config } from '../config';
import { ToasterService } from '../services/toaster/toaster.service';
import { HttpService } from '../services/http/http.service';

@Component({
  selector: 'app-nutrition-program-uploader',
  templateUrl: './nutrition-program-uploader.component.html',
  styleUrls: ['./nutrition-program-uploader.component.sass']
})
export class NutritionProgramUploaderComponent implements OnInit {
  nutritionCategories = [];
  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private commonService: CommonService) { 
    this.getNutritionCategory();
  }

  ngOnInit(): void {
    const routes:any = [{
      name: 'Uploader',
      path: 'uploader'
    },{
      name: 'Nutrition Program Uploader',
      path: 'nutrition-program-uploader'
    }];

    this.commonService.setHeaderData(routes);
  }

  getNutritionCategory() {
    const url = `${config.apiUrl}/nutritionprograms/getnutritiontemplatespercategory/`;
    this.httpService.getCall(url).subscribe(result => {
      this.nutritionCategories = result;
    }, err => {
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

}
