import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { config } from 'src/app/config';
import { HttpService } from 'src/app/services/http/http.service';
import { LogService } from 'src/app/services/log/log.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { UiService } from 'src/app/services/uiloader/uiloader.service';


let exeTimer: any;

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.sass']
})
export class CompanyListComponent implements OnInit {
  deletehighlight: boolean = false;
  searchTxt: string = '';
  isAllSelected: any = false;
  searchText = '';
  allCompanies:any =[];
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  datasource: any = [];
  columnsList: string[] = ['id', 'name', 'companyDomain', 'actions'];
  adminId:any;


  constructor(private httpService: HttpService,
    private ui: UiService,
    private toasterService: ToasterService,
    private logService: LogService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.searchText = '';
    this.adminId = config.ADMIN_USER_ROLE;
     this.getCompanyList();
  }


  getCompanyList(){
    const url = `${config.apiUrl}/companyadmin/getcompanies`;
    this.httpService.getCall(url).subscribe(result => {
      this.allCompanies = result;
      this.datasource =result;
      this.ui.spin$.next(false);
    }, err => {
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

  deleteCompany(company:any,index:Number){
    this.ui.spin$.next(true);
    this.httpService.deleteCall(`${config.apiUrl}/companyadmin/deletecompany/${company.id}`,{}).subscribe(data => {
      if(data){
       this.getCompanyList();
       this.toasterService.show('success', 'Success', 'Company Deleted Successfully');
       
      }     
     },
     err => {
       this.ui.spin$.next(false);
       this.logService.logError(err);
     });

  }

  searchRecords(text: string) {
    this.searchText = text;
    this.pagination.pageIndex = 0;
    if (!text) this.datasource = this.allCompanies;
    else {
      let result = this.datasource.filter((company: any) => company.name.toLowerCase().includes(text));
      this.datasource = result;
    }
  }

  searchResult(ev: any) {
    clearTimeout(exeTimer);
    exeTimer = setTimeout(() => {
      this.searchRecords(ev.target.value);
    }, 1000);
  }

  getServerData(e: any) {
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
  }


  masterSelection() {
    let highlgt = this.datasource.find((o: any) => o.checked == true);
    highlgt ? this.deletehighlight = true : this.deletehighlight = false;
  }

  cancelEdit(row: any, field: string, i: number = 5) {
    this.datasource[i][`${field}Edit`] = false;
  }

  enableEdit(row: any, field: any, i: number) {
    this.datasource[i][`${field}Edit`] = true;
  }

  saveNewValue(row: any, key: string, i: number) {
    this.httpService.putCall(`${config.apiUrl}/companyadmin/updatecompany/${row.id}`, {name:row.name, company_url:row.company_url}).subscribe(data => {
      if(data.success){
       this.ui.spin$.next(false);
      }     
     },
     err => {
       this.ui.spin$.next(false);
       this.logService.logError(err);
     });
  }
  createCompanyAdmin(company:any){
    this.httpService.postCall(`${config.apiUrl}/superadmin/createcompanyadmin`, company).subscribe(data => {
     if(data.success){
      this.getCompanyList();
      this.toasterService.show('success', 'Success', 'Company and Company Admin Created Successfully.');
     }     
    },
    err => {
      this.ui.spin$.next(false);
      this.logService.logError(err);
    });

  }

  addCompanyDialog(){
    const dialogRef = this.dialog.open(CreateCompanyDialog, {
      width: '600px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.name) {
        this.ui.spin$.next(true);
        this.httpService.postCall(`${config.apiUrl}/companyadmin/createcompany`, {'name': result.name, 'company_url':result.company_url || ""}).subscribe(data => {
          if(data.success){
            result['company_id'] = data.success.id
            if (result.selectedFile) {
              this.uploadCompanyLogo(result.selectedFile, result['company_id']);
            }
            this.createCompanyAdmin(result);
          }
          // this.ui.spin$.next(false);
        },
        err => {
          this.ui.spin$.next(false);
          this.logService.logError(err);
        });        
      }
    });

  }

  uploadCompanyLogo(fileData:any, companyId: any) {
    const formData = new FormData();
    formData.append("logo", fileData[0], `${new Date().getTime()}.jpg`);
    this.httpService.putCall(`${config.apiUrl}/companyadmin/updatecompanylogo/${companyId}`, formData).subscribe((data:any) => {
      
    },
    (err:any) => {
      this.logService.logError(err);
    });

  }
}

@Component({
  selector: 'create-company-dialog',
  templateUrl: './create-company-dialog.html',
  styleUrls: ['../../newprogram/newprogram.component.sass']
})
export class CreateCompanyDialog {
  constructor(
    public dialogRef: MatDialogRef<CreateCompanyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }

  selectFile(ev:any) {
    this.data.selectedFile = ev.target.files;
  }

  // updateUrl(){
  //   this.data.company_url = "test_url";
  //   }

  //   generateUrl(name: string): string {
  //     return name
  //       .toLowerCase()
  //       .replace(/[^a-z0-9\s]/g, '') // Remove special characters
  //       .replace(/\s+/g, '-')        // Replace spaces with hyphens
  //       .trim();                     // Remove leading/trailing spaces
  //   }
  
}
