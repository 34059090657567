<div class="main-content">
    <h1>Dashboard</h1>
    <mat-card>Waiting for UX</mat-card>

    <highcharts-chart
    [Highcharts] = "highcharts" 
    [options] = "weightChartOptions" 
    style = "width: 100%; height: 400px; display: block;">
 </highcharts-chart>

 <highcharts-chart
 [Highcharts] = "highcharts" 
 [options] = "bodyFatChartOptions" 
 style = "width: 100%; height: 400px; display: block;">
</highcharts-chart>

<highcharts-chart
[Highcharts] = "highcharts" 
[options] = "dashboardWeightChartOptions" 
style = "width: 100%; height: 400px; display: block;">
</highcharts-chart>

<highcharts-chart
[Highcharts] = "highcharts" 
[options] = "nutritionChartOptions" 
style = "width: 100%; height: 400px; display: block;">
</highcharts-chart>

<mat-progress-bar mode="determinate" value="40"></mat-progress-bar>

<circle-progress
  [percent]="85"
  [radius]="100"
  [animation]="true"
  [animationDuration]="300"
></circle-progress>
</div>
