<div
class="answer-option"
>
<mat-radio-group aria-label="Select an option" 
[ngClass]="'col-'+colType" 
>
    <mat-radio-button class="radio-select" [value]="item.key" [checked]="item.key === itemValue" (click)="onOptionChange(item.key)" *ngFor="let item of values">{{item.value}}</mat-radio-button>
  </mat-radio-group>
  <div *ngIf="specified_strings.length > 0">
    <div class="radio-footer">{{specified_strings[0].start}}</div>
    <div class="radio-footer last">{{specified_strings[1].end}}</div>
  </div>
</div
>