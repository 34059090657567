import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-selectcheckbox',
  templateUrl: './selectcheckbox.component.html',
  styleUrls: ['./selectcheckbox.component.sass']
})
export class SelectcheckboxComponent implements OnInit {
  @Input() values:any = [];
  @Output() action = new EventEmitter<any>();
  @Input() itemValueInput:string = '';
  @Input() itemValue:any = '';
  @Input() indexValue:number = -1;
  checkedArr:any = [];
  constructor() { }

  ngOnInit(): void {
    this.checkedArr = this.itemValue ? this.itemValue.split(',') : [];
  }

  onOptionChange(event:any, key:any) {
    let data = this.checkedArr;
    const arrIndex = data.indexOf(key);
    if (arrIndex !== -1) {
      data.splice(arrIndex, 1);
    } else {
      if (key === 'none') {
        this.checkedArr = [];
        data = [];
      }
      data.push(key);
    }
    const obj = {
      index: this.indexValue,
      info: data
    };
    this.action.emit(obj);
  }

  onAction(data:any) {
    const obj = {
      index: this.indexValue,
      other_input: data.info
    };
    this.action.emit(obj);
  }

  getIsSelected(val:number) {
    return this.checkedArr.indexOf(val) !== -1
  }
}
