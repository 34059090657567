import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-surveyfinish',
  templateUrl: './surveyfinish.component.html',
  styleUrls: ['./surveyfinish.component.sass']
})
export class SurveyfinishComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.gaEvent('survey_finish', 'survey_finish', 'Create User on filling Survey');
  }

}
