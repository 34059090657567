<div class="overlay" *ngIf="showSpinner">
  <div class="center">
    <circle-progress [percent]="templateProgress" [radius]="60" [backgroundPadding]="5" [outerStrokeWidth]="16"
      [innerStrokeWidth]="8" [outerStrokeColor]="'#4882c2'" [innerStrokeColor]="'#4882c2'" [animation]="true"
      [animationDuration]="300">
    </circle-progress>
  </div>
</div>

<div>
  <div class="top-section table-container">
    <table>
      <mat-form-field class="template-copy-width" appearance="fill">
        <mat-label>All Companies</mat-label>
        <mat-select>
          <mat-option *ngFor="let company of allCompanies" [value]="company.id" (click)="onSelectCompany(company)">
            {{company.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-selection-list #templates [(ngModel)]="selectedOptions">
        <mat-list-option *ngFor="let template of typesOfTemplates" [value]="template" 
          [disabled]="template.disabled">
          {{template.templateName}}
        </mat-list-option>
      </mat-selection-list>

      <button mat-raised-button color="primary" style="float: right;margin-top:2%" (click)="onsubmitTemplateCopy()"
        [disabled]="!selectedCompany || isDisableSubmitButton">Save Template Configuration</button>
    </table>

  </div>
</div>