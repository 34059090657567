<div>
    <div class="main-header-survey">
    </div>
    <div class="main-container">
        <div class="getting-know">
            ThankYou!
        </div>
        <div class="would-love">
            Check your email to find out the next steps. We are excited to start this journey with you!
        </div>
        <div class="sub-container">
            <div class="success">
                <mat-icon class="success-icon" aria-hidden="false" aria-label="Success Icon">check_circle_outline</mat-icon>
            </div>
        </div>
    </div>
</div>