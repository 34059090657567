import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { LogService } from '../services/log/log.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../services/common/common.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../services/toaster/toaster.service';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {FormBuilder, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-programcreation',
  templateUrl: './programcreation.component.html',
  styleUrls: ['./programcreation.component.sass'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class ProgramcreationComponent implements OnInit { 
  headerData:any = {};
  start_weight:any = '10';
  start_body_fat:any = '22';
  start_date:any = '';
  end_date:any = ''
  dateValue:any = '';
  fat_mass:any = 0;
  lean_mass:any = 0;
  active = 'stats';
  user_id: any;
  user_data: any;
  age: any;
  errorMsg:string = '';
  goalsData: any = [
    {measurement: 'Body Fat', current: '%', classification: '', chart: 'bodyfat', isDisabled: false}
  ];
  //,{measurement: 'Muscle Mass', current: '', classification: '', chart: 'musclemass', isDisabled: true}
  goalsBodyFat: any;
  goalsMuscleGain: any;
  goalsColumns: string[] = ['measurement', 'current', 'classification', 'chart'];
  bodyFatChart:any;
  recommenedGoalTime: any = 12;
  roadmapData: any;
  roadmap: any;
  goalDuration: any;
  roadmapDataOverview: any;
  defaultDurations = ["4 weeks", "8 weeks", "12 weeks", "16 weeks", "20 weeks", "24 weeks"];
  goalsDuartions = this.defaultDurations;
  startErr = false;
  goalErr = false;

  minDate:any = '';
  constructor(private authService: AuthService,
    private httpService: HttpService,
    private ui: UiService,
    private logService: LogService,
    private cookieService: CookieService,
    public commonService: CommonService,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private toasterService: ToasterService,private _formBuilder: FormBuilder,private route: ActivatedRoute) { 
    // this.headerData = this.authService.getGoalData();
    // this.user_id = this.headerData.selectedUserId;
    const dt = new Date();
    dt.setFullYear(dt.getFullYear() - 10);
    this.minDate = dt;
    
  }
  // formatLabel(value: number) {
  //    return `${value}lbs`;
  // }
  calculateMass(){
    this.fat_mass = Math.round((this.start_weight * (this.start_body_fat / 100)) * 10) / 10;
    this.lean_mass = Math.round((this.start_weight - this.fat_mass) * 10) / 10;
  }
  
  ngOnInit(): void {
    this.authService.hideDisplayHeaderValue();
    this.dateValue = new FormControl(new Date());
    // this.user_id = this.cookieService.get('user_actual_logged_id');
    this.user_id = this.route.snapshot.params.userId;
    this.calculateMass();
    this.getClientsDetail();
    
  }

  onNavigateBack() {
    this.location.back();
  }

  onNavigateBackUser() {
    this.router.navigate(['/userdetails/' + this.user_id ]);
  }

  getClientsDetail() {
    this.httpService.postCall(`${config.apiUrl}/getclients`, {user_id: this.user_id}).subscribe(result => {
      this.user_data = result.rows[0];
      this.headerData= { 
        userName: `${this.user_data?.firstname} ${this.user_data?.lastname}`,
      }
      let dob = this.user_data?.personal?.birthdate;
      this.age = this.commonService.roundAge(this.commonService.convertDate2Age(dob));
      this.getBodyFatClassification();
    }, err => {
      this.logService.logError(err);
    });
  }

  getBodyFatClassification() {
    const payload = {
      client_id: this.user_id, 
      gender: this.user_data?.personal?.gender,
      user_age: this.age
    }
    this.httpService.postCall(`${config.apiUrl}/getbodyfatclassifications`, payload).subscribe(result => {
      this.bodyFatChart = result;
    }, err => {
      this.errorMsg = err?.error?.error;
      this.toasterService.show('error', 'Error Occurred', this.errorMsg);
      this.logService.logError(err);
    });
  }

  onAction(item:any) {
    if (item.type === 'start_weight') {
      this.start_weight = item.info;
    } else if (item.type === 'start_body_fat') {
      this.start_body_fat = item.info;
    }
    this.fat_mass = Math.round((this.start_weight * (this.start_body_fat / 100)) * 10) / 10;
    this.lean_mass = Math.round((this.start_weight - this.fat_mass) * 10) / 10;
  }

  numvalidation(val:any){
    return /^\s*(\d+|\.\d+|\d+\.\d+|\d+\.)?\s*$/.test(val);
  }

  handleBack() {
    if(this.active === 'goal') {
      this.active = 'stats';
      this.startErr = false;
    } else if (this.active === 'roadmap') {
      this.active = 'goal';
      this.goalErr = false;
    }
  }

  handleNext(stepper: MatStepper) {
    if(this.active === 'stats') {
      if (!this.bodyFatChart) {
        this.toasterService.show('error', 'Error Occurred', this.errorMsg);
        return false;
      }
      if (this.start_weight && this.start_body_fat && this.numvalidation(this.start_weight) && this.numvalidation(this.start_body_fat)) {
        let key = Object.keys(this.bodyFatChart).filter((o:any) => {
          const [low, high] = this.bodyFatChart[o].displayValue.replace(/\%/g, '').split(' - ');
          if (o === 'veryhigh' && parseInt(this.start_body_fat) > 33) {
            return o;
          } else if(parseInt(this.start_body_fat) > parseInt(low) && parseInt(this.start_body_fat) < parseInt(high)) {
            return o;
          } 
        });
        let classification = this.bodyFatChart[key[0]];
        this.goalsData[0].classification = classification?.displayName;
        this.goalsData[0].current = this.start_body_fat + '%';
        this.goalsBodyFat = this.start_body_fat;
        this.goalsMuscleGain = "0"
        stepper.next();

        // let weightKey = Object.keys(this.bodyFatChart).filter((o:any) => {
        //   const [low, high] = this.bodyFatChart[o].displayValue.replace(/\%/g, '').split(' - ');
        //   if (o === 'veryhigh' && parseInt(this.start_weight) > 33) {
        //     return o;
        //   } else if(parseInt(this.start_weight) > parseInt(low) && parseInt(this.start_weight) < parseInt(high)) {
        //     return o;
        //   } 
        // });
        // let weightClassification = this.bodyFatChart[weightKey[0]];
        // this.goalsData[1].classification = weightClassification?.displayName;
        // this.goalsData[1].current = this.start_weight;

        this.active = 'goal' 
      } else {
        this.startErr = true;
        this.goalErr = false;
      }
    } else if(this.active === 'goal') {
      if(this.goalsBodyFat && this.goalsMuscleGain && this.numvalidation(this.goalsBodyFat) && this.numvalidation(this.goalsMuscleGain)) {    
        this.ui.spin$.next(true);
        let payload = {
          start_weight: this.start_weight,
          start_bodyfat: this.start_body_fat,
          goal_bodyfat: this.goalsBodyFat,
          goal_muscle_gain_pounds: this.goalsMuscleGain,
          user_age: this.age,
          gender: this.user_data?.personal?.gender,
          client_id: this.user_id
        }
        this.httpService.postCall(`${config.apiUrl}/getestimatedweeksforgoal`, payload).subscribe(result => {
          this.roadmapDataOverview = result;
          this.goalDuration = result.weekstogoal;
          let arr = [];
          for (let i = 0; i < Math.floor(result.package_purchased / 4); i++) {
            let numb = (i+1) * 4;
            if (numb === result.weekstogoal) {
              arr.push(numb + ' weeks (recommended)');
              this.recommenedGoalTime = numb;
            } else {
              arr.push(numb + ' weeks');
            }
          }
          if (result.weekstogoal % 4 !== 0) {
            const moduleVal = result.weekstogoal % 4;
            let recommendedWeek = 0;
            if (moduleVal > 2) {
              const leftOver = 4 - moduleVal;
              recommendedWeek = result.weekstogoal + leftOver;
              arr.push(`${recommendedWeek} weeks (recommended)`);
              this.recommenedGoalTime = recommendedWeek;
            } else {
              const lastRecord = arr[arr.length - 1].split(' ')[0];
              arr[arr.length - 1] = `${lastRecord} weeks (recommended)`;
              this.recommenedGoalTime = lastRecord;
            }
          }
          this.recommenedGoalTime = this.recommenedGoalTime.toString();
          this.goalsDuartions = arr;
          this.start_date = this.commonService.getFormattedDate(this.dateValue.value)
          let end_date:any = this.commonService.addDays(parseInt(this.recommenedGoalTime)*7, this.start_date);
          this.end_date = this.commonService.getFormattedDate(end_date);
          this.active = 'roadmap';
          this.ui.spin$.next(false);
          stepper.next();
        }, err => {
          this.logService.logError(err);
          this.ui.spin$.next(false);
        });
      } else {
        this.goalErr = true;
      }
    } else if(this.active === 'roadmap') {
      let payload = {
        user_id: this.user_id,
        start_weight: this.start_weight,
        start_bodyfat: this.start_body_fat,
        goal_bodyfat: this.goalsBodyFat,
        goal_muscle_gain_pounds: this.goalsMuscleGain,
        user_age: this.age,
        gender: this.user_data?.personal?.gender,
        packages_purchased: this.recommenedGoalTime
       }
      this.httpService.postCall(`${config.apiUrl}/savegoal`, payload).subscribe(result => {
        const id = result.success.split('id:')
        this.router.navigate([`/program/${this.user_id}/${id[1].trim()}`]);
      }, err => {
        this.logService.logError(err);
        this.ui.spin$.next(false);
      });
    }
    return true;
  }

  onChangeProgram(ev:any) {    
    // let end_date:any = this.commonService.addDays(parseInt(this.recommenedGoalTime)*7, this.start_date);
    // this.end_date = this.commonService.getFormattedDate(end_date);
  }

  viewChart(key:any) {
    if (key == "bodyfat") {
      // let val: any = '<table>';
      // Object.keys(this.bodyFatChart).forEach((element:any) => {
      //   val = val + '<tr><td>'+this.bodyFatChart[element].displayValue+'</td><td>'+this.bodyFatChart[element].displayName+'</td></tr>';
      // });
      // val = val+'</table>';
      const val = `<div><img class="body_fat_chart" src="../../assets/body-fat-chart.jpg" alt="Body Chart values" /></div>`;
      this.dialog.open(ChartDialog, { data: val });
    }
  }

}
@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <div mat-dialog-content class="dialogContent chartDialog">
      <div [innerHTML]="data"></div>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button class="resultsbtn" mat-button (click)="responseHandler()" cdkFocusInitial>Ok</button>
    </div>`,
  styleUrls: ['./programcreation.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ChartDialog {
  constructor(
    public dialogRef: MatDialogRef<ChartDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    
    responseHandler(): void {
      this.dialogRef.close();
    }
}
