<div class="main-container">
    <div class="sub-container-header">
        <div  *ngIf="headerData.userName">
            <span class="start-info" (click)="onNavigateBack()">{{headerData.userName}}</span> <span class="slash-info">/</span> <span class="end-info">New Program</span>
        </div>
        <div class="white program-info">
            New Program
            <span class="close_icon" (click)="onNavigateBackUser()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
    </div>
    <div class="sub-container">
        <mat-horizontal-stepper  #stepper labelPosition="bottom">
            <mat-step>
                <ng-template matStepLabel>STATS</ng-template>
                <div class="nav-req-msg" *ngIf="startErr">Enter numeric values for both Weight and Bodyfat to proceed. </div>
                <div style="display: flex; flex-direction: column;margin:2%">
                <div class="col-33">
                    <div>Start Date</div>
                    <div class="input_field date-time">
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker" [formControl]="dateValue" [min]="minDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-33">
                    <div>Start Weight</div>
                    <div class="input_field"><app-selectinput placeholder="Weight" [indexValue]="0" [setsValue]="0"
                            [itemValue]="start_weight" type="start_weight" (action)="onAction($event)"></app-selectinput>
                    </div>
                    <div>lbs</div>
                    
                    <span class="irs-max">0lbs</span><mat-slider #startWeight thumbLabel min="0" max="600" (change)="calculateMass()" [(ngModel)]="start_weight">
                    </mat-slider>
                    <span class="irs-max">600lbs</span>
          
                </div>
                <div class="col-33">
                    <div>Start Body Fat</div>
                    <div class="input_field"><app-selectinput placeholder="Body Fat" [indexValue]="0" [setsValue]="0" [itemValue]="start_body_fat" type="start_body_fat" (action)="onAction($event)" ></app-selectinput></div>
                    <div>%</div>
                    <span class="irs-max">0%</span><mat-slider class="custom" #bodyFat thumbLabel min="0" max="70" (change)="calculateMass()" [(ngModel)]="start_body_fat">
                    </mat-slider>
                    <span class="irs-max">70%</span>
                </div>
            </div>
                <div style="display: flex; flex-direction: row; justify-content: space-around;">
                    <mat-card class="fat_mass">
                        <mat-card-content style="margin: 32px;">
                            <p class="spec_info">{{fat_mass}} lbs</p>
                        </mat-card-content>
                        <mat-card-actions style="border-top: 1px solid #d8e2e7;background: #f6f8fa; padding: 15px 10px;">
                            <span style="font-weight: 600; text-transform: uppercase; line-height: 1rem;">Fat Mass</span>
                        </mat-card-actions>
                    </mat-card>
                    <mat-card class="fat_mass">
                        <mat-card-content style="margin: 32px;">
                            <p class="spec_info">{{lean_mass}} lbs</p>
                        </mat-card-content>
                        <mat-card-actions style="border-top: 1px solid #d8e2e7;background: #f6f8fa; padding: 15px 10px;">
                            <span style="font-weight: 600; text-transform: uppercase; line-height: 1rem;">Lean Mass</span>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div class="button-container">
                    <button mat-button class="nextbtn active" (click)="handleNext(stepper)">Next</button>
                  </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>GOAL</ng-template>
                <div  style="display: flex; justify-content: center;">
                    <table class="healthy_range">
                        <tbody>
                            <tr>
                                <th>Current classification</th>
                                <th>Range</th>
                            </tr>
                            <tr [ngStyle]="{'background-color': (start_body_fat >= 0 && start_body_fat <= 11.5) ? 'rgb(221, 221, 221)' : ''}">
                                <td>Essential Fat</td>
                                <td>0% - 11.5%</td>
                            </tr>
                            <tr  [ngStyle]="{'background-color': (start_body_fat >= 11.6 && start_body_fat <= 19) ? 'rgb(221, 221, 221)' : ''}">
                                <td>Optimal</td>
                                <td>11.6% - 19%</td>
                            </tr>
                            <tr [ngStyle]="{'background-color': (start_body_fat >= 19.1 && start_body_fat <= 25) ? 'rgb(221, 221, 221)' : ''}">
                                <td>Moderate</td>
                                <td>19.1% - 25%</td>
                            </tr>
                            <tr [ngStyle]="{'background-color': (start_body_fat >= 25.1 && start_body_fat <= 33) ? 'rgb(221, 221, 221)' : ''}">
                                <td>High</td>
                                <td>25.1% - 33%</td>
                            </tr>
                            <tr [ngStyle]="{'background-color': start_body_fat > 33 ? 'rgb(221, 221, 221)' : ''}">
                                <td>Very high</td>
                                <td>Above 33%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class=" goals-container form-container">
                    <div class="nav-req-msg" *ngIf="goalErr">Enter numeric values for both Bodyfat and Muscle Gain to proceed. </div>
                    <div class="form-row">
                        <label for="Goal-Body-Fat">Clients Age</label>
                        <span class="units">{{age}} Years</span>                    
                    </div>
                    <div class="form-row">
                        <label for="Goal-Body-Fat">Goal Body Fat</label>
                        <input id="Goal-Body-Fat" [(ngModel)]="goalsBodyFat"  name="goalsBodyFat" autocomplete="off" />
                        <span class="units" style="margin-right: 28px;"></span>
                        <span class="irs-max">0%</span><mat-slider #goalFat thumbLabel min="0" max="70" [(ngModel)]="goalsBodyFat">
                        </mat-slider>
                        <span class="irs-max">70%</span>
                    </div>
                    <div class="form-row">
                        <label for="Goal-Muscle-Gain">Goal Muscle Gain</label>
                        <input id="Goal-Muscle-Gain" [(ngModel)]="goalsMuscleGain" name="goalsMuscleGain" autocomplete="off" />
                        <span class="units" style="margin-right: 18px;"></span>
                        <span class="irs-max">-50lbs</span><mat-slider #goalGain thumbLabel min="-50" max="50" [(ngModel)]="goalsMuscleGain">
                        </mat-slider>
                        <span class="irs-max">50lbs</span>
                    </div>
                </div>
                <div class="button-container">
                    <button class="backbtn" *ngIf="active !== 'stats'" matStepperPrevious (click)="handleBack()">Back</button>
                    <button mat-button  class="nextbtn active"   (click)="handleNext(stepper)">Next</button>
                  </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>COMMIT</ng-template>
                <div style="margin-top: 10px;display:flex; align-items:center">
                  <div class="col-md-3">
                      <section class="widget-simple-sm">
                          <div class="statistic">
                              <span class="text">Start Weight</span>
                          </div>
                          <div class="widget-simple-sm-statistic">
                              <span class="counter">{{roadmapDataOverview?.start?.weight}} lbs</span>
                          </div>
                      </section>
                  </div>
                  <div>
                      <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                  </div>
                  <div class="col-md-3">
                      <section class="widget-simple-sm">
                          <div class="statistic">
                              <span class="text">Goal Weight</span>
                          </div>
                          <div class="widget-simple-sm-statistic">
                              <span class="counter green">{{roadmapDataOverview?.goal?.weight}} lbs</span>
                          </div>
                      </section>
                  </div>
                  <div>
                      <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                  </div>
                  
                  <div class="col-md-3">
                      <section class="widget-simple-sm">
                          <div class="statistic">
                              <span class="text">Weight Change</span>
                          </div>
                          <div class="widget-simple-sm-statistic">
                              <span class="counter">{{commonService.fixedDecimal(roadmapDataOverview?.goal?.weight - roadmapDataOverview?.start?.weight)}} lbs</span>
                          </div>
                      </section>
                  </div>
              </div>
              <div style="margin-top: 10px;display:flex; align-items:center">
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Start Body Fat</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter">{{roadmapDataOverview?.start?.bodyfat}} %</span>
                        </div>
                    </section>
                </div>
                <div>
                    <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                </div>
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Goal Body Fat</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter green">{{roadmapDataOverview?.goal?.bodyfat}} %</span>
                        </div>
                    </section>
                </div>
                <div>
                    <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                </div>
                
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Body Fat Change</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter">{{commonService.fixedDecimal(roadmapDataOverview?.goal?.bodyfat - roadmapDataOverview?.start?.bodyfat)}} %</span>
                        </div>
                    </section>
                </div>
            </div>
            <div style="margin-top: 10px;display:flex; align-items:center">
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Start Lean Mass</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter">{{roadmapDataOverview?.start?.leanmass}} lbs</span>
                        </div>
                    </section>
                </div>
                <div>
                    <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                </div>
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Goal Lean Mass</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter green">{{roadmapDataOverview?.goal?.leanmass}} lbs</span>
                        </div>
                    </section>
                </div>
                <div>
                    <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                </div>
                
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Lean Mass Change</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter">{{commonService.fixedDecimal(roadmapDataOverview?.goal?.leanmass - roadmapDataOverview?.start?.leanmass)}} lbs</span>
                        </div>
                    </section>
                </div>
            </div>
            <div style="margin-top: 10px;display:flex; align-items:center">
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Start Fat Mass</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter">{{roadmapDataOverview?.start?.fatmass}} lbs</span>
                        </div>
                    </section>
                </div>
                <div>
                    <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                </div>
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Goal Fat Mass</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter green">{{roadmapDataOverview?.goal.bodyfatinpounds}} lbs</span>
                        </div>
                    </section>
                </div>
                <div>
                    <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                </div>
                
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Fat Mass Change</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter">{{commonService.fixedDecimal(roadmapDataOverview?.goal.bodyfatinpounds - roadmapDataOverview?.start?.fatmass)}} lbs</span>
                        </div>
                    </section>
                </div>
            </div>
            <div style="margin-top: 10px;display:flex; align-items:center">
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Start Date</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter">{{start_date}}</span>
                        </div>
                    </section>
                </div>
                <div>
                    <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                </div>
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Estimated End Date</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter green">{{end_date}}</span>
                        </div>
                    </section>
                </div>
                <div>
                    <svg height="120px" width="120px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-227.1 -227.1 681.30 681.30" xml:space="preserve" fill="#000000" stroke="#000000" stroke-width="22.709600000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <polygon style="fill:#010002;" points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 146.933,181.902 152.835,187.811 227.096,113.55 "></polygon> </g> </g> </g></svg>
                </div>
                
                <div class="col-md-3">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">Sessions to Goal</span>
                        </div>
                        <div class="widget-simple-sm-statistic">
                            <span class="counter">{{roadmapDataOverview?.weekstogoal}}</span>
                        </div>
                    </section>
                </div>
            </div>
            <div style="margin-top: 10px;display:flex; justify-content: end; align-items:center">
                <div class="program-creation-dropdown" style="width: 29%;">
                    <section class="widget-simple-sm">
                        <div class="statistic">
                            <span class="text">PACKAGE PURCHASED</span>
                        </div>
                            <mat-form-field>
                                <mat-select [(ngModel)]="recommenedGoalTime" (selectionChange)="onChangeProgram($event)" name="dropdown">
                                  <mat-option *ngFor="let data of goalsDuartions" [value]="data.split(' ')[0]">
                                    {{data}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>     
                    </section>
                </div>
            </div>
            <div class="button-container">
                <button class="backbtn" *ngIf="active !== 'stats'" matStepperPrevious (click)="handleBack()">Back</button>
                <button class="nextbtn active" (click)="handleNext(stepper)" style="padding-top: 8px; padding-bottom: 8px;">{{active !== 'roadmap'? 'Next' : 'Create Program'}}</button>
            </div>
              </mat-step>
            
        </mat-horizontal-stepper>
</div>