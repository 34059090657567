<div class="container">
    <div>
        <div class="main-containerc">
            <div class="contents-container" *ngIf="currentPage === 'survey'">
                <div class="title-container">
                    <span class="title">#1 Survey Summary</span>
                    <a class="closesubs" (click)="openDialog()">X</a>
                </div>
                <div class="contents">
                    <span class="survey-note">Review survey and take notes if needed.</span>
                    <div class="survey-info">
                        <div *ngFor="let obj of surveyDetails; index as i" class="qa-container">                            
                            <div class="survey-ques">{{i+1}}. {{obj.label}}</div>
                            <div class="survey-ans" *ngIf="obj.type === 'radio'">
                                <span *ngFor="let options of obj.values">
                                    <span *ngIf="options.key === obj.selected_values">
                                        {{options.value}}
                                    </span>
                                </span>
                            </div>
                            <div class="survey-ans" *ngIf="obj.type === 'checkbox'">
                                <ul>
                                    <li *ngFor="let options of obj.values">
                                        <span *ngIf="options.key === obj.selected_values">
                                            {{options.value}}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="survey-ans" *ngIf="obj.type === 'textarea'">
                                {{obj.selected_values}}
                            </div>
                            <div class="survey-ans" *ngIf="obj.type === 'textbox'">
                                {{obj.selected_values}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contents-container" *ngIf="currentPage === 'inbody'">
                <div class="title-container">
                    <span class="title">#2 InBody Results</span>
                    <span class="closesubs" (click)="openDialog()">X</span>
                </div>
                <div class="contents">
                    <span class="survey-note">Verify inBody results or <span class="inbody-manual" (click)="manualInbodyEntry()">input manually</span>.</span>
                    <div *ngIf="inbodyError" style="color: red; padding-top: 20px;">Inbody data was required to proceed, either enter values manually</div>
                    <div *ngIf="showmanual" class="manual-entry">
                        <div *ngIf="stfillError" style="color: red;">Enter All Entries</div>
                        <div *ngIf="stnumError" style="color: red;">Enter Valid Entries</div>
                        <div class="form-container">
                            <div class="form-row">
                                <label for="start-Body-Fat">Start Body Fat</label>
                                <input id="start-Body-Fat" class="start-entries" [(ngModel)]="startBodyFat" autocomplete="off" (keyup)="startEntry()" />
                                <span class="units">%</span>
                            </div>
                            <div class="form-row">
                                <label for="start-Muscle-Gain">Start Weight</label>
                                <input id="start-Muscle-Gain" [(ngModel)]="startWeight" class="start-entries" autocomplete="off" (keyup)="startEntry()" />
                                <span class="units">lbs</span>
                            </div>
                            <div class="form-row">
                                <label for="start-gender">Gender</label>
                                <select id="start-gender"[(ngModel)]="startGender" class="start-entries">
                                    <option value="f">Female</option>
                                    <option value="m">Male</option>
                                </select>
                            </div>
                            <div class="form-row">
                                <label for="start-age">Age</label>
                                <span class="timeline">
                                    <mat-form-field appearance="fill">
                                        <input matInput [matDatepicker]="picker" autocomplete="off" readonly="true" [(ngModel)]="startAge" (dateChange)="startEntry()">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </span>
                            </div>
                        </div>
                    </div>
                <div *ngIf="!showmanual">
                    <h1 class="inbody-title">InBody</h1>
                    <div>
                        <div class="inbody-info">
                            <div>Id</div>
                            <div>{{consultantData ? consultantData.rows[0].firstname : ''}} {{consultantData ? consultantData.rows[0].lastname : ''}}</div>
                        </div>
                        <div class="inbody-info">
                            <div>Height</div>
                            <div>{{inbodyData.height}}</div>
                        </div>
                        <div class="inbody-info">
                            <div>Age</div>
                            <div>{{inbodyAge}}</div>
                        </div>
                        <div class="inbody-info">
                            <div>Gender</div>
                            <div>{{inbodyData.sex}}</div>
                        </div>
                        <div class="inbody-info">
                            <div>Test Date / Time</div>
                            <div>{{inbodyDataComp ? (inbodyDataComp.USER_REG_DATE | date: 'dd.MM.yyyy h:mm a') : ''}}</div>
                        </div>
                    </div>
                    <div class="inbody-report">
                        <h3>Body Composition Analysis</h3>
                        <div class="bcatable">
                            <div class="bcaheader">
                                <div class="bcatitle lbl-values">
                                    <div class="hidefield">label</div>
                                </div>
                                <div class="bcatitle val-col">Values</div>
                                <div class="bcatitle centeralign">Total Body Water</div>
                                <div class="bcatitle centeralign">Lean Body Mass</div>
                                <div class="bcatitle weight-col centeralign">Weight</div>
                            </div>
                            <div class="inbody-data">
                                <div class="lbl-values">Intercellular Water <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.ICW: ''}}</div>
                                <div class="brdrt" style="width:23%;"><div class="hidefield">empty</div></div>
                                <div class="brdrt" style="width:22%;"><div class="hidefield">empty</div></div>
                                <div class="brdrt" style="width:10%;"><div class="hidefield">empty</div></div>
                            </div>
                            <div class="inbody-data">
                                <div class="lbl-values">Extracellular Water <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.ECW : ''}}</div>
                                <div class="centeralign brdrt brdbt" style="width:23%;">{{inbodyDataComp ? inbodyDataComp.TBW : ''}}</div>
                                <div class="brdrt" style="width:22%;"><div class="hidefield">empty</div></div>
                                <div class="brdrt" style="width:10%;"><div class="hidefield">empty</div></div>
                            </div>
                            <div class="inbody-data">
                                <div class="lbl-values">Dry Lean Mass <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.DM : ''}}</div>
                                <div class="brdbt" style="width:23%;"><div class="hidefield">empty</div></div>
                                <div class="centeralign brdrt brdbt" style="width: 22%;">{{inbodyDataComp? inbodyDataComp.DM : ''}}</div>
                                <div class="brdrt" style="width:10%;"><div class="hidefield">empty</div></div>
                            </div>
                            <div class="inbody-data">
                                <div class="lbl-values">Body Fat Mass <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.BFM : ''}}</div>
                                <div class="brdbt" style="width:23%;"><div class="hidefield">empty</div></div>
                                <div class="brdbt" style="width:22%;"><div class="hidefield">empty</div></div>
                                <div class="brdrt brdbt centeralign" style="width: 10%;">{{inbodyDataComp? inbodyDataComp.WT : ''}}</div>
                            </div>
                        </div>
                        <h3>Muscle-Fat Analysis</h3>
                        <div>
                            <div class="rangeheader">
                                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                                <div class="rcol-3">-</div>
                                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">Weight <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.WT : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">SMM <span>(lbs)</span><span class="desclbl">Selected Muscle Mass</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.SMM : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">Body Fat Mass <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.BFM : ''}}</div>
                            </div>
                        </div>
                        <h3>Obesity Analysis</h3>
                        <div>
                            <div class="rangeheader">
                                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                                <div class="rcol-3">-</div>
                                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">BMI <span>(kg/m2)</span><span class="desclbl">Body Mass Index</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.BMI : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">PBF <span>(%)</span><span class="desclbl">Percent Body Mass</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.PBF : ''}}</div>
                            </div>
                        </div>
                        <h3>Segmental Lean Analysis</h3>
                        <div>
                            <div class="rangeheader">
                                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                                <div class="rcol-3">-</div>
                                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">Right Arm <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LRA : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">Left Arm <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LLA : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">Trunk <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LT : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">Left Leg <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LLL : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">Right Leg <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LRL : ''}}</div>
                            </div>
                        </div>
                        <h3>ECW/TBW Analysis</h3>
                        <div>
                            <div class="rangeheader">
                                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                                <div class="rcol-3">-</div>
                                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">ECW/TBW </div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.TBW : ''}}</div>
                            </div>
                        </div>
                        <h3>Body Composition History</h3>
                        <div>
                            <div class="rangeheader">
                                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                                <div class="rcol-3">-</div>
                                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">Weight <span>(lbs)</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.WT : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">SMM <span>(lbs)</span><span class="desclbl">Selected Muscle Mass</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.SMM : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">PBF <span>(%)</span><span class="desclbl">Percent Body Mass</span></div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.PBF : ''}}</div>
                            </div>
                            <div class="range-values">
                                <div class="lbl-values">ECW/TBW </div>
                                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.TBW : ''}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="contents-container" *ngIf="currentPage === 'goals'">
                <div class="title-container">
                    <span class="title">#3 Set Goals</span>
                    <span class="closesubs" (click)="openDialog()">X</span>
                </div>
                <div class="contents">
                    <span class="survey-note">Set client goals using the chart below.</span>
                    <div class="goals-container">
                        <div class="table-container">
                            <table mat-table [dataSource]="goalsData">
                                <ng-container matColumnDef="measurement">
                                    <th mat-header-cell *matHeaderCellDef>Measurement</th>
                                    <td mat-cell *matCellDef="let element"> {{element.measurement}} </td>
                                </ng-container>

                                <ng-container matColumnDef="current">
                                    <th mat-header-cell *matHeaderCellDef>Current</th>
                                    <td mat-cell *matCellDef="let element"> {{element.current}} </td>
                                </ng-container>

                                <ng-container matColumnDef="classification">
                                    <th mat-header-cell *matHeaderCellDef>Classification</th>
                                    <td mat-cell *matCellDef="let element"> {{element.classification}} </td>
                                </ng-container>

                                <ng-container matColumnDef="chart">
                                    <th mat-header-cell *matHeaderCellDef>Chart</th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <a class="view-link" (click)="viewChart(element.chart)">VIEW</a> 
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="goalsColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: goalsColumns;"></tr>
                            </table>
                        </div>
                        <div class="form-container">
                            <div *ngIf="errorDisp" style="color: red; padding-top: 20px;">Enter both Body fat and Muscle Gain Values</div>
                            <div *ngIf="numError" style="color: red; padding-top: 20px;">Enter Numeric Values</div>
                            <div class="form-row">
                                <label for="Goal-Body-Fat">Goal Body Fat</label>
                                <input id="Goal-Body-Fat" [(ngModel)]="goalsBodyFat" autocomplete="off" (keyup)="goalEstimate('bodyFat')" />
                                <span class="units">%</span>
                            </div>
                            <div class="form-row">
                                <label for="Goal-Muscle-Gain">Goal Muscle Gain</label>
                                <input id="Goal-Muscle-Gain" [(ngModel)]="goalsMuscleGain" autocomplete="off" (keyup)="goalEstimate('muscleGain')" />
                                <span class="units">lbs</span>
                            </div>
                            <div class="form-row">
                                <label for="Goal-Muscle-Gain">Weeks to Goal</label>
                                <span class="units"><img src="assets/loading.gif" style="width: 20px;" *ngIf="!goalDuration" />{{goalDuration}} weeks</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contents-container" *ngIf="currentPage === 'roadmap'">
                <div class="title-container">
                    <span class="title">#4 Roadmap</span>
                    <span class="closesubs" (click)="openDialog()">X</span>
                </div>
                <div class="contents">
                    <span class="survey-note">Set client goals using the chart below.</span>
                    <select class="optSelection" [(ngModel)]="recommenedGoalTime" (change)="setGoalsDuration()">
                        <option *ngFor="let opt of goalsDuartions" [value]="opt.split(' ')[0]">{{opt}}</option>
                    </select>
                    <mat-icon class="downloadicon">note_add</mat-icon>
                    <div class="data-container">
                        <div *ngFor="let item of roadmap" class="roadmap-display">
                            <div class="roadmap-header">{{item.for}}</div>
                            <div class="roadmap-data">
                                <div class="roadmap-fields" *ngFor="let value of item.values">
                                    <div class="rmdata-title">{{value.label}}</div>
                                    <div class="rmdata-value">{{value.data}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contents-container" *ngIf="currentPage === 'cost'">
                <div class="title-container">
                    <span class="title">#5 Cost</span>
                    <span class="closesubs" (click)="openDialog()">X</span>
                </div>
                <div class="contents">
                    <span class="survey-note">Set client goals using the chart below.</span>
                    <select class="optSelection" [(ngModel)]="recommenedGoalTime" (change)="setGoalsDuration()">
                        <option *ngFor="let opt of goalsDuartions" [value]="opt.split(' ')[0]">{{opt}}</option>
                    </select>
                    <div class="costTable">
                        <div class="costrow">
                            <span class="costlbl">Monthly</span>
                            <span class="costvalue">$400</span>
                        </div>
                        <div class="costrow">
                            <span class="costlbl">Total</span>
                            <span class="costvalue">$1200</span>
                        </div>
                        <div class="btmFlds">
                            <span class="fldlbl">Promo Code</span>
                            <input type="text" placeholder="code" class="codeinput" />
                            <button class="resultsbtn">Update</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-container">
                <button class="backbtn" *ngIf="currentPage !== 'survey'" (click)="handleBack()">Back</button>
                <button class="nextbtn active" (click)="handleNext()">{{currentPage !== 'cost'? 'Next' : 'Finish'}}</button>
                <div style="clear:both"></div>
            </div>
        </div>
        <div class="notes-container" [ngClass]="{'hidden': hideNotes}">
            <div class="title">
                <mat-icon (click)="notesSlide()">arrow_right_alt</mat-icon>
                <span>Notes</span>
            </div>
            <textarea placeholder="Note..." [(ngModel)]="notes"></textarea>
            <button class="notes-submit" (click)="addNotes()">Submit</button>
            <div style="clear:both"></div>
            <div class="notes-list">
                <div class="notes-detail" *ngFor="let note of notesList">
                    <span class="note-close" (click)="notesClose(note.id)">x</span>
                    {{note.notes}}
                    <p class="timestamp">{{note.created_at | date: 'dd/MM/yyyy h:mm:ss a'}}</p>
                </div>
            </div>
        </div>
    </div>
</div>