<div>
    <div class="col-33 padding-5 container">
        <div class="marginBottom fieldlbls">Name</div>
        <div>
            <app-selectinput placeholder="Name" type="foodName" [itemValue]="foodName" (action)="onAction($event)"></app-selectinput>
        </div>
    </div>
    <div class="col-33 paddingleft-right">
        <div>
            <app-selectinput placeholder="Food Search" type="foodSearch" [itemValue]="foodSearch" (action)="onAction($event)"></app-selectinput>
        </div>
    </div>
    <div class="nutrition-table">
        <table mat-table [dataSource]="foodSearchTableData" class="mat-elevation-z10" multiTemplateDataRows>
            <ng-container matColumnDef="name">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> NAME </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="client-name">{{element.item_name}}</div> 
                </td>
            </ng-container>
            <ng-container matColumnDef="unit">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> UNIT </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="client-name">{{element.serving_uom}}</div> 
                </td>
            </ng-container>
            <ng-container matColumnDef="servingqty">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> SERVING QTY </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="client-name">{{element.serving_qty}}</div> 
                </td>
            </ng-container>
            <ng-container matColumnDef="cals">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> CALS </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="client-name">-</div> 
                </td>
            </ng-container>
            <ng-container matColumnDef="photo">
                <th class="fieldlbls" mat-header-cell *matHeaderCellDef> PHOTO </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="client-name">
                        <img class="img_food_resource" [src]="element.thumbnail"  alt="Nutrition resource image" />
                    </div> 
                </td>
            </ng-container>
            
            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let element" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"> 
                    <div *ngIf="element.foods">
                        <div *ngFor="let record of element.foods.alt_measures;let i = index" class="spec_row">
                            <mat-icon class="delete_icon" *ngIf="record.selected" (click)="clearNutrients(record)">delete_outline</mat-icon> 
                            <span (click)="onSpecificFoodItem(record, element, i)">
                                <mat-icon *ngIf="!record.selected" class="success_icon">add_box</mat-icon> 
                                <span class="align_item">{{element.item_name}} - {{record.name}}</span>
                                <div>
                                    <span class="spn-margin">Calories: {{record.calories}}</span>
                                    <span class="spn-margin">Protein: {{record.protein}}</span>
                                    <span class="spn-margin">Carbs: {{record.carbs}}</span>
                                    <span class="spn-margin">Fat: {{record.fat}}</span>
                                    <span *ngFor="let strength of micronutrients" class="spn-margin">{{strength.label}}: {{record[strength.key]}}</span>
                                </div>
                            </span>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="cursorPointer main-content-row example-element-row" *matRowDef="let element; columns: displayedColumns; let i = dataIndex;" (click)="onFoodSearchSelect(element, i);expandedElement = expandedElement === element ? null : element;"
            [class.example-expanded-row]="expandedElement === element"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator
            *ngIf="foodSearchTableData.length > 0"
            [length]="pagination.count"
            [pageIndex]="pagination.pageIndex"
            [pageSize]="pagination.perPage"
            [pageSizeOptions]="[10, 20, 30, 40]"
            (page)="getServerData($event)"
            showFirstLastButtons></mat-paginator>
    </div>
    <div *ngIf="foodSearchTableData.length === 0" class="no_records">
        <span>No matching records found</span>
    </div>
    <div class="select-ddl col-33 padding-5">
        <div class="fieldlbls">Food Category</div>
        <mat-form-field class="width-full">
            <mat-label>Select Category</mat-label>
          <mat-select [(ngModel)]="selected_food_category" [disabled]="!foodsCategories || foodsCategories.length === 0" (ngModelChange)="onChangeFoodCategory()">
            <mat-option *ngFor="let data of foodsCategories"  [value]="data.id">{{data.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <span *ngIf="!foodsCategories || foodsCategories.length === 0" class="error_info">Categories are not found</span>
    </div>
    <div>
        <div class="col-33 inlineBlock padding-5">
            <app-selectinput placeholder="New food category..." type="newFoodCategory" [itemValue]="newFoodCategory" (action)="onAction($event)"></app-selectinput>
        </div>
        <div class="select-ddl col-33 inlineBlock padding-5">
            <mat-form-field class="width-full">
                <mat-label>Make subcategory of...</mat-label>
              <mat-select [(ngModel)]="selected_sub_food_category" [disabled]="!foodsCategories || foodsCategories.length === 0" (ngModelChange)="onChangeFoodCategory()">
                <mat-option *ngFor="let data of foodsCategories"  [value]="data.id">{{data.name}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div class="fieldlbls">Description</div>
        <textarea [(ngModel)]="description"></textarea>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div class="fieldlbls">Note</div>
        <textarea [(ngModel)]="note"></textarea>
    </div>
    <div>
        <div class="col-33 inlineBlock padding-5">
            <div class="marginBottom fieldlbls">Default Qty</div>
            <div>
                <app-selectinput placeholder="Default Qty" type="defaultQty" [itemValue]="fields.defaultQty" (action)="onAction($event)"></app-selectinput>
            </div>
        </div>
        <div class="col-33 inlineBlock padding-5">
            <div class="marginBottom fieldlbls">Unit</div>
            <div>
                <app-selectinput placeholder="Unit" type="unit" [itemValue]="fields.unit" (action)="onAction($event)"></app-selectinput>
            </div>
        </div>
        <div class="macro-nutrients">
            <div class="padding-5 fieldlbls">
                Macronutrients
            </div>
            <div>
                <div class="col-25 padding-5">
                    <div class="marginBottom fieldlbls">Calories</div>
                    <div>
                        <app-selectinput placeholder="Calories" type="calories" [itemValue]="fields.calories" (action)="onAction($event)"></app-selectinput>
                    </div>
                </div>
                <div class="col-25 padding-5">
                    <div class="marginBottom fieldlbls">Protein</div>
                    <div>
                        <app-selectinput placeholder="Protein" type="protein" [itemValue]="fields.protein" (action)="onAction($event)"></app-selectinput>
                    </div>
                </div>
                <div class="col-25 padding-5">
                    <div class="marginBottom fieldlbls">Carbs</div>
                    <div>
                        <app-selectinput placeholder="Carbs" type="carbs" [itemValue]="fields.carbs" (action)="onAction($event)"></app-selectinput>
                    </div>
                </div>
                <div class="col-25 padding-5">
                    <div class="marginBottom fieldlbls">Fat</div>
                    <div>
                        <app-selectinput placeholder="Fat" type="fat" [itemValue]="fields.fat" (action)="onAction($event)"></app-selectinput>
                    </div>
                </div>
            </div>
        </div>
        <div class="micro-nutrients">
            <div class="padding-5 fieldlbls">
                Micronutrients
            </div>
            <div>
                <div class="col-25 padding-5" *ngFor="let item of micronutrients">
                    <div class="marginBottom fieldlbls">{{item.label}}</div>
                    <div>
                        <app-selectinput [placeholder]="item.label" [type]="item.key" [itemValue]="item.value" (action)="onAction($event)"></app-selectinput>
                    </div>
                </div>
            </div>
        </div>
        <div class="link_upload">
            <span (click)="imageInput.click()">UPLOAD IMAGE SEQUENCE</span>
            <input #imageInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="uploadImage($event)" accept="image/*"/>
        </div>
        <div>
            <div *ngFor="let image of foodImageSequence"
                class="foodImageBlock"
            >
                <img [src]="image" class="food-image" />
            </div>
        </div>
        <div class="link_upload">
            <span (click)="receipeInput.click()">UPLOAD RECIPE FILE</span>   <span class="uploaded_recipe" *ngIf="foodRecipeFormData.name">({{foodRecipeFormData.name}})</span>
            <input #receipeInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="uploadFile($event)" accept="application/pdf"/>
        </div>
        <div class="col-33">
            <div class="marginBottom">Video URL</div>
            <div>
                <app-selectinput placeholder="" type="videoUrl" [itemValue]="videoUrl" (action)="onAction($event)"></app-selectinput>
            </div>
        </div>
        <mat-card *ngIf="formInvalidFields.length > 0" class="error_info">
            <div>Please fill required fields</div>
            <ul>
                <li *ngFor="let item of formInvalidFields">{{item}}</li>
            </ul>
        </mat-card>
        <div class="submit_food">
            <button class="cancelbtn" mat-raised-button (click)="cancelForm()" >Cancel</button>
            <button mat-raised-button color="primary" (click)="onSubmitFood()" >Submit</button>
        </div>
    </div>
</div>
