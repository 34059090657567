import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '../config';
import { CommonService } from '../services/common/common.service';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { UiService } from '../services/uiloader/uiloader.service';

@Component({
  selector: 'app-program-archive',
  templateUrl: './program-archive.component.html',
  styleUrls: ['./program-archive.component.sass']
})
export class ProgramArchiveComponent implements OnInit {
  programArchiveData:any = [];
  user:any ={};

  constructor(private commonService:CommonService, private ui: UiService,private router:Router,
    private httpService: HttpService, private toasterService:ToasterService,private logService: LogService ) { }

  ngOnInit(): void {
    const routes = [{
      name: 'Program archive',
      path: 'clientprogramarchive'
    }];
    this.commonService.setHeaderData(routes);
    this.fetchProgramArchive();
  }
  displayedColumns: string[] = ['program', 'session', 'measurements', 'nutrition','exercise'];
  dataSource = [];
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  
  navigateToMeasurement(programId:any){
    this.router.navigate([`/sessions/${this.user.id}/${programId}`]);
  }

  navigateToNutrition(programId:any){
    this.router.navigate([`/clientnutrition/${programId}`]);
  }

  navigateToCompostion(programId:any){
    this.router.navigate([`/clientcomposition/${programId}`]);
  }
  navigateToExcercise(programId:any){
    this.router.navigate([`/clientexercise/${programId}`]);
  }



  fetchProgramArchive() {
     this.ui.spin$.next(true);
    const url = `${config.apiUrl}/programarchive`;
    this.httpService.postCall(url,{}).subscribe(data => {
      this.user = data.user;
     this.dataSource = this.formatArchiveData(data.programswithgoals)
      this.ui.spin$.next(false); 
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error Occurred while fetching progrmas');
    });
  }

  // Function to return date in MM-DD-YYYY format
  getDateFormat(date: Date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${(day < 10 ? '0' : '') + day}-${(month < 10 ? '0' : '') + month}-${year}`;
  }
  
  formatArchiveData(programswithgoals:any){
    programswithgoals.forEach((goal:any) => {
      if(goal.programs?.length > 0){
       goal.programs.forEach((program:any,index:any) => {
        this.programArchiveData.push({
          "programName":index ==0 ? goal.name:"",
          "sessionName":program.name,
          "session_start_date":program.program_start_date,
          "programId":goal.id,
          "startDate": this.getDateFormat(new Date(goal.start_date)),
          "endDate": this.getDateFormat(new Date(goal.estimated_end_date)),
          "session":program,
          "measurements": program.weights,

          })
       })
      }else{
        this.programArchiveData.push({
          "programName":goal.name,
          "sessionName":"",
          "session_start_date":"",
          "programId":goal.id,
          "startDate": this.getDateFormat(new Date(goal.start_date)),
          "endDate": this.getDateFormat(new Date(goal.estimated_end_date)),
          "session":{},
          "measurements": [],

        })

      }
    })
    return this.programArchiveData;
  }
 

}
