<div class="main-container">
    <div class="sub-container-header" *ngIf="headerData.userName">
        <div>
            <span class="start-info cursorPointer" (click)="onNavigateBack()">{{headerData.userName}}</span> <span class="slash-info">/</span> <span class="end-info">{{headerData.name ? headerData.name : 'Programs'}}</span>
        </div>
        <div class="white program-info">
            {{headerData.name}}
            <span class="close_icon" (click)="onNavigateBackUser()">
                <mat-icon>close</mat-icon>
            </span>
        </div>
        <div class="sub-title">{{headerData.duration}}</div>
    </div>
    <div class="body-content">
        <mat-tab-group mat-align-tabs="start" >
            <mat-tab label="Sessions">
                <div class="main-content">
                    <div class="body-sub-info">
                        <div class="sessions-header">Sessions</div>
                        <div class="add-session" (click)="addSession()">ADD SESSION</div>
                        <div class="add-session" (click)="noSessionsFound ? '' : copySessionPopUp()" [ngClass]="{'disable_div':noSessionsFound}">COPY SESSION</div>
                    </div>
                    <div>
                        <table mat-table [dataSource]="sessionsTableData" class="mat-elevation-z10" >
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> NAME </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <div class="client-name">{{element.name}}</div> 
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef> SESSION DATE </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <div class="client-name">{{commonService.getFormattedDate(element.program_start_date)}}</div> 
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="weight">
                                <th mat-header-cell *matHeaderCellDef> WEIGHT </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <div class="client-name">{{element.weights.length > 0 ? element.weights[0].weight : ''}}</div> 
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="bodyfat">
                                <th mat-header-cell *matHeaderCellDef> BODY FAT </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <div class="client-name">{{element.weights.length > 0 ? element.weights[0].bodyfat : ''}}</div> 
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef> STATUS </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <div class="client-name" *ngIf="activeProgramId === element.id">Active</div>
                                    <div class="client-name" *ngIf="activeProgramId !== element.id">In Active</div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row class="cursorPointer" *matRowDef="let row; columns: displayedColumns;" (click)="onProgramSelection(row)"></tr>
                        </table>
                        <div *ngIf="noSessionsFound">
                            No sessions found
                        </div>
                    </div>
                </div>                
            </mat-tab>
            <mat-tab label="Goals">
                <div class="main-content">
                    <div>
                        <div class="start-data">
                            <table class="disp-table">
                                <tr><td class="lbl">Start Weight</td><td class="vals">{{goalsData?.start_weight}} lbs</td></tr>
                                <tr><td class="lbl">Start Body Fat</td><td class="vals">{{goalsData?.start_bodyfat}} %</td></tr>
                                <tr><td class="lbl">Start Lean Mass</td><td class="vals">{{goalsData?.start_leanmass}} lbs</td></tr>
                                <tr><td class="lbl">Start Fat Mass</td><td class="vals">{{goalsData?.start_fatmass}} lbs</td></tr>
                                <tr><td class="lbl">Start Date</td><td class="vals">{{start_date}}</td></tr>
                                <tr><td class="lbl">Sessions to Goal</td><td class="vals">{{goalsData?.weekstogoal}}</td></tr>
                            </table>
                        </div> 
                        <div class="goal-data">
                            <table class="disp-table">
                                <tr><td class="lbl">Goal Weight</td><td class="vals">{{goalsData?.goal_weight}} lbs</td></tr>
                                <tr><td class="lbl">Goal Body Fat</td><td class="vals">{{goalsData?.goal_bodyfat}} %</td></tr>
                                <tr><td class="lbl">Goal Lean Mass</td><td class="vals">{{goalsData?.goal_leanmass}} lbs</td></tr>
                                <tr><td class="lbl">Goal Fat Mass</td><td class="vals">{{goalsData?.goal_bodyfat_pounds}} lbs</td></tr>
                                <tr><td class="lbl">End Date</td><td class="vals">{{end_date}}</td></tr>
                                <tr><td class="lbl">Sessions Purchased</td><td class="vals">{{goalsData?.packagepurchased}}</td></tr>
                            </table>
                        </div>
                        <div class="program_differ_Data">
                            <table class="disp-table">
                                <tr><td class="lbl">Weight Change</td><td class="vals">{{commonService.fixedDecimal(goalsData?.goal_weight - goalsData?.start_weight)}} lbs</td></tr>
                                <tr><td class="lbl">Body Fat Change %</td><td class="vals">{{commonService.fixedDecimal(goalsData?.goal_bodyfat - goalsData?.start_bodyfat)}} %</td></tr>
                                <tr><td class="lbl">Lean Mass Change %</td><td class="vals">{{commonService.fixedDecimal(goalsData?.goal_leanmass - goalsData?.start_leanmass)}} lbs</td></tr>
                                <tr><td class="lbl">Fat Mass Change</td><td class="vals">{{commonService.fixedDecimal(goalsData?.goal_bodyfat_pounds - goalsData?.start_fatmass)}} lbs</td></tr>
                                <tr><td class="lbl">Session To Goal</td><td class="vals">{{commonService.getWeaksDiffer(end_date, start_date)}}</td></tr>
                            </table>
                        </div>
                    </div>
                </div>                
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
