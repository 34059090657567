<footer class="footer"
  *ngIf="isDisplayFooter$ | async as isDisplayHeader"
>
    <div class="container">
    <div class="content has-text-centered">
      <p>
        Made with <3 by <a href="https://twitter.com/chrisoncode">Chris from Scotch</a>
      </p>
    </div>
    </div>
    </footer>