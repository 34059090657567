import { Component, OnInit, Input, Inject } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { config } from '../../config';
import { HttpService } from '../../services/http/http.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

let foodSearchTimeout:any;
@Component({
  selector: 'app-foodsuploadercreate',
  templateUrl: './foodsuploadercreate.component.html',
  styleUrls: ['./foodsuploadercreate.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FoodsuploadercreateComponent implements OnInit {
  foodName:string = '';
  foodSearch:string = '';
  foodSearchTableData:any = [];
  displayedColumns:any = ['name', 'unit', 'servingqty', 'cals', 'photo'];
  expandedElement: any;
  selected_food_category:any = '';
  selected_sub_food_category:any = '';
  description:string = '';
  note:string ='';
  newFoodCategory:any = '';
  videoUrl:string = '';
  fields: any = {

  };

  foodImageSequence:any = [];
  foodImageSequenceFormData:any = [];

  foodRecipeFormData:any = '';
  
  message = '';

  pagination = {
    count: 0,
    perPage: 10,
    pageIndex: 0,
    totalCount: 0
  };

  totalRecords = [];

  formInvalidFields:any = [];

  requiredFields = ['name', 'calories', 'protein', 'carbs', 'fat', 'foodcategory'];

  @Input() foodsCategories:any;
  micronutrients = config.microNutrients;

  constructor(private httpService: HttpService,
    private ui: UiService,
    public dialog: MatDialog,
    private toasterService: ToasterService) {
    
  }

  ngOnInit(): void {
  }

  getPaginatorRecords(data:any) {
    const clone = JSON.parse(JSON.stringify(data));
    const specPageIndex = this.pagination.pageIndex * this.pagination.perPage;
    const records = clone.splice(specPageIndex, this.pagination.perPage);
    this.foodSearchTableData =  records;
  }

  getFoodSearch() {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/nutrition/search/${this.foodSearch}`;
    this.httpService.getCall(url).subscribe(result => {
      this.pagination.totalCount = result.results.length;
      this.pagination.pageIndex = 0;
      this.totalRecords = result.results;
      this.pagination.count = this.totalRecords.length;
      this.getPaginatorRecords(this.totalRecords);
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error occurred, Please try again');
    });
  }

  onAction(event:any) {
    if (event.type === 'foodSearch') {
      this.foodSearch = event.info;
      clearTimeout(foodSearchTimeout);
      foodSearchTimeout = setTimeout(()=>{
        this.getFoodSearch();
      }, 1000);
    } else if (event.type === 'videoUrl') {
      this.videoUrl = event.info;
    } else if (event.type === 'newFoodCategory') {
      this.newFoodCategory = event.info;
    } else if (event.type === 'foodName') {
      this.foodName = event.info;
    } else {
      this.fields[event.type] = event.info;
    }
  }

  onSubmitFood() {
    const url = `${config.apiUrl}/foods/createfoodtemplate`;
    
    const item:any = {
      name: this.foodName,
      description: this.description,
      foodcategory: this.newFoodCategory ? this.newFoodCategory : this.selected_food_category,
      // subcat: this.selected_sub_food_category,
      video_url: this.videoUrl,
      calories: this.fields.calories,
      protein: this.fields.protein,
      carbs: this.fields.carbs,
      fat: this.fields.fat,
      qty: this.fields.defaultQty,
      unit: this.fields.unit,
      note: this.note
     };
     const isFormInValid: any = [];
     this.requiredFields.forEach((key: string) => {
      if (!item[key]) {
        isFormInValid.push(key);
      }
      // if (item.foodcategory && isNaN(item.foodcategory)) {
      //   if (!item.subcat) {
      //     isFormInValid.push('Sub category');
      //   }
      // }
     });

    if (isFormInValid.length > 0) {
      this.formInvalidFields = isFormInValid;
      setTimeout(() => {
        this.formInvalidFields = [];
      }, 3000);
      return false;
    }

    this.ui.spin$.next(true);
    const obj: any = {};
    this.micronutrients.forEach((item: any) => {
      obj[item.key] = item.value;
    });
     const req = {...obj, ...item};

     this.httpService.postCall(url, req).subscribe(data => {
       this.updateImages(data.data.id);
       if (this.foodImageSequenceFormData.length === 0 && !this.foodRecipeFormData.name) {
        this.resetForm();
        this.toasterService.show('success', 'Success', 'Food created successfully');
       }
       this.ui.spin$.next(false);

     }, err => {
       this.ui.spin$.next(false);
     });

     return true;
  }

  updateImages(templateId:number) {
    const formData = new FormData();
    this.foodImageSequenceFormData.forEach((item:any) => {
      formData.append("sequence", item);
    });
    if (this.foodRecipeFormData.name) {
      formData.append('recipepdf', this.foodRecipeFormData);
    }
    const url = `${config.apiUrl}/foods/uploadimages/${templateId}`;

    this.httpService.putCall(url, formData).subscribe(result => {
      this.resetForm();
    }, err => {

    });
  }

  resetForm() {  
    this.videoUrl = '';
    this.newFoodCategory = '';
    this.foodName = '';
    this.foodSearch = '';
    this.description = '';
    this.note = '';
    this.foodSearchTableData = [];
    this.selected_food_category = '';
    this.selected_sub_food_category = '';
    this.newFoodCategory = '';
    this.foodRecipeFormData = '';
    this.foodImageSequence = [];
    this.foodImageSequenceFormData = [];
    this.resetNutrients();
  }

  resetNutrients() {
    this.micronutrients.forEach(element => {
      element.value = '';
    });
    this.fields = {
      defaultQty: '',
      unit: '',
      calories: '',
      protein: '',
      carbs: '',
      fat: ''
    }
  }

  onFoodSearchSelect(data:any, index:number) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/nutrition/nutrientswithdetails`;
    const obj = {
      item: data.item_name
    };
    this.httpService.postCall(url, obj).subscribe(data => {
      this.foodSearchTableData[index].foods = data.foods[0];
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
    });
  }

  onSpecificFoodItem(item:any, element:any, pos:any) {
    const obj = {
      defaultQty: item.qty,
      unit: item.unit,
      calories: item.calories,
      protein: item.protein,
      carbs: item.carbs,
      fat: item.fat
    };
    element.foods.alt_measures.forEach((val:any) => {
      delete val.selected;
    });
    item.selected = true;
    element.foods.alt_measures = [item, ...element.foods.alt_measures.slice(0, pos), ...element.foods.alt_measures.slice(pos+1)];
    this.fields = {...this.fields, ...obj};
    this.micronutrients.forEach((rec:any) => {
      rec.value = item[rec.key];
    });
  }

  onChangeFoodCategory() {

  }

  getServerData(e:any) {
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.getPaginatorRecords(this.totalRecords);
  }

  toBase64(file:any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async preview(files:any) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    this.foodImageSequenceFormData.push(files[0]);

    const data:any = await this.toBase64(files[0]);
    const modified = data.split('base64,');

    this.foodImageSequence.push(data);

  }

  uploadImage(fileInputEvent:any) {
    var files = fileInputEvent.target.files;
    this.preview(files);
  }

  uploadFile(fileInputEvent:any) {
    var files = fileInputEvent.target.files;
    this.foodRecipeFormData = files[0];
  }

  cancelForm() {
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: "Are you sure you want to reset form?" });
    dialogRef.afterClosed().subscribe(result => {
      result ? this.resetForm() : "";
    });
  }

  clearNutrients(rec:any) {
    let dialogRef = this.dialog.open(ConfirmationDialog, { data: "Are you sure you want to reset selected value?" });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.resetNutrients();
        delete rec.selected;
      }
    });
  }

}

@Component({
  selector: 'confirmation-dialog',
  template: `
    <div mat-dialog-content class="dialogContent">{{data}}</div>
    <div mat-dialog-actions class="dialog-actions">
      <button mat-button class="resultsbtn cancaldlg" (click)="responseHandler(false)">Cancel</button>
      <button class="resultsbtn" mat-button (click)="responseHandler(true)" cdkFocusInitial>Ok</button>
    </div>`,
  styleUrls: ['./foodsuploadercreate.component.sass']
})
export class ConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
    }

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}