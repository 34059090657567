<div class="main-content">
    <div class="sub-header">
        <span>
            <button mat-icon-button aria-label="Go Back" (click)="onGoBack()">
                <mat-icon class="back-icon" aria-hidden="false" aria-label="Go Back">chevron_left</mat-icon>
            </button>
        </span>
        <span class="user-name">
            {{prospectProfile.firstname}} {{prospectProfile.lastname}}
        </span>
    </div>
    <div class="prospect-profile-container">
        <section class="spec-section">
            <div>
                <div class="header">
                    <span>Prospect Profile</span>
                    <span class="act-as-user">
                        <button mat-button color="primary" (click)="onSurveyResults()">SURVEY RESULTS</button>
                    </span>
                </div>
                <div>
                    <div class="no-highlight-inner">
                        <div class="item top-border">
                            <span>Name</span><span>{{prospectProfile.firstname}} {{prospectProfile.lastname}}</span>
                        </div>
                    </div>
                    <div class="no-highlight-inner">
                        <div class="item" >
                            <span>Email</span><span>{{prospectProfile.email}}</span>
                        </div>
                    </div>
                    <div class="no-highlight-inner"
                    *ngIf="!isDisplayDeleteProspect"
                    >
                        <div class="item" >
                            <span>Consultant</span>
                            <span *ngIf="prospectProfile.consultant">{{prospectProfile.consultant}}</span>
                            <span *ngIf="!prospectProfile.consultant" class="no-option">Assign</span>
                        </div>
                    </div>
                    <div class="no-highlight-inner"
                    *ngIf="!isDisplayDeleteProspect"
                    >
                        <div class="item" >
                            <span>Status</span><span>{{prospectProfile.enabled == 1 ? 'Active' : 'InActive'}}</span>
                        </div>
                    </div>
                    <div [ngClass]="{'highlight': selectedItem === 'consultant_id'}"
                        *ngIf="isDisplayDeleteProspect"
                    >
                    <div class="highlight-inner">
                        <div class="item" (click)="onItemSelect('consultant_id')">
                            <span>Consultant</span>
                            <span *ngIf="prospectProfile.consultant">{{prospectProfile.consultant}}</span>
                            <span *ngIf="!prospectProfile.consultant" class="no-option">Assign</span>
                        </div>
                        <app-useritem *ngIf="selectedItem === 'consultant_id'"  name="Select Consultant" [itemValue]="prospectProfile.consultant_id" type="select" [selectData]="consultantData" (action)="onAction($event)"></app-useritem>
                    </div>
                    </div>
                    <div [ngClass]="{'highlight': selectedItem === 'enabled'}"
                        *ngIf="isDisplayDeleteProspect"
                    >
                    <div class="highlight-inner">
                        <div class="item" (click)="onItemSelect('enabled')">
                            <span>Status</span>
                            <span>{{prospectProfile.enabled == 1 ? 'Active' : 'InActive'}}</span>
                            <!-- <span *ngIf="!prospectProfile.status" class="no-option">Set Status</span> -->
                        </div>
                        <app-useritem *ngIf="selectedItem === 'enabled'"  name="Select Status" [itemValue]="prospectProfile.enabled" type="select" [selectData]="statusData" (action)="onAction($event)"></app-useritem>
                    </div>
                </div>
                    <div *ngIf="isDisplayDeleteProspect">
                        <div class="item delete-prospect"
                            (click)="onDeleteProspect()"
                        >
                            <span>Delete Prospect</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>