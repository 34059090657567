import { Component, OnInit, Inject} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SocialAuthService, GoogleLoginProvider, SocialUser, FacebookLoginProvider } from 'angularx-social-login';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { AppleLoginProvider } from '../providers/apple-login.provider'
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { CommonService } from '../services/common/common.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { LogService } from '../services/log/log.service';
import { UiService } from '../services/uiloader/uiloader.service';

@Component({
  selector: 'app-loginflow',
  templateUrl: './loginflow.component.html',
  styleUrls: ['./loginflow.component.sass']
})
export class LoginflowComponent implements OnInit {
  socialUser: SocialUser = {response: '', provider: '', email: '', id: '', name: '', photoUrl: '', firstName: '', lastName: '', authToken: '', idToken: '', authorizationCode: ''};
  loginflow: boolean = true;
  loginError: string = '';
  invalidUserName = false;
  selectedCompany = -1;
  triggeredSocial = false;
  isCompanyDomain = false;
  constructor(private router: Router,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private appleLoginProvider: AppleLoginProvider,
    private fb: FormBuilder,
    private httpService: HttpService,
    private commonService: CommonService,
    private toasterService: ToasterService,
    private logService: LogService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ui: UiService) { }
    form: any = {};
    formUsername:any = {};
    formCompanyDomain:any = {};
    private formSubmitAttempt: boolean = false;
    private formSubmitAttemptUsername: boolean = false;
    forgetPWDform:any;
    private forgetfmSubmitAttempt: boolean = false;
    invalidLogins = false;
    companiesList:any = [];
    companySelected = new FormControl();
    isDisplayCompaniesForm = false;
    usernameDisabled = true;
    companyDomain:any = '';

  ngOnInit(): void {
    this.companyDomain = this.route.snapshot.params.companyDomain;
    if(this.companyDomain && this.companyDomain != 'login'){
      this.httpService.getCall(`${config.apiUrl}/getcompanydetails/${this.companyDomain}`).subscribe(result => {
       if(result?.id){
        this.selectedCompany = result.id;
        this.isCompanyDomain =true;
       }
      }, err => {
        this.toasterService.show('error', 'error', err?.errors ? err.errors : 'Error while fetching companies list');
        this.isCompanyDomain =false;
      });
    }
    this.commonService.gaEvent('login_flow_initial', 'login_flow', 'Initial Login Flow Screen');
    this.form = this.fb.group({
      userName: [{value: '', disabled: true}, Validators.required],
      password: ['', Validators.required],
      companySelected: [-1, Validators.required]
    });
    this.forgetPWDform = this.fb.group({
      email: ['', Validators.required]
    });

    this.formUsername = this.fb.group({
      userName: ['', Validators.required]
    });

    this.formCompanyDomain = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    })

    this.route.queryParams.subscribe((params:any) => {
      if (params.invalid) {
        this.loginError = 'User not exists with provided email';
      }
    });

    this.socialAuthService.authState.subscribe((user:any) => {
      this.socialUser = user;
      if (user) {
        if (this.triggeredSocial) {
          return false;
        } else {
          this.triggeredSocial = true;
          this.httpService.getCall(`${config.apiUrl}/fetchusercompanies/${user.email}`).subscribe(result => {
            this.companiesList = result.map((item:any) => item.company);
            if (this.companiesList.length === 0) {
              this.toasterService.show('error', 'error', 'No companies enabled for this user');  
            } else if (this.companiesList.length === 1) {
              user.company_id = this.companiesList[0].id;
              this.onSetSocialLoginsData(user);
            } else {
              const dialogRef = this.dialog.open(SocialLoginsCompanySelection, { data: {companiesList: this.companiesList}, minWidth: 350, minHeight: 220 });
              dialogRef.afterClosed().subscribe((result:any) => {
                if (result.companySelectedId) {
                  user.company_id = result.companySelectedId;
                  this.onSetSocialLoginsData(user);
                } else {
                  this.toasterService.show('error', 'error', 'Dialog closed with out selecting company');  
                }
              });
            }
            
          }, err => {
            this.invalidUserName = true;
            this.toasterService.show('error', 'error', err?.errors ? err.errors : 'Error while fetching companies list');
          });
      }
    } else {
      // this.loginError = 'Error occurred, Please try again';
    }
    return true;
    });
  }

  onSetSocialLoginsData(user:any) {
    const obj = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      company_id: user.company_id
    };
    this.authService.setUserDetails(obj);
    if (user.provider === config.PROVIDER_GOOGLE) {
      this.onGoogleValidate(user);
    } else if (user.provider === config.PROVIDER_FACEBOOK) {
      this.onFacebookValidate(user);
    }
  }

  onSelectCompany(id:number) {
    this.selectedCompany = id;
  }

  isFieldUsernameInvalid(field: string) {
    return (
      (!this.formUsername.get(field).valid && this.formUsername.get(field).touched) ||
      (this.formUsername.get(field).untouched && this.formSubmitAttemptUsername)
    );
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }

  onLoginBack() {
    this.isDisplayCompaniesForm = false;
  }

  onUserNameSubmit() {
    if (this.formUsername.value) {
      const { userName } = this.formUsername.value;
      this.form.controls['userName'].setValue(userName);
      
      this.httpService.getCall(`${config.apiUrl}/fetchusercompanies/${userName}`).subscribe(result => {
        this.companiesList = result.map((item:any) => item.company);
        this.companySelected = new FormControl(this.companiesList[0].id);
        this.selectedCompany = this.companiesList[0].id;
        this.isDisplayCompaniesForm = true;
        this.invalidUserName = false;
      }, err => {
        this.invalidUserName = true;
        this.toasterService.show('error', 'error', err?.errors ? err.errors : 'Error while fetching companies list');
      });
    }
    this.formSubmitAttemptUsername = true;
  }

  onGoogleValidate(user:any) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/auth/gmail`;
    const reqData = {
      token: user.idToken,
      company_id: user.company_id
    };
    this.httpService.postCall(url, reqData).subscribe(result => {
      this.authService.storeJwtToken(result);
      this.onUserSuccess();
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.loginError = err.error.errors[0];
      //this.toasterService.show('error', 'error', err?.errors ? err.errors : 'Error while validating google');
      this.logService.logError(err, 'Error while validating google', 500);
    });
  }

  onFacebookValidate(user:any) {
    this.ui.spin$.next(true);

    const url = `${config.apiUrl}/auth/facebook`;
    const reqData = {
      token: user.authToken,
      company_id: user.company_id
    };
    this.httpService.postCall(url, reqData).subscribe(result => {
      this.authService.storeJwtToken(result);
      this.onUserSuccess();
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.loginError = err.error.errors[0];
      //this.toasterService.show('error', 'error', err?.errors ? err.errors : 'Error while validating facebook');
      this.logService.logError(err, 'Error while validating facebook', 500);
    });
  }

  isFieldInvalid(field: string) {
    if(!this.loginflow && !this.isCompanyDomain) {
      return (
        (!this.forgetPWDform.get(field).valid && this.forgetPWDform.get(field).touched) ||
        (this.forgetPWDform.get(field).untouched && this.forgetfmSubmitAttempt)
      );
    }
    if(this.isCompanyDomain) {
      return (
        (!this.formCompanyDomain.get(field).valid && this.formCompanyDomain.get(field).touched) ||
        (this.formCompanyDomain.get(field).untouched)
      );
    }
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    this.commonService.gaEvent('general_login', 'general_submit', 'General Submit');
    if (this.form.valid) {
      const details = {
        password: this.form.value.password,
        company_id: this.selectedCompany,
        userName: this.formUsername.value.userName
      };
      this.authService.login(details);
    }
    //this.invalidLogins = true;
    this.formSubmitAttempt = true;
  }

  onCompanyLogin() {
    const details = {
      password: this.formCompanyDomain.value.password,
      company_id: this.selectedCompany,
      userName: this.formCompanyDomain.value.userName
    };
    this.authService.login(details);
  }

  onCreateAccount() {
    const obj = {
      email: 'abcde@gmail.com'
    };
    this.authService.setUserDetails(obj);
    this.onUserSuccess();
  }

  onUserSuccess() {
    this.authService.onLoginSuccess();
  }

  loginWithGoogle(): void {
    this.loginError = '';
    this.commonService.gaEvent('google_login', 'google_login', 'Login with Google');
    this.triggeredSocial = false;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  loginWithFacebook():void {
    this.loginError = '';
    this.triggeredSocial = false;
    this.commonService.gaEvent('facebook_login', 'facebook_login', 'Login with Facebook');
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  loginWithApple(): void {
    this.commonService.gaEvent('apple_login', 'apple_login', 'Login with Apple');
    this.appleLoginProvider.signIn();
  }

  signOut(): void {
    this.commonService.gaEvent('signout', 'signout', 'Sign out from application');
    this.socialAuthService.signOut();
  }

  forgetPWD() {
    this.loginflow = false;
  }

  onForgetSubmit() {
    let obj = this.forgetPWDform.value;
    this.httpService.postCall(`${config.apiUrl}/forgotpassword`, {"email": obj.email}).subscribe(result => {
      this.toasterService.show('success', 'success', result.success);  
    }, err => {
      this.toasterService.show('error', 'error', err?.error?.error);
    });
  }

  goBack() {
    this.loginflow = true;
  }
}

@Component({
  selector: 'social-logins-company-selection',
  template: `
  <h1 mat-dialog-title>Select PreferredCompany</h1>
    <div mat-dialog-content class="dialogContent">
    <div class="select-ddl">
    <mat-form-field class="width-full recipe-ddl-dialog">
      <mat-label>Select Company</mat-label>
      <mat-select [(ngModel)]="companySelectedId">
        <mat-option *ngFor="let item of companiesList"  [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>
    `,
  styleUrls: ['./loginflow.component.sass']
})
export class SocialLoginsCompanySelection {
  companySelectedId = -1;
  companiesList:any = [];

  constructor(
    public dialogRef: MatDialogRef<SocialLoginsCompanySelection>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.companiesList = data.companiesList;
    }

  responseHandler(action:any): void {
    const obj:any = {};
    if (action) {
      obj.companySelectedId = this.companySelectedId;
    }
    this.dialogRef.close(obj);
  }
}