import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EmbedVideoService } from 'ngx-embed-video';

import { CommonService } from '../services/common/common.service';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';

let exetime:any;
let exeSaveTime:any;

@Component({
  selector: 'app-clientexercise',
  templateUrl: './clientexercise.component.html',
  styleUrls: ['./clientexercise.component.sass']
})

export class ClientexerciseComponent implements OnInit {
  programsData:any = [];
  exerciseData:any = [];
  program_id:number = 0;
  maxDate:any = new Date();
  clientDetails:any={};
  current_program_id:number = 0;
  defaultSetsValue:number = 0;
  exerciseCtrl:any;
  isCreateInProgress:boolean = false;
  isExercisesExists:boolean = false;
  planName:any;
  exerciseErrorInfo:string = '';
  sortDate:any;
  isTodayDate = true;

  exerciseDate = new FormControl(new Date());

  exercises: any = [];

  weightReps = {
    header_1: 'WEIGHT',
    header_2: 'REPS'
  };

  set = '33';
  indexval = 0;
  constructor(private commonService: CommonService,
    private httpService: HttpService,
    private loader: UiService,
    private dialog: MatDialog,private ui: UiService,
    private toasterService: ToasterService,private route: ActivatedRoute, private platformLocation: PlatformLocation) { 
      this.exerciseCtrl = new FormControl();
    }

  ngOnInit(): void {
    const routes: any = [
      { name: 'Exercise', path: 'clientexercise' }
    ];
    this.commonService.setHeaderData(routes);
    this.getProgramsList();
    this.route.queryParams.subscribe((params:any) => {
      this.sortDate = params.sortDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
      history.pushState(null, '', location.href);
      this.platformLocation.onPopState(()=>{
        history.pushState(null, '', location.href)
      })
      this.exerciseDate.setValue(this.sortDate);
      this.isTodayDate =false;
    })
    this.route.paramMap.subscribe((params: any) => {
      this.program_id = params.get('programId');
    });
    this.getExerciseData();
  }

  async downloadAsPDF() {
    const url = `${config.apiUrl}/getexercisespdf/${this.clientDetails.id}/${this.clientDetails.activeprogram_id} `;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while created successfully');
      console.log(err);
    });
    return true;
  }

  setCurrentProgram() {
    // if(this.current_program_id === this.program_id) {
    //   this.exerciseDate.setValue(null);
    //   this.isTodayDate =true;
    // }
    this.programsData.forEach((item: any) => {
      if (item.id === this.current_program_id) {
        item.name = 'Current Session';
      }
    });
    // if (!this.isExercisesExists && this.programsData.length > 0) {
      // this.program_id = this.programsData[0].id;
      // this.getExerciseData();
    // }
  }

  getProgramsList() {
    const url = `${config.apiUrl}/getclientprograms`;
    this.httpService.postCall(url, {}).subscribe(result => {
      this.clientDetails = result.clientDetais;
      this.current_program_id = result.clientDetais.activeprogram_id;
      this.program_id = result.clientDetais.activeprogram_id;
      this.programsData = result.programs;
      this.setCurrentProgram();
    }, err => {

    });
  }

  getEPEDetails(data:any) {
    const arr:any = [];
    data.forEach((item:any) => {
      if(item.setdetails && item.setdetails.length === 0) {
        item.setdetails.push({
          reps: 0,
          rest: 0,
          speed: 0,
          log_reps: "",
          log_weight: "",
          isnewset: true,
          clientnote: ''
        });
        arr.push(item);
      } else {
        if (item.setdetails) {
          item.setdetails.forEach((rec:any) => {
            if (rec.log_reps === 0) {
              rec.log_reps = '';
            }
            if (rec.log_weight === 0) {
              rec.log_weight = ''
            }
          });
        } else {
          if (item.log_heartrate === 0) {
            item.log_heartrate = '';
          }
          if (item.log_duration === 0) {
            item.log_duration = ''
          }
        }
        arr.push(item);
      }
    });
    return arr;
  }

  getExerciseData(isProgram?:any, isDate?:any) {
    const url = `${config.apiUrl}/logexercisesingle`;
    let reqData:any = {program_id:'',logdate:''};
    const datepickerDate = this.commonService.getGeneralFormatDate(new Date(this.exerciseDate.value));
    const todayDate = this.commonService.getGeneralFormatDate(new Date());
    if(datepickerDate != todayDate)this.isTodayDate =false;
    let date:any = typeof this.exerciseDate.value == 'string'? new Date(this.exerciseDate.value):  this.exerciseDate.value;
    if(isProgram || isDate){
      reqData.program_id =  reqData.program_id !== 0  && !isDate ? this.program_id : ''
      reqData.logdate = this.commonService.getGeneralFormatDate(date);
    }
     else {
      reqData.logdate= this.commonService.getGeneralFormatDate(date || new Date());
      if(this.program_id !== 0) reqData.program_id = this.program_id;
    }
    this.loader.showSpinner();
    this.httpService.postCall(url, reqData).subscribe(result => {
      let resArr:any = [];
      let plans = result[0].plans || [];
      this.planName = plans.length > 0 ? plans[plans.length - 1].plan_name:'';
      // if (this.program_id === 0) {
        this.program_id = result[0].program_id;
      // }
      if (this.current_program_id === 0) {
        this.current_program_id = result[0].program_id;
      }
      result[0].plans.forEach((item:any) => {
       if(item.epe.length > 0) item.epe[0].plan_name = item.plan_name;
        resArr = [...resArr, ...this.getEPEDetails(item.epe)];
      });
      this.exerciseData = resArr;
      this.setCurrentProgram();
      this.isExercisesExists = true;
      this.loader.stopSpinner();
    }, err => {
      this.isExercisesExists = false;
      this.exerciseErrorInfo = err?.error?.message;
      this.loader.stopSpinner();
      if (err.status !== 404) {
        this.toasterService.show('error', 'Error Occurred', err?.error?.message);
      }
      this.setCurrentProgram();
    });
  }

  onDateSelect(event:any) {
    const datepickerDate = this.commonService.getGeneralFormatDate(this.exerciseDate.value);
    const todayDate = this.commonService.getGeneralFormatDate(new Date());
    if (datepickerDate === todayDate) {
      this.isTodayDate = true;
      this.exerciseDate.setValue(null);
    } else {
      this.isTodayDate = false;
    }
    this.getExerciseData(false,true);
  }

  onChangeProgram () {
    let program = this.programsData.find((exe:any) => exe.id === this.program_id);
    const todayDate = this.commonService.getGeneralFormatDate(new Date());
    if (program.program_start_date === todayDate) {
      this.isTodayDate = true;
    } else {
      this.isTodayDate = false;
    }
    this.exerciseDate.setValue(program.program_start_date);
    this.getExerciseData(true,false);
  }

  updateExerciseData(event:any) {
    let reqData:any = {}; 
    if (event.clientnote) {
      reqData = {
        data: {
          clientnote: event.clientnote
        },
        logexercisesingle_id: this.exerciseData[event.index].logexercisesingle_id,
      };
    } else if (this.exerciseData[event.index].setdetails) {
      reqData = {
        data: this.exerciseData[event.index].setdetails[event.setIndex]
      };
      if (this.exerciseData[event.index].setdetails[event.setIndex].isnewset) {
        this.isCreateInProgress = true;
        this.loader.showSpinner();
        reqData.isnewset = true;
        reqData.setiteration = this.exerciseData[event.index].setdetails.length;
        reqData.logexercisesingle_id = this.exerciseData[event.index].logexercisesingle_id;
      } else {
        reqData.logexercisesingleset_id = this.exerciseData[event.index].setdetails[event.setIndex].logexercisesingleset_id;
      }
    } else {
      reqData = {
        logexercisesingle_id: this.exerciseData[event.index].logexercisesingle_id,
        data: {
          log_heartrate: this.commonService.getValidateNumber(this.exerciseData[event.index].log_heartrate),
          log_duration: this.commonService.getValidateNumber(this.exerciseData[event.index].log_duration)
        }
      }
    }
    const url = `${config.apiUrl}/logexercisesingle/updatelogdata`;
    this.httpService.postCall(url, reqData).subscribe(result => {
      this.isCreateInProgress = false;
      this.loader.stopSpinner();
      if (result.data) {
        this.exerciseData[event.index].setdetails[event.setIndex].logexercisesingleset_id = result.data.logexercisesingleset_id;
        this.exerciseData[event.index].setdetails[event.setIndex].clientadded = 1;
        delete this.exerciseData[event.index].setdetails[event.setIndex].isnewset;
      } else if (event.clientnote) {
        this.exerciseData[event.index].clientnote = event.clientnote;
      }
    }, err => {
      this.isCreateInProgress = false;
      this.loader.stopSpinner();
    });
  }

  createManualEntry(event:any) {
    const data = this.exerciseData[event.index];
    let requestDate = this.exerciseDate.value ? this.exerciseDate.value : new Date();
    const obj:any = {
      "logdate": this.commonService.getGeneralFormatDate(requestDate),
      "exercisetemplate_id": data.exercisetemplate_id,
      "cat_id": data.cat_id,
      "plan_name": data.plan_name || this.planName,
      "name": data.name,
      "sets":1,
      "clientadded":1,
      "program_id": this.program_id,
      "data": []
    }
    if (data.isSetDetails) {
      obj.data = data.setdetails;
    } else {
      obj.data = [{
        log_heartrate: this.exerciseData[event.index].log_heartrate,
        log_duration: this.exerciseData[event.index].log_duration
      }];
    }
    const url = `${config.apiUrl}/logexercisesingle/createmanualentry`;
    this.httpService.postCall(url, obj).subscribe((result) => {
      this.isCreateInProgress = false;
      this.loader.stopSpinner();
      this.getExerciseData();
    }, err => {
      this.isCreateInProgress = false;
      this.loader.stopSpinner();
    });
  }

  saveExerciseData(event:any) {
    if(this.isCreateInProgress) {
      return false;
    }
    if (this.exerciseData[event.index].isManualEntry) {
      this.isCreateInProgress = true;
      this.loader.showSpinner();
      this.createManualEntry(event);
    } else if (this.exerciseData[event.index].logexercisesingle_id) {
      this.updateExerciseData(event);
    } else {
      this.isCreateInProgress = true;
      this.loader.showSpinner();
      let requestDate = this.exerciseDate.value ? this.exerciseDate.value : new Date();
      const reqData = {
        logdate: this.commonService.getGeneralFormatDate(requestDate),
        program_id: this.program_id,
        clientadded: 1,
        exerciseplanexercise_id: this.exerciseData[event.index].exerciseplanexercise_id,
        data: this.exerciseData[event.index].setdetails || [{
          log_heartrate: this.exerciseData[event.index].log_heartrate,
          log_duration: this.exerciseData[event.index].log_duration
        }]
      };

      const url = `${config.apiUrl}/logexercisesingle/create`;

      this.httpService.postCall(url, reqData).subscribe(result => {
        this.loader.stopSpinner();
        this.getExerciseData();
        this.isCreateInProgress = false;
      }, err => {
        this.isCreateInProgress = false;
        this.loader.stopSpinner();
        console.log(err);
      });
    }
    return false;
  }

  onAction(event:any) {
    if (this.exerciseData[event.index].setdetails) {
      this.exerciseData[event.index].setdetails[event.setIndex][event.type] = event.info;
    } else {
      this.exerciseData[event.index][event.type] = event.info;
    }

    clearTimeout(exeSaveTime);
    exeSaveTime = setTimeout(() => {
      this.saveExerciseData(event);
    });
  }

  onAddSet(i:number) {
    const len = this.exerciseData[i].setdetails.length;
    const record = JSON.parse(JSON.stringify(this.exerciseData[i].setdetails[len-1]));
    record.log_reps = '';
    record.log_weight = '';
    record.isnewset = true;
    this.exerciseData[i].setdetails.push(record);
  }

  onAddNote(i: number) {
    const data = this.exerciseData[i];
    let dialogRef = this.dialog.open(NoteDialog, { data: {'note': data.clientnote}, minWidth: 350, minHeight: 300 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result.status) {
        data.changeNotes = result.note;
        this.updateExerciseData({clientnote: result.note, index: i });
      }
    });
  }

  onDeleteSet(i:number, j:number) {
    const singlesetid = this.exerciseData[i].setdetails[j].logexercisesingleset_id;
    const url = `${config.apiUrl}/deletelogexercisesingleset/${singlesetid}`;
    this.httpService.deleteCall(url, {}).subscribe(result => {
      this.exerciseData[i].setdetails.splice(j, 1);
    }, (err:any) => {
      console.log(err);
    });
  }

  onExerciseDelete(i:number) {
    const singleid = this.exerciseData[i].logexercisesingle_id;
    if (singleid) {
      const url = `${config.apiUrl}/deletelogexercisesingle/${singleid}`;
      this.httpService.deleteCall(url, {}).subscribe(result => {
        this.exerciseData.splice(i, 1);
      }, (err:any) => {
        console.log(err);
      });
    } else {
      this.exerciseData.splice(i, 1);
    }
  }

  fetchExercises(txt:string) {
    const url = `${config.apiUrl}/searchexercises`;
    const reqData = {
      exercise: txt
    }
    this.httpService.postCall(url, reqData).subscribe(result => {
      this.exercises = result;
    }, err => {

    });
  }

  onSearchExercise(ev:any) {
    const text = ev.target.value;
    clearTimeout(exetime);
    exetime = setTimeout(() => {
      this.fetchExercises(text);
    }, 1000);
  }

  getDefaultRecord(item:any, isManual:boolean) {
    const len = this.exerciseData.length;
    let obj:any = {
      isManualEntry: isManual,
      "exercisetemplate_id": item.id,
      "cat_id": item.exercisetemplatecategory_id,
      "plan_name": this.exerciseData[ len - 1] ? this.exerciseData[ len - 1].plan_name : '',
      "name": item.name,
      "sets":1,
      "clientadded":1,
      }
      if (!isManual) {
        obj.isnewset = true;
      }
      if (item.exercisetemplatecategory_id === 1) {
        obj.heartrate = 0;
        obj.duration = 0;
        obj.log_heartrate = "";
        obj.duration = "";
        obj.isSetDetails = false;
      } else {
        obj.reps = 0;
        obj.rest = 0;
        obj.sets = 0;
        obj.speed = 0;
        const arr = [
          {
            reps: 0,
            rest: 0,
            speed: 0,
            log_reps: "",
            log_weight: ""
          }
        ]
        obj.setdetails = arr;
        obj.isSetDetails = true;
      }
      return obj;
  }

  onAddExerciseNew(item:any) {
    console.log(item);
    this.exerciseCtrl.setValue('');
    this.exerciseData.push(this.getDefaultRecord(item, true));
  }

  onVideoView(videoUrl:string, name:string) {
    let dialogRef = this.dialog.open(VideoDialog, { data: {videoUrl: videoUrl, name: name} });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
      }
    });
  }

  onExerciseInfo(description: string, name: string, exercisetemplateimages: any) {
    let dialogRef = this.dialog.open(ClientExerciseInfo, { data: {description: description, name: name, images: exercisetemplateimages}, maxWidth: 500 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
      }
    });
  }

}


@Component({
  selector: 'dialog-overview-add-note-dialog',
  template: `
  <h1 mat-dialog-title>Note</h1>
    <div mat-dialog-content class="dialogContent">
      <textarea (change)="onNoteChange($event)" [value]="data.note" class="add-note-textarea"> </textarea>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>
    `,
  styleUrls: ['./clientexercise.component.sass']
})
export class NoteDialog {
  constructor(
    public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoteChange(ev:any) {
      this.data.note = ev.target.value;
    }

  responseHandler(action:any): void {
    let obj = {
      status: false,
      note: ''
    };
    if (action) {
      obj = {
        status: true,
        note: this.data.note
      };
    }
    this.dialogRef.close(obj);
  }  
}

@Component({
  selector: 'video-view-dialog',
  template: `
    <h1 mat-dialog-title>{{data.name}}</h1>
    <div mat-dialog-content class="dialogContent">
    <div [innerHTML]="iframe_html"></div>
    </div>
    <div mat-dialog-actions class="video-dialog-action">
      <button mat-raised-button color="primary" class="resultsbtn video_success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>OK</button>
    </div>`,
  styleUrls: ['./clientexercise.component.sass']
})
export class VideoDialog {
  vimeoUrl: string = '';
  iframe_html: any;
  constructor(
    private embedService: EmbedVideoService,
    public dialogRef: MatDialogRef<VideoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.vimeoUrl = data.videoUrl;
      this.iframe_html = this.embedService.embed(this.vimeoUrl,
        {
          query: { portrait: 0, color: '333',autoplay:'1' },
          attr: { width: 1000, height: 500, allow:"autoplay" }
        });
    }

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'exercise-info-dialog',
  template: `<h1 mat-dialog-title>{{data.name}}</h1>
  <div mat-dialog-content class="dialogContent">
    <div>
      {{data.description}}
    </div>
    <div>
      <span
        *ngFor="let item of data.images"
      >
        <img [src]="exerciseTemplateImageBaseUrl + item" />
      </span>
    </div>
  </div>
  <div mat-dialog-actions class="video-dialog-action">
    <button mat-raised-button color="primary" class="resultsbtn video_success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>OK</button>
  </div>`,
  styleUrls: ['./clientexercise.component.sass']
})
export class ClientExerciseInfo {
  exerciseTemplateImageBaseUrl = '';
  constructor(
    public dialogRef: MatDialogRef<ClientExerciseInfo>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.exerciseTemplateImageBaseUrl = config.exerciseTemplateImageUrl;
    }

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}
