import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { ToasterService } from '../services/toaster/toaster.service';
import { CommonService } from '../services/common/common.service';

@Component({
  selector: 'app-exercisesuploader',
  templateUrl: './exercisesuploader.component.html',
  styleUrls: ['./exercisesuploader.component.sass']
})

export class ExercisesuploaderComponent implements OnInit {
  @ViewChild('editTemplates') editTemplates:any;
  @ViewChild('createTemplates') createTemplates:any;  
  exercisesCategories:any = [];
  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private commonService: CommonService
    ) { }

  ngOnInit(): void {
    const routes:any = [{
      name: 'Uploader',
      path: 'uploader'
    },{
      name: 'Exercises Uploader',
      path: 'exercises-uploader'
    }];

    this.commonService.setHeaderData(routes);
    this.getCategoryExercise();
  }

  getCategoryExercise() {
    const url = `${config.apiUrl}/exercises/getexercisetempcategory/`;
    this.httpService.getCall(url).subscribe(result => {
      this.exercisesCategories = result;
    }, err => {
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

  onTabChanged(event:any) {
    //Calling clearSearchTxt function of exercisesuploaderedit
    if(event.index==1 && event.tab.textLabel=='Edit'){
      this.editTemplates.clearSearchTxt();
      this.editTemplates.getExercisesTemplatesList();
    } else if(event.index==0 && event.tab.textLabel=='Create'){
      this.createTemplates.clearValues();
    }
  }
}
