<div class="nutrition-container">
    <div class="title">
        Nutrition
    </div>
    <div class="contents">
        <div class="section1">
            <div class="dropdown">
                <mat-form-field>
                    <mat-select [(ngModel)]="program_id" name="dropdown" (selectionChange)="onChangeProgram($event)" placeholder="Select Session">
                      <mat-option *ngFor="let data of programsData" [value]="data.id">
                        {{data.name}}<span *ngIf="data.goal.name!=null && data.goal.name!=''"> ({{data.goal.name}})</span>
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-date-picker">
                <mat-form-field appearance="fill">
                    <div (click)="picker.open()" class="today-lbl" [style.display]="isTodayDate ? 'block' : 'none'">Today</div>
                    <input [style.display]="!isTodayDate ? 'block' : 'none'"  matInput [matDatepicker]="picker" [readonly]="true"
                     (focus)="picker.open()" (dateChange)="pickerUpdate()" [(ngModel)]="filterdate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div>
            <button mat-raised-button class="margin_10" *ngIf="substitutionguide_url" (click)="showGuide('substitution_guide')" color="secondary">
                <mat-icon>print</mat-icon>
                Substitution Guide
            </button>
            <button mat-raised-button class="margin_10" *ngIf="diningout_url" (click)="showGuide('diningout_guide')" color="secondary">
                <mat-icon>print</mat-icon>
                Dining Out Guide
            </button>
            <button mat-raised-button class="margin_10" color="secondary"  (click)="onSelectShoppingList()">
                <mat-icon>print</mat-icon>
                Shopping List
            </button>
            <button mat-raised-button class="margin_10" color="secondary"  (click)="downloadAsPDF()">
                <mat-icon>print</mat-icon>
                Print
            </button>
        </div>
        <div class="section2" *ngIf="mealsplan && mealsplan.length>0">
            <div class="sub-title">Totals Logged</div>
            <div class="boxes">
                <div class="box" *ngFor="let data of boxes">
                    <div class="box-title">{{data.field}}</div>
                    <div class="values">
                        <span class="orange" [ngClass]="{'green': data.total == data.actual, 'red': data.total > data.actual}">{{data.total | number:'1.0-0'}}</span><span class="orange" [ngClass]="{'green': data.total >= data.actual}">/{{data.actual | number:'1.0-0'}} {{data.unit}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section3">
            <div class="compliance"  *ngIf="mealsplan && mealsplan.length>0">
                Percentage compliance: <span class="orange">0-89%</span>, <span class="green">90-100%</span>, <span class="red">101%+</span>
            </div>
            <div *ngIf="errorMsg.length > 0" class="err-info">
                <mat-card>{{errorMsg}}</mat-card>
            </div>
            <div *ngFor="let plan of mealsplan">
                <div class="plan-title">{{plan.name}}</div>
                <div *ngFor="let item of plan.mealplans">
                    <div class="head-section">
                        <div class="sub-title">{{item.name}}</div>
                        <div class="right-container">
                            <div class="search-container">
                                <form class="example-form">
                                    <div class="input-container">
                                        <mat-icon matPrefix>search</mat-icon>
                                        <input type="text" placeholder="Add Food" class="search-input" 
                                        [matAutocomplete]="auto"
                                        (keyup)="searchResult($event, item.id)" 
                                        [formControl]="nutritionCtrl"/>
                                    </div>
                                        <mat-autocomplete #auto="matAutocomplete" >
                                            <div class="search-button-row">
                                                <button mat-raised-button color="{{searchCategory === 'common' ? 'primary':''}}"  (click) ="updateSearchCategory('common')">Common</button>
                                                <button  mat-raised-button color="{{searchCategory === 'branded' ? 'primary':''}}" style="margin-left:10px ;" (click) ="updateSearchCategory('branded')">Branded</button>
                                            </div>
                                            <hr/>
                                            <mat-option *ngFor="let obj of item.searchResult[searchCategory]; let last = last;" [value]="obj.item_name" (click)="insertFood(obj, item.id)">
                                               <div class="result_option">
                                                <img alt="" class="example-option-img" [src]="obj.thumbnail" height="45" width="40">
                                                <div>
                                                    <div *ngIf="!obj.nix_item_id">
                                                        <p>{{obj.item_name}}</p>
                                                        <p>{{obj.serving_qty}} {{obj.serving_uom}}</p>
                                                    </div>
                                                    <div class="prod-info" *ngIf="obj.nix_item_id">
                                                        <p >{{obj.item_name}}</p>
                                                        <p>Brand: {{obj.brand_name}}, {{obj.calories}} calories, {{obj.serving_qty}} {{obj.serving_uom}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <mat-divider *ngIf="!last"></mat-divider>
                                            </mat-option>
                                        </mat-autocomplete>
                                </form>
                            </div>
                            <div class="time-container">
                                <input type="time" placeholder="Add Food" [(ngModel)]="item.log_time ? item.log_time : item.time" class="time-input" autocomplete="off" (change)="timeChange($event, item.id)"/>
                            </div>
                        </div>
                    </div>
                    <app-nutrition-table [datasource]="tableData[item.id] ? tableData[item.id] : []" [overAllTotal]="overAllTotal.bind(this)" [type]="item.name.toLowerCase()" (delete)="deleteFoods($event)" (onAddNote)="onAddNote($event)"
                        [overAllTableUpdate]="overAllTableUpdate.bind(this, item.id)" (rowChange)="addItem($event)"></app-nutrition-table>
                </div>
            </div>
        </div>
    </div>
</div>
