import { Component, OnInit, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { LogService } from '../../services/log/log.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import { CommonService } from '../../services/common/common.service';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-fooduploaderedit',
  templateUrl: './fooduploaderedit.component.html',
  styleUrls: ['./fooduploaderedit.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FooduploadereditComponent implements OnInit {
  isTableExpanded = false;
  @Input() foodsCategories:any;
  @Input() userPrivilege:any;
  isAllSelected:any = false;
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  datasource:any;
  columnsList: string[] = ['name', 'category', 'calories', 'protein', 'carbs', 'fat', 'unit', 'qty'];
  deletehighlight = false;
  categories: any = [];
  searchTxt: any = '';
  constructor(private httpService: HttpService,
    private ui: UiService,
    private logService: LogService,
    private toasterService: ToasterService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.getFoodData();
  }

  getFoodData(txt='', fromIndex=0, limit=25) {
    this.ui.spin$.next(true);
    const payload:any =  {
      orderByOn: "name", 
      orderBy: "ASC",
      fromIndex: fromIndex,
      searchtext: txt,
      limit: limit
    };
    this.httpService.postCall(`${config.apiUrl}/foods/getfoodtemplates`, payload).subscribe((result:any) => {
      this.formatTabledata(result);
      this.masterSelection();
      this.pagination.count = result.count;
      this.ui.spin$.next(false);
    }, (err:any) => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  formatTabledata(data:any) {
    const rows = data?.rows;
    this.datasource = rows.map((o:any) => {
      o.checked = false;
      o.isExpanded = false;
      this.columnsList.map((ob:any) => {
        const key = 'new'+ob;
        o[key] = o[ob];
      })
      o['newcategory'] = o.foodtemplatecategory_id;
      return o;
    })
  }

  masterSelection() {
    let highlgt = this.datasource.find((o:any) => o.checked == true);
    highlgt ? this.deletehighlight = true : this.deletehighlight = false;
  }

  setSelection(ev:any, row:any='') {
    let overall = true;
    this.datasource = this.datasource.map((ob:any) => {
      if(row && ob.id === row.id) ob.checked = ev.checked;
      if(!row) ob.checked = ev.checked;
      if(ob.checked == false) overall = false;
      return ob;
    });
    this.masterSelection();
    this.isAllSelected = overall;
  }

  enableEdit(row:any, field:any) {
    this.datasource = this.datasource.map((ob:any) => { 
      if(ob.id === row.id){
        const key = field + 'edit';
        ob[key] = true;
      } 
      return ob;
    });
  }

  saveNewValue(row:any, field:any) {
    this.ui.spin$.next(true);
    const key = 'new' + field;
    const payload = {
      [field]: row[key],
      "foodtemplatecategory_id": row.foodtemplatecategory_id
    }
    let url:any;
    if(field === 'qty') {
      url = `${config.apiUrl}/foods/updatefoodtemplateqty/${row.id}`;
    } else {
      url = `${config.apiUrl}/foods/updatefoodtemplate/${row.id}`;
    }
    this.httpService.putCall(url, payload).subscribe((result:any) => {
      const newvalue = 'new' + field;
      this.datasource = this.datasource.map((ob:any) => { 
        if(ob.id === row.id){
          const key = field + 'edit';
          ob[key] = false;
          ob[field] = ob[newvalue];
        } 
        return ob;
      });
      this.ui.spin$.next(false);
    }, (err:any) => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  cancelEdit(row:any, field:any) {
    this.datasource = this.datasource.map((ob:any) => { 
      if(ob.id === row.id){
        const key = field + 'edit';
        ob[key] = false;
      } 
      return ob;
    });
  }

  debounceSearch = () => {
    let timer:any;
    let context:any = this;
    return function(eve:any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        context.getFoodData(eve.target.value);
      }, 300);
    }
  }

  searchResult = this.debounceSearch();

  getServerData(ev:any) {
    this.getFoodData(this.searchTxt, (ev.pageIndex * ev.pageSize), ev.pageSize);
  }

  deleteFoods() {
    this.ui.spin$.next(true);
    const payload = this.datasource.filter((obj:any) => obj.checked).map((obj:any) => obj.foodtemplatecategory_id);
    this.httpService.postCall(`${config.apiUrl}/foods/deletefoodtemplate`, {template_ids: payload}).subscribe((result:any) => {
      this.datasource = this.datasource.filter((val: any) => !val.checked);
      this.masterSelection();
      this.ui.spin$.next(false);
    }, (err:any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error);
      this.ui.spin$.next(false);
    });
  }

}
