<h1 mat-dialog-title>{{data.isEditMode ? 'Edit Role' : 'Create Role'}}</h1>
<div mat-dialog-content class="dialogContent" >
    <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>Role Name</mat-label>
        <input  matInput [(ngModel)]="data.name">
      </mat-form-field>
      <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>Description</mat-label>
        <input  matInput [(ngModel)]="data.description">
      </mat-form-field>
      <mat-form-field class="create-company-full-width" appearance="fill">
        <mat-label>Permissions</mat-label>
        <mat-select [(value)]="data.permissionsSelected" multiple>
          <mat-option *ngFor="let permission of data.permissionItems" [value]="permission.id">{{permission.display_name}}</mat-option>
        </mat-select>
      </mat-form-field>
</div>
<div mat-dialog-actions class="dialogActions text-right">
    <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" [mat-dialog-close]="data"
    cdkFocusInitial>
      {{data.isEditMode ? 'Edit' : 'Save'}}
  </button>
</div>