import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../services/toaster/toaster.service';
import { AuthService } from '../auth/auth.service';
import { UiService } from '../services/uiloader/uiloader.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.sass']
})
export class NotesComponent implements OnInit {

  constructor(public dialog: MatDialog, private httpService: HttpService, private route: ActivatedRoute, private toasterService: ToasterService,
    private authService: AuthService,private ui: UiService) { }
  displayedColumns: string[] = ['select','name', 'description', 'created_on', 'actions'];
  dataSource = [];
  clientId: any;
  actualLoggedUserId:any
  consultantClients = [];
  consultants= [];
  selectedNotes:Array<any> = [];
  pagination = {
    count: 0,
    perPage: 500,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };

  ngOnInit(): void {
    this.clientId = this.route.snapshot.params.id;
    this.actualLoggedUserId = this.authService.getActualLoggedUserId();
    this.getConsultantNotes();
    this.getConsultantClients();
    this.getConsultants();
  }

  selectNotes(notes:any){
   if(!notes.checked){
    this.selectedNotes.push(notes.id);
   }else {
    this.selectedNotes= this.selectedNotes.filter(note => note != notes.id);
   }
  }

  getConsultantNotes() {
    const url = this.clientId 
        ? `${config.apiUrl}/notes/getClientNotes/${this.clientId}` 
        : `${config.apiUrl}/notes/getConsultantNotes/${this.actualLoggedUserId}`;
    this.httpService.getCall(url).subscribe(
        (data: any) => {
            this.dataSource = data.map((note: any) => {
                return this.clientId 
                    ? { ...note, checked: false } 
                    : note.notes_v2;
            });
        },
        (err) => {
            console.error('Error fetching consultant notes:', err); 
        }
    );
}

  getConsultantClients() {
    const url = `${config.apiUrl}/fetchloggedinconsultantclients`;
    this.httpService.postCall(url, { "client_id": this.clientId }).subscribe((res: any) => {
      this.consultantClients = res;
    }, (err) => {
      console.log(err);
    });
  }

  getConsultants() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: this.pagination.pageIndex,
      limit: this.pagination.perPage,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: this.pagination.searchtext
    };
    this.httpService.postCall(`${config.apiUrl}/companyadmin/getconsultants`, obj).subscribe((data:any) => {
      this.consultants = data.rows;
      this.ui.spin$.next(false);
    },
    err => {
      this.ui.spin$.next(false);
    });
  }

  openNotesDialog(data:any) {
    const dialogRef = this.dialog.open(AddNotesDialogComponent, {
      data: { 
        consultantClients: this.consultantClients,
        consultants: this.consultants,
        loggedInClientId: this.clientId,
        actualLoggedUserId: this.actualLoggedUserId,
        ...data
      },
      width: "50%"
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getConsultantNotes();
      }
    });
  }
  
  addNotes() {
    this.openNotesDialog({ selectedNotes: [] });
  }
  
  shareNotes() {
    this.openNotesDialog({ selectedNotes: this.selectedNotes });
  }
  

  eidtNotes(notes:any){
    const editdialogRef = this.dialog.open(EditNotesDialogComponent, { data: {notes, clientId:this.clientId,consultantClients: this.consultantClients, consultants:this.consultants }, width: "50%" });
    editdialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteNotes(data: any,) {
    let dialogRef = this.dialog.open(AlertDialog, { data: { 'content': 'Are you sure you want to delete the notes?' } });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.ui.spin$.next(true);
      if (result) {
        const url = `${config.apiUrl}/notes/deletenote/${data.id}`;
        this.httpService.deleteCall(url, {}).subscribe(data => {
          this.getConsultantNotes();
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'Notes deleted successfully');
        }, err => {
          this.toasterService.show('error', 'Error', 'Error Occurred, Please try again');
          this.ui.spin$.next(false);
        });
      }
    });
    return true;
  }
}


@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <h1 mat-dialog-title>Confirmation</h1>
  <div mat-dialog-content class="dialogContent">
      <p>{{data.content}}</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Ok</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
  styleUrls: ['./notes.component.sass']
})
export class AlertDialog {
  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}


@Component({
  selector: 'edit-notes.component',
  templateUrl: './edit-notes.component.html',
  styleUrls: ['./notes.component.sass']
})
export class EditNotesDialogComponent {
  constructor( public dialogRef: MatDialogRef<EditNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private httpService: HttpService){}

    updateNotes(notes:any) {
      console.log("Update Notes", notes)
    }

}

@Component({
  selector: 'add-notes.component',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./notes.component.sass']
})
export class AddNotesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private httpService: HttpService,private toasterService:ToasterService) {

  }
  title: string = 'Test';
  description: string = '';
  is_private: boolean = true;
  client_id: any =this.data.loggedInClientId ?  parseInt(this.data.loggedInClientId) : "";
  saveNotes(note: {
    client_id: string;
    title: string;
    description: string;
    is_private: boolean;
  }): void {
    const { client_id, title, description, is_private } = note;
    const handleResponse = (res: any) => {
      this.toasterService.show('success', 'Success', ' Notes assigned to Consultants successfully');
    };
  
    const handleError = (err: any) => {
      console.error('An error occurred:', err);
      this.toasterService.show('error', 'Error', 'Error Occurred, Please try again');
    };
  
    if (this.data.selectedNotes?.length > 0) {
      this.assignNotesToConsultants(client_id, this.data.selectedNotes)
        .subscribe(handleResponse, handleError);
    } else {
      this.createClientNote(title, description, is_private)
        .subscribe(handleResponse, handleError);
    }
  }
  
  private assignNotesToConsultants(clientId: string, noteIds: string[]) {
    return this.httpService.postCall(`${config.apiUrl}/notes/assignnotestoconsultants`, { consultant_ids: [clientId], note_ids: noteIds });
  }
  
  private createClientNote(title: string, description: string, isPrivate: boolean) {
    const clientId = this.data.loggedInClientId || this.data.actualLoggedUserId;
    return this.httpService.postCall(`${config.apiUrl}/notes/createclientnote/${clientId}`, { name: title, description, is_private: isPrivate });
  }
  
  responseHandler(action: any): void {
    this.dialogRef.close(action);
  }

}
