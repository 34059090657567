<div class="main-content roles-editor-container">
  <div class="sub-header">
    <span class="user-name">
      Roles Editor
    </span>
  </div>
  <div>
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">
      <mat-tab label="Add Role">
        <div class="sub-content">
          <div class="top-section">
            <div class="search-container">
              <div class="input-container">
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" placeholder="Search" [(ngModel)]="searchTxt" class="search-input" autocomplete="off"
                  (keyup)="searchResult($event)" />
              </div>
              <button mat-raised-button color="primary" style="float: right;" (click)="addRole()">Add Role</button>
            </div>
          </div>
          <div class="table-container">
            <table mat-table [dataSource]="filtered" multiTemplateDataRows>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                  <span class="disp-name">Role Id</span>
                </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex" class="name_field">
                  <div class="expand-icon">
                    <span class="food-name">
                      <span class="disp-name">{{element.id}} </span>
                    </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="display_name">
                <th mat-header-cell *matHeaderCellDef><span class="disp-name"> Role Name </span></th>
                <td mat-cell *matCellDef="let element; let i = dataIndex">
                  <span class="disp-name" (click)="enableEdit(element, 'display_name', i)"
                    *ngIf="!element.display_nameEdit">{{element.display_name}}</span>
                  <span *ngIf="element.display_nameEdit" class="edit-column">
                    <input [(ngModel)]="element.display_name" [placeholder]="element.display_name" class="edit-field" />
                    <button class="savebtn" (click)="saveNewValue(element, 'display_name', i)">Save</button>
                    <button class="cancelbtn" (click)="cancelEdit(element, 'display_name', i)">Cancel</button>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex">
                  <mat-icon class="padding-t-10 action-icon" (click)="editRole(element,i)"
                  >edit</mat-icon>
                  <mat-icon class="padding-t-10 action-icon" (click)="deleteRole(element,i)"
                    *ngIf="element.id != adminId">delete</mat-icon>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsList;sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsList;" class="element-row"
                [class.expanded-row]="element.isExpanded"></tr>
            </table>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Roles">
        <div class="sub-content">
          <div class="role-selector">
            <mat-form-field>
              <mat-label>Select Role</mat-label>
              <mat-select [(ngModel)]="role" name="dropdown" (selectionChange)="onChangeRole()">
                <mat-option *ngFor="let data of rolesData" [value]="data.id">
                  {{data.display_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <span class="title">Available Screens</span>
          </div>
          <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

              <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.name}}</span>
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef> Display Name </th>
                <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element">
                  <span>{{element.description}}</span>
                </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="read">
                <th mat-header-cell *matHeaderCellDef> Read </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox (change)="onPrivilegeChange(element, 'read')" class="example-margin"
                    [(ngModel)]="element.read"></mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="write">
                <th mat-header-cell *matHeaderCellDef> Write </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox (change)="onPrivilegeChange(element, 'write')" class="example-margin"
                    [(ngModel)]="element.write"></mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="admin">
                <th mat-header-cell *matHeaderCellDef> Admin </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox (change)="onPrivilegeChange(element, 'admin')" class="example-margin"
                    [(ngModel)]="element.admin"></mat-checkbox>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
