import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-selectinput',
  templateUrl: './selectinput.component.html',
  styleUrls: ['./selectinput.component.sass']
})
export class SelectinputComponent implements OnInit {
  @Input() placeholder:string = '';
  @Input() itemValue:string = '';
  @Input() isFocus:string = '0';
  @Output() action = new EventEmitter<any>();
  @Input() indexValue:number = -1;
  @Input() setsValue:number = -1;
  @Input() type:string = '';
  @Input() isDisabled:boolean = false;
  userQuestionUpdate = new Subject<string>();

  constructor() {
    this.userQuestionUpdate.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe((event:any) => {
        const obj = {
          index: this.indexValue,
          setIndex: this.setsValue,
          info: event.target.value,
          type: this.type
        };
        sessionStorage.setItem('selected_input', this.indexValue+'_'+this.setsValue+'_'+this.type);
        if(event.keyCode == '9') return;
        this.action.emit(obj);
      });
   }

  ngOnInit(): void {
  }

  onValueChange() {
    
  }

  onInputChange(event:any) {
   this.userQuestionUpdate.next(event)
  }

  onInputFocus(event:any) {
    if (this.isFocus == '1') {
      sessionStorage.setItem('selected_input', this.indexValue+'_'+this.setsValue+'_'+this.type);
      event.target.select()
    }
  }
}
