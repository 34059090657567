<div class="container">
    <!-- <app-consultantheader></app-consultantheader> -->
    <div>
        <div class="main-containerc">
            <h1>Prospects</h1>
            <div class="search-container">
                <!-- <span class="title">Clients</span> -->
                <div class="input-container">
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text" placeholder="Search name or email" class="search-input" autocomplete="off" (keyup)="searchResult($event)" (change)="searchResult($event)" />
                </div>
            </div>
            <h3 *ngIf="!isAdminRole">Start Consultation</h3>
            <div class="table-container">
                <table mat-table [dataSource]="startConsulatant" class="mat-elevation-z8" >                
                    <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> NAME </th>
                    <td mat-cell *matCellDef="let element"> 
                        <img class="avatar" [src]="element.name.profilePic" />
                        <div class="client-name">{{element.name.fullname}}</div> 
                    </td>
                    </ng-container>
                
                    <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> EMAIL </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.email}}
                    </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef> PHONE </th>
                        <td mat-cell *matCellDef="let element">
                        {{element.phone}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> STATUS </th>
                        <td mat-cell *matCellDef="let element"  style="font-style: italic; color:#949494">
                            {{element.status.text}}
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="isAdminRole ? assignConsultant(row)  : handleConsultation(row.status.id)"></tr>
                </table>
                <div *ngIf="startConsulatant.length === 0" class="emtmsg">No records found</div>
            </div>
            <h3 *ngIf="!isAdminRole">Awaiting Payment</h3>
            <div class="table-container awaiting" *ngIf="!isAdminRole">
                <table mat-table [dataSource]="awaitingPayment" class="mat-elevation-z8" >                
                    <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> NAME </th>
                    <td mat-cell *matCellDef="let element"> 
                        <img class="avatar" [src]="element.name.profilePic" />
                        <div class="client-name">{{element.name.fullname}}</div> 
                    </td>
                    </ng-container>
                
                    <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> EMAIL </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.email}}
                    </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef> PHONE </th>
                        <td mat-cell *matCellDef="let element">
                        {{element.phone}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> STATUS </th>
                        <td mat-cell *matCellDef="let element" style="font-style: italic; color:#F64646">
                            {{element.status}}
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div *ngIf="awaitingPayment.length === 0" class="emtmsg">No records found</div>
            </div>
        </div>
        <!-- <div class="todo-container">
            <div class="title">
                To-Do
                <span class="edit">EDIT</span>
            </div>
            <div class="todo-list">
                <div *ngIf="todolist.length === 0" style="padding-left: 20px;padding-top: 20px;">No Consultant available</div>
                <div *ngFor="let item of todolist" class="todo-card">
                    <div class="text-detail">
                        <span class="proposal-title">{{item.title}}</span>
                        <span class="proposer">{{item.proposer}}</span>
                    </div>
                    <button (click)="handleConsultation(item.id)" class="consultantbtn">Start Consultation</button>
                </div>
            </div>
        </div> -->
    </div>
</div>