import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { config } from '../config';
import { LogService } from '../services/log/log.service';
import { CommonService } from '../services/common/common.service';
import { UiService } from '../services/uiloader/uiloader.service';

@Component({
  selector: 'app-inbody',
  templateUrl: './inbody.component.html',
  styleUrls: ['./inbody.component.sass']
})
export class InbodyComponent implements OnInit {
  @Input() specificProspectId:string = '';
  inbodyDataComp:any = {};
  clientMeasurementDates:any = [];
  selectedDate:any= '';

  constructor(private httpService: HttpService,
    private logService: LogService,private ui: UiService,
    private common: CommonService) { }

  ngOnInit(): void {
    
  }
  
  ngOnChanges() {
    this.getClientMeasurements();
    this.getClientMeasurementsOnDates();
  }

  getClientMeasurementsOnDates() {
    if(this.specificProspectId) {
    this.httpService.postCall(`${config.apiUrl}/inbody/getclientmeasuredondates`, {"usertoken": this.specificProspectId}).subscribe(data => {
      data.measuredDates.forEach((date:any) => this.clientMeasurementDates.push({displayText : this.common.getInbodyFormattedDate(date,'-'),dateValue:date}));
    },
    err => {
      this.logService.logError(err);
    });
  }
  }

  getClientMeasurements() {
    if(this.specificProspectId) {
      this.httpService.postCall(`${config.apiUrl}/inbody/getclientmeasurements`, {"usertoken": this.specificProspectId}).subscribe(data => {
       this.inbodyDataComp = data;
        this.inbodyDataComp.formattedDateTimes = this.common.getInbodyFormattedDate(data.DATETIMES,'-');
        this.selectedDate = data.DATETIMES;
      },
      err => {
        this.logService.logError(err);
      });
    }
  }

  getMeasurementDataByDate() {
    this.ui.spin$.next(true);
    this.httpService.postCall(`${config.apiUrl}/inbody/getclientmeasurementsfordate`, {"usertoken": this.specificProspectId,"measuredOn":this.selectedDate}).subscribe(data => {
      this.inbodyDataComp = data;
      this.inbodyDataComp.formattedDateTimes = this.common.getInbodyFormattedDate(data.DATETIMES,'-');
      this.selectedDate = data.DATETIMES;
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
    });


  }

}
