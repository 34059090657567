import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidenavService {
  private sideNavToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sideNav: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  get isSideNavToggle() {
    return this.sideNavToggle.asObservable();
  }

  get sideNavData() {
    return this.sideNav.asObservable();
  }

  constructor(
    ) {  }

  handleSideNav(isToggle: any) {
      this.sideNavToggle.next(isToggle);
  }

  fetchSideNav(data:any) {
    // let data = [];
    // if (sideData.length === 4) {
    //   data = [{"id":1,"name":"", icon: 'home_outline', "display_name":"Home","description":"Marketing and news for all users.","sort":0,"menuitem":2},{"id":29,"name":"consultant-clients", icon: 'people_outline', "display_name":"Clients","description":"View all clients for logged in consultant.","sort":1,"menuitem":2},{"id":30,"name":"resources","display_name":"Resources", icon: 'info', "description":"View all Resouces.","sort":2,"menuitem":2},{"id":31,"name":"uploader", 
    //   icon: 'file_upload_outline',
    //   "display_name":"Uploader","description":"Manage Uploaders.","sort":4,"menuitem":2}]
    // } else {
    //   data = [{
    //     icon: 'home_outline',
    //     display_name: 'Home',
    //     name: ''
    //   },
    //   {
    //     icon: 'people_outline',
    //     display_name: 'Clients',
    //     name: 'company-clients'
    //   },
    //   {
    //     icon: 'info',
    //     display_name: 'Prospects',
    //     name: 'prospects'
    //   },
    //   {
    //     icon: 'file_upload_outline',
    //     display_name: 'Uploader',
    //     name: 'uploader'
    //   },
    //   {
    //     icon: 'settings_outline',
    //     display_name: 'Platform Setup',
    //     name: 'platform-setup'
    //   }];
    // }
    this.sideNav.next(data);
  }

}
