<div>
    <div class="col-33 padding-5">
        <div class="marginBottom">Name</div>
        <div>
            <app-selectinput placeholder="Name" type="exerciseName" [itemValue]="exerciseName" (action)="onAction($event)"></app-selectinput>
            <div style="color:#F00;">{{validationErrors['exerciseName']}}</div>
        </div>
    </div>
    <div class="select-ddl col-33 padding-5">
        <div>Exercise Category</div>
        <mat-form-field class="width-full">
            <mat-label>Select Category</mat-label>
          <mat-select [(ngModel)]="selected_exercise_category" (ngModelChange)="onChangeExerciseCategory()">
            <mat-option *ngFor="let data of exercisesCategories"  [value]="data.id">{{data.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div style="color:#F00;">{{validationErrors['exercisecategory']}}</div>
    </div>
    <div>
        <div class="col-33 inlineBlock padding-5">
            <app-selectinput placeholder="New exercise category..." type="newExerciseCategory" [itemValue]="newExerciseCategory" (action)="onAction($event)"></app-selectinput>
        </div>
        <div class="select-ddl col-33 inlineBlock padding-5">
            <mat-form-field class="width-full">
                <mat-label>Make subcategory of...</mat-label>
              <mat-select [(ngModel)]="selected_sub_exercise_category" (ngModelChange)="onChangeExerciseSubCategory()">
                <mat-option *ngFor="let data of exercisesCategories"  [value]="data.id">{{data.name}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div>Description</div>
        <textarea [(ngModel)]="description"></textarea>
    </div>
    <div class="multi_line_input col-66 padding-5">
        <div>Note</div>
        <textarea [(ngModel)]="note"></textarea>
    </div>
    <input #imageInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="onUploadImageSequence($event)" accept="image/*"/>
    <div class="link_upload"
        (click)="imageInput.click()"
    >
        UPLOAD IMAGE SEQUENCE
    </div>
    <div>
        <div *ngFor="let image of exerciseImageSequence"
            class="exerciseImageBlock"
        >
            <img [src]="image" class="exercise-image" />
        </div>
    </div>
    <div class="col-33">
        <div class="marginBottom">Video URL</div>
        <div>
            <app-selectinput placeholder="Video URL" type="videoUrl" [itemValue]="videoUrl" (action)="onAction($event)"></app-selectinput>
        </div>
    </div>
    <div class="submit_exercise">
        <button mat-stroked-button (click)="onResetExercise()" class="margin-right-5">Reset Changes</button>
        <button mat-raised-button color="primary" (click)="onSubmitExercise()" >Submit</button>
    </div>
</div>
