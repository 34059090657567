import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-useritem',
  templateUrl: './useritem.component.html',
  styleUrls: ['./useritem.component.sass']
})
export class UseritemComponent implements OnInit {
  @Input()  name: string = '';
  @Input() itemValue:string = '';
  @Input() dateValue:any = '';
  @Input() type:string = 'general';
  @Input() inputType:string = 'text';
  @Output() action = new EventEmitter<any>();
  @Input() radioOptions:any = [];
  @Input() userData:any = [];
  @Input() selectData:any = [];
  @Input() disabled:any = false;
  
  @ViewChild('usernameInput') usrFld: ElementRef;

  maxBirthDate:any;
  minStartDate:any;

  constructor() {
    const dt = new Date();
    dt.setFullYear(dt.getFullYear() - 10);
    this.maxBirthDate = dt;

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100);
    this.minStartDate = minDate;
   }

  ngOnInit(): void {
    this.dateValue = new FormControl(new Date(this.dateValue));
    console.log(this.type);
  }

  ngAfterViewInit() {
    if (this.usrFld) {
      this.usrFld.nativeElement.focus();
    }
  }

  formatNum(val:any) {
    return parseInt(val) < 10 ? `0${val}` : val;
  }

  getDateFormat(date:any) {
    return `${this.formatNum(date.getMonth() + 1)}/${this.formatNum(date.getDate())}/${date.getFullYear()}`;
  }

  onSaveData(isSave:boolean) {
    console.log(this.itemValue);
    if (this.type === 'multiinputs') {
      const specobj:any = {};
      this.userData.forEach((item:any) =>{
        specobj[item.key] = item.value;
      });

      const obj = {
        isSave,
        type:  this.type,
        data: specobj
      }
      this.action.emit(obj);
    } else {
      const obj = {
        isSave,
        value: this.type === 'date' ? this.getDateFormat(this.dateValue.value): this.itemValue
      }
      this.action.emit(obj);
    }
  }

}
