import { Component, OnInit, Input, Inject } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { ToasterService } from '../../services/toaster/toaster.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-exercisesuploadercategories',
  templateUrl: './exercisesuploadercategories.component.html',
  styleUrls: ['./exercisesuploadercategories.component.sass']
})
export class ExercisesuploadercategoriesComponent implements OnInit {
  selected_category:any = -1;
  default_value = -1;

  @Input() exercisesCategories:any;

  displayedColumns = ['name', 'parentcategory','exercisetemplates', 'delete'];
  datasource:any = [];
  
  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private ui: UiService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getExercisesTempPerCategory();
  }

  onChangeExerciseCategory(index:number) {
    const record = this.datasource[index];
    const url = `${config.apiUrl}/exercises/updateexercisetempcategory/${record.id}`;
    const reqObj = {
      parent_id: record.parent_id
    };

    this.httpService.putCall(url, reqObj).subscribe(record => {

    }, err => {

    });

  }

  onFilterExerciseCategory() {
    this.getExercisesTempPerCategory(); 
  }

  getExercisesTempPerCategory() {
    this.ui.spin$.next(true);
    const categoryId = this.selected_category === -1 ? '' : this.selected_category;
    const url = `${config.apiUrl}/exercises/getexercisetempspercategory/${categoryId}`;
    this.httpService.getCall(url).subscribe(result => {
      this.datasource = result;
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
    });
  }

  deleteHandle(id: number, index: number) {
    let dialogRef = this.dialog.open(NavCopyDialog, { data: "" });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ui.spin$.next(true);
        const url = `${config.apiUrl}/exercises/deleteexercisetempcategory/${id}`;
        this.httpService.deleteCall(url, {}).subscribe(result => {
          const arr = this.datasource;
          arr.splice(index, 1);
          this.datasource = arr;
          this.datasource = this.datasource.filter((record: any) => {
            return id != record.id;
          });
          this.ui.spin$.next(false);
        }, err => {
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
        });
      }
    });
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <h1 mat-dialog-title>Delete selected category</h1>
  <div mat-dialog-content class="dialogContent">
      <p>Are you sure? This cannot be undone.</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
  styleUrls: ['/../../exercisesuploader/exercisesuploader.component.sass']
})
export class NavCopyDialog {
  constructor(
    public dialogRef: MatDialogRef<NavCopyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}