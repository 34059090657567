<div class="main-content">
    <div>
      <div class="inlineBlock user-search-count">
        <span class="title">Clients</span>
        <span class="user-count">{{pagination.totalCount}}</span>
        <span class="search-user">
            <mat-form-field appearance="outline">
                <mat-label>Search name or email</mat-label>
                <input matInput placeholder="Search name or email" (keyup)="onSearchUser($event)" autocomplete="off">
                <mat-icon matPrefix>search</mat-icon>
              </mat-form-field>
        </span>
      </div>
      <div class="inlineBlock add-user">
        <span>
          <button mat-raised-button color="primary"
          [style.background-color]="commonService.getCustomerCustomColor()"
          (click)="addUser()"
          >
            <mat-icon>add_circle</mat-icon>
            Add User</button>
        </span>
      </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource.data" class="mat-elevation-z" 
          matSort (matSortChange)="onTableSort($event)" matSortDisableClear
        >

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="profilePic">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.isProfilePicExisted"><img class="avatar" [src]="element.profilePic" /></span>
                <span class="avatarlabel" *ngIf="!element.isProfilePicExisted && element.userChars.length > 0">{{element.userChars}}</span>
                <span *ngIf="!element.isProfilePicExisted && element.userChars.length === 0"><img class="avatar" [src]="element.profilePic" /></span>
              </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element">
                 {{element.name}} <br/>
                 <span class="label" (click)="specificUser(element)">{{element.email}}</span>
                 </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="roles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
              <td mat-cell *matCellDef="let element">
              <div *ngFor="let item of element.roles" style="display: inline-block;">
                <span class="label">{{item}}</span></div>
              </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="consultantName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Consultant </th>
              <td mat-cell *matCellDef="let element"> {{element.consultantName}} </td>
            </ng-container>

            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
              <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
            </ng-container>

            <ng-container matColumnDef="userStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element">  
                <mat-slide-toggle class="status"(change)="statusUpdate($event, element.id)" [checked]="element.userStatus"></mat-slide-toggle>
              </td>
            </ng-container>
            <ng-container matColumnDef="settings">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let element">  
                <button mat-raised-button class="settings"  (click)="navigateToSettings(element)"><mat-icon>build</mat-icon></button>
            </ng-container>          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
          </table>
          <span *ngIf="dataSource.length == 0" class="emtmsg">No records found</span>
          <mat-paginator *ngIf="pagination.totalCount>10"
                [length]="pagination.count"
                [pageIndex]="pagination.pageIndex"
                [pageSize]="pagination.perPage"
                [pageSizeOptions]="[10, 25, 50, 100, 200]"
                (page)="getServerData($event)"
          showFirstLastButtons></mat-paginator>
    </div>
</div>