import { Component, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { config } from '../../config';
import { HttpService } from '../../services/http/http.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import { LogService } from '../../services/log/log.service';

let mealsTimer:any;

@Component({
  selector: 'app-mealsuploaderedit',
  templateUrl: './mealsuploaderedit.component.html',
  styleUrls: ['./mealsuploaderedit.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MealsuploadereditComponent implements OnInit {
  @Input() mealsCategories:any;

  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };

  searchText = '';

  isAllSelected = false;

  columnsToDisplay = ['name', 'category', 'description', 'note', 'video_url'];
  innerDisplayedColumns = ['name', 'cat_name', 'calories', 'protein', 'carbs', 'fat', 'unit', 'qty'];

  innerTables:any;
  innerSort:any;

  deletehighlight = false;

  dataSource:any = new MatTableDataSource();

  expandedElement: any | null;

  constructor(private cd: ChangeDetectorRef,
    private httpService: HttpService,
    private toasterService: ToasterService,
    private ui: UiService,
    private logService: LogService) { }

  ngOnInit(): void {
    this.getMealsTemplatesList()
  }

  getMealsTemplatesList() {
    const url = `${config.apiUrl}/meals/getmealtemplates`;
    const reqBody = {
      "orderByOn":"name",
      "orderBy":"ASC",
      "limit":this.pagination.perPage,
      "fromIndex":this.pagination.pageIndex,
      "searchtext": this.searchText
    };
    this.httpService.postCall(url, reqBody).subscribe(result => {
      this.dataSource.data = result.rows.map((item:any) => ({
        ...item,
        isExpanded: false
      }
      ));
      this.pagination.count = result.count;
    }, err => {

    });
  }

  getServerData(e:any) {
    console.log(e.pageIndex);
    console.log(e.pageSize);
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.getMealsTemplatesList();
  }

  specificRecord(row:any, index: number) {
    console.log(row);
    const url = `${config.apiUrl}/meals/getmealtemplatefoods/${row.id}`;
    this.httpService.getCall(url).subscribe(result => {
      this.dataSource.data[index].inner = result.mealtemplatefoods.map((item:any) => ({
        ...item,
        mealId: row.id,
        isExpanded: false
      }));
    }, err => {

    });
  }

  enableEdit(row:any, field:any, i:number) {
    const result:any = this.dataSource;
    result.data[i][`${field}Model`] = result.data[i][`${field}`];
    result.data[i][`${field}Edit`] = true;
  }

  saveNewValue(row:any, key:string, i:number) {
    const url = `${config.apiUrl}/meals/updatemealtemplate/${row.id}`;
    let req:any = {
    }
    if (key === 'category') {
      req.mealcategory = row.mealtemplatecategory_id;
    } else {
      req[key] = row[`${key}Model`];
    }
    this.ui.spin$.next(true);
    this.httpService.putCall(url, req).subscribe(result => {
      if (key === 'category') {
        const records = this.mealsCategories.filter((item:any) => item.id === row.mealtemplatecategory_id);
        this.dataSource.data[i].mealtemplatecategory.name = records[0].name;
      } else {
        this.dataSource.data[i][key] = req[key];
      }
      this.cancelEdit(row, key, i);
      this.ui.spin$.next(false);
      console.log(result);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      console.log(err);
    });
  }

  cancelEdit(row:any, field:string, i:number = 5) {
    const result:any = this.dataSource;
    result.data[i][`${field}Edit`] = false;
  }

  deleteMealTemplates() {
    this.ui.spin$.next(true);
    const payload = this.dataSource.data.filter((obj:any) => obj.checked).map((obj:any) => obj.mealtemplatecategory_id);
    this.httpService.putCall(`${config.apiUrl}/meals/deletemealtemplates`, {meal_ids: payload}).subscribe((result:any) => {
      this.dataSource.data = this.dataSource.data.filter((val: any) => !val.checked);
      this.masterSelection();
      this.ui.spin$.next(false);
      this.getMealsTemplatesList();
    }, (err:any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      this.ui.spin$.next(false);
    });
  }

  deleteFoodItems(index: number) {
    this.ui.spin$.next(true);
    const payload = this.dataSource.data[index].inner.filter((obj:any) => obj.checked).map((obj:any) => obj.id);
    const url = `${config.apiUrl}/meals/deletemealsfoods/${this.dataSource.data[index].id}`;
    this.httpService.putCall(url, {food_ids: payload}).subscribe(item => {
      this.dataSource.data[index].inner = this.dataSource.data[index].inner.filter((item:any) => payload.indexOf(item.id) === -1);
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
    });
  }

  masterSelection() {
    let highlgt = this.dataSource.data.find((o:any) => o.checked == true);
    highlgt ? this.deletehighlight = true : this.deletehighlight = false;
  }

  setSelection(ev:any, row:any='') {
    let overall = true;
    this.dataSource.data = this.dataSource.data.map((ob:any) => {
      if(row && ob.id === row.id) ob.checked = ev.checked;
      if(!row) ob.checked = ev.checked;
      if(!ob.checked) overall = false;
      return ob;
    });
    this.masterSelection();
    this.isAllSelected = overall;
  }

  onFoodSetSelection(event:any, index:number) {
    const checkedRecords = this.dataSource.data[index].inner.filter((item:any) => item.checked);
    if (checkedRecords.length > 0) {
      this.dataSource.data[index].deletehighlight = true;
    } else {
      this.dataSource.data[index].deletehighlight = false;
    }

    this.dataSource.data = this.dataSource.data.filter((item:any) => item);
    console.log(event);
  }

  searchRecords(text:string) {
    this.searchText = text;
    this.pagination.pageIndex = 0;
    this.getMealsTemplatesList();
  }

  searchResult(ev:any) {
    clearTimeout(mealsTimer);
    mealsTimer = setTimeout(() => {
      this.searchRecords(ev.target.value);
    }, 1000);
  }

  onFoodSelection(ev:any) {
    const record = this.dataSource.data[ev.selectedIndex];
    const url = `${config.apiUrl}/meals/addfoodtomealtemplate/${record.id}`;
    const obj = {
      foods:[ev]
    };
    debugger;
    this.httpService.putCall(url, obj).subscribe(item => {
      this.dataSource.data[ev.selectedIndex].inner.push(ev);
      this.dataSource.data[ev.selectedIndex].inner = this.dataSource.data[ev.selectedIndex].inner.filter((record:any) => record);
    }, err => {
      console.log(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
    });
  }

  onMealFoodInnerSelect(ev:any, innerIndex: number, index:number) {
    const url = `${config.apiUrl}/meals/getmealtemplatefoods/${ev.mealId}/${ev.id}`;
    this.httpService.getCall(url).subscribe(result => {
      this.dataSource.data[index].inner[innerIndex].foods = result.mealtemplatefoods[0];
    }, err => {

    });
  }

}
