<div class="main-content">
  <div>
    <div class="inlineBlock user-search-count">
      <span class="title">Reports</span>
      <span class="user-count">{{dataSource.length}}</span>
      <span class="search-user">
        <mat-form-field appearance="outline">
          <mat-label>Search name or email</mat-label>
          <input matInput placeholder="Search name or email" (keyup)="onSearchUser($event)" autocomplete="off">
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
      </span>
    </div>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z">

      <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->


      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="clientData">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a class="label" (click)="downloadClientData(element,'downloadconsultantclients')">Download Clients Data</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="clientProgress">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <a class="label"  (click)="downloadClientData(element,'downloadcientsprogress')">Download Clients Progress</a>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <span *ngIf="!dataSource.length" class="emtmsg">No records found</span>
    <mat-paginator *ngIf="pagination.totalCount>10" [length]="pagination.count" [pageIndex]="pagination.pageIndex"
      [pageSize]="pagination.perPage" [pageSizeOptions]="[10, 25, 50, 100, 200]"  (page)="getServerData($event)" showFirstLastButtons></mat-paginator>
  </div>
</div>