import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
const headers= new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/pdf');

@Injectable()
export class HttpService {
  configUrl = 'assets/config.json';
  
  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    return throwError(
        error);
  }

  getCall(url:string) {
    return this.http.get<any>(url)
    .pipe(
      catchError(this.handleError) // then handle the error
    );
  }

  getCallFile(url:string, type?:any) {
    return this.http.get<any>(url, {headers, responseType: type ? type : 'blob' as 'json'})
    .pipe(
      catchError(this.handleError) // then handle the error
    );
  }

  postCall(url:string, data:any) {
    return this.http.post<any>(url, data)
    .pipe(
      catchError(this.handleError)
    );
  }

  putCall(url:string, data:any) {
    return this.http.put<any>(url, data)
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteCall(url:string, options:any) {
    return this.http.delete<any>(url, options)
    .pipe(
      catchError(this.handleError)
    );
  }
}
