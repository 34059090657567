import { Component, OnInit, Input } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { CommonService } from '../../services/common/common.service';
import { HttpService } from '../../services/http/http.service';
import { config } from '../../config';
import { LogService } from '../../services/log/log.service';
import { AppConstants } from '../../constants/appConstants';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-exercise-plans-uploader-create',
  templateUrl: './exercise-plans-uploader-create.component.html',
  styleUrls: ['./exercise-plans-uploader-create.component.sass']
})
export class ExercisePlansUploaderCreateComponent implements OnInit {
  @Input() exerciseCategories:any;

  searchTree:any;

  exerciseName:string = '';
  newExerciseCategory:string = '';
  newPlanName:string = '';

  instructions:string = '';
  description:string = '';

  selected_sub_exercise_category:number = -1;
  selected_exercise_category:number = -1;

  obtainedDataSource:any = [];

  templateData:any = [];

  srr = {
    set: '',
    reps: '',
    rest: '',
    speed: ''
  };

  constructor(private commonService: CommonService,
    private httpService: HttpService,
    private toasterService: ToasterService,
    private logService:LogService) { }

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      isDragDisabled: node.isDragDisabled,
      exeData: node
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node:any) => node.level,
    (node:any) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: any) => node.expandable;

  ngOnInit(): void {
    this.formatTreedata();
  }

  formatTreedata() {
    this.httpService.getCall(`${config.apiUrl}/exercise/getexercisecategorytabtree`).subscribe(result => {
      const data = result.exerciseplancategoryindex;
      const parsed = JSON.parse(data);
      this.dataSource.data = parsed;
      this.obtainedDataSource = parsed;
    }, err => {
      this.logService.logError(err);
    });
  }

  onAction(event:any) {
    if (event.type === 'exerciseName') {
      this.exerciseName = event.info;
    } else if (event.type === 'newExerciseCategory') {
      this.newExerciseCategory = event.info;
    } else if (event.type === 'newPlanName') {
      this.newPlanName = event.info;
    }
  }

  onChangeExerciseCategory() {
    this.newExerciseCategory = '';
    this.selected_sub_exercise_category = -1;
  }

  onChangeExerciseSubCategory() {
    this.selected_exercise_category = -1;
  }

  onResetExercisePlan() {
    this.exerciseName = '';
    this.description = '';
    this.instructions = '';
    this.newExerciseCategory = '';
    this.selected_exercise_category = -1;
    this.selected_sub_exercise_category = -1;
    this.templateData = [];
  }

  getReqExercisePlans() {
    return this.templateData.map((item:any) => ({
      name: item.planName,
      days: item.weeks || '',
      exercises: item.exercises.map((rec:any) => {
        return {
          exercisetemplate_id: rec.id,
          catname: rec.catname,
          catid: rec.catid,
          name: rec.name,
          sets: rec.sets,
          reps: rec.reps,
          rest: rec.rest,
          speed: rec.speed
        }
      })
    }));
  }

  onSubmitExercisePlan() {
    const url = `${config.apiUrl}/exerciseplans/createexerciseplantemplate`;
    const reqBody = {
      name: this.exerciseName,
      category: this.newExerciseCategory || this.selected_exercise_category,
      subcat: this.selected_sub_exercise_category,
      description: this.description,
      instructions: this.instructions,
      exerciseplans: this.getReqExercisePlans()
    };

    this.httpService.postCall(url, reqBody).subscribe(result => {
      this.onResetExercisePlan();
      this.toasterService.show('success', 'Success', 'Plan created successfully');
    }, err => {
      this.toasterService.show('error', 'Error', 'Error while creating plan');
    });
  }

  onExerciseAddPlan() {
    const newName = `New Plan ${this.templateData.length + 1}`;
    this.templateData.push({planName: newName, exercises: [],
      days: AppConstants.weeksKeys.map((day)=> { return {value: false, key: day}}),
    });
  }

  deleteExercise(id:any, planId:any) {

  }

  searchExercise(ev:any) {
    let filteredTreeData = [];
    if(ev.target.value){
      filteredTreeData = this.commonService.objectFilter(this.obtainedDataSource, ev.target.value);
      this.dataSource.data = filteredTreeData;
    } else {
      this.dataSource.data = this.obtainedDataSource;
      this.treeControl.collapseAll();
    }
  }

  copyExercise(exercise:any, planid:any) {

  }

  drop(event: any, index:number) {
    //event.item.data.exeData
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.templateData[index].exercises.push(event.item.data.exeData);
      // transferArrayItem(
      //   event.previousContainer.data,
      //   event.container.data,
      //   event.previousIndex,
      //   event.currentIndex,
      // );
    }
  }

  deletePlan(id:number) {

  }

  copyExePlan(item:any) {

  }

  updateAllRecords() {
    this.templateData.forEach((records:any) => {
      records.exercises.forEach((item:any) => {
        if (item.cat_id !== -1) {
          item.sets = this.srr.set;
          item.reps = this.srr.reps;
          item.rest = this.srr.rest;
          item.speed = this.srr.speed;
        }
      });
    });

    this.srr = {
      set: '',
      reps: '',
      rest: '',
      speed: ''
    };
  }

  updateOverallSRR(index:any) {
    const record = this.templateData[index];
    record.exercises.forEach((item:any) => {
      if (item.cat_id !== -1) {
        item.sets = record.oSet;
        item.reps = record.oReps;
        item.rest = record.oRest;
        item.speed = record.oSpeed;
      }
    });
    record.oSet = '';
    record.oReps = '';
    record.oRest = '';
    record.oSpeed = '';
  }

  onSelectAllDays(index:any) {
      const isAllWeeksSelected = !this.templateData[index].isAllWeeksSelected;
      const checkedDays:any = [];

      this.templateData[index].days.forEach((item:any) => {
        item.value = isAllWeeksSelected;
        if (item.value) {
          checkedDays.push(item.key);
        }
      });
      this.templateData[index].isAllWeeksSelected = isAllWeeksSelected;
      this.templateData[index].weeks = checkedDays.join(',').toLowerCase();
  }

  onWeekSelection(index:any) {

    const data = this.templateData[index];
    const checkedDays:any = [];
    data.days.forEach((item:any) => {
      if (item.value) {
        checkedDays.push(item.key);
      }
    });
    if (checkedDays.length === 7) {
      data.isAllWeeksSelected = true;
    } else {
      data.isAllWeeksSelected = false;
    }
    data.weeks = checkedDays.join(',').toLowerCase();
  }
}
