import { Component, OnInit, Inject } from '@angular/core';
import { UiService } from '../services/uiloader/uiloader.service';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';
import { config } from '../config';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-surveysummary',
  templateUrl: './surveysummary.component.html',
  styleUrls: ['./surveysummary.component.sass']
})
export class SurveysummaryComponent implements OnInit {
  surveyDetails:any = [];
  constructor(private ui:UiService,
    private httpService: HttpService,
    private logService: LogService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log(this.data.person.userId);
    }

  ngOnInit(): void {
    this.ui.spin$.next(true);
    this.httpService.getCall(`${config.apiUrl}/surveysfeedback/surveysfeedback/${this.data.person.userId}`).subscribe(data => {
      this.surveyDetails = data.success.survey_details;
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

}
