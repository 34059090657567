import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from './services/sidenav/sidenav.service';
import { ProgressbarService } from './services/progressbar/progressbar.service';
import { AuthService } from './auth/auth.service';
import { Location } from '@angular/common';
import { CommonService } from './services/common/common.service';
import { loadSpace } from '@usersnap/browser'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.common.getIsPendingChanges()) {
      return false;
    }
    return true;
  }
  title = 'my-app';
  isSideNavToggle$: any = false;
  isLoggedIn$: any = false;
  isDisplaySpinner$: any = false;
  sideNavData$: any = [];
  selectedItem:string = '';
  sub: any = {};
  customBgColor = '#000';
  companyDetails$: any = {};
  logoUrl:any ='';
  constructor(private sidenavService: SidenavService,
    private progressbarService: ProgressbarService,
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private common: CommonService) { 

    }

  ngOnInit() {
    const spaceApiKey = '55111a3c-30fa-485b-8ed0-d50c6389ae7a'
    loadSpace(spaceApiKey).then((api) => {
      api.init()
    })
    
    this.sidenavService.isSideNavToggle.subscribe(x =>{
      this.isSideNavToggle$ = x;
    });
    this.authService.sideMenuSelected.subscribe(x => {
      this.selectedItem = x;
    });
    this.authService.onCompanyDetails.subscribe(result => {
      this.companyDetails$ = result;
      this.logoUrl = result.logo;
    });
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isDisplaySpinner$ = this.progressbarService.isDisplaySpinner;
    this.sideNavData$ = this.sidenavService.sideNavData;
    this.setSelectedSideMenu();
    this.logoUrl = this.companyDetails$.logo || "https://www.totalhealthandfitness.com/wp-content/themes/totalhealthfitness/build/img/Logo.svg"
  }

  setSelectedSideMenu() {
    const path = this.location.path();
    if (path !== '/login') {
      let specificPath = path.replace('/', '');
      if (specificPath === 'dashboard') {
        specificPath = '';
      }
      this.selectedItem = specificPath;
    }
  }

  redirect(path:string, id:number,displayName:string) {
    if(displayName.toLowerCase().trim() === 'program binder'){
      window.open("/assets/NewClientBinder_2018_Education.pdf", "_blank");
      return;
    }
    this.selectedItem = path;
    if (id !== 9999) {
      this.router.navigate([`/${path}`]);
    } else {
      this.authService.switchBackToAdmin();
    }
  }

}
