<div *ngIf="specificProspectId">
    <div class="dropdown-text">
    <mat-form-field appearance="fill" class="form_field">
        <mat-label>Measured On:</mat-label>
        <mat-select   [(ngModel)]="selectedDate" (ngModelChange)="getMeasurementDataByDate()">
          <mat-option *ngFor="let d of clientMeasurementDates" [value]="d.dateValue">
            {{d.displayText}}
        </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <h1 class="inbody-title">InBody</h1>
    <div>
        <div class="inbody-info">
            <div>Id</div>
            <div>{{inbodyDataComp.NAME}}</div>
        </div>
        <div class="inbody-info">
            <div>Height</div>
            <div>{{inbodyDataComp.HT}}</div>
        </div>
        <div class="inbody-info">
            <div>Age</div>
            <div>{{inbodyDataComp.AGE}}</div>
        </div>
        <div class="inbody-info">
            <div>Gender</div>
            <div>{{inbodyDataComp.GENDER}}</div>
        </div>
        <div class="inbody-info">
            <div>Test Date / Time</div>
            <div>{{inbodyDataComp ? inbodyDataComp.formattedDateTimes : ''}}</div>
        </div>
    </div>
    <div class="inbody-report">
        <h3>Body Composition Analysis</h3>
        <div class="bcatable">
            <div class="bcaheader">
                <div class="bcatitle lbl-values">
                    <div class="hidefield">label</div>
                </div>
                <div class="bcatitle val-col">Values</div>
                <div class="bcatitle centeralign">Total Body Water</div>
                <div class="bcatitle centeralign">Lean Body Mass</div>
                <div class="bcatitle weight-col centeralign">Weight</div>
            </div>
            <div class="inbody-data">
                <div class="lbl-values">Intercellular Water <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.ICW: ''}}</div>
                <div class="brdrt" style="width:23%;"><div class="hidefield">empty</div></div>
                <div class="brdrt" style="width:22%;"><div class="hidefield">empty</div></div>
                <div class="brdrt" style="width:10%;"><div class="hidefield">empty</div></div>
            </div>
            <div class="inbody-data">
                <div class="lbl-values">Extracellular Water <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.ECW : ''}}</div>
                <div class="centeralign brdrt brdbt" style="width:23%;">{{inbodyDataComp ? inbodyDataComp.TBW : ''}}</div>
                <div class="brdrt" style="width:22%;"><div class="hidefield">empty</div></div>
                <div class="brdrt" style="width:10%;"><div class="hidefield">empty</div></div>
            </div>
            <div class="inbody-data">
                <div class="lbl-values">Dry Lean Mass <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp? inbodyDataComp.DM : ''}}</div>
                <div class="brdbt" style="width:23%;"><div class="hidefield">empty</div></div>
                <div class="centeralign brdrt brdbt" style="width: 22%;">{{inbodyDataComp ? inbodyDataComp.FFM : ''}}</div>
                <div class="brdrt" style="width:10%;"><div class="hidefield">empty</div></div>
            </div>
            <div class="inbody-data">
                <div class="lbl-values">Body Fat Mass <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.BFM : ''}}</div>
                <div class="brdbt" style="width:23%;"><div class="hidefield">empty</div></div>
                <div class="brdbt" style="width:22%;"><div class="hidefield">empty</div></div>
                <div class="brdrt brdbt centeralign" style="width: 10%;">{{inbodyDataComp? inbodyDataComp.WT : ''}}</div>
            </div>
        </div>
        <h3>Muscle-Fat Analysis</h3>
        <div>
            <div class="rangeheader">
                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                <div class="rcol-3">-</div>
                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
            </div>
            <div class="range-values">
                <div class="lbl-values">Weight <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.WT : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">SMM <span>(lbs)</span><span class="desclbl">Selected Muscle Mass</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.SMM : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">Body Fat Mass <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.BFM : ''}}</div>
            </div>
        </div>
        <h3>Obesity Analysis</h3>
        <div>
            <div class="rangeheader">
                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                <div class="rcol-3">-</div>
                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
            </div>
            <div class="range-values">
                <div class="lbl-values">BMI <span>(kg/m2)</span><span class="desclbl">Body Mass Index</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.BMI : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">PBF <span>(%)</span><span class="desclbl">Percent Body Mass</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.PBF : ''}}</div>
            </div>
        </div>
        <h3>Segmental Lean Analysis</h3>
        <div>
            <div class="rangeheader">
                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                <div class="rcol-3">-</div>
                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
            </div>
            <div class="range-values">
                <div class="lbl-values">Right Arm <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LRA : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">Left Arm <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LLA : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">Trunk <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LT : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">Left Leg <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LLL : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">Right Leg <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.LRL : ''}}</div>
            </div>
        </div>
        <h3>ECW/TBW Analysis</h3>
        <div>
            <div class="rangeheader">
                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                <div class="rcol-3">-</div>
                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
            </div>
            <div class="range-values">
                <div class="lbl-values">ECW/TBW </div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.WED : ''}}</div>
            </div>
        </div>
        <h3>Body Composition History</h3>
        <div>
            <div class="rangeheader">
                <div class="bcatitle lbl-values"><div class="hidefield">label</div></div>
                <div class="rcol-2"><mat-icon>arrow_drop_down</mat-icon></div>
                <div class="rcol-3">-</div>
                <div class="rcol-4"><mat-icon>arrow_drop_up</mat-icon></div>
                <div style="clear: both;"></div>
            </div>
            <div class="range-values">
                <div class="lbl-values">Weight <span>(lbs)</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.WT : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">SMM <span>(lbs)</span><span class="desclbl">Selected Muscle Mass</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.SMM : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">PBF <span>(%)</span><span class="desclbl">Percent Body Mass</span></div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.PBF : ''}}</div>
            </div>
            <div class="range-values">
                <div class="lbl-values">ECW/TBW </div>
                <div class="val-col">{{inbodyDataComp ? inbodyDataComp.TBW : ''}}</div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!specificProspectId" class="err-info">
    <mat-card>User does not have Inbody Measurements</mat-card>
</div>