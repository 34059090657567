import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProgressbarService {
  private displaySpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isDisplaySpinner() {
    return this.displaySpinner.asObservable();
  }

  constructor(
  ) {}

  handleSpinner(display: any) {
      this.displaySpinner.next(display);
  }

}
