import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

import { AppConstants } from '../constants/appConstants';
import { CommonService } from '../services/common/common.service';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import {CdkDropList, moveItemInArray, copyArrayItem, transferArrayItem, CdkDragExit, CdkDragDrop} from '@angular/cdk/drag-drop';
import { FormArray, FormControl } from '@angular/forms';
import { LogService } from '../services/log/log.service';
import { UiService } from '../services/uiloader/uiloader.service';
import {jsPDF} from 'jspdf';
import html2canvas from "html2canvas";
import { AuthService } from '../auth/auth.service';
import { Location } from '@angular/common';
import { ToasterService } from '../services/toaster/toaster.service';
import { CookieService } from 'ngx-cookie-service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SortPipe } from '../sort.pipe';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

let sessionTimeout:any;
let nutritionTimeout:any;

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.sass']
})

export class SessionComponent implements OnInit {

  newPlanName:string = '';
  selectedTabIndex:number = 0;
  nutritionCtrl:any;
  weeks:any = AppConstants.weekConstants;
  mealType:any = AppConstants.mealConstants;
  meal_type_id:string = '';
  index:number = 0;
  specificData:any = {};
  sessionId:any;
  userId:any;
  treeData:any = [];
  copyPlanObject:any ={
    client:'sameClinet',
    selectedUser:'',
    selectedSession:'',
    clientDetais:{},
    sessions: [],
    users:[],
  };
  totalMicronutrients:any ={};
  isManualFood:boolean =true;
  customFoodName:any=''
  isAllWeeksSelected = false;

  errorMsg:string = '';

  nutrition:any = [];

  selectedMealIndex = 0;
  mealPlans:any = [];
  meals:any = [];

  headerData:any = {};

  transferringItem: string | undefined = undefined;

  itemDisabled:boolean = true;

  sideTotal = {
    calories: 0,
    protein: 0,
    carbs: 0,
    fat: 0,
    protein_pct: 0,
    carbs_pct: 0,
    fat_pct: 0
  }
  hideTitle: any = false;

  sessionPlansOptions = [];

  specificSessionPlan = [];
  fixedDiv = false;
  drops:any[] = [];

  updateWaterCount(mealPlanIndex:any,mealsIndex:any,item: any, count: any) {
    this.httpService.putCall(`${config.apiUrl}/updatewaterformeal/${mealPlanIndex}/${item.id}`, { "water_cnt": count }).subscribe((result) => {
      this.meals[mealsIndex].water_count = Array.from({ length: count});
    }, err => {
      this.ui.spin$.next(false);
    });
  }

  addDrop(item:any,mealsIndex:any) {
  const meal_index =this.mealPlans[this.selectedMealIndex].id;
  const count = item.water_count.length+1;
  this.updateWaterCount(meal_index,mealsIndex,item,count); 
  }

  deleteDrop(item: any,mealsIndex: any){
    const meal_index =this.mealPlans[this.selectedMealIndex].id;
    const count = item.water_count.length-1;
    this.updateWaterCount(meal_index,mealsIndex,item,count);
  }

  saveItem(data:any, mealId:number) {
    let obj = { };
    let  url = `${config.apiUrl}/addmealtemplatefoodstosession`;

    if (data.nutritionprogram) {
      obj = {
        id: data.id,
        session_id: this.sessionId,
        nutritionprogram: data.nutritionprogram,
        client_id: this.specificData.clientId
      };
    } else if (data.mealtemplate) {
      if (mealId === -1) {
        this.toasterService.show('error', 'Info', 'You can not add meal template items with out meal plan');
        return false;
      }
      obj = {
        id: data.id,
        session_id: this.sessionId,
        mealtemplate: data.mealtemplate,
        client_id: this.specificData.clientId,
        meal_id: mealId
      };
    } else if (data.recipe) {
      if (mealId === -1) {
        this.toasterService.show('error', 'Info', 'You can not add recipe items with out meal plan');
        return false;
      }
      obj = {
        id: data.id,
        session_id: this.sessionId,
        client_id: this.specificData.clientId,
        recipe: data.recipe,
        meal_id: mealId
      };
    } else if (data.foodData) {
      if (mealId === -1) {
        this.toasterService.show('error', 'Info', 'You can not add food items with out meal plan');
        return false;
      }
      obj = {
        id: data.id,
        session_id: this.sessionId,
        client_id: this.specificData.clientId,
        meal_id: mealId,
        mealplan_id: data.mealplan_id
      };
      const foodSpecificData = JSON.parse(data.foodData);

      foodSpecificData.calories = this.commonService.getFixedValue(foodSpecificData.calories);
      foodSpecificData.carbs = this.commonService.getFixedValue(foodSpecificData.carbs);
      foodSpecificData.protein = this.commonService.getFixedValue(foodSpecificData.protein);
      foodSpecificData.fat = this.commonService.getFixedValue(foodSpecificData.fat);

      obj = {...obj, ...foodSpecificData};
      url = `${config.apiUrl}/addmealfood`;
    }
    this.ui.spin$.next(true);
    this.httpService.postCall(url, obj).subscribe(item => {
      if (data.foodData) {
        this.meals[data.draggedIndex].mealfoods.push(item);
      } else {
        this.errorMsg = '';
        const isRecordVaries = item[0].mealplans.length !== this.mealPlans.length;
        this.mealPlans = item[0].mealplans;
        if (isRecordVaries) {
          this.selectedMealIndex = this.mealPlans.length - 1;
        }
      }
      this.setWeeksMeals();
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', err?.error?.error);
    });
    return true;
  }

  drop(event: any,  index:number) {
    sessionStorage.removeItem("selected_input");
    if (event.previousContainer.id !== event.container.id) {
      if (event.item.data) {
        copyArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        //console.log(event.item.data);
        if (index !== -1) {
          event.item.data.mealplan_id = this.meals[index].mealplan_id;
          event.item.data.draggedIndex = index;
        }
        this.saveItem(event.item.data, index !== -1 ? this.meals[index].id : -1);
      } else {
        const reqObj = {
          deletefrom: {
            id: event.previousContainer.data[event.previousIndex].id,
            meal_id: event.previousContainer.id
          },
          addinto: {
            meal_id: event.container.id,
            display_order: event.currentIndex+1
          }
         }
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        this.httpService.putCall(`${config.apiUrl}/reorderfoodsbwmeals`, reqObj).subscribe((result) => {
          const mealsData = this.meals;
          mealsData[index].mealfoods = result.foods;
          this.mealPlans[this.selectedMealIndex].meals = mealsData;
          this.setWeeksMeals();
         }, err => {
          this.ui.spin$.next(false);
         });
        
      }
    } else {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.reorderMealFoods(this.meals[index]);
    }

    this.transferringItem = undefined;
  }

  reorderMealFoods(data:any) {
    const url = `${config.apiUrl}/reordermealfoods/${data.id}`;
    const foodsData = data.mealfoods;
    const arr = foodsData.map((item:any, index:number) => ({
      id: item.id,
      display_order: index + 1
    }));
    const obj = {
      foods: arr
    };
    this.httpService.putCall(url, obj).subscribe(result => {

    }, err => {

    });
  }

  onOrderChangeNutrition(event:any, mealIndex:number, foodIndex:number) {
    console.log(event);
  }

  entered(e:any) {
    this.transferringItem = undefined;
  }

  exited(e: any) {
    this.transferringItem = e.item.data;
  }

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      nutritionprogram: node.nutritionprogram,
      mealtemplate: node.mealtemplate,
      recipe: node.recipe,
      foodData: (!node.nutritionprogram && !node.mealtemplate && !node.recipe) ? JSON.stringify(node) : null
    };
  };

  treeControl = new FlatTreeControl<any>(
    (node:any) => node.level,
    (node:any) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  obtainedDataSource = [];
  diningout_url:any;
  substitutionguide_url:any;

  constructor(public commonService: CommonService, private httpService: HttpService, private router: Router, private route: ActivatedRoute,
    private logService: LogService, private ui: UiService, private authService: AuthService, private location: Location, private cookieService: CookieService,
    private dialog: MatDialog,
    private toasterService: ToasterService) { 
    // const goalData = this.authService.getGoalData();
    this.specificData = {
      programId: this.authService.getSelectedProgramId(),
      clientId: this.route.snapshot.params.userId
    };

    // this.headerData = goalData;

    this.dataSource.data = [];

    this.nutritionCtrl = new FormArray([]);
    // this.nutritionCtrl.setValue('');

  }
  expandAll(checked:boolean){
    if(checked){
      this.treeControl.expandAll();
    }
    else this.treeControl.collapseAll();
  }

  hasChild = (_: number, node: any) => node.expandable;

  ngOnInit(): void {
    this.authService.hideDisplayHeaderValue();
    this.sessionId = this.route.snapshot.params.sessionId;
    this.userId = this.route.snapshot.params.userId;
    if(this.cookieService.get('editsession') === 'true') {
      this.hideTitle = true;
    }
    this.getMealsData();
    this.getProgramNutritionTabTree();
    this.getStaticPages()
  }

  getStaticPages(){
    const url = `${config.apiUrl}/staticpages`;
    this.httpService.getCall(url).subscribe(result => {
     this.substitutionguide_url = result.substitutionguide_url;
     this.diningout_url= result.diningout_url;
    }, err => {
      console.log(err);
    });
  }

  onTabChanged($event:any) {
    if (this.commonService.getIsPendingChanges()) {
      if ($event.index === 0) {
        return false;
      }
      this.selectedTabIndex = 0;
      let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Confirmation', message: 'You have unsaved changes, Are you sure to Leave?' }, width: '400px' });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (typeof result !== 'undefined') {
          if (!result) {
            this.selectedTabIndex = 0;
          } else {
            this.commonService.setIsPendingChanges(false);
            this.selectedTabIndex = $event.index;
          }
        }
      });
    } else {
      this.selectedTabIndex = $event.index;
    }
    return true;
  }

  filterChanged(filterText: any) {
    let filteredTreeData = [];
    if(filterText.target.value)
    {
      function filter(array:any, text:any) {
        const getChildren = (result:any, object:any) => {
          var re = new RegExp(text, 'gi');
          if (object.name.match(re)) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.children)) {
            const children = object.children.reduce(getChildren, []);
            if (children.length) result.push({ ...object, children });
          }
          return result;
        };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.obtainedDataSource, filterText.target.value);
      this.dataSource.data = filteredTreeData;

      //this.treeControl.expandAll();
    } else {
      this.dataSource.data = this.obtainedDataSource;
      this.treeControl.collapseAll();
    }
  }

  timeChange(ev:any) {

  }

  getProgramNutritionTabTree() {
    const url = `${config.apiUrl}/getprogramnutritiontabtree`;
    this.httpService.getCall(url).subscribe(result => {
      const data = result[0].programnutritiontabtreeindex;
      const parsed = JSON.parse(data);
      // parsed.forEach((item:any) => {
      //   item.isDragDisabled = true;
      // });
      this.dataSource.data = parsed;
      this.obtainedDataSource = parsed;
    }, err => {

    });
  }

  setWeeks(data:any) {
    let isAllWeeks = true;
    this.weeks.forEach((item:any, index:any) => {
      this.weeks[index].checked = data[item.key];
      if (!data[item.key]) {
        isAllWeeks = false;
      }
    });

    this.isAllWeeksSelected = isAllWeeks;
  }

  getTotalCount(data:any) {
    if (!data ||data.length === 0) {
      return [];
    }
    this.totalMicronutrients ={
      fiber:0,
      sugars:0,
      calcium:0,
      iron:0,
      magnesium:0,
      potassium:0,
      sodium:0,
      zinc:0,
      vitamin_c:0,
      thiamin:0,
      riboflavin:0,
      niacin:0,
      pantothenic_acid:0,
      vitamin_b6:0,
      vitamin_b12:0,
      vitamin_a:0,
      vitamin_e:0,
      vitamin_d:0,
      vitamin_d3:0,
      dha:0,
      epa:0,
      ala:0,
      fatty_acids:0,
      cholesterol:0,
      leucine:0,
      phosphorus:0,

    }
    const total = {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      protein_pct: 0,
      carbs_pct: 0,
      fat_pct: 0
    };

    data.forEach((item:any, i:number) => {
      const obj:any = {
        totalCals: 0,
        totalProtein: 0,
        totalCarbs: 0,
        totalFat: 0,
        totalFiber: 0,
        totalSugar: 0,
        totalCalcium: 0,
        totalIron: 0,
        totalMagnesium: 0,
        totalPotassium: 0,
        totalSodium: 0,
        totalZinc: 0,
        totalVitaminC: 0,
        totalThiamin: 0,
        totalRiboflavin: 0,
        totalNiacin: 0,
        totalPantothenicAcid: 0,
        totalVitaminB6: 0,
        totalVitaminB12: 0,
        totalVitaminA: 0,
        totalVitaminE: 0,
        totalVitaminD: 0,
        totalVitaminD3: 0,
        totalEpa: 0,
        totalDha: 0,
        totalAla: 0,
        totalFattyAcids: 0,
        totalCholesterol: 0,
        totalLeucine: 0,
        totalPhosphorus: 0,
        water_count: Array.from({ length: item.water_8oz }),

      };
      item.mealfoods.forEach((record:any) => {
        obj.totalCals += this.commonService.isValidDecimalNumber(record.calories);
        obj.totalProtein += this.commonService.isValidDecimalNumber(record.protein);
        obj.totalCarbs += this.commonService.isValidDecimalNumber(record.carbs);
        obj.totalFat += this.commonService.isValidDecimalNumber(record.fat);
        obj.totalFiber += this.commonService.isValidDecimalNumber(record.fiber);
        obj.totalSugar += this.commonService.isValidDecimalNumber(record.sugars);
        obj.totalCalcium += this.commonService.isValidDecimalNumber(record.calcium);
        obj.totalIron += this.commonService.isValidDecimalNumber(record.iron);
        obj.totalMagnesium += this.commonService.isValidDecimalNumber(record.magnesium);
        obj.totalPotassium += this.commonService.isValidDecimalNumber(record.potassium);
        obj.totalSodium += this.commonService.isValidDecimalNumber(record.sodium);
        obj.totalZinc += this.commonService.isValidDecimalNumber(record.zinc);
        obj.totalVitaminC += this.commonService.isValidDecimalNumber(record.vitamin_c);
        obj.totalThiamin += this.commonService.isValidDecimalNumber(record.thiamin);
        obj.totalRiboflavin += this.commonService.isValidDecimalNumber(record.riboflavin);
        obj.totalNiacin += this.commonService.isValidDecimalNumber(record.niacin);
        obj.totalPantothenicAcid += this.commonService.isValidDecimalNumber(record.pantothenic_acid);
        obj.totalVitaminB6 += this.commonService.isValidDecimalNumber(record.vitamin_b6);
        obj.totalVitaminB12 += this.commonService.isValidDecimalNumber(record.vitamin_b12);
        obj.totalVitaminA += this.commonService.isValidDecimalNumber(record.vitamin_a);
        obj.totalVitaminE += this.commonService.isValidDecimalNumber(record.vitamin_e);
        obj.totalVitaminD += this.commonService.isValidDecimalNumber(record.vitamin_d);
        obj.totalVitaminD3 += this.commonService.isValidDecimalNumber(record.vitamin_d3);
        obj.totalEpa += this.commonService.isValidDecimalNumber(record.epa);
        obj.totalDha += this.commonService.isValidDecimalNumber(record.dha);
        obj.totalAla += this.commonService.isValidDecimalNumber(record.ala);
        obj.totalFattyAcids += this.commonService.isValidDecimalNumber(record.fatty_acids);
        obj.totalCholesterol += this.commonService.isValidDecimalNumber(record.cholesterol);
        obj.totalLeucine += this.commonService.isValidDecimalNumber(record.leucine);
        obj.totalPhosphorus += this.commonService.isValidDecimalNumber(record.phosphorus);
        
        record.log_qty = record.qty;
        if (record.is_added_manual === 1) {
          record.enabled = true;
        }
      });

      obj.totalCals = this.commonService.roundValues(obj.totalCals);
      obj.totalProtein = this.commonService.roundValues(obj.totalProtein);
      obj.totalFat = this.commonService.roundValues(obj.totalFat);
      obj.totalCarbs = this.commonService.roundValues(obj.totalCarbs);


      total.calories += obj.totalCals;
      total.protein += obj.totalProtein;
      total.fat += obj.totalFat;
      total.carbs += obj.totalCarbs;
      this.totalMicronutrients.fiber += obj.totalFiber;
      this.totalMicronutrients.sugars += obj.totalSugar;
      this.totalMicronutrients.calcium += obj.totalCalcium;
      this.totalMicronutrients.iron += obj.totalIron;
      this.totalMicronutrients.magnesium += obj.totalMagnesium;
      this.totalMicronutrients.potassium += obj.totalPotassium;
      this.totalMicronutrients.sodium += obj.totalSodium;
      this.totalMicronutrients.zinc += obj.totalZinc;
      this.totalMicronutrients.thiamin += obj.totalVitaminC;
      this.totalMicronutrients.riboflavin += obj.totalRiboflavin;
      this.totalMicronutrients.niacin += obj.totalNiacin;
      this.totalMicronutrients.pantothenic_acid += obj.totalPantothenicAcid;
      this.totalMicronutrients.vitamin_b6 += obj.totalVitaminB6;
      this.totalMicronutrients.vitamin_b12 += obj.totalVitaminB12;
      this.totalMicronutrients.vitamin_a += obj.totalVitaminA;
      this.totalMicronutrients.vitamin_e += obj.totalVitaminE;
      this.totalMicronutrients.vitamin_d += obj.totalVitaminD;
      this.totalMicronutrients.vitamin_d3 += obj.totalVitaminD3;
      this.totalMicronutrients.epa += obj.totalEpa;
      this.totalMicronutrients.dha += obj.totalDha;
      this.totalMicronutrients.ala += obj.totalAla;
      this.totalMicronutrients.fatty_acids += obj.totalFattyAcids;
      this.totalMicronutrients.cholesterol += obj.totalCholesterol;
      this.totalMicronutrients.leucine += obj.totalLeucine;
      this.totalMicronutrients.phosphorus += obj.totalPhosphorus;
      
      const totalmacros = obj.totalProtein * 4 + obj.totalCarbs * 4 + obj.totalFat * 9;

      obj.protein_pct = totalmacros > 0 ? Math.round(obj.totalProtein * 4 / totalmacros * 100) : 0
      obj.carbs_pct = totalmacros > 0 ? Math.round(obj.totalCarbs * 4 / totalmacros * 100) : 0;
      obj.fat_pct = totalmacros > 0 ? Math.round(obj.totalFat * 9 / totalmacros * 100) : 0;

      data[i] = {
        ...data[i],
        ...obj
      };
    });
    const totalCompleteMacros = total.protein * 4 + total.carbs * 4 + total.fat * 9;
    total.protein_pct = totalCompleteMacros > 0 ? Math.round(total.protein * 4 / totalCompleteMacros * 100) : 0;
    total.carbs_pct = totalCompleteMacros > 0 ? Math.round(total.carbs * 4 / totalCompleteMacros * 100) : 0;
    total.fat_pct = totalCompleteMacros > 0 ? Math.round(total.fat * 9 / totalCompleteMacros * 100) : 0;
    this.sideTotal = total;
    return data;
  }

  setWeeksMeals() {
    this.setWeeks(this.mealPlans[this.selectedMealIndex] ? this.mealPlans[this.selectedMealIndex] : {});
    this.meals = this.getTotalCount(this.mealPlans[this.selectedMealIndex] ? this.mealPlans[this.selectedMealIndex].meals : []);
    this.getSessionPlans();
  }

  addNoteFoodItem(i:number, j:number, item:any) {
    let dialogRef = this.dialog.open(NoteDialog, { data: {'note': item.note}, minWidth: 350, minHeight: 300 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result.status) {
        item.changeNotes = result.note;
        this.updateFoodItem(i, j, item);
      }
    });
  }

  getUniqueSessionPlans() {
    const data:any = this.sessionPlansOptions[this.selectedMealIndex] || {};
    if (!data.meals) {
      return false;
    }
    const arr:any = ['BreakFast', 'Snack', 'Lunch', 'Dinner'];
    data.meals.forEach((item:any) => {
      if (arr.findIndex((val: string) => val.replace(/ /g, '').toLowerCase() === item.name.replace(/ /g, '').toLowerCase() ) === -1) {
        arr.push(item.name);
      }
    });
    this.specificSessionPlan = arr; 

    setTimeout(() => {
      const selectedInput = sessionStorage.getItem('selected_input');
      if(selectedInput)document.getElementById(selectedInput)?.focus();
      this.ui.spin$.next(false);
  }, 500);
    return true;
  }

  onSelectMealPlan(i:number, event:any) {
    this.onChangeMealType(i, event.target.value);
  }

  getSessionPlans() {
    if (this.sessionPlansOptions.length === 0) {
    const url = `${config.apiUrl}/getsessionplans`;
    const reqObj = {
      session_id: this.sessionId,
      client_id: this.userId,
     };
     this.httpService.postCall(url, reqObj).subscribe((data:any) => {
      this.sessionPlansOptions = data.mealplans;
      this.getUniqueSessionPlans();
     }, (err) => {
       console.log(err);
     });
    } else {
      this.getUniqueSessionPlans();
    }
  }

  getMealsData() {
    const obj = {
      "program_id": this.sessionId,
      "client_id": this.specificData.clientId
    };

    this.httpService.postCall(`${config.apiUrl}/getprogrammealplans`, obj).subscribe((data:any) => {
      this.mealPlans = data[0].mealplans;
      this.httpService.postCall(`${config.apiUrl}/getgoalsessions`, {'goal_id': data[0].goal_id}).subscribe(({user}) => {
        this.headerData= {
          userName: `${user?.firstname} ${user?.lastname}`,
          sessionName: data[0].name
        }
      });
      this.setWeeksMeals();
      this.errorMsg = '';
    }, (err:any) => {
      if (err.error?.error) {
        this.errorMsg = err.error.error;
      }
    });
  }

  onAddPlan() {
    const req = {
      program_id: this.sessionId,
      name: this.newPlanName,
      client_id: this.specificData.clientId
    };
    this.ui.spin$.next(true);
    this.httpService.postCall(`${config.apiUrl}/createmealplan`, req).subscribe(result => {
      this.newPlanName = '';
      this.mealPlans = result[0].mealplans;
      this.selectedMealIndex = this.mealPlans.length - 1;
      this.setWeeksMeals();
      this.ui.spin$.next(false);
      this.errorMsg = '';
      this.toasterService.show('success', 'Success', 'Plan created successfully');
    }, err => {
      this.ui.spin$.next(false);
      if (err.error?.error) {
        this.errorMsg = err.error.error;
      }
      this.toasterService.show('error', 'Error', 'Failed to create plan');
    });
  }

  calculateValue(val:any, qty:any, logQty:any) {
    const res = val / qty;
    return this.commonService.getFixedValue(res * logQty);
  }

  updateFoodItem(i:number, j:number, item:any = {}) {
    this.ui.spin$.next(true);
    const data = this.meals[i].mealfoods[j];
    if (data.id) {
      const url = `${config.apiUrl}/updatemealfood/${data.id}`;
      if (isNaN(data.qty)) {
        return false;
      }
      if(this.isManualFood && this.customFoodName){
        this.meals[i].mealfoods[j].name = this.customFoodName;
        this.meals[i].mealfoods[j].displayName = this.customFoodName;
      }
      //!data.qty || data.qty === '0' || 
      //data.log_qty = data.qty;
      const obj:any = {
        "meal_id": data.meal_id,
        "name": (this.isManualFood && this.customFoodName) ? this.customFoodName : data.name,
        "qty": data.qty,
        "calories": this.calculateValue(data.calories, data.log_qty, data.qty),
        "carbs": this.calculateValue(data.carbs, data.log_qty, data.qty),
        "unit": data.unit,
        "protein": this.calculateValue(data.protein, data.log_qty, data.qty),
        "fat": this.calculateValue(data.fat, data.log_qty, data.qty)
       };

       if (item.changeNotes) {
        obj.note = item.changeNotes;
       }
       this.httpService.putCall(url, obj).subscribe(result => {
        sessionStorage.removeItem("selected_input");
        this.customFoodName= '';
        this.meals[i].mealfoods[j].calories = obj.calories;
        this.meals[i].mealfoods[j].carbs = obj.carbs;
        this.meals[i].mealfoods[j].protein = obj.protein;
        this.meals[i].mealfoods[j].fat = obj.fat;
        this.meals[i].mealfoods[j].log_qty = obj.qty;
        this.meals[i].mealfoods[j].qty = obj.qty;
        this.meals[i].mealfoods[j].note = obj.note;
        this.setWeeksMeals();
       }, err => {
        this.toasterService.show('error', 'Error', 'Error Occurred while updating food');
       });
    } else {
      if (Object.keys(item).length === 0) {
        return false;
      }
      const obj = {
        meal_id: this.meals[i].id,
        mealplan_id: this.meals[i].mealplan_id,
        ...item
      };
      obj.calories = this.commonService.getFixedValue(obj.calories);
      obj.carbs = this.commonService.getFixedValue(obj.carbs);
      obj.protein = this.commonService.getFixedValue(obj.protein);
      obj.fat = this.commonService.getFixedValue(obj.fat);
      const url = `${config.apiUrl}/addmealfood`;
      this.httpService.postCall(url, obj).subscribe(result => {
        this.customFoodName= '';
        result.carbs = parseFloat(result.carbs);
        result.protein = parseFloat(result.protein);
        result.calories = parseFloat(result.calories);
        result.fat = parseFloat(result.fat);
        this.meals[i].mealfoods[j] = result;
        this.setWeeksMeals();
      }, err => {
        this.toasterService.show('error', 'Error', 'Error Occurred while creating food');
      });
    }
    return true;
  }

  onActionItem(obj:any, item:any) {
    obj[item.type] = item.info;
    if (!obj.id) {
      clearTimeout(nutritionTimeout);
      nutritionTimeout = setTimeout(() => {
        obj.name = obj.displayName;
        obj.is_added_manual = 1;
        this.updateFoodItem(item.index, item.setIndex, obj);
      }, 500);
    } else if (obj.is_added_manual === 1) {
      clearTimeout(nutritionTimeout);
      nutritionTimeout = setTimeout(() => {
        this.updateFoodItem(item.index, item.setIndex);
      }, 500);
    } else {
      clearTimeout(nutritionTimeout);
      nutritionTimeout = setTimeout(() => {
        this.updateFoodItem(item.index, item.setIndex);
      }, 500);
    }
  }

  onAction(item:any) {
    if (item.type === 'new_plan') {
      this.newPlanName = item.info;
    } else {
      const obj = this.meals[item.index].mealfoods[item.setIndex];
      if (obj.log_qty == 0 && item.type == 'qty' && item.qty != 0) {
        const reqType = this.httpService.postCall(`${config.apiUrl}/nutrition/nutrients`, {item: obj.name});
        reqType.subscribe((data) => {
          const result = data.foods[0];
          obj.qty = result.qty;
          obj.log_qty = result.qty;
          obj.calories = result.calories;
          obj.carbs = result.carbs;
          obj.protein = result.protein;
          obj.fat = result.fat;
          this.onActionItem(obj, item);
        }); 
      } else {
        this.onActionItem(obj, item);
      }
      
    }
  }

  onSelectAllDays() {
    this.isAllWeeksSelected = !this.isAllWeeksSelected;
    this.onUpdateWeeks({}, this.isAllWeeksSelected);
  }

  onUpdateWeeks(item:any, isAllWeeks = false) {
    const data = this.mealPlans[this.selectedMealIndex];
    const url = `${config.apiUrl}/updatemealplan/${data.id}`;
    const { key } = item;
    const obj: any = {
      "program_id": this.sessionId,
    };
      if(item.key && !isAllWeeks){
         obj[key] = item.checked ? 1 : 0;
         let emptyDays = this.weeks.filter((week:any) => week.checked === false);
        if (emptyDays.length > 0) this.isAllWeeksSelected = false;
        else this.isAllWeeksSelected =true;
        }
      else {
        this.weeks.forEach((record:any, index:number) => {
          this.weeks[index].checked = this.isAllWeeksSelected;
          obj[record.key] = this.isAllWeeksSelected ? 1 : 0;
        });
      } 
    this.httpService.putCall(url, obj).subscribe(result => {
      if (!isAllWeeks) {
        this.mealPlans[this.selectedMealIndex][key] = item.checked;
      } else {
        this.weeks.forEach((record:any) => {
          this.mealPlans[this.selectedMealIndex][record.key] = this.isAllWeeksSelected;
        })
      }
    }, err => {
      this.toasterService.show('error', 'Error', 'Failed to update week');
    });
  }

  onParentTreeItem(index:number) {

  }

  onChangeMealType(i:number, name:string = '') {
    const data = this.meals[i];
    if (name.length > 0) {
      data.name = name;
    }
    const url = `${config.apiUrl}/updatemeal/${data.id}`;
    const obj = {
      mealplan_id: data.mealplan_id,
      name: data.name,
      time: data.time
    };

    this.httpService.putCall(url, obj).subscribe(item => {

    }, err => {

    });
  }

  searchResult(ev:any) {

  }

  onMealSelection(index:number) {
    this.selectedMealIndex = index;
    this.setWeeksMeals();
  }

  // copyMealSection(data:any) {
    // this.ui.spin$.next(true);
    // const obj = {
    //   "to_mealplan_id": data.mealplan_id,
    //   "from_mealplan_id": data.mealplan_id,
    //   "from_meal_id": data.id
    // };
    // const url = `${config.apiUrl}/copymealplanmeal`;
    // this.httpService.postCall(url, obj).subscribe(data => {
    //   this.ui.spin$.next(false);
    //   this.mealPlans = data[0].mealplans;
    //   this.setWeeksMeals();
    //   this.toasterService.show('success', 'Success', 'Meal copied successfully');
    // }, err => {
    //   this.ui.spin$.next(false);
    //   this.toasterService.show('error', 'Error', 'Failed to copy meal');
    // });
  // }

  copyFoodItem(data:any, index:number) {
    if (!data.meal_id) {
      this.toasterService.show('error', 'Error', 'This record can not be copied');
      return false;
    }
    this.ui.spin$.next(true);
    const obj = {
      "from_meal_id": data.meal_id,
      "food_id": data.id
    };
    const url = `${config.apiUrl}/copymealfood`;
    this.httpService.postCall(url, obj).subscribe(data => {
      this.meals[index].mealfoods.push(data);
      this.setWeeksMeals();
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Food copied successfully');
    }, err => {
      this.toasterService.show('error', 'Error', 'Error Occurred while copying food');
      this.ui.spin$.next(false);
    });
    return false;
  }

  deleteMealFood(data: any, i: number, j: number) {
    if (!data.meal_id) {
      this.meals[i].mealfoods.splice(j, 1);
      return true;
    }
    let dialogRef = this.dialog.open(NavDialog, { data: { 'content': 'Are you sure you want to delete food item?' } });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ui.spin$.next(true);
        const url = `${config.apiUrl}/deletemealfood/${data.meal_id}/${data.id}`;
        this.httpService.deleteCall(url, {}).subscribe(data => {
          sessionStorage.removeItem("selected_input");
          this.meals[i].mealfoods.splice(j, 1);
          this.setWeeksMeals();
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'Meal deleted successfully');
        }, err => {
          this.toasterService.show('error', 'Error', 'Error Occurred, Please try again');
          this.ui.spin$.next(false);
        });
      }
    });
    return true;
  }
  onSelectShoppingList(){
    const url = `${config.apiUrl}/getshoppinglistpdf/${this.specificData.clientId}/${this.sessionId}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while creating PDF file');
    });
    return true;
  }

  onSelectRecipes(){
    if (this.mealPlans.length === 0) {
      return false;
    }
    const url = `${config.apiUrl}/getrecipepdf/${this.sessionId}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      if (err.status === 404) {
        this.toasterService.show('error', 'Error', 'Recipes not found.');
      } else {
        this.toasterService.show('error', 'Error', 'Error while creating PDF file');
      }
    });
    return true;
  }

  deleteMealPlan(data:any, i:number) {
    let dialogRef = this.dialog.open(NavDialog, { data: {'content': 'Are you sure you want to delete meal ?'} });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/deletemeal/${data.mealplan_id}/${data.id}`;
    this.httpService.deleteCall(url, {}).subscribe(data => {
      this.meals.splice(i, 1);
      this.setWeeksMeals();
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Meal deleted successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while deleting meal');
    });
  }
});
  }

  openSessionPopup() {
    let copySessiondialogRef = this.dialog.open(CopyDialogComponent, { data: { 'title': "Select session for client", "copyUser": true, copyPlanObject: this.copyPlanObject, specificData: this.specificData }, width: '600px' });
    copySessiondialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.copyNutritionPlan();
      }
    })
  }
  
  getPrograms(reqObj: any) {
    this.ui.spin$.next(true);
    const url = `${config.apiUrl}/getclientprograms`;
    this.httpService.postCall(url, reqObj).subscribe(data => {
      this.ui.spin$.next(false);
      this.copyPlanObject.clientDetais = data.clientDetais;
      this.copyPlanObject.sessions = data.programs;
     this.openSessionPopup();
    }, err => {
      this.ui.spin$.next(false);
      this.dialog.closeAll();
      this.toasterService.show('error', 'Error', 'Error Occurred while fetching progrmas');
    });
  }
  copyNutritionPlan() {
    this.ui.spin$.next(true);
    let url = '';
    let reqObj= {};
    if(this.copyPlanObject.plan){
      url =`${config.apiUrl}/copymealplanmeal`;
      reqObj = {
        "to_mealplan_id":this.copyPlanObject.selectedMeal?this.copyPlanObject.selectedMeal.id:'',
        "from_mealplan_id":this.copyPlanObject.plan.mealplan_id,
        "from_meal_id":this.copyPlanObject.plan.id 
        }
    }
    else{
     url = `${config.apiUrl}/copymealplan`;
     reqObj = {
      "from_program_id": this.specificData.programId,
      "from_mealplan_id": this.mealPlans[this.selectedMealIndex] ? this.mealPlans[this.selectedMealIndex].id : '',
      "to_program_id": this.copyPlanObject.selectedSession,
    }
  }

    this.httpService.postCall(url, reqObj).subscribe(data => {
      this.ui.spin$.next(false);
     if(this.copyPlanObject.client === 'sameClinet') {
      this.mealPlans = data[0].mealplans;
      this.setWeeksMeals();
     }
     this.toasterService.show('success', 'Success', `Meal ${this.copyPlanObject.plan ? this.copyPlanObject.plan?.name:'plan' } copied successfully`);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error Occurred while copying meal');
    });
  }

  copyPlan(plan?:any) {
    this.copyPlanObject = {
      client: 'sameClinet',
      selectedUser: '',
      selectedSession: '',
      selectedMeal:'',
      clientDetais: {},
      sessions: [],
      users: [],
      plan:plan ? plan :'',
      sessionPlansOptions:[]
    };
    let CopydialogRef = this.dialog.open(CopyDialogComponent, { data: { 'title': 'Do you want to copy for', 'initial': true, copyPlanObject: this.copyPlanObject }, width: '500px' });
    CopydialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.copyPlanObject.client === 'sameClinet') {
          this.copyPlanObject.sessionPlansOptions =this.sessionPlansOptions;
          const reqObj = {
            "client_id": this.specificData.clientId,
          }
          this.getPrograms(reqObj);
        }
        else if (this.copyPlanObject.client === 'differentClient') {
          this.ui.spin$.next(true);
          const url = `${config.apiUrl}/fetchloggedinconsultantclients`;
          this.httpService.postCall(url, { "client_id": this.specificData.clientId }).subscribe(data => {
            this.ui.spin$.next(false);
            this.copyPlanObject.users = data;
            let copyUserdialogRef = this.dialog.open(CopyDialogComponent, { data: { 'title': "Select Clients", "copyUser": false, copyPlanObject: this.copyPlanObject }, width: '600px' });
            copyUserdialogRef.afterClosed().subscribe((result: any) => {
              if (result) {
                const reqObj = {
                  "client_id": this.copyPlanObject.selectedUser?.id,
                }
                this.getPrograms(reqObj);
              }
            })
          }, err => {
            this.ui.spin$.next(false);
            this.dialog.closeAll();
            this.toasterService.show('error', 'Error', 'Error Occurred while fetching clients');
          });
        }
      }
    })
  }


  
  deletePlan() {
    let dialogRef = this.dialog.open(NavDialog, { data: {'content': 'Are you sure you want to delete meal plan ?'}  });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this.ui.spin$.next(true);
        const data = this.mealPlans[this.selectedMealIndex];
        const url = `${config.apiUrl}/deletemealplan/${this.sessionId}/${data.id}`;
        this.httpService.deleteCall(url, {}).subscribe(item => {
          this.mealPlans.splice(this.selectedMealIndex, 1);
          if (this.selectedMealIndex > (this.mealPlans.length - 1)) {
            this.selectedMealIndex = this.mealPlans.length - 1;
          }
          this.setWeeksMeals();
          this.ui.spin$.next(false);
          this.toasterService.show('success', 'Success', 'Meal plan deleted successfully');
        }, err => {
          this.ui.spin$.next(false);
          this.toasterService.show('error', 'Error', 'Error while deleting meal plan');
      });
    }
  });
  }

  addFoodItem(event:any, i:number) {
    const obj = JSON.parse(JSON.stringify({displayName: '', enabled: true, name: 'Drag food or type'}));
    this.meals[i].mealfoods.push(obj);
    this.isManualFood =true;
    setTimeout(()=>{
      //event.target.parentElement.previousElementSibling.children[1].children[0].children[0].children[0].focus();
    });
  }

  fetchSearchedNutrition(value:string, i:number, j:number) {
    if(!value) return;
    this.meals[i].mealfoods[j].displayName = value;
    this.httpService.getCall(`${config.apiUrl}/nutrition/search/${value}`).subscribe(data => {
      this.meals[i].mealfoods[j].foodoptions = data.results;
    },
    err => {
      this.logService.logError(err);
    });
  }

  onSearchBlur(event:any,i:number, j:number) {
    setTimeout(() => {
      if (this.meals[i]?.mealfoods[j]?.foodoptions) {
        this.meals[i].mealfoods[j].foodoptions = [];
      }
    }, 1000);
  }

  onSearchNutrition(ev:any, i:number, j:number) {
    const { value = '' } = ev.target;
    this.customFoodName =value;
    this.isManualFood =true;
    clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(() => {
      this.fetchSearchedNutrition(value, i, j);
    }, 600);
  }

  onSelectNutrition(item:any, mealIndex:number, foodIndex:number) {
    //this.nutritionCtrl.setValue('');
    this.ui.spin$.next(true);
    let reqType:any;
    if (item.nix_item_id) {
      reqType = this.httpService.getCall(`${config.apiUrl}/nutrition/searchbyscan/${item.nix_item_id}`);
    } else {
      reqType = this.httpService.postCall(`${config.apiUrl}/nutrition/nutrients`, {item: item.item_name});
    }

    reqType.subscribe((data:any) => {
      const [ result ] = data.foods;
      this.isManualFood =false;
      this.customFoodName= '';
      this.meals[mealIndex].mealfoods[foodIndex].name = result.food_name;
      this.meals[mealIndex].mealfoods[foodIndex].log_qty = result.qty;
      this.meals[mealIndex].mealfoods[foodIndex].qty = result.qty;
      this.meals[mealIndex].mealfoods[foodIndex].calories = result.calories;
      this.meals[mealIndex].mealfoods[foodIndex].carbs = result.carbs;
      this.meals[mealIndex].mealfoods[foodIndex].protein = result.protein;
      this.meals[mealIndex].mealfoods[foodIndex].fat = result.fat;
      this.meals[mealIndex].mealfoods[foodIndex].unit = result.unit;
      sessionStorage.setItem('selected_input', mealIndex+'_'+foodIndex+'_'+'name');
      this.updateFoodItem(mealIndex, foodIndex, result);
      this.ui.spin$.next(false);
    },
    (err:any) => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });

  }

  addMeal() {
    this.ui.spin$.next(true);
    const data = this.mealPlans[this.selectedMealIndex];
    const url = `${config.apiUrl}/createmeal`;
    const reqData = {
        "program_id": this.sessionId,
        "client_id": this.specificData.clientId,
        "mealplan_id": data.id,
        "name":"",
        "time":"09:00"
    };
    this.httpService.postCall(url, reqData).subscribe(item => {
      this.getMealsData();
      setTimeout(()=>{
        this.ui.spin$.next(false);
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      }, 1000);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', err?.errors);
      this.logService.logError(err);
    });
  }

  async downloadAsPDF() {
    if (this.mealPlans.length === 0) {
      return false;
    }
    const url = `${config.apiUrl}/getmealspdf/${this.specificData.clientId}/${this.sessionId}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while creating PDF file');
    });
    return true;
  }
  showGuide(name:string) {
    window.open(name === 'substitution_guide' ? this.substitutionguide_url : this.diningout_url);
  }

  onNavigateBack() {
    this.onNavigateBackUser(true);
  }

  onNavigatePage() {
    if (!this.headerData.client_id) {
      this.router.navigate(['/userdetails/' + this.specificData.clientId]);
      return;
    }
    this.router.navigate(['/userdetails/' + this.headerData.client_id]);
  }

  onNavigateBackUser(isNavBack = false) {
    if (this.commonService.getIsPendingChanges()) {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { title: 'Confirmation', message: 'You have unsaved changes, Are you sure to Leave?' }, width: '400px' });
      dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.commonService.setIsPendingChanges(false);
            if (isNavBack) {
              this.location.back();
            } else {
              this.onNavigatePage();
            }
          }
        });
    } else {
      this.onNavigatePage();
    }
  }

  onSideScroll(ev:any) {
    if (ev.currentTarget.scrollY > 310) {
      this.fixedDiv = true;
    } else {
      this.fixedDiv = false;
    }
  }

  onSortMealPlanLower(index:number, isLower:boolean) {
    const record = this.meals.splice(index, 1);
    const [ specific ] = record;
    const sortedIndex = isLower ? index + 1 : index - 1;
    this.meals.splice(sortedIndex, 0, specific);
    const actualRecord = this.meals[index];
    const sortedRecord = this.meals[sortedIndex];
    this.saveUpdatedOrder(sortedRecord, sortedIndex, actualRecord, index);
  }

  updateMealPlanName(mealName:string, mealId:number, index:number) {
    const url = `${config.apiUrl}/updatemealplan/${mealId}`;
    const reqObj = {
      program_id: this.specificData.programId,
      name: mealName
    };
    this.ui.spin$.next(true);
    this.httpService.putCall(url, reqObj).subscribe(result => {
      this.mealPlans[index].name = mealName;
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'Meal Plan Name updated successfully');
    }, err => {
      this.toasterService.show('error', 'Error', err?.errors);
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  onEditMealName(index:number) {
    const record = this.mealPlans[index];
    let dialogRef = this.dialog.open(NoteDialog, { data: {'note': record.name, 'isEditName': true}, minWidth: 350, minHeight: 300 });
    dialogRef.afterClosed().subscribe((result:any) => {
      if (result.status) {
        // item.changeNotes = result.note;
        this.updateMealPlanName(result.note, record.id, index);
      }
    });
  }
  saveUpdatedOrder(sortingRecord:any, sortingIndex:number, actualRecord:any, actualIndex:number) {
    const url = `${config.apiUrl}/reordermeals/${this.mealPlans[this.selectedMealIndex].id}`;
    const reqObj = {
      meals: [{
        id: sortingRecord.id,
        display_order: sortingIndex + 1
       },
       {
        id: actualRecord.id,
        display_order: actualIndex + 1
       }
      ]
    };

    this.httpService.putCall(url, reqObj).subscribe(result => {

    }, err => {
      this.toasterService.show('error', 'Error', err?.errors);
      this.logService.logError(err);      
    });
  }

}

@Component({
  selector: 'copy-overview-dialog',
  templateUrl:'./copy-overview-dialog.html',
  styleUrls:['./session.component.sass']
})
export class CopyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CopyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private sortPipe: SortPipe,private httpService:HttpService) {
      if(this.data.copyPlanObject.users.length > 0){
        data.copyPlanObject.users = data.copyPlanObject.users;
      } 
    }
    myControl = new FormControl();
    filteredUsers:any = this.data.copyPlanObject.users;
  ngOnInit() {
    this.myControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(res => {
        const filtered = this.data.copyPlanObject.users.filter((user: any) => user.firstname.includes(res));
        if (filtered.length > 0) {
          this.filteredUsers = filtered;
        }        
      });
  }
  displayFn(user?: any): string {
    return user ? (user.firstname + " " + user.lastname ) : '';
  }
  selectUser(user:any) {
    this.data.copyPlanObject.selectedUser =user;
  }
    selectSession(session:any) {
      // if(this.data.copyPlanObject.client === "differentClient" ){
     const url = `${config.apiUrl}/getsessionplans`;
      const reqObj = {
        session_id: session.id,
        client_id: this.data.copyPlanObject.selectedUser.id || this.data.specificData.clientId,
       };
       this.httpService.postCall(url, reqObj).subscribe((data:any) => {
        this.data.copyPlanObject.sessionPlansOptions = data.mealplans;
         }, (err) => {
         console.log(err);
       });  
      // }
    }
    responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  <h1 mat-dialog-title>Confirmation</h1>
  <div mat-dialog-content class="dialogContent">
      <p>{{data.content}}</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
  styleUrls: ['./session.component.sass']
})
export class NavDialog {
  constructor(
    public dialogRef: MatDialogRef<NavDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}

@Component({
  selector: 'dialog-overview-add-note-dialog',
  template: `
  <h1 mat-dialog-title>{{title}}</h1>
    <div mat-dialog-content class="dialogContent">
      <textarea (change)="onNoteChange($event)" [value]="data.note" class="add-note-textarea"> </textarea>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>
    `,
  styleUrls: ['./session.component.sass']
})
export class NoteDialog {
  title:string = 'Note';
  constructor(
    public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (this.data.isEditName) {
        this.title = 'Edit Meal Plan Name';
      }
    }

    onNoteChange(ev:any) {
      this.data.note = ev.target.value;
    }

  responseHandler(action:any): void {
    let obj = {
      status: false,
      note: ''
    };
    if (action) {
      obj = {
        status: true,
        note: this.data.note
      };
    }
    this.dialogRef.close(obj);
  }
}

@Component({
  selector: 'confirm-dialog',
  template: `<h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content class="dialogContent">
      <p>{{data.message}}</p>
    </div>
    <div mat-dialog-actions class="dialogActions">
      <button mat-raised-button color="primary" class="resultsbtn success_btn_dialog" (click)="responseHandler(true)" cdkFocusInitial>Submit</button>
      <button mat-button class="resultsbtn cancaldlg cancel_btn_dialog" (click)="responseHandler(false)">Cancel</button>
    </div>`,
    styleUrls : ['./compositionsession/confirmdialog.component.sass']
})

export class ConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    responseHandler(action:any): void {
      this.dialogRef.close(action);
    }
}
