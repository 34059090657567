<div class="main-container">
    <div class="header">
        Company Settings
    </div>
    <div>
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="Company Setup">
                <app-company-list #createTemplates  ></app-company-list>
            </mat-tab>
            <mat-tab label="Template Setup">
                <app-template-setup [allCompanies]='allCompanies'></app-template-setup>
            </mat-tab>
            <mat-tab label="Themes">
                <div class="themeing-main-content">
                    <div class="themeing-container">
                        <section class="spec-section">
                            <mat-form-field class="template-copy-width" appearance="fill" class="form_field_companies">
                                <mat-label>All Companies</mat-label>
                                <mat-select [(ngModel)]="selectedCompany.id">
                                  <mat-option *ngFor="let company of allCompanies" [value]="company.id" (click)="onSelectCompany(company)">
                                    {{company.name}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div [ngClass]="{'highlight': selectedItem === 'themecolour'}">
                                <div class="highlight-inner">
                                    <div class="item" (click)="onItemSelect('themecolour')">
                                        <span>Theme Colour</span><span>{{customerData.themecolour}}</span>
                                    </div>
                                    <app-useritem *ngIf="selectedItem === 'themecolour'"  name="Theme Colour" [itemValue]="customerData.themecolour" (action)="onAction($event)"></app-useritem>
                                </div>
                            </div>
                            <input #companyImageInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="uploadImage($event)" accept="image/*"/>
                            <div>
                                <div class="highlight-inner" (click)="companyImageInput.click()">
                                    <div class="item">
                                        <span>Upload Company Image</span>
                                        <span>{{selectedFile.name || 'Attach'}}</span>
                                    </div>
                                </div>
                            </div>
                
                            <div class="themeing-footer">
                                <button mat-flat-button color="primary" (click)="onSubmitTheme()">Submit</button>
                            </div>
                        </section>
                    </div>
                    <div class="img-theme-container">
                        <div>
                            <img [src]="selectedCompany.logo" />
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>