<div class="main-content user-details">
    <div class="sub-header">
        <!-- <span>
            <button mat-icon-button aria-label="Go Back" (click)="onGoBack()">
                <mat-icon class="back-icon" aria-hidden="false" aria-label="Go Back">chevron_left</mat-icon>
            </button>
        </span> -->
        <img [src]="userData.avatar" title="Change Profile Picture" class="avatar" (click)="imageInput.click()" *ngIf="userData.avatar"/>
        <span class="avatarlbl" (click)="imageInput.click()" *ngIf="userData.firstname && userData.lastname && !userData.avatar">
            {{userData.firstname.slice(0,1)}}{{userData.lastname.slice(0,1)}}
            <mat-icon title="Upload Profile Picture">camera_alt</mat-icon>
        </span>
        <span class="user-name">
            {{userData.firstname}} {{userData.lastname}}
        </span>
        <span class="act-user">
            <button mat-raised-button color="primary" (click)="onCreateProgram()">Create New Program</button>
        </span>
    </div>
    <div>
        <!-- [selectedIndex]="1" -->
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="activeIndex">
            <mat-tab label="Dashboard">
                <div class="main-content" *ngIf="userData.roles?.id;else default_dashboard">
                    <app-clientdashboard  [selectedUserRole]="userData.roles.id" [userId]="userData.user_id"></app-clientdashboard>
                </div> 
                <ng-template #default_dashboard>
                    <mat-card>
                        <section>
                          <img src="/assets/THFHome.png" alt="Fitness Image" width="100%">  
                        </section>
                    </mat-card>
                </ng-template>               
            </mat-tab>
            <mat-tab label="Programs">
                <div class="programs-container">
                    <div class="active-cols">
                        <h1>Active Programs</h1>
                        <table mat-table [dataSource]="activeData">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>NAME</th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="purchased">
                                <th mat-header-cell *matHeaderCellDef>SESSIONS PURCHASED</th>
                                <td mat-cell *matCellDef="let element"> {{element.purchased}} </td>
                            </ng-container>

                            <ng-container matColumnDef="duration">
                                <th mat-header-cell *matHeaderCellDef>SESSION DATES</th>
                                <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>STATUS</th>
                                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="colsdef"></tr>
                            <tr mat-row *matRowDef="let row; columns: colsdef;" (click)="onRowSelection(row)"></tr>
                        </table>
                        <div *ngIf="activeData.length === 0" class="emtmsg">No records found</div>
                    </div>
                    <div class="inactive-cols">
                        <h1>Past Programs</h1>
                        <table mat-table [dataSource]="inactiveData">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>NAME</th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="purchased">
                                <th mat-header-cell *matHeaderCellDef>SESSIONS PURCHASED</th>
                                <td mat-cell *matCellDef="let element"> {{element.purchased}} </td>
                            </ng-container>

                            <ng-container matColumnDef="duration">
                                <th mat-header-cell *matHeaderCellDef>SESSION DATES</th>
                                <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>STATUS</th>
                                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="colsdef"></tr>
                            <tr mat-row *matRowDef="let row; columns: colsdef;" (click)="onRowSelection(row)"></tr>
                        </table>
                        <div *ngIf="inactiveData.length === 0" class="emtmsg">No records found</div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Settings">
                <div class="settings-container">
                    <div>
                    <div>
                        <!-- <div *ngIf="imageLoading" class="user-avatar" >
                            <img src="/assets/loading.gif" />
                        </div> -->
                        <!-- <img class="user-avatar" [class.imgHidden]="imageLoading"
                            [src]="userData.avatar" default="/assets/profile.png"  (error)="onImgError()" (click)="imageInput.click()" (load)= "onImageLoaded()" /> -->
                            <input #imageInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="uploadImage($event)" accept="image/*"/>
                    </div>
                    <section class="spec-section">
                        <div>
                            <div class="header">
                                <span>Profile</span>
                                <span class="act-as-user" *ngIf="isActAsUserDisplay">
                                    <button mat-button color="primary" (click)="actAsUser()">ACT AS USER</button>
                                </span>
                            </div>
                            <div [ngClass]="{'highlight': selectedItem === 'name'}">
                                <div class="highlight-inner">
                                    <div class="item" (click)="onItemSelect('name')">
                                        <span>Name</span><span>{{userData.firstname}} {{userData.lastname}}</span>
                                    </div>
                                    <app-useritem *ngIf="selectedItem === 'name'"  name="Name" type="multiinputs" [userData]="userNamesData" (action)="onAction($event)"></app-useritem>
                                </div>
                            </div>
                            <div [ngClass]="{'highlight': selectedItem === 'email'}">
                                <div class="highlight-inner">
                                    <div class="item" (click)="onItemSelect('email')">
                                        <span>Email Address</span><span>{{userData.email}}</span>
                                    </div>
                                    <app-useritem *ngIf="selectedItem === 'email'"  name="Email Address" [itemValue]="userData.email" (action)="onAction($event)"></app-useritem>
                                </div>
                            </div>
                            <div [ngClass]="{'highlight': selectedItem === 'address'}">
                                <div class="highlight-inner">
                                    <div class="item" (click)="onItemSelect('address')">
                                        <span>Address</span><span>{{userData.aptsuite}}{{userData.aptsuite ? ',' : ''}} {{userData.address}}{{userData.address? ',': ''}} {{userData.city}}{{userData.city? ',': ''}} {{userData.state}}{{userData.state? ',': ''}} {{userData.postalcode}}</span>
                                    </div>
                                    <app-useritem *ngIf="selectedItem === 'address'"  name="Address" type="multiinputs" [userData]="userAddressData" [selectData]="selectData" (action)="onAction($event)"></app-useritem>
                                </div>
                            </div>
                            <div [ngClass]="{'highlight': selectedItem === 'gender'}">
                                <div class="highlight-inner">
                                    <div class="item" (click)="onItemSelect('gender')">
                                        <span>Gender</span><span>{{userData.gender}}</span>
                                    </div>
                                    <app-useritem *ngIf="selectedItem === 'gender'"  name="Gender" [itemValue]="userData.genderShort" type="gender" [radioOptions]="genderOptions" (action)="onAction($event)"></app-useritem>
                                </div>
                            </div>
                            <div [ngClass]="{'highlight': selectedItem === 'birthdate'}">
                                <div class="highlight-inner">
                                    <div class="item" (click)="onItemSelect('birthdate')">
                                        <span>Birth Date</span><span>{{userData.birthdate}}</span>
                                    </div>
                                    <app-useritem *ngIf="selectedItem === 'birthdate'" [disabled]=true name="Birth Date" type="date" [dateValue]="userData.birthdate" (action)="onAction($event)"></app-useritem>
                                </div>
                            </div>
                            <div [ngClass]="{'highlight': selectedItem === 'mobilephone'}">
                                <div class="highlight-inner">
                                    <div class="item" (click)="onItemSelect('mobilephone')">
                                        <span>Mobile Phone</span><span>{{userData.mobilephone}}</span>
                                    </div>
                                    <app-useritem *ngIf="selectedItem === 'mobilephone'"  name="Mobile Phone" [itemValue]="userData.mobilephone" (action)="onAction($event)"></app-useritem>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div>
                    <section class="spec-section">
                        <div>
                            <div class="header">
                                <span>Account</span>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': selectedItem === 'roles'}">
                            <div class="highlight-inner">
                                <div class="item" (click)="onItemSelect('roles')">
                                    <span>Roles</span><span>{{userData?.roles?.display_name}}</span>
                                </div>
                                <app-useritem *ngIf="selectedItem === 'roles'" name="Roles" [itemValue]="userData?.roles?.id" type="select" [selectData]="rolesData" (action)="onAction($event)"></app-useritem>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': selectedItem === 'password'}">
                            <div class="highlight-inner">
                                <div class="item" (click)="onItemSelect('password')">
                                    <span>Password</span><span>********</span>
                                </div>
                                <app-useritem *ngIf="selectedItem === 'password'" inputType='password' name="Password" [itemValue]="userData.password" (action)="onAction($event)"></app-useritem>
                            </div>
                        </div>
                        <div class="no-highlight">
                            <div class="highlight-inner">
                                <div class="item" (click)="onItemSelect('company')">
                                    <span>Company</span><span>{{userData.companyName}}</span>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'highlight': selectedItem === 'consultant_id', 'no-highlight' : !isActAsUserDisplay}" *ngIf="showFields">
                            <div class="highlight-inner">
                                <div class="item" (click)="isActAsUserDisplay ? onItemSelect('consultant_id') : ''">
                                    <span>Consultant</span>
                                    <span>{{userData.consultantName}}</span>
                                </div>
                                <app-useritem *ngIf="selectedItem === 'consultant_id'"  name="Select Consultant" [itemValue]="userData.consultant_id" type="select" [selectData]="consultantData" (action)="onAction($event)"></app-useritem>
                            </div>
                            </div>
                        <div [ngClass]="{'highlight': selectedItem === 'deactivateaccount'}" class="deactivate" *ngIf="showFields"
                            (click)="deactivateAccount()"
                        >
                            <div class="highlight-inner">
                                <div class="item no-border" (click)="onItemSelect('deactivateaccount')">
                                    <span *ngIf="userData.enabled === 1">Delete Account </span>
                                    <span *ngIf="userData.enabled === 0">Activate Account </span>
                                </div>
                            </div>
                        </div>
                        </section>
                </div>
            </div>

            </mat-tab>
            <mat-tab label="InBody">
                <div class="inbody-container">
                    <app-inbody [specificProspectId]="userData.mobilephone"></app-inbody>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>