import { Component, OnInit, Input } from '@angular/core';
import { ToasterService } from '../../services/toaster/toaster.service';
import { CommonService } from '../../services/common/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { config } from '../../config';
import { UiService } from '../../services/uiloader/uiloader.service';
import { HttpService } from '../../services/http/http.service';

let timeoutHandle:any;

@Component({
  selector: 'app-mealsuploadercreate',
  templateUrl: './mealsuploadercreate.component.html',
  styleUrls: ['./mealsuploadercreate.component.sass'],
})
export class MealsuploadercreateComponent implements OnInit {
  @Input() mealsCategories:any;
  mealName:string = '';
  description:string = '';
  note:string = '';
  videoUrl:string = '';

  newMealCategory:string = '';
  selected_sub_meal_category = -1;
  selected_meal_category = -1;
  datasource:any = new MatTableDataSource<any>();
  mealsSource:any = new MatTableDataSource<any>();

  totals:any = {};

  displayedColumns: string[] = ['name', 'category', 'calories', 'protein', 'carbs', 'fat', 'unit', 'quantity', 'delete'];

  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };

  formInvalidFields:any = [];
  requiredFields = ['name'];

  constructor(public commonService: CommonService,
    private httpService: HttpService,
    private ui: UiService,
    private toasterService: ToasterService) { 
    this.getFoodTemplates();
    // this.getMealsCategories();
  }

  ngOnInit(): void {
  }

  getMealsCategories() {
    const url = `${config.apiUrl}/meals/getmealtemplatecategory/`;
    this.httpService.getCall(url).subscribe(result => {

    }, err => {
      
    });
  }

  getFoodTemplates() {
    const url = `${config.apiUrl}/foods/getfoodtemplates`;
    const req = {
      limit: this.pagination.perPage,
      fromIndex: this.pagination.pageIndex
    };
    this.httpService.postCall(url, req).subscribe((result:any) => {
      this.mealsSource = result.rows;
      this.pagination.count = result.count;
    }, (err:any) => {

    })
  }

    getServerData(e:any) {
    console.log(e.pageIndex);
    console.log(e.pageSize);
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.getFoodTemplates();
  }

  onAction(event:any) {
    if (event.type === 'mealName') {
      this.mealName = event.info;
    } else if (event.type === 'newMealCategory') {
      this.newMealCategory = event.info;
    } else if (event.type === 'videoUrl') {
      this.videoUrl = event.info;
    }
  }

  onFoodSelection(event:any, isUpdated = false, isRemove = false) {
    console.log(event);
    const records = this.datasource.data;
    if (!isUpdated && !isRemove) {
      records.push(JSON.parse(JSON.stringify(event)));
    }
    const totalsCalculated = {
      cals: 0,
      protein: 0,
      carbs: 0,
      fat: 0
    };
    this.datasource.data = records.filter((item:any, index: number) => {
      if (event.deletedIndex !== index) {
        if (!isUpdated) {
          item.quantity = item.quantity || 1 ;
        }
        const quantityVal = item.quantity || 0;
        totalsCalculated.cals += item.calories * quantityVal;
        totalsCalculated.protein += item.protein * quantityVal;
        totalsCalculated.carbs += item.carbs * quantityVal;
        totalsCalculated.fat += item.fat * quantityVal;
        return item;
      }
      return false;
    });
    this.totals = totalsCalculated;
  }

  onDeleteRecord(ev: any, index: number) {
    this.onFoodSelection({deletedIndex: index}, false, true);
  }

  onQuantityChange(ev: any, record: any, index: number) {
    clearTimeout(timeoutHandle);
    timeoutHandle = setTimeout(() => {
      this.onFoodSelection({}, true);
    }, 1000);
  }

  onChangeMealCategory() {
    console.log(this.selected_meal_category);
    this.newMealCategory = '';
    this.selected_sub_meal_category = -1;
  }

  onChangeMealSubCategory() {
    this.selected_meal_category = -1;
  }

  specificRecord(record:any) {
    this.onFoodSelection(record);
    console.log(record);
  }

  onResetMeal() {
    this.datasource.data = [];
    this.mealName = '';
    this.note = '';
    this.description = '';
    this.selected_sub_meal_category = -1;
    this.selected_meal_category = -1;
    this.newMealCategory = '';
    this.onFoodSelection({}, false, true);
  }

  onSubmitMeal() {
    const reqObj:any = {
      name: this.mealName,
      video_url: this.videoUrl,
      note: this.note,
      description: this.description,
      mealcategory: this.newMealCategory || this.selected_meal_category,
      // subcat: this.selected_sub_meal_category,
      foods: [...this.datasource.data]
    };

    const isFormInValid: any = [];
    this.requiredFields.forEach((key: string) => {
     if (!reqObj[key]) {
       isFormInValid.push(key);
     }
    //  if (reqObj.mealcategory && isNaN(reqObj.mealcategory)) {
    //    if (!reqObj.subcat) {
    //      isFormInValid.push('Sub category');
    //    }
    //  }
    });
    if (reqObj.foods.length === 0) {
      isFormInValid.push('Please select atleast one food');
    }

    if (isFormInValid.length > 0) {
      this.formInvalidFields = isFormInValid;
      setTimeout(() => {
        this.formInvalidFields = [];
      }, 3000);
      return false;
    }

    this.ui.spin$.next(false);
    const url = `${config.apiUrl}/meals/createmealtemplate`;
    this.httpService.postCall(url, reqObj).subscribe(result => {
      this.onResetMeal();
      this.toasterService.show('success', 'Success', 'Meal created successfully');
    }, err => {
      this.toasterService.show('error', 'Error', 'Error occurred, Please try again');
      console.log(err);
    });
    return true;
  }
}
