<h2 mat-dialog-title>
  {{data.selectedNotes?.length>0? "Share Notes" : "Add Notes"}}</h2>
<form #myForm="ngForm" (submit)="saveNotes(myForm.value)">
  <mat-dialog-content class="mat-typography">
    <div class="formwidth">
      <mat-form-field class="fullwidth" *ngIf="data.selectedNotes?.length==0">
        <input matInput placeholder="Title" id="title" name="title" ngModel>
      </mat-form-field>
      <mat-form-field class="fullwidth" *ngIf="data.selectedNotes?.length ==0">
        <textarea matInput placeholder="Description" id="description" name="description" ngModel rows="5"
          cols="5"></textarea>
      </mat-form-field>
     <ng-container>
      <mat-form-field>
        <mat-label>Select Client </mat-label>
        <mat-select [(ngModel)]="client_id" name="client_id" id="client_id" >
          <mat-option  *ngFor="let user of (data.selectedNotes?.length>0 ? data.consultants :data.consultantClients)" [value]="user.id" >
            {{user.firstname}} {{user.lastname}} {{(!user.firstname && !user.lastname) ? user.email : ''}}
          </mat-option>
        </mat-select>
      </mat-form-field>
     </ng-container>
      <div style="display: flex; align-items: center; padding-top:10px; width: 20%; justify-content: space-between;"
        *ngIf="data.selectedNotes?.length==0">
        <span>Mark as Private</span>
        <mat-slide-toggle class="p-status" name="is_private" [(ngModel)]="is_private"></mat-slide-toggle>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close mat-raised-button color="secondary">Cancel</button>
    <button mat-button [mat-dialog-close]="true" mat-raised-button color="primary" type="submit" cdkFocusInitial>{{data.selectedNotes?.length>0 ?"Share"
      :"Save"}}</button>
  </mat-dialog-actions>
</form>