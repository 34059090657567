import { Component, OnInit, ElementRef } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { config } from '../config';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { CookieService } from 'ngx-cookie-service';
import { LogService } from '../services/log/log.service';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../services/data/data.service';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.sass']
})
export class ConsultantsComponent implements OnInit {
  isAdminRole = false;
  constructor(private route: ActivatedRoute, 
    private cookieService: CookieService, 
    private httpService: HttpService, 
    private commonService: CommonService, 
    private ui: UiService, 
    private router: Router, 
    private logService: LogService,
    private el: ElementRef,
    private authService: AuthService,
    private dataService: DataService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.params.id;
    const routes = [{
      name: 'Prospects',
      path: 'prospects'
    }];
    this.commonService.setHeaderData(routes);
    this.isAdminRole = this.authService.getUserRole() === config.ADMIN_USER_ROLE;
    this.ui.spin$.next(true);
    this.fetchUsers();
  }

  startConsulatant:any = [];
  awaitingPayment:any = [];
  displayedColumns: string[] = ['name', 'email', 'phone', 'status'];

  ngAfterContentChecked() { }

  specificUser(item:any){
    this.cookieService.set('consultant', 'true');
    this.router.navigate(['/userdetails', item.id ]);
  }

  private fetchUsers(txt = "") {
    const obj = {
      fromIndex: 0,
      limit: 50,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: txt ? txt : ""
    };
    let url = '';
    if (this.authService.getUserRole() === config.ADMIN_USER_ROLE) {
      url = `${config.apiUrl}/companyadmin/getcompanyprospects`;
    } else {
      url = `${config.apiUrl}/getconsultantprospects`;
    }
    this.httpService.postCall(url, obj).subscribe(data => {
      this.getFormatedData(data);
      // this.todolist = this.getTodoFormatedData(data.prospects);
      this.ui.spin$.next(false);
    },
    err => {
      this.ui.spin$.next(false);
      this.logService.logError(err);
    });
  }

  private getTodoFormatedData(data:any) {
    const { rows = [], count = 0 } = data;
    return rows.map((item:any) => ({
      id: item.id,
      title: "New Prospect",
      proposer: item.firstname && item.lastname ? `${item.firstname} ${item.lastname}` : item.firstname
    }));
  }

  private getFormatedData(data:any) {
    const { rows = [], count = 0 } = data;
    this.startConsulatant = [];
    this.awaitingPayment = [];
    for(let i=0; i<rows.length; i++) {
      let cur = rows[i];
      let obj:any = {
        id: cur.id,
        name: {
          fullname: `${cur.firstname} ${cur.lastname}`,
          profilePic: cur.personal && cur.personal.avatar && cur.personal.avatar.length > 0 ? `${config.imageUrl}${cur.personal.avatar}` : config.defaultProfilePic
        },
        firstname: cur.firstname,
        lastname: cur.lastname,
        phone: cur.personal && cur.personal.mobilephone  ? cur.personal.mobilephone : null,
        email: cur.email
      };
      if(cur.progress_status_id === 4 || cur.progress_status_id === 5 || this.authService.getUserRole() === config.ADMIN_USER_ROLE) {
        obj.status = {
          text: this.authService.getUserRole() === config.ADMIN_USER_ROLE ? 'Assign Consultant' : 'Start Consultation',
          id: cur.id
        };
        this.startConsulatant.push(obj);
      } else if(cur.progress_status_id === 7) {
        obj.status = 'Awaiting Payment';
        this.awaitingPayment.push(obj);
      }
    }
  }

  handleConsultation(data:any) {
    this.router.navigate(['/consultants', 'survey', data]);
  }

  assignConsultant(data:any) {
    this.dataService.setProspectSelected(data);
    this.router.navigate([`/prospectprofile`]);
  }

  searchResult(eve:any) {
    this.fetchUsers(eve.target.value);
  }

}
