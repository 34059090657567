import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { config } from '../config';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../services/toaster/toaster.service';
import { FormControl } from '@angular/forms';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-clientnutrition',
  templateUrl: './clientnutrition.component.html',
  styleUrls: ['./clientnutrition.component.sass']
})
export class ClientnutritionComponent implements OnInit {

 
  program_id: any;
  filterdate: any;
  programsData: any;
  clientDetais:any;
  errorMsg: string = '';
  boxes: any = [
    {field: 'Calories', actual: 0, total: 0, unit: 'kcal'},
    {field: 'Protein', actual: 0, total: 0, unit: 'g'},
    {field: 'Carbs', actual: 0, total: 0, unit: 'g'},
    {field: 'Fat', actual: 0, total: 0, unit: 'g'}
  ];
  initProg : any = false;
  mealsplan: any;
  tableData: any = {};
  foodIds: any = {};
  initTotal: any;
  foodChosen: any;
  tmpText: any;
  selectedFood: any;
  searchedinfo: any;
  maxDate: Date = new Date();
  minDate: Date = new Date();
  isTodayDate = true;
  clientDetails:any = {};
  nutritionCtrl:any;
  searchCategory:any= 'common';
  sortDate:any = '';
  diningout_url:any;
  substitutionguide_url:any;
  constructor(private commonService: CommonService, private httpService: HttpService, private logService: LogService, private ui: UiService,
    private route:ActivatedRoute,private toasterService:ToasterService,private platformLocation:PlatformLocation) {
      this.nutritionCtrl = new FormControl();
     }


  ngOnInit(): void {
    const routes:any = [{
      name: 'Nutrition',
      path: 'nutrition'
    }];
    this.getPrograms();
    this.getStaticPages()

    this.route.queryParams.subscribe((params:any) => {
      this.sortDate = params.sortDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
      history.pushState(null, '', location.href);
      this.platformLocation.onPopState(()=>{
        history.pushState(null, '', location.href)
      })
    })

    this.route.paramMap.subscribe((params:any) => {
      this.program_id= params.get('programId');
      if(this.program_id){
        this.formatNutritionData(this.program_id);
      }
      else this.formatNutritionData(new Date());
        });
    this.commonService.setHeaderData(routes);
    this.ui.spin$.next(true);
    this.initProg = true;
   
  }

  getStaticPages(){
    const url = `${config.apiUrl}/staticpages`;
    this.httpService.getCall(url).subscribe(result => {
     this.substitutionguide_url = result.substitutionguide_url;
     this.diningout_url= result.diningout_url;
    }, err => {
      console.log(err);
    });
  }

  updateSearchCategory(text:any){
    this.searchCategory = text;

  }

  getPrograms() {
    this.httpService.postCall(`${config.apiUrl}/getclientprograms`, {}).subscribe(data => {
      this.programsData = data.programs;
      this.clientDetais = data.clientDetais;
    }, 
    err => {
      this.logService.logError(err);
    });
  }

  async downloadAsPDF() {
    const url = `${config.apiUrl}/getmealspdf/${this.clientDetails.id}/${this.clientDetails.activeprogram_id}`;
    this.ui.spin$.next(true);
  
    this.httpService.getCallFile(url, 'arraybuffer').subscribe(result => {
      const blob = new Blob([result], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(blob);
      // Open the PDF in a new window
      const newWindow = window.open(fileURL, '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        // If the popup blocker prevents opening a new window, show an error message
        this.toasterService.show('error', 'Error', 'Popup blocker prevented opening the PDF.');
      }
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file opened successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while creating PDF');
      console.log(err);
    });
  }

  onChangeProgram(ev:any){
    this.ui.spin$.next(true);
    this.tableData = {};
    let program = this.programsData.find((program:any) => program.id == ev.value);
    if (this.clientDetais.activeprogram_id === program.id) {
      this.filterdate = new Date();
    } else {
      this.filterdate = new Date(program.program_start_date);
    }
    if(this.commonService.getGeneralFormatDate(this.filterdate) === this.commonService.getGeneralFormatDate(new Date())) this.isTodayDate =true;
    else this.isTodayDate =false;
    this.minDate = new Date(program.program_start_date);
    if(program.deactivated_date) this.maxDate = new Date(program.deactivated_date);
    this.formatNutritionData(this.filterdate, ev.value);
  }

  pickerUpdate() {
    this.ui.spin$.next(true);
    this.tableData = {};
    this.formatNutritionData(this.filterdate, '');
  }

  commonStructure(obj:any, name:string) {
    let self = this;
    if (!obj.log_qty) {
      obj.log_qty = obj.qty ? obj.qty : 1;
    }
    const ischecked = !!obj.toggle ? true : false;
    return {
      id: obj.id,
      data: {checked: ischecked, value: obj.name, rowid: obj.id, name: name, clientnote: obj.clientnote},
      calories: {
        actual: obj.is_added_manual === 1 ? 0 : obj.calories , 
        re: (ischecked && obj.log_qty) ? obj.calories * (obj.log_qty / obj.qty) : 0,
        actualVal: obj.calories
      },
      protein: {
        actual: obj.is_added_manual === 1 ? 0 : obj.protein, 
        re: (ischecked && obj.log_qty) ? obj.protein * (obj.log_qty / obj.qty) : 0,
        actualVal: obj.protein
      },
      carbs: {
        actual: obj.is_added_manual === 1 ? 0 : obj.carbs, 
        re: (ischecked && obj.log_qty) ? obj.carbs * (obj.log_qty / obj.qty) : 0,
        actualVal: obj.carbs
      },
      fat: {
        actual: obj.is_added_manual === 1 ? 0 : obj.fat, 
        re: (ischecked && obj.log_qty) ? obj.fat * (obj.log_qty / obj.qty) : 0,
        actualVal: obj.fat
      },
      servingsize: {unit: obj.unit, name: name, changed: obj.log_qty ? obj.log_qty : obj.qty, count: obj.qty, id: obj.id},
      delete: {
        show: obj.is_able_to_delete, name: name, rowid: obj.id, lognutritionsingle_id: obj.lognutritionsingle_id, hideActions: false,
        calories: self.commonService.roundValues(obj.calories),
        protein: self.commonService.roundValues(obj.protein),
        carbs: self.commonService.roundValues(obj.carbs),
        fat: self.commonService.roundValues(obj.fat),
      }
    }
  }

  formatNutritionData(date= new Date(), program_id='') {
    this.httpService.postCall(`${config.apiUrl}/clientnutrition`, {"logdate": this.commonService.getGeneralFormatDate(date), "program_id": program_id}).subscribe(data => {
      this.clientDetails = data.users;
      this.program_id = data.programs.id; //program_id === '' ? data.programs.id : program_id;
      this.programsData.map((ob:any) => {
        if(ob.id === this.program_id && this.initProg) {
          this.initProg = false;
          if (this.clientDetais.activeprogram_id === ob.id) {
            this.filterdate = this.sortDate ? this.filterdate = new Date(this.sortDate) : new Date();
          } else {
            this.filterdate = new Date(ob.program_start_date);
          }
          ob.name = 'Current Session';
        }
        return ob;
      });
      if(this.commonService.getGeneralFormatDate(this.filterdate) === this.commonService.getGeneralFormatDate(new Date())) this.isTodayDate =true;
      else this.isTodayDate =false;
      let pageData:any = [];
      data.programs.mealplans.map((plans:any) => {
        let mealplans = plans.meals;
        mealplans.map((ob: any  ) => {
          let tb = ob.foods ? ob.foods.map((obj:any) => {
            return this.commonStructure(obj, ob.id);
          }) : [];
          ob['searchTxt'] = '';
          ob['searchResult'] = [];
          this.foodIds[ob.id] = {
            meal_id: ob.meal_id,
            mealplan_id: ob.mealplan_id ? ob.mealplan_id : ob.lognutritionsingle_id
          }
          if(tb.length) {
            let calc = this.getTotalByTableData(tb);
            calc = calc !== false ? tb.push(calc) : tb;
          }
          this.tableData[ob.id] = tb;
          return tb;
        });
        pageData.push({name: plans.name, mealplans: mealplans});
      });
      this.mealsplan = pageData;
      this.errorMsg = '';
      this.overAllTotal(true);
      this.ui.spin$.next(false);
    },
    err => {
      this.mealsplan = [];
      this.ui.spin$.next(false);
      this.tableData = {};
      this.boxes.map((o:any)=> {
        o.actual = 0;
        o.total = 0;
        return o
      })
      if(err.error.message){
      this.errorMsg = err.error.message;  
      }else {
        this.errorMsg = err.error.error;
      }
      // this.errorMsg = err.error.error;
      this.logService.logError(err);
    });
  }

  getTotalByTableData(newVal:any) {
    if(newVal.length) {
      let checkedData = newVal.filter((o:any) => o.data.checked);
      return {
        id: 'total',
        data: {checked: 'none', value: 'Total'},
        calories: {
          actual: newVal.map((no:any) => no.calories.actual)?.reduce((a:any,b:any)=> a+b), 
          re: checkedData.length ? (checkedData.map((no:any) => no.calories.re)?.reduce((a:any,b:any)=> a+b)) : 0
        },
        protein: {
          actual: newVal.map((no:any) => no.protein.actual)?.reduce((a:any,b:any)=> a+b), 
          re: checkedData.length ? (checkedData.map((no:any) => no.protein.re)?.reduce((a:any,b:any)=> a+b)) : 0
        },
        carbs: {
          actual: newVal.map((no:any) => no.carbs.actual)?.reduce((a:any,b:any)=> a+b), 
          re: checkedData.length ? (checkedData.map((no:any) => no.carbs.re)?.reduce((a:any,b:any)=> a+b)) : 0
        },
        fat: {
          actual: newVal.map((no:any) => no.fat.actual)?.reduce((a:any,b:any)=> a+b), 
          re: checkedData.length ? (checkedData.map((no:any) => no.fat.re)?.reduce((a:any,b:any)=> a+b)) : 0
        },
        servingsize: '',
        delete: {show:false, rowid: '', hideActions: true}
      }
    } else {
      return false;
    }
  }

  insertFood(food:any, id:any) {
    let reqType:any;
    if (food.nix_item_id) {
      reqType = this.httpService.getCall(`${config.apiUrl}/nutrition/searchbyscan/${food.nix_item_id}`);
    } else {
      reqType = this.httpService.postCall(`${config.apiUrl}/nutrition/nutrients`, {item: food.item_name});
    }
    reqType.subscribe((data:any) => {
      this.searchedinfo = data.foods[0];
      const date = this.filterdate ? this.filterdate : new Date();
      let payload = {
        ...this.foodIds[id],
        log_qty: this.searchedinfo.serving_qty,
        isnutritionfood: true,
        program_id: this.program_id,
        foodItems: [{...food, ...this.searchedinfo, name: food.item_name, qty: this.searchedinfo.serving_qty, is_able_to_delete: true, is_added_manual: 1,  toggle: 1}],
        logdate: this.commonService.getGeneralFormatDate(date),
        mealfoods: [{id: food.id, log_qty: this.searchedinfo.serving_qty}],
      }
      this.ui.spin$.next(true);
      this.selectedFood = food;
      this.nutritionCtrl.setValue('');
      this.saveFoodData(payload, id);
    },
    (err:any) => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  timeChange(ev:any, mealid:any) {
    this.mealsplan.forEach((mealsList:any) => {      
      const fObj = mealsList.mealplans.filter((obj:any) => obj.id === mealid);
      const date = this.filterdate ? this.filterdate : new Date();
      if(fObj && !fObj[0].lognutritionsingle_id) {
        const payload = {
            logdate: this.commonService.getGeneralFormatDate(date),
            program_id: this.program_id,
            mealplan_id: fObj[0].mealplan_id,
            meal_id: fObj[0].meal_id,   
            log_water_8oz: fObj[0].water_8oz,
            waternote: fObj[0].waternote,
            log_time: ev.target.value
        }
        this.httpService.postCall(`${config.apiUrl}/createlognutritionsingle`, payload).subscribe(data => {
          
        },
        err => {
          this.logService.logError(err);
          this.ui.spin$.next(false);
        });
      } else {
        const payload = {
          lognutritionsingle_id: fObj[0].lognutritionsingle_id,
          program_id: this.program_id,
          data: {          
            logdate: this.commonService.getGeneralFormatDate(date),
            log_time: ev.target.value,
            log_water_8oz: fObj[0].water_8oz,
            waternote: fObj[0].waternote
          }  
        }
        this.httpService.postCall(`${config.apiUrl}/update/lognutritionsingles`, payload).subscribe(data => {
          
        },
        err => {
          this.logService.logError(err);
          this.ui.spin$.next(false);
        });
      }
    });
  }

  overAllTotal(init:boolean = false) {
    let foods = Object.keys(this.tableData).map(ob => this.tableData[ob]);
    foods = foods.concat.apply([], foods);
    let data:any = foods.filter((obj:any) => {if(obj.id !== 'total') return obj;});
    let calories = {actual:0, total:0};
    let carbs = {actual:0, total:0};
    let protein = {actual:0, total:0};
    let fat = {actual:0, total:0};
    const checkedData = data.filter((obj:any) => {
      if(obj.data.checked) return obj;
    });
    for(let j=0; j<checkedData.length; j++) {      
      calories.total = calories.total + checkedData[j].calories.re;
      carbs.total = carbs.total + checkedData[j].carbs.re;
      protein.total = protein.total + checkedData[j].protein.re;
      fat.total = fat.total + checkedData[j].fat.re;
    }
    if(init) {
      const initData = data.filter((obj:any) => {
        if(!obj.delete.show) return obj;
      });
      for(let i=0; i<initData.length; i++) {
        calories.actual = calories.actual + initData[i].calories.actual;
        carbs.actual = carbs.actual + initData[i].carbs.actual;
        protein.actual = protein.actual + initData[i].protein.actual;
        fat.actual = fat.actual + initData[i].fat.actual;
      }
      this.initTotal = {
        'calories': calories.actual,
        'protein': protein.actual,
        'carbs': carbs.actual,
        'fat': fat.actual
      }
    }
    this.boxes = [
      {field: 'Calories', actual: this.initTotal.calories, total: calories.total, unit: 'kcal'},
      {field: 'Protein', actual: this.initTotal.protein, total: protein.total, unit: 'g'},
      {field: 'Carbs', actual: this.initTotal.carbs, total: carbs.total, unit: 'g'},
      {field: 'Fat', actual: this.initTotal.fat, total: fat.total, unit: 'g'}
    ];
    this.ui.spin$.next(false);
  }

  deleteFoods(ev:any) {
    this.ui.spin$.next(true);
    let id = ev.val.rowid;
    this.httpService.deleteCall(`${config.apiUrl}/deletelognutrisinglefood/${ev.val.lognutritionsingle_id}/${id}`, {lognutritionsingle_id: id}).subscribe(data => {
      Object.keys(this.tableData).map((ob:any) => {
        this.tableData[ob] = this.tableData[ob].filter((u:any) => u.id !== ev.val.rowid);
        return ob;
      });
      this.overAllTotal();
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  onAddNote(ev:any) {
    
  }

  overAllTableUpdate(id:any, type:any, state:any) {
    const date = this.filterdate ? this.filterdate : new Date();
    let cPayload:any = [];
    let updateData = this.tableData[id].filter((o:any) => {return !!o.delete.lognutritionsingle_id});
    let payload = updateData.map((obj:any) => {
      return {
        lognutritionsinglefoods_id: obj.id,
        data:{
          log_qty: obj.servingsize.changed,
          clientnote: obj.note ? obj.note : '',
          toggle: state ? 1 : 0
        }
      };
    });
    let createData = this.tableData[id].filter((o:any) => {return !o.delete.lognutritionsingle_id && o.id !== 'total'});
    let fData = createData.map((obj:any) => {
      return {id: obj.id, log_qty: obj.servingsize.changed};
    });
    if(fData.length) {
      cPayload = [{
        ...this.foodIds[id],
        isnutritionfood: false,
        program_id: this.program_id,
        logdate: this.commonService.getGeneralFormatDate(date),
        mealfoods: fData,
        toggle: state ? 1 : 0
      }]
    }
    payload = [...payload, ...cPayload];
    if(payload.length) {
      this.ui.spin$.next(true);
      this.httpService.postCall(`${config.apiUrl}/lognutritionbulkfoods`, payload).subscribe(data => {
        let updt = this.tableData[id].map((o:any) => {
          o.data.checked !== 'none' ? o.data.checked = state : null;
          let udata = data.filter((ob:any) => ob.data.name === o.data.value);
          if(udata.length) {
            udata = udata[0].data;
            return this.commonStructure(udata, udata.lognutritionsingle_id);
          }
          return o;
        });
        updt = updt.filter((o:any) => o.id !== 'total');
        updt.push(this.getTotalByTableData(updt));
        this.tableData[id] = updt;
        this.overAllTotal();
        this.ui.spin$.next(false);
      },
      err => {
        this.logService.logError(err);
        this.ui.spin$.next(false);
      });
    }
  }

  addItem(value:any) {
    const date = this.filterdate ? this.filterdate : new Date();
    let data = value.obj.filter((n:any)=> n.id === value.id);
    if(data.length && data[0].delete.lognutritionsingle_id) {
      let payload = {
        lognutritionsinglefoods_id: data[0].id,
        data:{
        log_qty: value.count ? value.count : data[0].servingsize.changed,
        clientnote: '',
        toggle: value.ev.checked ? 1 : 0
        }
      }
      if (value.clientnote) {
        payload.data.clientnote = value.clientnote;
      }
      this.updateFoodData([payload]);
    } else {
      let payload = {
        ...this.foodIds[data[0].data.name],
        log_qty: value.count ? value.count : data[0].servingsize.changed,
        isnutritionfood: false,
        program_id: this.program_id,
        mealfoods: [{id: value.id, log_qty: value.count ? value.count : data[0].servingsize.changed}],
        logdate: this.commonService.getGeneralFormatDate(date),
        toggle: value.ev.checked ? 1 : 0
      }
      if (value.clientnote) {
        payload.mealfoods[0].clientnote = value.clientnote;
      }
      this.saveFoodData(payload, value.id, value.obj);
    }
    this.ui.spin$.next(true);
  }

  updateFoodByTableData(ob:any, recordId: any, clientnote = '') {
    return ob.map((uni:any) => {
      uni.calories.re = uni.data.checked ? uni.calories.actualVal * uni.servingsize.changed / uni.servingsize.count : 0;
      uni.carbs.re = uni.data.checked ? uni.carbs.actualVal * uni.servingsize.changed / uni.servingsize.count : 0;
      uni.protein.re = uni.data.checked ? uni.protein.actualVal * uni.servingsize.changed / uni.servingsize.count : 0;
      uni.fat.re = uni.data.checked ? uni.fat.actualVal * uni.servingsize.changed / uni.servingsize.count : 0;
      if (recordId === uni.id && clientnote) {
        uni.data.clientnote = clientnote;
      }
      return uni;
    })
  }

  updateFoodData(payload:any) {
    this.httpService.postCall(`${config.apiUrl}/update/lognutritionsinglefoods`, payload).subscribe(data => {
      Object.keys(this.tableData).map((ob) => {
        let tmp = this.tableData[ob].filter((n:any) => n.id !== 'total');
        const [record] = payload;
        tmp = this.updateFoodByTableData(tmp, record.lognutritionsinglefoods_id, record.data.clientnote);
        let calc = this.getTotalByTableData(tmp);
        calc = calc !== false ? tmp.push(calc) : tmp;
        this.tableData[ob] = tmp;
        return ob;
      });
      this.overAllTotal();
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  saveFoodData(payload:any, type:any = '', obj:any = '') {
    this.httpService.postCall(`${config.apiUrl}/createlognutritionsinglefoodv2`, payload).subscribe(data => {
      this.searchedinfo = data?.request?.foodItems[0];
      if(!obj) {
        this.searchedinfo.id = this.searchedinfo.lognutritionsinglefoods_id;
        const newRec = this.commonStructure(this.searchedinfo, type);
        let tdata = this.tableData[type].filter((obj:any) => {if(obj.id !== 'total') return obj;});
        tdata.push(newRec);
        let calc = this.getTotalByTableData(tdata);
        calc = calc !== false ? tdata.push(calc) : tdata;
        this.tableData[type] = tdata;
      } else {
        const udata = data[0]?.data;
        Object.keys(this.tableData).map((ob) => {
          let updt =  this.tableData[ob].map((u:any) => {
            if(u.id === type) {return this.commonStructure(udata, udata.lognutritionsingle_id);} return u;
          });
          updt = updt.filter((o:any) => o.id !== 'total');
          let calc = this.getTotalByTableData(updt);
          calc = calc !== false ? updt.push(calc) : updt;
          this.tableData[ob] = updt;
          return ob;
        });
      }
      this.overAllTotal();
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
      this.ui.spin$.next(false);
    });
  }

  showGuide(name:string) {
    window.open(name === 'substitution_guide' ? this.substitutionguide_url : this.diningout_url);
  }
  onSelectShoppingList(){
    const url = `${config.apiUrl}/getshoppinglistpdf/${this.clientDetais.id}/${this.clientDetais.activeprogram_id}`;
    this.ui.spin$.next(true);
    this.httpService.getCallFile(url).subscribe(result => {
      var file = new Blob([result], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Success', 'PDF file created successfully');
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error', 'Error while creating PDF file');
    });
    return true;
  }

  searchHandler = () => {
    let timer:any;
    let context:any = this;
    return function(eve:any, id:any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let txt = eve.target.value;
        if(txt !== ''){
          context.addFood(txt, id);
          context.tmpText = txt;
        }
      }, 300);
    }
  }

  searchResult = this.searchHandler();

  addFood(val:any, datatoinclude:any) {
    this.httpService.getCall(`${config.apiUrl}/nutrition/search/${val}`).subscribe(data => {
      this.mealsplan.map((element:any) => {
        const filterData = element.mealplans.filter((ob:any) => ob.id === datatoinclude);
        if(filterData[0]) filterData[0].searchResult = data.resultsv2;
      });
    },
    err => {
      this.logService.logError(err);
    });
  }

}
