import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { HttpService } from '../services/http/http.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { config } from '../config';
import { ToasterService } from '../services/toaster/toaster.service';
import { LogService } from '../services/log/log.service';

let searchTimeout:any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass']
})
export class ReportsComponent implements OnInit {


  constructor(private commonService: CommonService,private ui: UiService,
    private httpService: HttpService, private toasterService:ToasterService,private logService: LogService) { }

  ngOnInit(): void {
    const routes = [{
      name: 'Reports',
      path: 'reports'
    }];
    this.commonService.setHeaderData(routes);
    this.fetchUsers();
  }
  displayedColumns: string[] = ['email', 'name', 'clientData', 'clientProgress'];
  dataSource = [];
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  private getFormatedData(data:any) {
    const { rows = [], count = 0 } = data;
    this.pagination.count = count;
    return rows.map((item:any) => ({
      id: item.id,
      name: `${item.firstname} ${item.lastname}`,
      email: item.email,
    }));
  }

  private fetchUsers() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: this.pagination.pageIndex,
      limit: this.pagination.perPage,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: this.pagination.searchtext
    };
      this.httpService.postCall(`${config.apiUrl}/companyadmin/getconsultants`,obj).subscribe(data => {
      this.ui.spin$.next(false);
       this.dataSource = this.getFormatedData(data);
       this.pagination.totalCount = data.count;
    },
    err => {
      this.ui.spin$.next(false);
      this.dataSource = [];
      this.pagination.totalCount = 0;
      this.toasterService.show('error', 'Alert', err?.error?.error);
      this.logService.logError(err);
    });
  }
  downloadData(id:any,url:any){
    this.ui.spin$.next(true);
    this.httpService.getCall(`${config.apiUrl}/${url}/${id}`).subscribe(data => {
      this.ui.spin$.next(false);
      this.toasterService.show('success', 'Alert', 'Data downloaded successfully');
    },
    err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Alert', err?.error?.error);
      this.logService.logError(err);
    });
  }
  getServerData(e:any) {
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.fetchUsers();
  }
  onSearchUser(x:any) {
    this.pagination.pageIndex = 0;
    this.pagination.searchtext = x.target.value;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(()=>{
      this.fetchUsers();
    }, 1000);
  }
  downloadClientData(client:any,url:any){
    this.downloadData(client.id,url);
  }

}
