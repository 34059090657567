import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common/common.service';
import { config } from '../config';
import { ToasterService } from '../services/toaster/toaster.service';
import { HttpService } from '../services/http/http.service';

@Component({
  selector: 'app-exercise-plans-uploader',
  templateUrl: './exercise-plans-uploader.component.html',
  styleUrls: ['./exercise-plans-uploader.component.sass']
})
export class ExercisePlansUploaderComponent implements OnInit {
  exerciseCategories = [];
  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private commonService: CommonService) { 
    this.getExercisePlanCategory();
  }

  ngOnInit(): void {
    const routes:any = [{
      name: 'Uploader',
      path: 'uploader'
    },{
      name: 'Exercise Plan Uploader',
      path: 'exercise-plan-uploader'
    }];

    this.commonService.setHeaderData(routes);
  }

  getExercisePlanCategory() {
    const url = `${config.apiUrl}/exerciseplans/getexerciseplantemplatecategories/`;
    this.httpService.getCall(url).subscribe(result => {
      this.exerciseCategories = result;
    }, err => {
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

}
