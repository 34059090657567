import { Component, OnInit, ViewChild } from '@angular/core';
import { config } from '../config';
import { CommonService } from '../services/common/common.service';
import { HttpService } from '../services/http/http.service';
import { ToasterService } from '../services/toaster/toaster.service';
import { LogService } from '../services/log/log.service';
import { UiService } from '../services/uiloader/uiloader.service';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.sass']
})
export class CompanySettingsComponent implements OnInit {

  constructor(private httpService: HttpService,
    private toasterService: ToasterService,
    private commonService: CommonService,
    private logService: LogService,
    private ui: UiService) { }
    @ViewChild('editTemplates') editTemplates:any;
    @ViewChild('createTemplates') createTemplates:any;

    selectedFile:any = '';
    selectedItem:string = '';
    customerData:any = {
      themecolour: ''
    };
    companyDetails$:any = {};
    imageLoading = false;
    
    allCompanies:any = [];

    selectedCompany = {id: 0, logo: ''};

  ngOnInit(): void {
    const routes:any = [{
      name: 'Company Settings',
      path: 'company-settings'
    }];
    this.commonService.setHeaderData(routes);
  }

  onSelectCompany(company:any) {
    this.selectedCompany = company;
    this.customerData.themecolour = company.color_code;
  }

  onTabChanged(event:any) {
    if(event.tab.textLabel == 'Template Setup' || event.tab.textLabel === 'Themes'){
      this.getCompanyList();
    }
  }
  getCompanyList() {
    const url = `${config.apiUrl}/companyadmin/getcompanies`;
    this.httpService.getCall(url).subscribe(result => {
      this.allCompanies = result;
      if (result.length > 0 && this.selectedCompany.id === 0) {
        this.onSelectCompany(result[0]);
      } else {
        const record = result.filter((item:any) => item.id === this.selectedCompany.id);
        this.onSelectCompany(record[0]);
      }
      this.ui.spin$.next(false);
    }, err => {
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }
   
  onAction(item:any) {
    this.customerData[this.selectedItem] = item.value;
    this.selectedItem = '';
    this.ui.spin$.next(false);
  }

  onItemSelect(val:string) {
    this.selectedItem = val;
  }

  uploadImage(fileInputEvent: any) {
    this.selectedFile = fileInputEvent.target.files[0];
  }

  onSubmitTheme() {
    const formData = new FormData();
    formData.append("color_code", this.customerData.themecolour);
    formData.append("logo", this.selectedFile, `${new Date().getTime()}.jpg`);

    this.imageLoading = true;
    this.ui.spin$.next(true);
    this.httpService.putCall(`${config.apiUrl}/companyadmin/updatecompany/${this.selectedCompany.id}`, formData).subscribe((data:any) => {
      this.selectedFile =  {};
      // const time = new Date().getTime();
      // // this.userData.avatar = `${this.imgDomainUrl}${data.avatar}?t=${time}`;
      // const obj = {
      //   data: {
      //     avatar: data.avatar,
      //   },
      //   type: 'image',
      //   isSave: true
      // };
      // this.onAction(obj);
      this.getCompanyList();
    },
    (err:any) => {
      this.logService.logError(err);
    });
  }

}
