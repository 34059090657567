<div class="main-container">
    <div class="header">
        Recipes Uploader
    </div>
    <div>
        <mat-tab-group mat-align-tabs="start" >
            <mat-tab label="Create">
                <app-recipesuploadercreate [recipesCategories]="recipesCategories"></app-recipesuploadercreate>
            </mat-tab>
            <mat-tab label="Edit">
                <app-recipesuploaderedit [recipesCategories]="recipesCategories"></app-recipesuploaderedit>
            </mat-tab>
            <mat-tab label="Categories">
                <app-recipesuploadercategories [recipesCategories]="recipesCategories"></app-recipesuploadercategories>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>