import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import {FormControl} from '@angular/forms';
import { CommonService } from '../services/common/common.service';
import { UiService } from '../services/uiloader/uiloader.service';
import { config } from '../config';
import { HttpService } from '../services/http/http.service';
import { LogService } from '../services/log/log.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-roleseditor',
  templateUrl: './roleseditor.component.html',
  styleUrls: ['./roleseditor.component.sass']
})
export class RoleseditorComponent implements OnInit {
  rolesData:any = [];
  filtered:any = [];
  privilegesData:any = [];
  searchTxt:string = '';
  adminId:any = '';
  role:string = '';
  displayedColumns: string[] = ['name', 'displayName', 'description', 'read', 'write', 'admin'];
  dataSource = [];
  rolesConstant:any = {
    read: 1,
    write: 2,
    admin: 3
  };
  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };
  // datasource: any = [];
  columnsList: string[] = ['id', 'display_name','actions'];
  permissionItems = [];

  constructor(private location: Location,
    private commonService: CommonService,
    private ui: UiService,
    private logService: LogService,
    private httpService: HttpService,
    private toasterService: ToasterService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    const routes:any = [
      { name: 'Platform Setup', path: 'platform-setup'},
      { name: 'Roles Editor', path: 'roles-editor'}
    ];
    this.commonService.setHeaderData(routes);
    this.adminId = config.ADMIN_USER_ROLE;
    this.fetchRoles();
    this.getPageItems();
  }

  onTabChanged(event:any) {
  
  }

  getPageItems() {
    const url = `${config.apiUrl}/permissionitems`;
    this.httpService.getCall(url).subscribe(data => {
      const result = data.map((item:any) => ({
        "id": item.id,
        "display_name": item.display_name
      }));
      this.permissionItems = result;
      // this.datasource = result;
    },
    err => {
      this.logService.logError(err);
    });
  }

  fetchRoles() {
    const url = `${config.apiUrl}/permission/roles`;
    this.httpService.getCall(url).subscribe(data => {
      const result = data.map((item:any) => ({
        "id": item.id,
        "display_name": item.display_name,
        "description": item.description,
        "permissions": item.permission_roles.map((rec:any) => rec.permission_id)
      }));
      this.rolesData = result;
      this.filtered = result;
      this.ui.spin$.next(false);
    },
    err => {
      this.logService.logError(err);
    });
  }

  getFormattedPrivileges(data:any) {
    const result = data.map((item:any) => {
      const {privileges = []} = item;
      return { 
        permission_id: item.permission_id,
        name: item.permissions[0].name,
        displayName: item.permissions[0].display_name,
        description: item.permissions[0].description,
        read: privileges.indexOf(1) !== -1,
        write: privileges.indexOf(2) !== -1,
        admin: privileges.indexOf(3) !== -1
      }
    });
    return result;
  }

  fetchPermissions() {
    this.ui.spin$.next(true);
    const obj = {
      fromIndex: this.pagination.pageIndex,
      limit: this.pagination.perPage,
      orderBy: "DESC",
      orderByOn: 'id',
      searchtext: this.pagination.searchtext
    };
    const url = `${config.apiUrl}/permission/permissionprivileges/${this.role}`;
    this.httpService.getCall(url).subscribe(data => {
      this.dataSource = this.getFormattedPrivileges(data);
      this.ui.spin$.next(false);
    },
    err => {
      this.ui.spin$.next(false);
      this.logService.logError(err);
    });
  }

  deleteRole(row:any,index:Number){
    this.ui.spin$.next(true);
    this.httpService.deleteCall(`${config.apiUrl}/permission/deleterole/${row.id}`,{}).subscribe(data => {
      if(data){
       // this.getCompanyList();
       this.rolesData.splice(index, 1);
       this.toasterService.show('success', 'Success', 'Role Deleted Successfully');
       this.ui.spin$.next(false);
      }     
     },
     err => {
       this.ui.spin$.next(false);
       this.logService.logError(err);
       this.toasterService.show('success', 'Success', err.error.error);
     });
  }

  editRole(item:any, index:number) {
    const dialogRef = this.dialog.open(CreateRoleDialog, {
      width: '600px',
      data: {
        permissionItems: this.permissionItems,
        isEditMode: true,
        name: item.display_name,
        description: item.description,
        permissionsSelected: item.permissions
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.name) {
        this.ui.spin$.next(true);
        this.httpService.putCall(`${config.apiUrl}/permission/updaterole/${item.id}`, {'name': result.name, 'description': result.description, 'permissions': result.permissionsSelected}).subscribe(data => {
          if(data.message){
            this.fetchRoles();
          } else {
            this.ui.spin$.next(false);
          }
        },
        err => {
          this.ui.spin$.next(false);
          this.logService.logError(err);
        });        
      }
    });
  }

  addRole() {
    const dialogRef = this.dialog.open(CreateRoleDialog, {
      width: '600px',
      data: {
        permissionItems: this.permissionItems
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.name) {
        this.ui.spin$.next(true);
        this.httpService.postCall(`${config.apiUrl}/permission/createrole`, {'name': result.name, 'description': result.description, 'permissions': result.permissionsSelected}).subscribe(data => {
          if(data.message){
            this.fetchRoles();
          } else {
            this.ui.spin$.next(false);
          }
        },
        err => {
          this.ui.spin$.next(false);
          this.logService.logError(err);
        });        
      }
    });
  }

  cancelEdit(row: any, field: string, i: number = 5) {
    this.rolesData[i][`${field}Edit`] = false;
  }

  enableEdit(row: any, field: any, i: number) {
    this.rolesData[i][`${field}Edit`] = true;
  }

  searchResult(ev:any) {
    const text = this.searchTxt.toLowerCase();
    const filteredResult = this.rolesData.filter((item:any) => item.display_name.toLowerCase().indexOf(text) !== -1);
    this.filtered = filteredResult;
  }

  saveNewValue(row: any, key: string, i: number) {
    this.httpService.putCall(`${config.apiUrl}/permission/updaterole/${row.id}`, {name:row.display_name}).subscribe(data => {
      if(data.message){
       this.ui.spin$.next(false);
       this.cancelEdit(row, key, i);
      }     
     },
     err => {
       this.ui.spin$.next(false);
       this.logService.logError(err);
     });
  }

  onGoBack() {
    this.location.back();
  }

  onAction(item: any) {
    console.log(item);
  }

  getServerData(e:any) {
    console.log(e.pageIndex);
    console.log(e.pageSize);
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.fetchPermissions();
  }

  onChangeRole() {
    this.fetchPermissions();
  }

  onPrivilegeChange(item:any, type:string) {

    const url = `${config.apiUrl}/permission/updatepermissionprivileges/${this.role}`;
    const obj:any = {
      permission_id: item.permission_id,
      privilege_id_status: item[type] ? 1 : 0,
      privilege_id: this.rolesConstant[type]
    };
    this.httpService.putCall(url, obj).subscribe(data => {
      console.log(data);
    },
    err => {
      this.logService.logError(err);
    });
  }

}

@Component({
  selector: 'create-role-dialog',
  templateUrl: './create-role-dialog.html',
  styleUrls: ['../newprogram/newprogram.component.sass']
})
export class CreateRoleDialog {
  constructor(
    public dialogRef: MatDialogRef<CreateRoleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  responseHandler(action:any): void {
    this.dialogRef.close(action);
  }
}