<div>
    <div class="top-section">
        <div class="search-container">
          <div class="input-container">
              <mat-icon matPrefix>search</mat-icon>
              <input type="text" placeholder="Search" [(ngModel)]="searchText" class="search-input" autocomplete="off" (keyup)="searchResult($event)" />
          </div>
          <button class="deletebtn" [ngClass]="{'highlight': deletehighlight}" [disabled]="!deletehighlight" (click)="deleteNutritions()">Delete Selected</button>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="datasource" multiTemplateDataRows>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> 
                  <mat-checkbox class="checkbx" (change)="setSelection($event)" [checked]="isAllSelected"></mat-checkbox>
                  <span class="disp-name">Name</span>
                </th>
                <td mat-cell *matCellDef="let element; let i = dataIndex"
                  class="name_field"
                > 
                  <mat-checkbox class="checkbx" (click)="$event.stopPropagation()" (change)="setSelection($event, element)" [checked]="element.checked"></mat-checkbox>
                  <div class="expand-icon">
                    <span class="icon" (click)="onExpandNutritionItem(i); element.isExpanded = !element.isExpanded">
                      <mat-icon *ngIf="!element.isExpanded">keyboard_arrow_right</mat-icon>
                      <mat-icon *ngIf="element.isExpanded">keyboard_arrow_down</mat-icon>
                    </span>
                    <span class="food-name">
                      <span class="disp-name" (click)="enableEdit(element, 'name', i)" *ngIf="!element.nameEdit">{{element.name}} </span>
                      <span *ngIf="element.nameEdit" class="edit-column" >
                        <input [(ngModel)]="element.name" [placeholder]="element.name" class="edit-field" />
                        <button class="savebtn" (click)="saveNewValue(element, 'name', i)">Save</button>
                        <button class="cancelbtn" (click)="cancelEdit(element, 'name', i)">Cancel</button>
                      </span>
                    </span>
                  </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef><span class="disp-name"> Category </span></th>
                <td mat-cell *matCellDef="let element;let i = dataIndex"> 
                  <span class="disp-name" (click)="enableEdit(element, 'category', i)" *ngIf="!element.categoryEdit">{{element.nutritionprogramcategory.name}} </span>
                  <span *ngIf="element.categoryEdit" class="edit-column" >
                    <!-- <input [(ngModel)]="element.categoryModel" class="edit-field" /> -->
                    <div class="select-ddl">
                    <mat-form-field class="width-full">
                      <mat-label>Select Category</mat-label>
                    <mat-select [(ngModel)]="element.nutritionprogramcategory_id">
                      <mat-option *ngFor="let data of nutritionCategories"  [value]="data.id">{{data.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>
                    <button class="savebtn" (click)="saveNewValue(element, 'category', i)">Save</button>
                    <button class="cancelbtn" (click)="cancelEdit(element, 'category', i)">Cancel</button>
                  </span>
                </td>
              </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef><span class="disp-name"> Description </span></th>
                <td mat-cell *matCellDef="let element; let i = dataIndex"> 
                  <span class="disp-name" (click)="enableEdit(element, 'description', i)" *ngIf="!element.descriptionEdit">{{element.description}}</span>
                  <span *ngIf="element.descriptionEdit" class="edit-column" >
                    <input [(ngModel)]="element.description" [placeholder]="element.description" class="edit-field" />
                    <button class="savebtn" (click)="saveNewValue(element, 'description', i)">Save</button>
                    <button class="cancelbtn" (click)="cancelEdit(element, 'description', i)">Cancel</button>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="columnsList.length" >
                    <div class="expanded-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                        <div class="details">
                          <div>
                            <button class="deletebtn" [ngClass]="{'highlight': element.deletehighlight}" [disabled]="!element.deletehighlight" (click)="deleteInnerNutritions(i)">Delete Selected</button>
                          </div>
                          <table mat-table [dataSource]="element.nutritionprogrammealplans" multiTemplateDataRows class="mat-elevation-z10" >
                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox class="checkbx" (change)="setInnerSelection($event, i)" [checked]="element.isAllSelected"></mat-checkbox>
                                <span>Name</span>  
                              </th>
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                                <mat-checkbox class="checkbx" [(ngModel)]="innerElement.checked" [checked]="innerElement.checked" (change)="setInnerSelection($event, i, innerElement)"></mat-checkbox>
      
                                <div class="expand-icon">
                                  <span class="icon" (click)="onDayNutritionInnerSelection(innerElement, innerI, i); innerElement.isExpanded = !innerElement.isExpanded">
                                    <mat-icon *ngIf="!innerElement.isExpanded">keyboard_arrow_right</mat-icon>
                                    <mat-icon *ngIf="innerElement.isExpanded">keyboard_arrow_down</mat-icon>
                                  </span>
                                  <span class="food-name">
                                    <span class="disp-name" (click)="enableInnerEdit(innerElement, 'name', i, innerI)" *ngIf="!innerElement.nameEdit">{{innerElement.name}} </span>
                                    <span *ngIf="innerElement.nameEdit" class="edit-column" >
                                      <input [(ngModel)]="innerElement.nameModal" [placeholder]="innerElement.name" class="edit-field" />
                                      <button class="savebtn" (click)="saveInnerNewValue(innerElement, 'name', i, innerI)">Save</button>
                                      <button class="cancelbtn" (click)="cancelInnerEdit(innerElement, 'name', i, innerI)">Cancel</button>
                                    </span>
                                  </span>
                                </div>
                                
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="sunday">
                              <th mat-header-cell *matHeaderCellDef> Sunday </th>
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                                <span class="disp-name" (click)="enableInnerEdit(innerElement, 'sunday', i, innerI)" *ngIf="!innerElement.sundayEdit">{{innerElement.sunday ? true : false}}</span>
                                <span *ngIf="innerElement.sundayEdit" class="edit-column" >
                                  <mat-slide-toggle [(ngModel)]="innerElement.sundayModal">Sunday</mat-slide-toggle>
                                  <button class="savebtn" (click)="saveInnerNewValue(innerElement, 'sunday', i, innerI)">Save</button>
                                  <button class="cancelbtn" (click)="cancelInnerEdit(innerElement, 'sunday', i, innerI)">Cancel</button>
                                </span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="monday">
                              <th mat-header-cell *matHeaderCellDef> Monday </th>
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                                <span class="disp-name" (click)="enableInnerEdit(innerElement, 'monday', i, innerI)" *ngIf="!innerElement.mondayEdit">{{innerElement.monday ? true : false}}</span>
                                <span *ngIf="innerElement.mondayEdit" class="edit-column" >
                                  <mat-slide-toggle [(ngModel)]="innerElement.mondayModal">Monday</mat-slide-toggle>
                                  <button class="savebtn" (click)="saveInnerNewValue(innerElement, 'monday', i, innerI)">Save</button>
                                  <button class="cancelbtn" (click)="cancelInnerEdit(innerElement, 'monday', i, innerI)">Cancel</button>
                                </span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="tuesday">
                              <th mat-header-cell *matHeaderCellDef> Tuesday </th>
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                                <span class="disp-name" (click)="enableInnerEdit(innerElement, 'tuesday', i, innerI)" *ngIf="!innerElement.tuesdayEdit">{{innerElement.tuesday ? true : false}}</span>
                                <span *ngIf="innerElement.tuesdayEdit" class="edit-column" >
                                  <mat-slide-toggle [(ngModel)]="innerElement.tuesdayModal">Tuesday</mat-slide-toggle>
                                  <button class="savebtn" (click)="saveInnerNewValue(innerElement, 'tuesday', i, innerI)">Save</button>
                                  <button class="cancelbtn" (click)="cancelInnerEdit(innerElement, 'tuesday', i, innerI)">Cancel</button>
                                </span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="wednesday">
                              <th mat-header-cell *matHeaderCellDef> Wednesday </th>
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                                <span class="disp-name" (click)="enableInnerEdit(innerElement, 'wednesday', i, innerI)" *ngIf="!innerElement.wednesdayEdit">{{innerElement.wednesday ? true : false}}</span>
                                <span *ngIf="innerElement.wednesdayEdit" class="edit-column" >
                                  <mat-slide-toggle [(ngModel)]="innerElement.wednesdayModal">Wednesday</mat-slide-toggle>
                                  <button class="savebtn" (click)="saveInnerNewValue(innerElement, 'wednesday', i, innerI)">Save</button>
                                  <button class="cancelbtn" (click)="cancelInnerEdit(innerElement, 'wednesday', i, innerI)">Cancel</button>
                                </span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="thursday">
                              <th mat-header-cell *matHeaderCellDef> Thursday </th>
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                                <span class="disp-name" (click)="enableInnerEdit(innerElement, 'thursday', i, innerI)" *ngIf="!innerElement.thursdayEdit">{{innerElement.thursday ? true : false}}</span>
                                <span *ngIf="innerElement.thursdayEdit" class="edit-column" >
                                  <mat-slide-toggle [(ngModel)]="innerElement.thursdayModal">Thursday</mat-slide-toggle>
                                  <button class="savebtn" (click)="saveInnerNewValue(innerElement, 'thursday', i, innerI)">Save</button>
                                  <button class="cancelbtn" (click)="cancelInnerEdit(innerElement, 'thursday', i, innerI)">Cancel</button>
                                </span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="friday">
                              <th mat-header-cell *matHeaderCellDef> Friday </th>
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                                <span class="disp-name" (click)="enableInnerEdit(innerElement, 'friday', i, innerI)" *ngIf="!innerElement.fridayEdit">{{innerElement.friday ? true : false}}</span>
                                <span *ngIf="innerElement.fridayEdit" class="edit-column" >
                                  <mat-slide-toggle [(ngModel)]="innerElement.thursdayModal">Friday</mat-slide-toggle>
                                  <button class="savebtn" (click)="saveInnerNewValue(innerElement, 'friday', i, innerI)">Save</button>
                                  <button class="cancelbtn" (click)="cancelInnerEdit(innerElement, 'friday', i, innerI)">Cancel</button>
                                </span>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="saturday">
                              <th mat-header-cell *matHeaderCellDef> Saturday </th>
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex"> 
                                <span class="disp-name" (click)="enableInnerEdit(innerElement, 'saturday', i, innerI)" *ngIf="!innerElement.saturdayEdit">{{innerElement.saturday ? true : false}}</span>
                                <span *ngIf="innerElement.thursdayEdit" class="edit-column" >
                                  <mat-slide-toggle [(ngModel)]="innerElement.saturdayModal">Saturday</mat-slide-toggle>
                                  <button class="savebtn" (click)="saveInnerNewValue(innerElement, 'saturday', i, innerI)">Save</button>
                                  <button class="cancelbtn" (click)="cancelInnerEdit(innerElement, 'saturday', i, innerI)">Cancel</button>
                                </span>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="subExpandedDetail">
                              <td mat-cell *matCellDef="let innerElement; let innerI = dataIndex" colSpan="8">
                                <div class="expanded-detail" [@detailExpand]="innerElement.isExpanded ? 'expanded' : 'collapsed'">
                                  <div>
                                    <div>
                                      <button class="deletebtn" [ngClass]="{'highlight': innerElement.deletehighlight}" [disabled]="!innerElement.deletehighlight" (click)="deleteMealTypeNutritions(i, innerI)">Delete Selected</button>
                                    </div>
                                    <table mat-table [dataSource]="innerElement.nutritionprogrammeals" multiTemplateDataRows>
                                      <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef>
                                          <mat-checkbox class="checkbx" (change)="setMealTypeSelection($event, i, innerI)" [checked]="innerElement.isAllSelected"></mat-checkbox>
                                          <span>Name</span>
                                        </th>
                                        <td mat-cell *matCellDef="let mealTypeElement; let mealTypeInnerI = dataIndex"> 
                                          <mat-checkbox class="checkbx" [(ngModel)]="mealTypeElement.checked" [checked]="mealTypeElement.checked" (change)="setMealTypeSelection($event, i, innerI, mealTypeElement)"></mat-checkbox>
                                          <span class="icon" (click)="onMealTypeSelection(mealTypeElement, mealTypeInnerI, innerI, i); mealTypeElement.isExpanded = !mealTypeElement.isExpanded">
                                            <mat-icon *ngIf="!mealTypeElement.isExpanded">keyboard_arrow_right</mat-icon>
                                            <mat-icon *ngIf="mealTypeElement.isExpanded">keyboard_arrow_down</mat-icon>
                                          </span>
                                          {{mealTypeElement.name}}
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="time">
                                        <th mat-header-cell *matHeaderCellDef> Time </th>
                                        <td mat-cell *matCellDef="let mealTypeElement; let mealTypeInnerI = dataIndex"> 
                                          {{mealTypeElement.time}}
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="duration">
                                        <th mat-header-cell *matHeaderCellDef> Duration </th>
                                        <td mat-cell *matCellDef="let mealTypeElement; let mealTypeInnerI = dataIndex"> 
                                          {{mealTypeElement.duration}}
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="water_8oz">
                                        <th mat-header-cell *matHeaderCellDef> Waters </th>
                                        <td mat-cell *matCellDef="let mealTypeElement; let mealTypeInnerI = dataIndex"> 
                                          {{mealTypeElement.water_8oz}}
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="waternote">
                                        <th mat-header-cell *matHeaderCellDef> WaterNote </th>
                                        <td mat-cell *matCellDef="let mealTypeElement; let mealTypeInnerI = dataIndex"> 
                                          {{mealTypeElement.waternote}}
                                        </td>
                                      </ng-container>

                                      <ng-container matColumnDef="mealTypeExpandedDetail">
                                        <td mat-cell *matCellDef="let mealTypeElement; let mealTypeInnerI = dataIndex" colSpan="5">
                                          <div class="expanded-detail" [@detailExpand]="mealTypeElement.isExpanded ? 'expanded' : 'collapsed'">
                                            <div class="details">
                                              <div>
                                                <button class="deletebtn" [ngClass]="{'highlight': mealTypeElement.deletehighlight}" [disabled]="!mealTypeElement.deletehighlight" (click)="deleteMealNutritions(i, innerI, mealTypeInnerI)">Delete Selected</button>
                                              </div>
                                              <div>
                                                <table mat-table [dataSource]="mealTypeElement.nutritionprogrammealfoods" multiTemplateDataRows class="mat-elevation-z10" >
                                                  
                                                  <ng-container matColumnDef="name">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                      <mat-checkbox class="checkbx" (change)="setSpecificMealSelection($event, i, innerI, mealTypeInnerI)" [checked]="mealTypeElement.isAllSelected"></mat-checkbox>
                                                      <span>Name</span>
                                                    </th>
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex"> 
                                                      <mat-checkbox class="checkbx" (click)="$event.stopPropagation()" (change)="setSpecificMealSelection($event, i, innerI, mealTypeInnerI, specificMealElement)" [checked]="specificMealElement.checked"></mat-checkbox>
                                                      <div class="expand-icon">
                                                        <span class="icon" (click)="specificMealElement.isExpanded = !specificMealElement.isExpanded">
                                                          <mat-icon *ngIf="!specificMealElement.isExpanded">keyboard_arrow_right</mat-icon>
                                                          <mat-icon *ngIf="specificMealElement.isExpanded">keyboard_arrow_down</mat-icon>
                                                        </span>
                                                        <span class="food-name">
                                                          <span class="disp-name" (click)="enableSpecificMealEdit(specificMealElement, 'name', i, innerI, mealTypeInnerI, specficMealI)" *ngIf="!specificMealElement.nameEdit"> {{specificMealElement.name}} </span>
                                                          <span *ngIf="specificMealElement.nameEdit" class="edit-column" >
                                                            <input [(ngModel)]="specificMealElement.nameModal" [placeholder]="specificMealElement.name" class="edit-field" />
                                                            <button class="savebtn" (click)="saveSpecificMealNewValue(specificMealElement, 'name', i, innerI, mealTypeInnerI, specficMealI)">Save</button>
                                                            <button class="cancelbtn" (click)="cancelSpecificMealEdit(specificMealElement, 'name', i, innerI, mealTypeInnerI, specficMealI)">Cancel</button>
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                  </ng-container>

                                                  <ng-container matColumnDef="calories">
                                                    <th mat-header-cell *matHeaderCellDef> Calories </th>
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex"> 
                                                      {{specificMealElement.calories}}
                                                    </td>
                                                  </ng-container>

                                                  <ng-container matColumnDef="protein">
                                                    <th mat-header-cell *matHeaderCellDef> Protein </th>
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex"> 
                                                      {{specificMealElement.protein}}
                                                    </td>
                                                  </ng-container>

                                                  <ng-container matColumnDef="carbs">
                                                    <th mat-header-cell *matHeaderCellDef> Carbs </th>
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex"> 
                                                      {{specificMealElement.carbs}}
                                                    </td>
                                                  </ng-container>

                                                  <ng-container matColumnDef="fat">
                                                    <th mat-header-cell *matHeaderCellDef> Fat </th>
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex"> 
                                                      {{specificMealElement.fat}}
                                                    </td>
                                                  </ng-container>

                                                  <ng-container matColumnDef="unit">
                                                    <th mat-header-cell *matHeaderCellDef> Unit </th>
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex"> 
                                                      {{specificMealElement.unit}}
                                                    </td>
                                                  </ng-container>

                                                  <ng-container matColumnDef="qty">
                                                    <th mat-header-cell *matHeaderCellDef> Qty </th>
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex"> 
                                                      <span class="disp-name" (click)="enableSpecificMealEdit(specificMealElement, 'qty', i, innerI, mealTypeInnerI, specficMealI)" *ngIf="!specificMealElement.qtyEdit"> {{specificMealElement.qty}} </span>
                                                      <span *ngIf="specificMealElement.qtyEdit" class="edit-column" >
                                                        <input [(ngModel)]="specificMealElement.qtyModal" [placeholder]="specificMealElement.qty" class="edit-field" />
                                                        <button class="savebtn" (click)="saveSpecificMealNewValue(specificMealElement, 'qty', i, innerI, mealTypeInnerI, specficMealI)">Save</button>
                                                        <button class="cancelbtn" (click)="cancelSpecificMealEdit(specificMealElement, 'qty', i, innerI, mealTypeInnerI, specficMealI)">Cancel</button>
                                                      </span>
                                                    </td>
                                                  </ng-container>

                                                  <ng-container matColumnDef="note">
                                                    <th mat-header-cell *matHeaderCellDef> Note </th>
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex"> 
                                                      <span class="disp-name" (click)="enableSpecificMealEdit(specificMealElement, 'note', i, innerI, mealTypeInnerI, specficMealI)" *ngIf="!specificMealElement.noteEdit"> {{specificMealElement.note}} </span>
                                                      <span *ngIf="specificMealElement.noteEdit" class="edit-column" >
                                                        <input [(ngModel)]="specificMealElement.noteModal" [placeholder]="specificMealElement.note" class="edit-field" />
                                                        <button class="savebtn" (click)="saveSpecificMealNewValue(specificMealElement, 'note', i, innerI, mealTypeInnerI, specficMealI)">Save</button>
                                                        <button class="cancelbtn" (click)="cancelSpecificMealEdit(specificMealElement, 'note', i, innerI, mealTypeInnerI, specficMealI)">Cancel</button>
                                                      </span>
                                                    </td>
                                                  </ng-container>

                                                  <ng-container matColumnDef="specificMealExpandedDetail">
                                                    <td mat-cell *matCellDef="let specificMealElement; let specficMealI = dataIndex" colSpan="5">
                                                      <div class="expanded-detail" [@detailExpand]="specificMealElement.isExpanded ? 'expanded' : 'collapsed'">
                                                        <div class="details">
                                                          <div>
                                                            <div *ngFor="let item of specificMealElement | keyvalue">
                                                              <span>{{item.key}}</span>: <span>{{item.value}}</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </ng-container>

                                                  <tr mat-header-row *matHeaderRowDef="specificMealColumns;sticky: true"></tr>
                                                  <tr mat-row *matRowDef="let specificMealElement; columns: specificMealColumns;" class="element-row" [class.expanded-row]="specificMealElement.isExpanded"></tr>
                                                  <tr mat-row *matRowDef="let row; columns: ['specificMealExpandedDetail']" class="detail-row"></tr>
                                                </table>
                                              </div>
                                              <div>
                                                <app-nutritionix-food [selectedIndex]="i" (action)="onFoodSelection($event)"></app-nutritionix-food>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </ng-container>
                                      
                                      <tr mat-header-row *matHeaderRowDef="mealTypesColumns;sticky: true"></tr>
                                      <tr mat-row *matRowDef="let mealTypeElement; columns: mealTypesColumns;" class="element-row" [class.expanded-row]="mealTypeElement.isExpanded"></tr>
                                      <tr mat-row *matRowDef="let row; columns: ['mealTypeExpandedDetail']" class="detail-row"></tr>
                                    </table>
                                  </div>
                                </div>
                              </td>
                            </ng-container>
                            
                            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns;sticky: true"></tr>
                            <tr mat-row *matRowDef="let innerElement; columns: innerDisplayedColumns;" class="element-row" [class.expanded-row]="innerElement.isExpanded"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['subExpandedDetail']" class="detail-row"></tr>
                          </table>
                        </div>
                    </div>
                </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsList;sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsList;" class="element-row" [class.expanded-row]="element.isExpanded"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator
        [length]="pagination.count"
        [pageIndex]="pagination.pageIndex"
        [pageSize]="pagination.perPage"
        [pageSizeOptions]="[10, 25, 50, 100, 200]"
        (page)="getServerData($event)"
  showFirstLastButtons></mat-paginator>
    </div>
</div>