<div class="main-container">
    <div class="header">
        Foods Uploader
    </div>
    <div>
        <mat-tab-group mat-align-tabs="start" >
            <mat-tab label="Create" *ngIf="userPrivilege > 1">
                <app-foodsuploadercreate [foodsCategories]="foodsCategories"></app-foodsuploadercreate>
            </mat-tab>
            <mat-tab label="Edit">
                <app-fooduploaderedit [userPrivilege] = "userPrivilege" [foodsCategories]="foodsCategories"></app-fooduploaderedit>
            </mat-tab>
            <mat-tab label="Settings" >
                <app-fooduploadsettings [userPrivilege] = "userPrivilege"></app-fooduploadsettings>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>