import { Component, OnInit, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { config } from '../../config';
import { HttpService } from '../../services/http/http.service';
import { UiService } from '../../services/uiloader/uiloader.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { LogService } from '../../services/log/log.service';

let nutritionTimer: any;

@Component({
  selector: 'app-nutrition-program-uploader-edit',
  templateUrl: './nutrition-program-uploader-edit.component.html',
  styleUrls: ['./nutrition-program-uploader-edit.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class NutritionProgramUploaderEditComponent implements OnInit {
  @Input() nutritionCategories:any;

  deletehighlight: boolean = false;
  isAllSelected: any = false;

  pagination = {
    count: 0,
    perPage: 25,
    pageIndex: 0,
    searchtext: '',
    totalCount: 0
  };

  searchText = '';

  datasource: any = [];
  columnsList: string[] = ['name', 'category', 'description'];
  innerDisplayedColumns: string[] = ['name', 'sunday','monday','tuesday','wednesday','thursday','friday','saturday'];
  mealTypesColumns:string[] = ['name', 'time', 'duration', 'water_8oz', 'waternote'];
  specificMealColumns:string[] = ['name', 'calories', 'carbs', 'protein', 'fat', 'unit', 'qty', 'note'];

  constructor(private httpService: HttpService,
    private ui: UiService,
    private toasterService: ToasterService,
    private logService: LogService) { 
    this.getNutritionPrograms();
  }

  ngOnInit(): void {
  }

  getNutritionPrograms() {
    const url = `${config.apiUrl}/nutritionprograms/getnutritionprograms`;
    const reqObj = {
      "orderByOn": "name",
      "orderBy": "ASC",
      "limit": this.pagination.perPage,
      "fromIndex": this.pagination.pageIndex,
      "searchtext": this.searchText
    };
    this.httpService.postCall(url, reqObj).subscribe((result:any) => {
      this.datasource = result.rows.map((item: any) => ({
        ...item,
        isExpanded: false
      }
      ));
      this.pagination.count = result.count;
      this.ui.spin$.next(false);
    }, err => {
      this.ui.spin$.next(false);
      this.toasterService.show('error', 'Error Occurred', 'Error occurred, Please try again');
    });
  }

  getServerData(e: any) {
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.perPage = e.pageSize;
    this.getNutritionPrograms();
  }

  deleteNutritions() {
    this.ui.spin$.next(true);
    const payload = this.datasource.filter((obj: any) => obj.checked).map((obj: any) => obj.id);
    this.httpService.putCall(`${config.apiUrl}/nutritionprograms/deletenutritionprograms`, { nutritionprogram_ids: payload }).subscribe((result: any) => {
      this.datasource = this.datasource.filter((val: any) => !val.checked);
      this.masterSelection();
      this.toasterService.show('success', 'Success', 'Nutrition template deleted successfully');
      this.ui.spin$.next(false);
      this.getNutritionPrograms();
    }, (err: any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      this.ui.spin$.next(false);
    });
  }

  onExpandNutritionItem(index:number) {
    // const data = this.meal
  }

  searchRecords(text: string) {
    this.searchText = text;
    this.pagination.pageIndex = 0;
    this.getNutritionPrograms();
  }

  searchResult(ev: any) {
    clearTimeout(nutritionTimer);
    nutritionTimer = setTimeout(() => {
      this.searchRecords(ev.target.value);
    }, 1000);
  }

  saveNewValue(row: any, key: string, i: number) {
    const url = `${config.apiUrl}/nutritionprograms/updatenutritionprogram/${row.id}`;
    let req: any = {
    }
    // if (key === 'category') {
    //   req.exercisecategory = row.nutritionprogramcategory_id;
    // } else {
      req[key] = row[`${key}`];
    // }
    if(req[key]=="" || req.nutritionprogramcategory_id=="" || req.nutritionprogramcategory_id<=0){
      this.toasterService.show('error', "Invalid "+key+" value", key+" value is required to update");
      return;
    }
    
    this.httpService.putCall(url, req).subscribe(result => {
      if (key === 'category') {
        const records = this.nutritionCategories.filter((item: any) => item.id === row.nutritionprogramcategory_id);
        this.datasource[i].nutritionprogramcategory.name = records[0].name;
      }
      this.cancelEdit(row, key, i);
      this.toasterService.show('success', 'Success', 'Updated successfully');
    }, err => {
      this.toasterService.show('error', 'Failed', 'Failed to update');
    });
  }

  masterSelection() {
    let highlgt = this.datasource.find((o: any) => o.checked == true);
    highlgt ? this.deletehighlight = true : this.deletehighlight = false;
  }

  setSelection(ev: any, row: any = '') {
    let overall = true;
    this.datasource = this.datasource.map((ob: any) => {
      if (row && ob.id === row.id) ob.checked = ev.checked;
      if (!row) ob.checked = ev.checked;
      if (!ob.checked) overall = false;
      return ob;
    });
    this.masterSelection();
    this.isAllSelected = overall;
  }

  masterInnerSelection(index:number) {
    let highlgt = this.datasource[index].nutritionprogrammealplans.find((o: any) => o.checked == true);
    highlgt ? this.datasource[index].deletehighlight = true : this.datasource[index].deletehighlight = false;
  }

  setInnerSelection(ev:any, index:number, row:any = '') {
    let overall = true;
    this.datasource[index].nutritionprogrammealplans = this.datasource[index].nutritionprogrammealplans.map((ob: any) => {
      if (row && ob.id === row.id) ob.checked = ev.checked;
      if (!row) ob.checked = ev.checked;
      if (!ob.checked) overall = false;
      return ob;
    });
    this.masterInnerSelection(index);
    this.datasource[index].isAllSelected = overall;
  }

  enableEdit(row: any, field: any, i: number) {
    this.datasource[i][`${field}Edit`] = true;
  }

  cancelEdit(row: any, field: string, i: number = 0) {
    this.datasource[i][`${field}Edit`] = false;
  }

  enableInnerEdit(row:any, field: string, i: number, innerI: number) {
    this.datasource[i].nutritionprogrammealplans[innerI][`${field}Edit`] = true;
    if (field !== 'name') {
      this.datasource[i].nutritionprogrammealplans[innerI][`${field}Modal`] = this.datasource[i].nutritionprogrammealplans[innerI][`${field}`];
    }
  }

  saveInnerNewValue(row:any, field: string, i: number, innerI: number) {
    const { id } = this.datasource[i];
    const url = `${config.apiUrl}/nutritionprograms/updatenutritionprogrammealplan/${id}/${row.id}`;
    const reqObj:any = {
    };
    if (field === 'name') {
      reqObj[field] = row[`${field}Modal`];
    } else {
      reqObj[field] = row[`${field}Modal`] ? 1 : 0;
    }
    this.httpService.putCall(url,reqObj).subscribe(result => {
      this.datasource[i].nutritionprogrammealplans[innerI][field] = reqObj[field];
      this.cancelInnerEdit(row, field, i, innerI);
    }, err => {

    });
  }

  cancelInnerEdit(row:any, field: string, i: number, innerI: number) {
    this.datasource[i].nutritionprogrammealplans[innerI][`${field}Edit`] = false;
  }

  deleteInnerNutritions(index:number) {
    this.ui.spin$.next(true);
    const { id } = this.datasource[index];
    const payload = this.datasource[index].nutritionprogrammealplans.filter((obj: any) => obj.checked).map((obj: any) => obj.id);
    this.httpService.putCall(`${config.apiUrl}/nutritionprograms/deletenutritionprogrammealplans/${id}`, { nutritionprogrammealplan_ids: payload }).subscribe((result: any) => {
      this.datasource[index].nutritionprogrammealplans = this.datasource[index].nutritionprogrammealplans.filter((val: any) => !val.checked);
      this.masterInnerSelection(index);
      this.datasource[index].isAllSelected = false;
      this.toasterService.show('success', 'Success', 'Nutrition template deleted successfully');
      this.ui.spin$.next(false);
      // this.getNutritionPrograms();
    }, (err: any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      this.ui.spin$.next(false);
    });
  }

  onDaySetSelection(event:any, i:number) {

  }

  onDayNutritionInnerSelection (event:any, innerIndex:number, index:number) {
    const { id } = this.datasource[index];
    const url = `${config.apiUrl}/nutritionprograms/getnutritionprogrammealplans/${id}`;
    this.httpService.getCall(url).subscribe(result => {
      const { nutritionprogrammeals } = result.nutritionprogrammealplans[0];
      this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals = nutritionprogrammeals;
    }, err => {
      console.log(err);
    });
  }

  deleteMealTypeNutritions(parentIndex:number, innerIndex:number) {

  }

  masterMealTypeSelection(index:number, innerIndex:number) {
    let highlgt = this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals.find((o: any) => o.checked == true);
    highlgt ? this.datasource[index].nutritionprogrammealplans[innerIndex].deletehighlight = true : this.datasource[index].nutritionprogrammealplans[innerIndex].deletehighlight = false;
  }

  setMealTypeSelection(ev:any, index:number, innerIndex:number, row:any = '') {
    let overall = true;
    this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals = this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals.map((ob: any) => {
      if (row && ob.id === row.id) ob.checked = ev.checked;
      if (!row) ob.checked = ev.checked;
      if (!ob.checked) overall = false;
      return ob;
    });
    this.masterMealTypeSelection(index, innerIndex);
    this.datasource[index].nutritionprogrammealplans[innerIndex].isAllSelected = overall;
  }

  masterSpecificMealSelection(index:number, innerIndex:number, mealTypeInnerIndex:number) {
    let highlgt = this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealTypeInnerIndex].nutritionprogrammealfoods.find((o: any) => o.checked == true);
    highlgt ? this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealTypeInnerIndex].deletehighlight = true : this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealTypeInnerIndex].deletehighlight = false;
  }

  setSpecificMealSelection(ev:any, index:number, innerIndex:number, mealTypeInnerIndex:number, row:any = '') {
    let overall = true;
    this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealTypeInnerIndex].nutritionprogrammealfoods = this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealTypeInnerIndex].nutritionprogrammealfoods.map((ob: any) => {
      if (row && ob.id === row.id) ob.checked = ev.checked;
      if (!row) ob.checked = ev.checked;
      if (!ob.checked) overall = false;
      return ob;
    });
    this.masterSpecificMealSelection(index, innerIndex, mealTypeInnerIndex);
    this.datasource[index].nutritionprogrammealplans[innerIndex].isAllSelected = overall;
  }

  cancelSpecificMealEdit(row:any, field: string, index: number, innerI: number, mealInnerI: number, specificMealI: number) {
    this.datasource[index].nutritionprogrammealplans[innerI].nutritionprogrammeals[mealInnerI].nutritionprogrammealfoods[specificMealI][`${field}Edit`] = false;
  }

  enableSpecificMealEdit(row:any, field: string, index: number, innerI: number, mealInnerI: number, specificMealI: number) {
    const specificele = this.datasource[index].nutritionprogrammealplans[innerI].nutritionprogrammeals[mealInnerI].nutritionprogrammealfoods[specificMealI];
    specificele[`${field}Edit`] = true;
    specificele[`${field}Modal`] = JSON.parse(JSON.stringify(specificele[`${field}`])); 
  }

  saveSpecificMealNewValue(row:any, field: string, index: number, innerI: number, mealInnerI: number, specificMealI: number) {
    const specificEle = this.datasource[index].nutritionprogrammealplans[innerI].nutritionprogrammeals[mealInnerI];
    const url = `${config.apiUrl}/nutritionprograms/updatenutritionprogrammealfood/${specificEle.id}/${row.id}`;
    const reqObj = {
      [field]: row[`${field}Modal`]
    };
    this.httpService.putCall(url, reqObj).subscribe(result => {
      this.datasource[index].nutritionprogrammealplans[innerI].nutritionprogrammeals[mealInnerI].nutritionprogrammealfoods[specificMealI][field] = reqObj[field];
      this.cancelSpecificMealEdit(row, field, index, innerI, mealInnerI, specificMealI);
    }, err => {

    });
  }

  onMealTypeSelection(row: any, mealInnerIndex:number, innderIndex:number, index:number) {

  }

  deleteMealNutritions(index:number, innerIndex:number, mealIndex:number) {
    const mealId = this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealIndex].id;
    const url = `${config.apiUrl}/nutritionprograms/deletenutritionprogrammealfoods/${mealId}`;
    const payload = this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealIndex].nutritionprogrammealfoods.filter((obj: any) => obj.checked).map((obj: any) => obj.id);
    this.httpService.putCall(url, { food_ids: payload }).subscribe((result: any) => {
      this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealIndex].nutritionprogrammealfoods = this.datasource[index].nutritionprogrammealplans[innerIndex].nutritionprogrammeals[mealIndex].nutritionprogrammealfoods.filter((val: any) => !val.checked);
      this.masterSpecificMealSelection(index, innerIndex, mealIndex);
      this.toasterService.show('success', 'Success', 'Nutrition template deleted successfully');
      this.ui.spin$.next(false);
    }, (err: any) => {
      this.logService.logError(err);
      this.toasterService.show('error', 'Error', err?.error?.errors[0]?.msg);
      this.ui.spin$.next(false);
    });
  }

  onFoodSelection(event:any) {
    console.log(event);
  }

}
