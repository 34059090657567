<mat-card class="loginError" *ngIf="loginError.length > 0">{{loginError}}</mat-card>

<mat-card class="login-card" *ngIf="loginflow">
  <div class="header">
    <span class="spn_backspace" *ngIf="isDisplayCompaniesForm"
      (click)="onLoginBack()"
    >
      <mat-icon >keyboard_backspace</mat-icon>
    </span>
    Sign in to Elevate
  </div>
  <div class="signin-content">
    <!-- <mat-card>
          <mat-card-content> -->
    <form [formGroup]="formUsername" (ngSubmit)="onUserNameSubmit()" *ngIf="!isDisplayCompaniesForm && !isCompanyDomain">
      <p>Please login to continue</p>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="User" formControlName="userName" required>
        <mat-error *ngIf="isFieldUsernameInvalid('userName')">
          Please enter user name
        </mat-error>
      </mat-form-field>

      <mat-error *ngIf="invalidUserName">
        Invalid Username, Please check Username
      </mat-error>

      <button mat-raised-button color="primary" type="submit">Submit</button>
    </form>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="isDisplayCompaniesForm && !isCompanyDomain">
      <mat-form-field class="full-width-input">
        <input matInput placeholder="User" formControlName="userName" required
          autocomplete="off"
        >
        <mat-error *ngIf="isFieldInvalid('userName')">
          Please enter user name
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <mat-select  [formControl]="companySelected" placeholder="Select Company" [compareWith]="compareObjects"
        >
          <mat-option *ngFor="let data of companiesList" [value]="data.id" (click)="onSelectCompany(data.id)">
            {{data.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Password" formControlName="password" required>
        <mat-error *ngIf="isFieldInvalid('password')">
          Please enter password
        </mat-error>
      </mat-form-field>

      <mat-error *ngIf="invalidLogins">
        Invalid credentials, Please check logins
      </mat-error>

      <button mat-raised-button color="primary" type="submit">Login</button>
    </form>
    <form [formGroup]="formCompanyDomain" *ngIf="isCompanyDomain" (ngSubmit)="onCompanyLogin()">
      <mat-form-field class="full-width-input">
        <input matInput placeholder="User" formControlName="userName" required
          autocomplete="off"
        >
        <mat-error *ngIf="isFieldInvalid('userName')">
          Please enter user name
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Password" formControlName="password" required>
        <mat-error *ngIf="isFieldInvalid('password')">
          Please enter password
        </mat-error>
      </mat-form-field>

      <mat-error *ngIf="invalidLogins">
        Invalid credentials, Please check logins
      </mat-error>

      <button mat-raised-button color="primary" type="submit">Login</button>


    </form>



    <span class="forget-pwd" (click)="forgetPWD()">Forgot Password</span>
    <!-- </mat-card-content>
</mat-card> -->
  </div>
  <div>
    <div class="separator">or</div>
  </div>
  <div>
    <button class="social-btn btn-google" (click)="loginWithGoogle()">
      <img src="/assets/google.jpg" alt="Google" class="social-icons" />
      <span>Sign in to Google</span>
    </button>
    <button class="social-btn btn-apple" (click)="loginWithApple()">
      <img src="/assets/apple.png" alt="Apple" class="social-icons" />
      <span>Sign in to Apple</span>
    </button>
    <button class="social-btn btn-facebook" (click)="loginWithFacebook()">
      <img src="/assets/fb.ico" alt="Facebook" class="social-icons" />
      <span>Sign in to Facebook</span>
    </button>
    <!-- <mat-error *ngIf="loginError.length > 0">
          {{loginError}}
        </mat-error> -->
  </div>
  <!-- <div>
        <button mat-raised-button class="create-account"
            (click)="onCreateAccount()"
        >Create Account</button>
    </div> -->
</mat-card>
<mat-card class="login-card" *ngIf="!loginflow">
  <div class="backtogo">
    <mat-icon (click)="goBack()">arrow_right_alt</mat-icon>
    <div class="backtxt" (click)="goBack()">Back</div>
  </div>
  <div class="signin-content">
    <form [formGroup]="forgetPWDform" (ngSubmit)="onForgetSubmit()">
      <mat-form-field class="full-width-input">
        <input matInput placeholder="Email" formControlName="email" required>
        <mat-error *ngIf="isFieldInvalid('email')">
          Please inform your email
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit">Submit</button>
    </form>
  </div>
</mat-card>
