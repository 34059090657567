<div>
    <div class="top-section">
        <div class="search-container">
          <div class="input-container">
              <mat-icon matPrefix>search</mat-icon>
              <input type="text" placeholder="Search" [(ngModel)]="searchTxt" class="search-input" autocomplete="off" (keyup)="searchResult($event)" />
          </div>
          <button class="deletebtn" [ngClass]="{'highlight': deletehighlight}" [disabled]="!deletehighlight" (click)="deleteExercises()">Delete Selected</button>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="datasource" multiTemplateDataRows>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-checkbox class="checkbx" (change)="setSelection($event)" [checked]="isAllSelected"></mat-checkbox> 
              <span class="disp-name">Name</span>
            </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"
              class="name_field"
            > 
              <mat-checkbox class="checkbx" (click)="$event.stopPropagation()" (change)="setSelection($event, element)" [checked]="element.checked"></mat-checkbox>
              <div class="expand-icon">
                <span class="icon" (click)="element.isExpanded = !element.isExpanded">
                  <mat-icon *ngIf="!element.isExpanded">keyboard_arrow_right</mat-icon>
                  <mat-icon *ngIf="element.isExpanded">keyboard_arrow_down</mat-icon>
                </span>
                <span class="food-name">
                  <span class="disp-name" (click)="enableEdit(element, 'name', i)" *ngIf="!element.nameEdit">{{element.name}} </span>
                  <span *ngIf="element.nameEdit" class="edit-column" >
                    <input [(ngModel)]="element.name" [placeholder]="element.name" class="edit-field" />
                    <button class="savebtn" (click)="saveNewValue(element, 'name', i)">Save</button>
                    <button class="cancelbtn" (click)="cancelEdit(element, 'name', i)">Cancel</button>
                  </span>
                </span>
              </div>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef><span class="disp-name"> Description </span></th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"> 
              <span class="{{element.description ? 'disp-name' : 'disp-name error'}}" (click)="enableEdit(element, 'description', i)" *ngIf="!element.descriptionEdit">{{element.description ? element.description : 'Empty'}}</span>
              <span *ngIf="element.descriptionEdit" class="edit-column" >
                <input [(ngModel)]="element.description" [placeholder]="element.description || ''" class="edit-field" />
                <button class="savebtn" (click)="saveNewValue(element, 'description', i)">Save</button>
                <button class="cancelbtn" (click)="cancelEdit(element, 'description', i)">Cancel</button>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef class="width_20"><span class="disp-name"> Note </span></th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"> 
              <span class="{{element.note ? 'disp-name' : 'disp-name error'}}" (click)="enableEdit(element, 'note', i)" *ngIf="!element.noteEdit">{{element.note ? element.note : 'Empty'}} </span>
              <span *ngIf="element.noteEdit" class="edit-column" >
                <input [(ngModel)]="element.note" class="edit-field" />
                <button class="savebtn" (click)="saveNewValue(element, 'note', i)">Save</button>
                <button class="cancelbtn" (click)="cancelEdit(element, 'note', i)">Cancel</button>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef><span class="disp-name"> Category </span></th>
            <td mat-cell *matCellDef="let element;let i = dataIndex"> 
              <span class="disp-name" (click)="enableEdit(element, 'category', i)" *ngIf="!element.categoryEdit">{{element.exercisetemplatecategory.name}} </span>
              <span *ngIf="element.categoryEdit" class="edit-column" style="margin-top: 4%;" >
                <!-- <input [(ngModel)]="element.categoryModel" class="edit-field" /> -->
                <div class="select-ddl">
                <mat-form-field class="width-full">
                  <mat-label>Select Category</mat-label>
                <mat-select [(ngModel)]="element.exercisetemplatecategory_id">
                  <mat-option *ngFor="let data of exercisesCategories"  [value]="data.id">{{data.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              </div>
                <button class="savebtn margin_0" (click)="saveNewValue(element, 'category', i)">Save</button>
                <button class="cancelbtn margin_0" (click)="cancelEdit(element, 'category', i)">Cancel</button>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="video_url">
            <th mat-header-cell *matHeaderCellDef><span class="disp-name"> Video URL </span></th>
            <td mat-cell *matCellDef="let element; let i = dataIndex"> 
              <span class="disp-name" (click)="enableEdit(element, 'video_url', i)" *ngIf="!element.video_urlEdit">{{element.video_url}} </span>
              <span *ngIf="element.video_urlEdit" class="edit-column" >
                <input [(ngModel)]="element.video_url" class="edit-field" />
                <button class="savebtn" (click)="saveNewValue(element, 'video_url', i)">Save</button>
                <button class="cancelbtn" (click)="cancelEdit(element, 'video_url', i)">Cancel</button>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="columnsList.length" >
              <div class="expanded-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                <div class="details">
                  <input #imageInput hidden="true" type="file" name="photo" onclick="this.value=null" (change)="onUploadImageSequence($event, i)" accept="image/*"/>
                  <span class="link_upload" (click)="imageInput.click()">Upload Image Sequence</span>
                  <div *ngIf="element && element.exercisetemplateimages">
                    <div *ngFor="let specimage of element.exercisetemplateimages"
                        class="exerciseImageBlock"
                    >
                    <div class="img-wrap">
                      <span class="close" (click)="deleteExerciseTemplateImage(element.id, specimage.id, i)">&times;</span>
                      <img [src]="imageCustomUrl+specimage.image" class="exercise-image" />
                    </div>               
                        
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsList;sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsList;" class="element-row" [class.expanded-row]="element.isExpanded"></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
        <mat-paginator
        [length]="pagination.count"
        [pageIndex]="pagination.pageIndex"
        [pageSize]="pagination.perPage"
        [pageSizeOptions]="[10, 25, 50, 100, 200]"
        (page)="getServerData($event)"
  showFirstLastButtons></mat-paginator>
    </div>
</div>