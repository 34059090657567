import { Injectable } from '@angular/core';
import { SwPush } from "@angular/service-worker";
import { config } from "../../config";
import { HttpService } from '../http/http.service';
import { LogService } from '../log/log.service';

@Injectable({
  providedIn: 'root'
})
export class WebpushService {
  VAPID_PUBLIC_KEY:string = config.vapidPublicKey;

  constructor(private swPush: SwPush, private http: HttpService, private logService: LogService) { }

  createPushNotification(payload:any) {
    const obj = {
      "subscription": payload
    };
    this.http.postCall(`${config.apiUrl}/webnotification/create`, obj).subscribe( result => {
      console.log(result);
    }, err => {
      this.logService.logError(err);    
    });
  }

  registerPushNotification() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
  })
  .then(sub => {
      console.log("Notification Subscription: ");
      console.log(JSON.stringify(sub));
      this.createPushNotification(JSON.stringify(sub));
  })
  .catch(err => console.error("Could not subscribe to notifications", err));
  }
}
